import React, {useContext, useEffect, useState} from 'react'
import mission from "../../assets/img/alger3.jpg"
import { Container,Card,Row,Col } from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css"
import CoverImg from 'components/General/CoverImg'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'
import { fetchAPIMissions } from 'services/institut-national'
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser'
import { getAsset } from 'services/assets'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList,ShimmerSectionHeader,ShimmerFeaturedGallery } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'

export default function MissionOne({ville}) {
    const {slug,title} = useParams();
    const [data, setData] = React.useState(); 
    const [loading, setLoading] = React.useState(true); 
    const { language } = useContext(LanguageContext);
    useEffect(()=>{
        window.scrollTo({top:0})
        fetchAPIMissions({region:ville}).then((x)=>{
            console.log(x)
            var z=x.items[0].data['Missions']?.[language]?.filter(x=>x.slug===slug || x.Title===slug);
            console.log(slug)
            console.log(title)
            //console.log(z)
            setData(z[0])
            setLoading(false)
          });
      },[])
      const [banner, setBanner] = useState();
useEffect( () => {
  if(data?.Image)
  getAsset(data?.Image).then((srcImage)=>{
    setBanner(srcImage);
  });
  
}, [data?.Image]);
      try{var text = parse(data?.description)}catch($){
        text = data?.description
      }

  return loading? (<><ShimmerSectionHeader /><ShimmerPostList postStyle="STYLE_FOUR" col={3} row={1} gap={30} /></>) :(
    <>
        <CoverImg img={banner??mission}/>
        <Breadcrumb/>
        <Container className='lh-lg mb-5' dir={language==='ar'?"rtl":""}>
            <MainTitle>{data?.Title??"Mission de coopération éducative et linguistique"}</MainTitle>
            {data?.description ? <div className='intro text-black-50' >
            {text}
            </div> :
                <>
            <div className='intro text-black-50' >
                
                <p className='mt-5 lh-lg text-start'>
                    L'Algérie est le troisième pays francophone dans le monde avec plus de 11
                    millions de locuteurs. Dans ce contexte linguistique, la mission principale du pôle
                    éducatif est de diffuser des outils permettant de mêler langue et culture française. Pour
                    ce faire, le pôle éducatif s'appuie sur plusieurs outils:
                </p>

                <Row >
                    <Col md={6} className="mb-3 mb-md-0">
                        <Card className='p-4 mb-3 h-100 text-start'>
                            <h5 className='fw-bold blue-ifa'>La plate-forme IF-cinéma </h5>
                            <p>qui permet de visionner des films français soit chez
                                soi, soit dans les différentes antennes de l’institut français d’Algérie lors des projections
                                organisées pour les groupes scolaires.
                            </p>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-3 mb-md-0">
                        <Card className='p-4 mb-3 h-100 text-start'>
                            <h5 className='fw-bold blue-ifa'>Une bibliotèque en ligne accessible grâce à la plate-forme Culturethèque.</h5>
                            <p>Les lecteurs peuvent ainsi demander à faire venir un livre, un magazine ou un journal dans la médiathèque la plus proche de chez eux.</p>
                        </Card>
                    </Col> 
                </Row>

            </div>

            <div style={{textAlign:"left",marginTop:"3rem"}} className='intro text-black-50'>
                <p>
                La coopération éducative englobe aussi le suivi des écoles françaises qui assurent un enseignement dans les deux langues :
                </p>
                <Row >
                    <Col md={6} className="mb-3 mb-md-0">
                        <Card className='p-4 mb-3 h-100'>
                        <h5 className='fw-bold blue-ifa'>Niveaux collège et lycée </h5>
                        <p>le <b>Lycée International Alexandre Dumas (LIAD)</b> et ses antennes à Oran et Annaba. Il existe une filière d'excellence OIB de langue arabe dès le collège au LIAD.</p>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-3 mb-md-0">
                        <Card className='p-4 mb-3 h-100'>
                        <h5 className='fw-bold blue-ifa'>Niveaux école maternelle et primaire </h5>
                        <p><b> La Petite école d'Hydra.</b></p>
                        </Card>
                    </Col> 
                </Row>
            </div>

            <div style={{textAlign:"left",marginTop:"3rem"}} className='intro text-black-50'>
                <p >
                    Sur le plan linguistique, les départements de langue française au sein des différentes ennes de l’ Institut Français d’Algérie permettent d'apprendre le français et de se préparer aux diplômes et examens de langue française.
                </p>
                <Row >
                    <Col md={6} className="mb-3 mb-md-0">
                        <Card className='p-4 mb-3 h-100'>
                            <h5 className='fw-bold blue-ifa'>L'apprentissage du français</h5>
                            <p>
                                Les départements de langue française proposent
                                des programmes de formation innovants pour apprendre, se perfectionner, se
                                spécialiser en français dans les domaines universitaire et professionnel. Ces
                                programmes détiennent le label qualité « français langue étrangère » (FLE) et
                                répondent aux critères du Cadre européen commun de référence pour les langues.
                            </p>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-3 mb-md-0">
                        <Card className='p-4 mb-3 h-100'>
                            <h5 className='fw-bold blue-ifa'>Les certifications en langue françaises</h5>
                            <p>
                                Une équipe pédagogique qualifiée et
                                habilitée assure la préparation aux diploˆmes de langue fran¸caise reconnus par
                                les minist`eres fran¸cais de l’E´
                                ducation nationale et de l’Enseignement supérieur
                            </p>
                        </Card>
                    </Col>
                    
                </Row>

            </div>
            </>}
        </Container>
       
    </>
  )
}
