import React, { useContext, useEffect, useState } from 'react'
import { Container} from "react-bootstrap";
import Bandeau from 'components/Etudes/Bandeau';
import Breadcrumb from 'components/breadcrumb/breadcrumb';
import MainTitle from 'components/General/MainTitle';
import BgShape from 'components/General/BgShape';
import { useParams } from 'react-router-dom';
import samples from 'data/SamplePages';
import CoverImg from 'components/General/CoverImg';
import { fetchAPIEspaces } from 'services/institut-national'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import { Col,Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from "./institut/institut.module.css"
import CityMenuCard from 'components/Cities/CityMenuCard'
import { ShimmerTitle,ShimmerSimpleGallery } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext';

function EspacesPage({region='national', title=null}) {
    const {action_url,title_url} = useParams()
    const selectedAction = samples[0]
    const [institut, setInstitut] = React.useState();
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const { language } = useContext(LanguageContext);

    React.useEffect( () => {
      
      function fetchData() {
setLoading(true)
        fetchAPIEspaces({region:region}).then((items)=>{
          //const res = await fetchAPIInstitutDetails(results.posts[0].id)
          var el=items.items[0]?.data;
          //console.log(el);
          
            setInstitut(el);
            var d=[]

            if(el?.Espaces[language??'fr'].length>0){
              el?.Espaces[language??'fr'].map(e => {
                //console.log(e)
                  var img = e?.Image?.iv? e?.Image?.iv[0]: e?.Image?.[language]? e?.Image?.[language][0] : e.Image? e.Image[0]:"";
                  d.push({title:e.Titre,img:undefined,url:`${e?.Titre??"#"}/${e?.slug??e?.Titre}`,description:e.Description,src:img })
                            
                  /*getAsset(img).then((srcImage)=>{
                    e.srcImage=srcImage;
                    e.id=el.id;
        
                            d.push({title:e.Titre,img:srcImage,url:`${e?.Titre??"#"}/${e?.slug??e?.Titre}`,description:e.Description,src:img })
                            setData(d)
                        });*/
                        
                        
                    });
                    setData(d)
                    setLoading(false)
                    //setData(d)
                    }  
        });
      }
        
      fetchData();
  }, []);


    try{var text = parse(institut?.Description?.iv??institut?.Description[language??'fr'])}catch($){
      text = institut?.Description?.[language??'fr']
    }
    try{var citation = parse(institut?.Citation?.iv??institut?.Citation[language??'fr'])}catch($){
      citation = institut?.Citation?.[language??'fr']
    }
    
    //console.log(data)
    //console.log(region)
    function showSpaces(x){
      return (
      x?.map((el,index)=>
        (
               <Col  md={5} >
                   <Link to={el?.url??"#"}>
                       <CityMenuCard  menu={el} />
                   </Link>
               </Col>
               )
   ))
    }
    const [banner, setBanner] = useState();
useEffect( () => {
  if(institut?.banner?.[language])
  getAsset(institut?.banner?.[language]).then((srcImage)=>{
    setBanner(srcImage);
  });
  
}, []);
  return loading? <ShimmerSimpleGallery card imageHeight={300} caption /> :(
    <div className=" position-relative overflow-hidden" dir={language==='ar'?"rtl":""}>
            {/*<BgShape type="yellow"  right="-50px" top="400px" rotate="-150deg"/>
            <BgShape type="blue"  left="-80px" top="900px" rotate="140deg"/>
  <BgShape type="red"  right="-60px" top="1300px" rotate="-140deg"/>*/}
            <CoverImg img={banner??institut?.srcImage??selectedAction.img}/>
            <Breadcrumb/>
            <Container >
              <MainTitle className="my-4">{title_url??title??selectedAction.title}</MainTitle>
              {text??
              <p className='text-start lh-lg text-black-50  mb-5'>
              Nous vous accueillons dans nos cinq antennes dans des espaces chaleureux, pour enfants et pour adultes.
              </p>}
            </Container>

              <Bandeau link={institut?.lienCitation?.iv??'#'} style={{margin:"5rem 0"}}>
                  {citation??<p className='fs-5 my-0'>Découvrez nos espaces ci-dessous :</p>}
              </Bandeau>
              
              <div >
            <Row className={`${styles} justify-content-center gap-3  py-3`} center>
                
                {
                  showSpaces(data)
              }
            </Row>
        </div>
    </div>
    
  )
}

export default EspacesPage