import {login} from "./auth"
import { baseUrl } from "./consts"
import { coursRoutes } from "./apiRoutes"

export async function getCredit() {
    const response=await login();
if (response.ok) {
    var data = await response.json();
    //console.log(data)
    const res = await fetch(`${baseUrl}${"api/content/ifa/credit"}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+data['access_token']
      },
      
    });

    const json = await res.json();
  
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    //console.log(json);
    return json;
}
}
export async function getLiensUtiles() {
    const response=await login();
if (response.ok) {
    var data = await response.json();
    //console.log(data)
    const res = await fetch(`${baseUrl}${"api/content/ifa/liens-utiles"}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+data['access_token']
      },
      
    });

    const json = await res.json();
  
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    //console.log(json);
    return json;
}
}
export async function getMentionsLegales() {
    const response=await login();
if (response.ok) {
    var data = await response.json();
    //console.log(data)
    const res = await fetch(`${baseUrl}${"api/content/ifa/mentions-legales"}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+data['access_token']
      },
      
    });

    const json = await res.json();
  
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    //console.log(json);
    return json;
}
}