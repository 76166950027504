import { useContext, useEffect, useReducer } from 'react';
import { RiArrowRightSFill } from "react-icons/ri";
import EtudesMobileDropdown from './EtudesMobileDropdown';
import { dropdownContext } from './MobileMenuItem';
const initialState = {
    franceDpd: false,
    algDdp: false
}
const EtudesMobileMenu = ({item,style}) => {
  const {dropdown,setDropdown} = useContext(dropdownContext)
    const handleClick = (type)=>{
        if (type === "En France") {
            dispatch({type:"france"})
        } else if (type === "En Algérie") {
            dispatch({type:"alg"})
        }else{
            dispatch({type:type})
        }
    }
    const reducer = (state,action)=>{
        const {type,payload} = action;
        switch (type) {
            case "france" :
                return {
                    ...state,
                    franceDpd:!state.franceDpd,
                }
            case "alg" :
                return {
                    ...state,
                    algDdp:!state.algDdp,
                }
            case "all" :
              return {
                franceDpd:false,
                algDdp:false
            }
        
            default:
                break;
        }
    }
    const [state,dispatch] = useReducer(reducer, initialState)

    // when the big dropdown is closed => the etudeDropdown close too
    useEffect(()=>{
      if (!dropdown) {
        dispatch({type:"all"})
      }
    },[dropdown])
  return (
    <>

      {dropdown ? 
      Object.entries(item.submenu).map((el, index) => {
        return (
          <div key={index}>
            <button className="nav-link ps-3 d-flex justify-content-between align-items-center gap-2" style={style} onClick={()=>handleClick(el[0])}>
              {el[0]}
              {<RiArrowRightSFill />}
            </button>
            <EtudesMobileDropdown className="ps-5" etudesDropdown={el[0]==="En France"?state?.franceDpd:state?.algDdp} submenu={el?.[1]} />
          </div>
        );
      })
       :null}
      
    </>
  );
}

export default EtudesMobileMenu