import React from 'react'
import { Link } from 'react-router-dom'
import DropdownCard from './DropdownCard'
import styles from "./menuItemsList.module.css"


const DropDownGrid = ({subsubmenu}) => {
  return (
    <div className={styles["dropdown-etudes-grid"]}>
        <h6 className='fw-bold '><Link to={subsubmenu.url} className=' mb-3'> En {subsubmenu.title}</Link></h6>
        {
             subsubmenu.submenu.map((el,index)=>{
                 return <DropdownCard key={index} url={el.url} title={el.title} img={el.img}/>
             })
         }
    </div>
  )
}

export default DropDownGrid