import {fetchAPIMissionsRegion,fetchAPIEspacesRegion} from "./institut-region"
import {login} from "./auth"

export  async function fetchAPIInstitut() {
    const response=await fetch("https://altadmin.if-algerie.com/identity-server/connect/token",{
      contentType: "application/x-www-form-urlencoded",
      body: new URLSearchParams({
          grant_type: "client_credentials",
          client_id: "ifa:default",
          client_secret: "iwruhktybm9nci4efz2okuwibldlottkxgztjgfgbnux",
          scope: "squidex-api",
      }),
      method: "POST",
  });
  if (response.ok) {
      var data = await response.json();
      //console.log(data)
      const res = await fetch("https://altadmin.if-algerie.com/api/content/ifa/institut-presentation", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization":"Bearer "+data['access_token']
        },
        
      });
  
      const json = await res.json();
    
      if (json.errors) {
        console.error(json.errors);
        throw new Error("Failed to fetch API");
      }
      //console.log(json);
      return json;
    }
    }
  
    export  async function fetchAPIInstitutDetails($id) {
      const response=await fetch("https://altadmin.if-algerie.com/identity-server/connect/token",{
        contentType: "application/x-www-form-urlencoded",
        body: new URLSearchParams({
            grant_type: "client_credentials",
            client_id: "ifa:default",
            client_secret: "iwruhktybm9nci4efz2okuwibldlottkxgztjgfgbnux",
            scope: "squidex-api",
        }),
        method: "POST",
    });
    if (response.ok) {
        var data = await response.json();
        //console.log(data)
        const res = await fetch("https://altadmin.if-algerie.com/api/content/ifa/institut-presentation/"+$id, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization":"Bearer "+data['access_token']
          },
          
        });
    
        const json = await res.json();
      
        if (json.errors) {
          console.error(json.errors);
          throw new Error("Failed to fetch API");
        }
        //console.log(json);
        return json;
      }
      }

      export  async function fetchAPIMissions({region='national'}) {
        if(region!=="national")
        return await fetchAPIMissionsRegion(region)
        else{
        const response=await login();
      if (response.ok) {
          var data = await response.json();
          //console.log(data)
          const res = await fetch("https://altadmin.if-algerie.com/api/content/ifa/nos-missions", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "Authorization":"Bearer "+data['access_token']
            },
            
          });
      
          const json = await res.json();
        
          if (json.errors) {
            console.error(json.errors);
            throw new Error("Failed to fetch API");
          }
          //console.log(json);
          return json;
        }}
        }

        export  async function fetchAPIRecrutement() {
          const response=await fetch("https://altadmin.if-algerie.com/identity-server/connect/token",{
            contentType: "application/x-www-form-urlencoded",
            body: new URLSearchParams({
                grant_type: "client_credentials",
                client_id: "ifa:default",
                client_secret: "iwruhktybm9nci4efz2okuwibldlottkxgztjgfgbnux",
                scope: "squidex-api",
            }),
            method: "POST",
        });
        if (response.ok) {
            var data = await response.json();
            //console.log(data)
            const res = await fetch("https://altadmin.if-algerie.com/api/content/ifa/recrutement", {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "Authorization":"Bearer "+data['access_token']
              },
              
            });
        
            const json = await res.json();
          
            if (json.errors) {
              console.error(json.errors);
              throw new Error("Failed to fetch API");
            }
            //console.log(json);
            return json;
          }
          }
          export  async function fetchAPIEspaces({region='national'}) {
            if(region!=="national")
        return await fetchAPIEspacesRegion(region)
        else{
            const response=await login();
          if (response.ok) {
              var data = await response.json();
              //console.log(data)
              const res = await fetch("https://altadmin.if-algerie.com/api/content/ifa/nos-espaces", {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  "Authorization":"Bearer "+data['access_token']
                },
                
              });
          
              const json = await res.json();
            
              if (json.errors) {
                console.error(json.errors);
                throw new Error("Failed to fetch API");
              }
              //console.log(json);
              return json;
            }}
            }
            export  async function fetchAPIProjects() {
              const response=await fetch("https://altadmin.if-algerie.com/identity-server/connect/token",{
                contentType: "application/x-www-form-urlencoded",
                body: new URLSearchParams({
                    grant_type: "client_credentials",
                    client_id: "ifa:default",
                    client_secret: "iwruhktybm9nci4efz2okuwibldlottkxgztjgfgbnux",
                    scope: "squidex-api",
                }),
                method: "POST",
            });
            if (response.ok) {
                var data = await response.json();
                //console.log(data)
                const res = await fetch("https://altadmin.if-algerie.com/api/content/ifa/appels-projets", {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    "Authorization":"Bearer "+data['access_token']
                  },
                  
                });
            
                const json = await res.json();
              
                if (json.errors) {
                  console.error(json.errors);
                  throw new Error("Failed to fetch API");
                }
                //console.log(json);
                return json;
              }
              }
              export  async function fetchAPIBourses() {
                const response=await fetch("https://altadmin.if-algerie.com/identity-server/connect/token",{
                  contentType: "application/x-www-form-urlencoded",
                  body: new URLSearchParams({
                      grant_type: "client_credentials",
                      client_id: "ifa:default",
                      client_secret: "iwruhktybm9nci4efz2okuwibldlottkxgztjgfgbnux",
                      scope: "squidex-api",
                  }),
                  method: "POST",
              });
              if (response.ok) {
                  var data = await response.json();
                  //console.log(data)
                  const res = await fetch("https://altadmin.if-algerie.com/api/content/ifa/bourses-condidatures", {
                    method: "GET",
                    headers: {
                      "Content-Type": "application/json",
                      "Authorization":"Bearer "+data['access_token']
                    },
                    
                  });
              
                  const json = await res.json();
                
                  if (json.errors) {
                    console.error(json.errors);
                    throw new Error("Failed to fetch API");
                  }
                  //console.log(json);
                  return json;
                }
                }

                export  async function fetchAPIActionsCulturelles() {
                  const response=await fetch("https://altadmin.if-algerie.com/identity-server/connect/token",{
                    contentType: "application/x-www-form-urlencoded",
                    body: new URLSearchParams({
                        grant_type: "client_credentials",
                        client_id: "ifa:default",
                        client_secret: "iwruhktybm9nci4efz2okuwibldlottkxgztjgfgbnux",
                        scope: "squidex-api",
                    }),
                    method: "POST",
                });
                if (response.ok) {
                    var data = await response.json();
                    //console.log(data)
                    const res = await fetch("https://altadmin.if-algerie.com/api/content/ifa/actions-culturelles", {
                      method: "GET",
                      headers: {
                        "Content-Type": "application/json",
                        "Authorization":"Bearer "+data['access_token']
                      },
                      
                    });
                
                    const json = await res.json();
                  
                    if (json.errors) {
                      console.error(json.errors);
                      throw new Error("Failed to fetch API");
                    }
                    //console.log(json);
                    return json;
                  }
                  }