import React from 'react'
import { TiMediaPlay } from "react-icons/ti";

function MissionLink({children}) {
  return (
    <a href="#" className="d-block text-primary fw-bold text-underlined">
        <TiMediaPlay />
        <u>{children}</u>
    </a>
  )
}

export default MissionLink