import React from 'react'

function CoverImg({img}) {
  return (
    <div className="bg-dark text-white rounded-0 bg-image mt-3" style={{
        height: "15em",
        backgroundImage:`url(${img})`,
        backgroundSize:"Cover",
        backgroundPosition:"Center",
        marginTop:"4rem",
        }}>
    </div>
  )
}

export default CoverImg