import React from 'react'
import { Card } from 'react-bootstrap';
import {FaPhoneSquareAlt}from 'react-icons/fa';
import {ImLocation2} from 'react-icons/im';
import { toCapitalize } from 'helpers/Global/toCapitalize';
import info from "../../data/etudes/campus-france"

function CampusCard({city}) {
 

const selectedRegion = info.find(el=>el.name === city)
  return (
    <Card className=' text-start w-content-fit rounded-0'>
      <img src ={selectedRegion.image} alt={`Campus France ${toCapitalize(selectedRegion?.name)}`}/>
      <Card.Body>
        <h4 className='blue-ifa fw-bold'>{toCapitalize(selectedRegion?.name)}</h4>
        <span> <ImLocation2 className='me-2 blue-ifa'/>{selectedRegion.address}</span>
        <span className='d-block '> <FaPhoneSquareAlt className='me-2 blue-ifa'/>{selectedRegion.phone}</span>
      </Card.Body>
    </Card>
  )
}

export default CampusCard