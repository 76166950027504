import alger from "../../assets/img/algermediatheque.jpg"
import annaba from "../../assets/img/annabamediatheque.jpg"
import oran from "../../assets/img/oranmediatheque.jpg"
import constantine from "../../assets/img/constantinemediatheque.jpg"
import tlemcen from "../../assets/img/tlemcenmediatheque.jpg"
import culturetheque from "../../assets/img/cultureteque.png"

const villes = [
    /*{
        id:1,
        name:"",
        img:culturetheque,
        url:"https://www.culturetheque.com/DZA/"
    },*/
    {
        id:16,
        name:"alger",
        img:alger,
        url:"/alger/mediatheque"
    },
    {
        id:23,
        name:"annaba",
        img:annaba,
        url:"/annaba/mediatheque"
    },
    {
        id:25,
        name:"constantine",
        img:constantine,
        url:"/constantine/mediatheque"
    },
    {
        id:31,
        name:"oran",
        img:oran,
        url:"/oran/mediatheque"
    },
    {
        id:13,
        name:"tlemcen",
        img:tlemcen,
        url:"/tlemcen/mediatheque"
    },
     
]

export {villes}