import React, {useEffect} from 'react'
import mission from "../../assets/img/alger3.jpg"
import { Container} from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css"
import CoverImg from 'components/General/CoverImg'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'



export default function MissionFour() {
  useEffect(()=>{
    window.scrollTo({top:0})
  },[])
  return (
    <>
      <CoverImg img={mission} />
      <Breadcrumb />
      <Container className="lh-lg mb-5">
        <MainTitle> Mission de coopération universitaire</MainTitle>
        <div className="intro text-black-50">
          <p className="mt-5 lh-lg text-start">
            A travers la mission de coopération universitaire, la France promeut
            la coopération algéro-française dans les domaines de l’éducation,
            de la formation professionnelle, de l’enseignement supérieur et de
            la recherche scientifique. Cette coopération est encadrée par la
            Convention de Partenariat entre la France et l’Algérie signée en
            2007..
          </p>
          <p className="lh-lg text-start">
            Cette coopération s’axe autour de trois grands programmes :
            <ul>
                <li>
                    <b>Le partenariats Hubert Curien (PHC)</b> qui favorisent la
                    mobilité des jeunes chercheurs et les partenariats.
                </li>
                <li>
                    <b>Un programme pour les projets innovants dans le domaine de
                    l’Intelligence Sociale et du Numérique</b> qui promeut des
                    projets centrés sur les nouvelles technologies dans les
                    écoles d’ingénieurs (Mise en place de six FabLab dans des
                    écoles d’ingénieurs algériennes; création d’une Chaire «
                    ville intelligente »; lancement d’un concours « FabLab
                    Challenge »).
                </li>
            </ul>
          </p> 
        </div>
      </Container>
    </>
  );
}
