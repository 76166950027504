export async function getPost(id) {
  const response=await fetch("https://altadmin.if-algerie.com/identity-server/connect/token",{
    contentType: "application/x-www-form-urlencoded",
    body: new URLSearchParams({
        grant_type: "client_credentials",
        client_id: "ifa:default",
        client_secret: "iwruhktybm9nci4efz2okuwibldlottkxgztjgfgbnux",
        scope: "squidex-api",
    }),
    method: "POST",
});
if (response.ok) {
    var data = await response.json();
    //console.log(data)
    const res = await fetch("https://altadmin.if-algerie.com/api/content/ifa/posts/"+id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+data['access_token']
      },
      
    });

    const json = await res.json();
  
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    //console.log(json);
    return json;
}
}
export async function getPage(slug) {
    /*const Client = new SquidexClient({
        appName: 'sample-blog',
        clientId: 'sample-blog:blog',
        clientSecret: 'ZxmQGgouOUmyVU4fh38QOCqKja3IH1vPu1dUx40KDec=',
        environment: 'https://cloud.squidex.io'
    });
    const { items } = await Client.contents.getContents('pages', { filter: `data/slug/iv eq '${slug}'` });

    if (items.length === 0) {
        return null;
    }

    return parsePage(items[0]);*/
}

export async function getPosts() {
    const response=await fetch("https://altadmin.if-algerie.com/identity-server/connect/token",{
    contentType: "application/x-www-form-urlencoded",
    body: new URLSearchParams({
        grant_type: "client_credentials",
        client_id: "ifa:default",
        client_secret: "iwruhktybm9nci4efz2okuwibldlottkxgztjgfgbnux",
        scope: "squidex-api",
    }),
    method: "POST",
});
if (response.ok) {
    var data = await response.json();
    //console.log(data)
    const res = await fetch("https://altadmin.if-algerie.com/api/content/ifa/posts", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+data['access_token']
      },
      
    });

    const json = await res.json();
  
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    //console.log(json);
    return json;
  }
}

export async function getPages() {
    const response=await fetch("https://altadmin.if-algerie.com/identity-server/connect/token",{
    contentType: "application/x-www-form-urlencoded",
    body: new URLSearchParams({
        grant_type: "client_credentials",
        client_id: "ifa:default",
        client_secret: "iwruhktybm9nci4efz2okuwibldlottkxgztjgfgbnux",
        scope: "squidex-api",
    }),
    method: "POST",
});
if (response.ok) {
    var data = await response.json();
    //console.log(data)
    const res = await fetch("https://altadmin.if-algerie.com/api/content/ifa/pages", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+data['access_token']
      },
      
    });

    const json = await res.json();
  
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    //console.log(json);
    return json;
  }
}

function parsePost(response) {
    return {
        id: response.id,
        title: response.data.title.iv,
        text: response.data.text.iv,
        slug: response.data.slug.iv
    };
}

function parsePage(response) {
    return {
        id: response.id,
        title: response.data.title.iv,
        text: response.data.text.iv,
        slug: response.data.slug.iv
    };
}