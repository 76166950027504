import React from 'react'
import styles from "./tests.module.css"
import parse from 'html-react-parser'

function CalendrierTestCard({data}) {
    try{
        var text = parse(data)
      }catch($){
        text = data
      }
  return (
      <div className="bg-light p-3">
          <h5 className="text-start blue-ifa fw-bold p-0">Calendrier</h5>
          <div className={styles.calendrier}>
            {text??
              <><div className='d-lg-block d-flex flex-wrap align-items-center justify-content-between my-1'>
                  <span className="fw-bold mb-0 ">Date des inscriptions en ligne</span>
                  <span className=''> à partir du 11 août 2022 </span>
              </div>
              <div className='d-lg-block d-flex flex-wrap align-items-center justify-content-between my-1' >
                  <span className="fw-bold mb-0 ">Dates des épreuves </span>
                  <span className=''>du 22 août 2022 à juillet 2023 </span>
              </div></>}
          </div>
      </div>
  )
}

export default CalendrierTestCard