export const TCF_tests = [
    {
        id:1,
        name:"TCF DAP",
        intro:"Le TCF dans le cadre de la demande d'admission préalable (DAP) est destiné à toute personne étrangère de plus de 16 ans souhaitant intégrer un licence 1 ou une école d'architecture au sein d'un établissement d'enseignement supérieur en France. Il évalue le niveau de langue (de A1 à C1).",
        prix:"-"
      },
      {
        id:2,
        name:"TCF TP tout public",
        intro:"L'objectif principal du TCF tout public est de permettre aux candidats de faire recon- naitre leur niveau de français dans le cadre de poursuite d'études en France et dans les pays francophones",
        prix:"-"
      
      },
      {
        id:3,
        name:"TCF Canada",
        intro:"L'objectif principal du TCF Canada est de permettre aux candidats de faire reconnaitre leur niveau de français dans le cadre d'une demande d'immigration ou de citoyenneté au Canada",
        prix:"-"
      }
]