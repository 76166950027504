import React, { Component, lazy } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IconCalendarEvent } from "bootstrap-icons/icons/calendar-event.svg";
import { ReactComponent as IconHandThumbsUp } from "bootstrap-icons/icons/hand-thumbs-up.svg";
import { ReactComponent as IconChatText } from "bootstrap-icons/icons/chat-text.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
// const Widgets = lazy(() => import("../../components/blog/Widgets"));
import img1 from "../../assets/img/blog/nature-1.webp";
import img2 from "../../assets/img/blog/nature-p-1.webp";
import img3 from "../../assets/img/blog/nature-2.webp";
import MainHeader from "../header/MainHeader";
import Footer from "../footer/Footer";
class BlogDetailView extends Component {
  constructor(props) {
    super();
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <MainHeader />
        <div className="card bg-dark text-white rounded-0 mb-3">
          <img src={img1} className="card-img rounded-0" alt="..." />
          <div className="card-img-overlay text-center">
            <h1 className="card-title position-absolute bottom-0 mb-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              posuere erat a ante.
            </h1>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="display-6 border-bottom pb-3 mb-1">Blog</div>
              <p className="text-muted small">
                <IconCalendarEvent className="i-va mr-1" /> Jan 1, 2021{" "}
                <i>Updated on Jan 02, 2020</i> by{" "}
                <Link to="/" className="mr-2">
                  Admin
                </Link>{" "}
                <span className="text-success mr-2">
                  <IconHandThumbsUp className="i-va mr-1" />
                  21k
                </span>
                <span className="text-primary">
                  <IconChatText className="i-va" /> 22k
                </span>
              </p>

              <div className="mb-3">
                <Link
                  to="/"
                  className="badge text-decoration-none bg-warning mr-2"
                >
                  Lorem
                </Link>
                <Link
                  to="/"
                  className="badge text-decoration-none bg-danger mr-2"
                >
                  Ipssum
                </Link>
                <Link
                  to="/"
                  className="badge text-decoration-none bg-dark mr-2"
                >
                  Lorem
                </Link>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                posuere erat a ante.
              </p>
              <hr />
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut
                assumenda eveniet officia minima reiciendis unde eum, nihil quia
                voluptatibus! Nisi, magnam vero odit aperiam ullam sequi aliquid
                eveniet quod dolor?
              </p>
              <blockquote className="blockquote bg-secondary p-3 text-white">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Integer posuere erat a ante.
                </p>
              </blockquote>

              <div className="row"></div>

              <p>
                Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae
                elit libero, a pharetra augue. For example,{" "}
                <code>&lt;section&gt;</code> should be wrapped as inline.
              </p>
              <ol>
                <li className="font-weight-bold">
                  Vestibulum id ligula porta felis euismod semper.
                </li>
                <li className="font-weight-bolder">
                  Cum sociis natoque penatibus et magnis dis parturient montes,
                  nascetur ridiculus mus.
                </li>
                <li className="font-weight-normal">
                  Maecenas sed diam eget risus varius blandit sit amet non
                  magna.
                </li>
                <li className="font-weight-light">
                  Maecenas sed diam eget risus.
                </li>
                <li className="font-weight-lighter">
                  texte (Maecenas sed diam eget risus).
                </li>
              </ol>
              <img src={img3} className="assets/img-fluid" alt="..." />
              <figure className="text-right">
                <blockquote className="blockquote">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Integer posuere erat a ante.
                  </p>
                </blockquote>
                <figcaption className="blockquote-footer">
                  Eget risus <cite title="Source Title">Dolor sit amet</cite>
                </figcaption>
              </figure>

              <dl className="row">
                <dt className="col-sm-3">Lorem sit amet</dt>
                <dd className="col-sm-9">
                  Rerum vel, velit, in quod vero expedita quibusdam neque quasi
                  iusto ipsum!
                </dd>
                <dt className="col-sm-3">Euismod</dt>
                <dd className="col-sm-9">
                  <p>
                    Vestibulum id ligula porta felis euismod semper eget lacinia
                    odio sem nec elit.
                  </p>
                  <p>Donec id elit non mi porta gravida at eget metus.</p>
                </dd>
                <dt className="col-sm-3">Malesuada porta</dt>
                <dd className="col-sm-9">
                  Etiam porta sem malesuada magna mollis euismod.
                </dd>
                <dt className="col-sm-3 text-truncate">
                  Truncated term is truncated
                </dt>
                <dd className="col-sm-9">
                  Fusce dapibus, tellus ac cursus commodo, tortor mauris
                  condimentum nibh, ut fermentum massa justo sit amet risus.
                </dd>
                <dt className="col-sm-3">Nesting</dt>
                <dd className="col-sm-9">
                  <dl className="row">
                    <dt className="col-sm-4">Nested definition list</dt>
                    <dd className="col-sm-8">
                      Aenean posuere, tortor sed cursus feugiat, nunc augue
                      blandit nunc.
                    </dd>
                  </dl>
                </dd>
              </dl>
            </div>
            <div className="col-md-4">
              <div className="p-4 mb-3 bg-light rounded ">
                <h4 className="font-italic">About Me</h4>

                <p className="mb-0">
                  Quis vero phasellus hac nullam, in quam vitae duis adipiscing
                  mauris leo, laoreet eget at quis, ante vestibulum vivamus vel.
                  Sapien lobortis, eget orci purus amet pede, consectetur neque
                  risus.
                </p>
              </div>
              <div className="mb-4 px-4 ">
                <h4 className="font-italic">Lorem ipsum</h4>

                <div className="card text-white">
                  <a
                    className="stretched-link text-decoration-none text-white"
                    href="/"
                  >
                    <img src={img2} className="card-img" alt="..." />
                    <div className="card-img-overlay">
                      <h5 className="card-title position-absolute bottom-0 mb-3">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry
                      </h5>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default BlogDetailView;
