import React, { useState } from 'react'
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";


const ICON_STYLE= {
    color:"rgb(53, 88, 162)",
    width:"50px",
    height:"50px",
    border:"solid 1px rgb(53, 88, 162)",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    borderRadius:"50%"
}
const ICON_HOVER_STYLE={
  ...ICON_STYLE,
  color:"rgb(6, 118, 170)",
  border:"solid 1px rgb(6, 118, 170)",
  transition: "all ease 0.3s",
  transform: "translateY(-0.5px)"
}

const SMALL_ICON_STYLE={
  color:"rgb(53, 88, 162)",
    width:"40px",
    height:"40px",
    border:"solid 1px rgb(53, 88, 162)",
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    borderRadius:"50%",
    fontSize:"25px"
}
const HOVER_SMALL_ICON_STYLE={
  ...SMALL_ICON_STYLE,
  color:"rgb(6, 118, 170)",
  transition: "all ease 0.3s",
  transform: "translateY(-0.5px)"
  
}

const icon =(type)=>{
  switch (type) {
    case "Youtube":
      return <FaYoutube />;
      break;
    case "Instagram":
      return <FaInstagram />;
      break;
    case "Tiktok":
      return <FaTiktok />;
      break;
    case "LinkedIn":
      return <FaLinkedinIn />;
      break;

    default:
      return <FaFacebook />;
      break;
  }
}

function SocialIcon({link,type,size}) {
  const [isHovering,setIsHovering]= useState(false)

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <a href={link} target="_blank" style={size=="small"?!isHovering?SMALL_ICON_STYLE:HOVER_SMALL_ICON_STYLE:!isHovering?ICON_STYLE:ICON_HOVER_STYLE} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>{icon(type)}</a>
  )
}

export default SocialIcon