import { Card} from "react-bootstrap";
import React,{useContext, useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import { getAsset } from "services/assets";
import { ShimmerTitle,ShimmerSimpleGallery } from 'react-shimmer-effects';
import { LanguageContext } from "services/LanguageContext";

function CourseCard({cours}) {
    var {Titre,srcImage,src,img} = cours.data
    const [image,setImage] = useState();
    const [loading, setLoading] = React.useState(true);
    const { language } = useContext(LanguageContext);
    const BgImg=(img)=>{
        return {
          height: "200px",
          backgroundImage:`url(${img})`,
          backgroundSize:"cover",
          backgroundPosition:"Center",
          backgroundRepeat:"no-repeat"
          }
      }
      useEffect( () => {
        //console.log(src);
        if(!img && !srcImage)
        getAsset(src).then((zz)=>{
          srcImage=zz;
          img=zz;
          setImage(zz);
          //console.log(zz)
          setLoading(false)
      });
    else{
      if(img && !srcImage)
      setImage(img)
    
    else
    setImage(srcImage)
    setLoading(false)  
  }});
  return loading? <ShimmerSimpleGallery card imageHeight={300} caption /> :(

          <Card className="card h-100">
            <span  style={BgImg(image)}>
            </span>
            <Card.Title
              className="mb-0"
              style={{ color: "rgba(5, 131, 189, 0.932)" }}
            >
              <h6 className="mb-0 p-3 fw-bold">{Titre[language]}</h6>
            </Card.Title>
          </Card>
  )
}

export default CourseCard