export  async function getAsset(id) {
    const response=await fetch("https://altadmin.if-algerie.com/identity-server/connect/token",{
      contentType: "application/x-www-form-urlencoded",
      body: new URLSearchParams({
          grant_type: "client_credentials",
          client_id: "ifa:default",
          client_secret: "iwruhktybm9nci4efz2okuwibldlottkxgztjgfgbnux",
          scope: "squidex-api",
      }),
      method: "POST",
  });
  if (response.ok) {
      var data = await response.json();
      const res = await fetch("https://altadmin.if-algerie.com/api/apps/ifa/assets/"+id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization":"Bearer "+data['access_token']
        },
        
      });
  
      const json = await res.json();
    
      if (json.errors) {
        console.error(json.errors);
        throw new Error("Failed to fetch API");
      }
      ////console.log("https://altadmin.if-algerie.com/api/assets/"+id);
      return "https://altadmin.if-algerie.com/api/assets/"+id;
    }
    }
  