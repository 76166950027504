import React from 'react'
import CoverImg from 'components/General/CoverImg'
import { Container} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import cover from '../../assets/img/cours-de-français.jpg'
import BgShape from 'components/General/BgShape'
import MainTitle from 'components/General/MainTitle'




function TousLesCoursAEC({ville}) {
  const antenne={alger:"1", annaba:   "2",constantine:"3",
  oran:"4",tlemcen:"5"};
  return (
    <div style={{backgroundColor:"whitesmoke",zIndex:"1"}} className=" position-relative overflow-hidden">
      {/*<BgShape type="blue"  right="-40px" top="100px" rotate="-140deg"/>*/}
      {/*<BgShape type="red"  right="-50px" top="800px" rotate="-150deg"/>*/}
      {/*<BgShape type="yellow"  left="-80px" top="500px" rotate="140deg"/>*/}
      {/*<BgShape type="blue"  left="-80px" top="2000px" rotate="140deg"/>*/}
        <CoverImg img={cover}/>
        <Breadcrumb/>
        <Container >
        <div className='mt-5'>
          <MainTitle>Tous les cours</MainTitle>
            <p className=''>
            Les Espaces Langue et Savoirs des cinq antennes de l’Institut français d’Algérie, à Alger, Annaba, Constantine, Oran et Tlemcen vous proposent des cours adaptés à tous les âges, à tous les niveaux, et à tous les besoins. </p>

            <p className=''>Nous proposons des cours pour les enfants dès le plus jeune âge (sauf pour l’instant à Alger), aux adolescents, aux adultes et aux professionnels. Vous pourrez également suivre des ateliers de conversation ou une préparation efficace au DELF-DALF.</p>
            <p className=''>À Alger, nous travaillons sur une nouvelle offre de cours et un nouvel espace à Hydra dédié aux enfants et aux adolescents et où nous vous proposerons dès janvier 2024 une offre de cours complète dès 6 ans.

            </p>
        </div>  
        {/* ==== Cours AEC ==== */}
        <div className="arc-en-ciel container">
        <div data-module="events" data-action="events-list-grid" data-param-quantity-column="3" data-param-event-type="23,24,25,26,27" data-events-to-show="2" data-param-detail_link="/aec-event/"  data-show-loading="true" data-param-etablishment-branch-id={ville==="national"?"":antenne[ville]}></div>

            {/*<div data-module="classes" data-action="courses-catalogue"  data-param-levels-to-filter-by="" data-param-etablishment-branch-id={ville==="national"?"":antenne[ville]}  data-show-loading="true"></div>  */}
            {/* <div data-module="classes" data-action="courses-catalogue" data-param-sidebar="true"></div> */}
            {/* <div data-module="classes" data-action="view-classes-with-filters" data-param-class-type-id="0" ></div> */}
            {/* <div data-module="classes" data-action="courses-catalogue" data-show-loading="true" data-param-etablishment-branch-id="0" data-param-classification-id="3" data-param-class-type-id="14|18|53" data-param-level-id="0" data-param-main-level-id="0" data-param-subject-id="1" data-param-period-id="0" data-param-id-student="" data-param-class-location="" data-param-class-format-id="0" id="kiosque-app-21670515823879" className="" data-ng-kiosque-app="true" data-module-initialized="true"></div>    */}
        </div>

        </Container>
    </div>
      )
}

export default TousLesCoursAEC