import React from 'react'
import { Link } from 'react-router-dom'
import styles from './mediatheque-card.module.css'


const MediathequeCityCard = ({ville,contain}) => {
  return (
    !contain ? 
    <Link to={ville.url}>
        <div className={`${styles.card}`} style={{
        backgroundImage:`url(${ville.img})`,
        backgroundSize: "cover",
        backgroundPosition:"center",
        backgroundRepeat:"no-repeat",
        height:"200px",
        }}>
          <span className='text-white text-capitalize'>{ville.name}</span>
        </div>
    </Link>
    :
    <a href={ville.url} target="_blank" rel="noreferrer">
        <div className={`${styles.card}`} style={{
        backgroundImage:`url(${ville.img})`,
        backgroundSize: "contain",
        backgroundPosition:"center",
        backgroundRepeat:"no-repeat",
        height:"200px",
        }}>
          <span className='text-white text-capitalize'>{ville.name}</span>
        </div>
    </a>
  )
}

export default MediathequeCityCard