import React from 'react'
import styles from "./menuItemsList.module.css"
import MenuItem from './MenuItem'
import EtudesDropDown from './EtudesDropDown';


function Dropdown({submenus,dropdown,depthLevel,title}) {

    depthLevel = depthLevel + 1;
    const dropdownClass = depthLevel > 1 ? styles.dropdown_submenu : "";

    // display multiple forms of drop down
    const displayDropDown = (title)=>{
      if (title !=="études") {
        return (
          <ul className={`${styles.dropdown} ${dropdownClass} ${dropdown&&styles.show}`}>
          {submenus.map((submenu, index) => {
            return  <MenuItem items={submenu} key={index} depthLevel={depthLevel} className={styles.dropItem} />
          }
          )}
        </ul>
        )
      } else {
        return (
        <EtudesDropDown dropdown = {dropdown} submenus={submenus}/>
        )
      }
    }

  return (
    displayDropDown(title)
  )
}

export default Dropdown