import React from "react";
import Agenda from "../Agenda/Agenda";
import MainHeader from "../header/MainHeader";
import Identity from "../Identity/Identity";
import SlideComponent from "../SlideComponent";
import Support from "../support/Support";
import NewsLetter from "../newsletter/NewsLetter";
import BgShape from "components/General/BgShape";
import HomeSlide from "components/home/HomeSlider";
import FooterTop from "components/footers/FooterTop";

import greenCapsule from "../../assets/img/capsule-green.png";
import {fetchAPIHome,fetchAPIHomeDetails} from "../../services/home-page"

export default function LandingPage() {
  const [id, setId] = React.useState();
  const [slides, setSlides] = React.useState();
  const [tempsforts, setTempsforts] = React.useState();
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    //if(!tempsforts.length)
  fetchAPIHome().then((items)=>{

  setSlides(items.items[0]?.data['Slides']??[]);
  setTempsforts(items.items[0]?.data['TempsForts']??[]);
  setId(items.items[0]?.id)
  ////console.log(items.items[0]?.data)
});
}, []);
  return (
    <div className=" position-relative overflow-hidden">
      {/*<BgShape type="red"  right="-60px" top="800px" rotate="-140deg"/>*/}
      {/*<BgShape type="yellow"  left="-80px" top="1000px" rotate="130deg"/>*/}
      {/*<BgShape type="yellow"  right="-50px" bottom="200px" rotate="-150deg"/>*/}
      {/*<BgShape type="blue"  left="-80px" bottom="500px" rotate="140deg"/>*/}
      <SlideComponent ></SlideComponent>
      {/* <HomeSlide/> */}
      <Agenda />
      
      {id?<Identity items={tempsforts} id={id}/>:<></>}
      <Support />
      <NewsLetter />
      <FooterTop/>
    </div>
  );
}
