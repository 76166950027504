import React, { useEffect, useState } from 'react'
import { Container} from 'react-bootstrap'
import CoverImg from 'components/General/CoverImg'
import france from "../../assets/img/etudier-en-france.jpg"
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import EtudesCardsGrid from 'components/Etudes/EtudesCardsGrid'
import MainTitle from 'components/General/MainTitle'
import {etudesHome} from "../../data/etudes/etudes"
import { getAsset } from 'services/assets'
import HTMLReactParser from 'html-react-parser'

const EtudesPage = () => {
    const etudesAlgerie = etudesHome.find((el)=>el.region==="algérie")
    const etudesFrance= etudesHome.find((el)=>el.region==="france")
    
  return (
    <>
    <CoverImg img={france}/>
    <Breadcrumb/>
    <Container >
    <MainTitle>Etudes</MainTitle>
            {HTMLReactParser(etudesFrance.description)}
        
    <div className='mb-2'>
        <h3 className='blue-ifa fw-bold text-start my-4'>En France</h3>
        <EtudesCardsGrid etudes={etudesFrance.etudes}/>
    </div>
    <div className=''>
        <h3 className='blue-ifa fw-bold text-start my-4'>En Algérie</h3>
        <EtudesCardsGrid etudes={etudesAlgerie.etudes}/>
    </div>
    </Container>
</>
  )
}

export default EtudesPage