import React from 'react'
import styles from "./menuItemsList.module.css"
import DropDownGrid from './DropDownGrid';

const EtudesDropDown = ({submenus,dropdown}) => {
  return (
    <div className={`${styles.dropdown_etudes } ${dropdown&&styles.show}`}>
        {
            submenus.map((el,index)=><DropDownGrid key={index} subsubmenu={el} dropdown={dropdown}/>)
        }
    </div>
  )
}

export default EtudesDropDown