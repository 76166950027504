import React, {useContext} from 'react'
import {Col,Row} from "react-bootstrap";
import EventCard from 'components/General/EventCard';
import {filterContext} from "../../pages/Agenda pages/AgendaPage"



function EventsGrid({time,region,type,month,max}) {
    // use the Filter Context:
    const {activity, events} = useContext(filterContext)

    // remove Events of : ESAA, Campus France, France Alumni, LIAD, PEH 
    const etudesTags = ["esaa","campus france","france alumni","liad","peh","actions culturelles"]
    const agendaEvents = events.filter(event =>etudesTags.indexOf(event.type.toLowerCase())==-1)

    // filter events by month (if the month props exist)
    let eventsByMonth
    if (month==="Tous les mois" || month === "Par mois") {
        eventsByMonth =agendaEvents
    } else{
        eventsByMonth = month ?agendaEvents.filter(event=>event.date.toLowerCase().includes(month.toLowerCase())): agendaEvents
    }
    
    


    // filter the eventsByMonth by type (if the type prop exist)
    let eventsByType
    type ? eventsByType = eventsByMonth.filter(event=>event.type.toLowerCase() === type.toLowerCase()) : eventsByType=eventsByMonth
    
    // use the var eventActivity instad of using the activity state directly inside the filtre function (because of 'Par activité' value)
    let eventActivity 
    activity[time] !== "Par activité" ? eventActivity = activity[time] : eventActivity=""
    
    const filtredEvents = eventsByType.filter(event=>{

            if (!time && !region && !eventActivity) {
                return event
            }else if(time && !region && !eventActivity){
                return event.time === time
            }else if(!time && region && !eventActivity){
                return event.region.toLowerCase() === region
            }else if(time && region && !eventActivity){
                return event.time === time && event.region.toLowerCase() === region
            }else if(!time && !region && eventActivity){
                return event.type.toLowerCase() ===eventActivity.toLowerCase()
            }else if(time && !region && eventActivity){
                return event.time===time && event.type.toLowerCase() ===eventActivity.toLowerCase()
            }else if(!time && region && eventActivity){
                return event.region.toLowerCase() === region && event.type.toLowerCase() ===eventActivity.toLowerCase()
            }else if(time && region && eventActivity){
                return event.time===time && event.region.toLowerCase() === region && event.type.toLowerCase() ===eventActivity.toLowerCase()
            }
    })


    const finalEvents = max ? filtredEvents.slice(0,max) : filtredEvents

    const EventList = finalEvents.map((event,index)=>{
        return (
          <Col lg={4} md={6} key={index}>
            <EventCard key={index} event={event} />
          </Col>
        );
    })

    // TO DO LATER: 
    // use month and region here from the state of the Filter context directly (kima activity) and not from the props
    // add filter of "Aujourd'hui" and "Cette semaine"

  return (
    <Row className="mb-4">
        {EventList}
    </Row>
  )
}

export default EventsGrid