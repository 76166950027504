import React,{createContext,useEffect,useState,useRef} from 'react'
import CoverImg from 'components/General/CoverImg'
import { Button, Container,Dropdown} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import culture from "../../assets/img/culture.jpg"
import BgShape from 'components/General/BgShape'
import SocialSection from 'components/General/SocialSection'
import { useNavigate,useLocation } from 'react-router-dom'



function AgendaPage2({type,city,brd}) {
  const eventFilters = {
    musique : "14-Musique",
    expo : "17-Expo",
    théatre : "15-theatre",
    cinéma : "11-Cinma",
    conférencesetrencontres : "18-Confrencesetrencontres",
    danse : "16-Danse",
    jeunesse: "19-Jeunesse",
    ateliers:"28-ateliers"
  }
  const antenne={alger:"1", annaba:   "2",constantine:"3",
    oran:"4",tlemcen:"5"};
  const filters = {
    musique : "musique",
    expo : "expo",
    théatre : "theatre",
    cinéma : "cinema",
    "conférences et rencontres" : "conferences-et-rencontres",
    danse : "danse",
    jeunesse: "jeunesse",
    ateliers:"ateliers"
  }
  const [eventType,setEventType] = useState(type)
  const initialRender = useRef(true);
  // change the eventType according to the type prop
  useEffect(()=>{
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      // window.location.reload()
      setEventType(type)
      //console.log('************ New refresh ***********')
    }
    
    //console.log("Event Type ===> ",filters[eventType])
    // window.location. reload()
  },[type])

  const displayEventGrid = (eventType)=>{
    return <div data-module="events" data-action="events-list" data-param-quanty-column="3" data-param-event-type={filters[eventType]} data-events-to-show="1" data-param-detail_link="/aec-event/"></div>
  }
  // handle filter
  const navigate = useNavigate()
  const handleFilter=(activity)=>{
    // //console.log("filteeeeeeeeeeeeer")
    // navigate("musique",{
    //   state: {
    //     type:"14-Musique"
    //   }
    // })
    navigate(filters[activity.toLowerCase()])
  }

  // recive data from useNavigate
  const location = useLocation()
  //location.state ? console.log(location.state.type) : console.log("No LOCATION")

  // activities for dropdown filter
const activities = ["Musique","Théatre","Danse","Expo","Cinéma","Conférences et rencontres","Jeunesse","Ateliers"]

const handleClick=()=>{
  const newPath = location.pathname.split("/")
  newPath.pop()
  const agendaPath = newPath.join("/")
  navigate(agendaPath)
}


    //console.log(city+" "+antenne[city]);
  return (
    <div className=" position-relative overflow-hidden">
      {/*<BgShape type="yellow" right="-40px" top="80px" rotate="-140deg" />
      <BgShape type="blue" right="-50px" top="800px" rotate="-150deg" />
      <BgShape type="red" left="-80px" top="500px" rotate="140deg" />*/}
      <CoverImg img={culture} />
      <Breadcrumb brd={brd} />


        {/* <div className="mt-5 py-3" style={{ backgroundColor: "whitesmoke" }}>
        </div> */}
        {type=="all" ? 
        <>
        <Container>
          <div className='py-4 d-flex justify-content-end mt-4'>
            <Dropdown>
                <Dropdown.Toggle
                id="dropdown-basic"
                className="rounded-0 border-0 p-2 text-start d-flex align-items-center justify-content-between bg-grey activity-filter-btn "
                >
                Par activité
                </Dropdown.Toggle>
                <Dropdown.Menu className='rounded-0' flip={false}>
                {activities.map((act,idx)=>{
                    return (<Dropdown.Item key={idx} onClick={()=>handleFilter(act)}>{act}</Dropdown.Item>)
                })}
                </Dropdown.Menu>
            </Dropdown>
          </div>
        </Container>
        <div className="arc-en-ciel container">
          {/* <div data-module="events" data-action="events-list-grid" data-param-quantity-column="3" data-param-event-type={location.state.type} data-events-to-show="2" data-param-detail_link="/aec-event/"  data-show-loading="true"></div> */}
          {/* <div data-module="events" data-action="events-list-grid" data-param-quantity-column="3" data-param-event-type={location.state.type ?location.state.type : ""} data-events-to-show="2" data-param-detail_link="/aec-event/"  data-show-loading="true"></div> */}
          <div data-module="events" data-action="events-list-grid" data-param-quantity-column="3" data-param-event-type="" data-events-to-show="2" data-param-detail_link="/aec-event/"  data-show-loading="true" data-param-etablishment-branch-id={city==="national"?"":antenne[city]}></div>
        </div>
      </>:
        <div>
           <Container>
        <div className='py-4 d-flex justify-content-end mt-4'>
          <button onClick={handleClick} className="rounded-0 bg-grey border-0 px-3 py-2">Toutes les activités</button>
        </div>
          <div className="arc-en-ciel my-5">
              <div data-module="events" data-action="events-list" data-param-quanty-column="3" data-param-event-type={eventFilters[type]} data-events-to-show="1" data-param-detail_link="/aec-event/" data-show-loading="true" data-param-etablishment-branch-id={city==="national"?"":antenne[city]}></div>
          </div>
        </Container>
        </div>
        }
        
      <SocialSection institut={true} ville={city}/>
    </div>
  );
}

export default AgendaPage2