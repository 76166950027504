import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./identity-style.css";
import { Link } from "react-router-dom";
import {
  Container,
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  Button,
  Carousel,
  Row,
  Col,
  Image,
} from "react-bootstrap";

import img1 from "../../assets/img/ComponentTMP_3-image.png";
import img6 from "../../assets/img/ComponentTMP_3-image6.png";
import img7 from "../../assets/img/ComponentTMP_3-image7.png";
import img2 from "../../assets/img/ComponentTMP_3-image2.png";
import img4 from "../../assets/img/ComponentTMP_3-image3.png";
import img3 from "../../assets/img/ComponentTMP_3-image4.png";
import img5 from "../../assets/img/ComponentTMP_3-image5.png";
import bg from "../../assets/img/identities-bg.png";
import featuredIMG from "../../assets/img/identity-featered.png";
import redCapsule from "../../assets/img/capsule-red.png";
import { getAsset } from "services/assets";
export default function Identity(props) {
  const {items, id} = props; 
  const [data, setData] = React.useState();
  const [featered, setFeatered] = React.useState();
  const [times, setTimes] = React.useState();
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    setData(items?.iv);
    //console.log(items?.iv)
    var xx = items?.iv?.filter(x=>x.Actif==true)[0];
    //console.log(xx)
    //setFeatered(data?.filter(x=>x.Actif==true)[0]);
    if(xx)
    getAsset(xx?.Image[0]).then((url)=>{
      //if(featered){
      //var xx=featered;
      xx.srcImage=url;
      xx.id=id;
    setFeatered(xx);
    });
    var yy = items?.iv?.filter(x=>!x.Actif||x.Actif==false);
    //console.log(yy)
    //setFeatered(data?.filter(x=>x.Actif==true)[0]);
    yy?.map(el => {
      if(el?.Image)
      getAsset(el?.Image[0]).then((url)=>{
        //if(featered){
        //var xx=featered;
        el.srcImage=url;
        el.id=id;
        setTimes(yy)
        //console.log("items set 1") 
    });
    else{
    setTimes(yy)
    //console.log("items set 2") 
  }
    
    
  //console.log(id) //}
    });
    
  ////console.log(items?.iv);
  ////console.log(featered)
  ////console.log(featered.srcImage)
}, [times?.every(x=>x.srcImage!==undefined&&x.srcImage!==null&&x.srcImage!=="")]);

  return (
    <div 
    style={{
      //backgroundColor: "#3558a2",
      backgroundImage: `url(${bg})`,
      display: "block",
      paddingBottom:"20px"
    }}>

    
    <Container  className="container">
    <Row>
      <Row>
        <Col style={{ position: "relative" }}>
          <h1
            style={{ color: "black", fontSize: "2rem", marginBottom: "46px",marginTop: "46px", fontWeight:"600" }}
          >
            {" "}
            Nos temps forts
          </h1>
        </Col>
      </Row>

      <Row className="justify-content-center"
        style={{
          alignContent: "center",
          width: "100%",
          //paddingLeft: "20px"
        }}
      >
        <Row style={{ textAlign: "initial", width: "100%" }}>
          <div style={{
            width:'200px',
            backgroundColor:'#ed4c5e',
      //backgroundImage: `url(${redCapsule})`,
      backgroundPosition: 'left',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: '5px',
    }}>
          <span style={{ color:'white' }}>A l'honneur ce mois-ci</span>
    </div>
    <Col md={12} style={{ textAlign: "initial", width: "100%" }} className="hover hover-custom">
              <Link to={`/institut/identity/${featered?.id??featered?.Titre??"#"}/${featered?.slug??featered?.Titre}`} className="mr-2">
                <div
                  className="iden-img3"
                  style={{
                    backgroundImage: `url(${featered?.srcImage?(featered?.srcImage):featuredIMG})`,
                    textAlign: "initial",
                    position: "relative",
                    height: "12.5rem",
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="hover-overlay"></div>
                  <div className="hover-custom-content">
                  <span className="hover-custom-title"
                    style={{
                      textAlign: "initial",
                      position: "absolute",
                      bottom: "0",
                      left: "20px",
                      color: "white",
                      fontSize: "1.5rem",
                    }}
                  >
                    {featered?.Titre??"CULTURE URBAINE"}
                  </span>
                  <p className="hover-custom-description small text-uppercase mb-0"></p>
                  </div>
                </div>
              </Link>
            </Col>
        </Row>
        <Row style={{ textAlign: "initial", width: "100%" }}>
          <div style={{
            backgroundColor:'#00b6ad',
      //backgroundImage: `url(${greenCapsule})`,
      width:'200px',
      backgroundPosition: 'left',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: '5px',
    }}>
          <span style={{ color:'white' }}>Et prochainement</span>
          </div>
        <Col md={12} style={{ textAlign: "initial" }}>
          <Row >
            {
              ////console.log(times?.length??"")
            (times?.length)?
              times?.slice(0, times?.length>3?3:times?.length)?.map((el,i)=>{
                //console.log(el?.srcImage)
                return <>
                <Col md={4} className="hover hover-custom" key={i+10}>
            
            <Link to={`/institut/identity/${el?.id??el?.Titre??"#"}/${el?.slug??el?.Titre}`}  className="mr-2">
              <div
                className="iden-img1"
                style={{
                  backgroundImage: `url(${el?.srcImage?(el?.srcImage):featuredIMG})`,
                  textAlign: "initial",
                  position: "relative",
                  height: "13.5rem",
                  overflow: "hidden",
                }}
              >
                <div className="hover-overlay"></div>
                <div className="hover-custom-content">
                <span className="hover-custom-title"
                  style={{
                    textAlign: "initial",
                    position: "absolute",
                    bottom: "0",
                    left: "20px",
                    color: "white",
                    fontSize: "1.5rem",
                  }}
                >
                  {el?.Titre}
                </span>
                <p className="hover-custom-description small text-uppercase mb-0"></p>
                </div>
              </div>
            </Link>
          </Col>
                </>
              })
            
            :<>
            <Col md={4} className="hover hover-custom">
            
            <Link to="/institut/identity/Nuit des idées" className="mr-2">
              <div
                className="iden-img1"
                style={{
                  textAlign: "initial",
                  position: "relative",
                  height: "13.5rem",
                  overflow: "hidden",
                }}
              >
                <div className="hover-overlay"></div>
                <div className="hover-custom-content">
                <span className="hover-custom-title"
                  style={{
                    textAlign: "initial",
                    position: "absolute",
                    bottom: "0",
                    left: "20px",
                    color: "white",
                    fontSize: "1.5rem",
                  }}
                >
                  Nuit des idées
                </span>
                <p className="hover-custom-description small text-uppercase mb-0"></p>
                </div>
              </div>
            </Link>
          </Col>
            <Col md={4} className="hover hover-custom">
              <Link to="/institut/identity/Langue arabe" className="mr-2">
                <div
                  className="iden-img2"
                  style={{
                    textAlign: "initial",
                    position: "relative",
                    height: "13.5rem",
                    overflow: "hidden",
                  }}
                >
                  <div className="hover-overlay"></div>
                  <div className="hover-custom-content">
                  <span className="hover-custom-title"
                    style={{
                      textAlign: "initial",
                      position: "absolute",
                      bottom: "0",
                      left: "20px",
                      color: "white",
                      fontSize: "1.5rem",
                    }}
                  >
                    Langue arabe
                  </span>
                  <p className="hover-custom-description small text-uppercase mb-0"></p>
                  </div>
                </div>
              </Link>
            </Col>
            <Col md={4} className="hover hover-custom">
              <Link to="/institut/identity/Novembre numérique" className="mr-2">
                <div
                  className="iden-img9"
                  style={{
                    textAlign: "initial",
                    position: "relative",
                    height: "13.5rem",
                    overflow: "hidden",
                  }}
                >
                  <div className="hover-overlay"></div>
                  <div className="hover-custom-content">
                  <span className="hover-custom-title"
                    style={{
                      textAlign: "initial",
                      position: "absolute",
                      bottom: "0",
                      left: "20px",
                      color: "white",
                      fontSize: "1.5rem",
                    }}
                  >
                  Novembre numérique
                  </span>
                  <p className="hover-custom-description small text-uppercase mb-0"></p>
                  </div>
                </div>
              </Link>
            </Col></>
            }
          </Row>
          <Row>{
          (times?.length>3)?
              times?.slice(3, times?.length>5?5:times?.length)?.map((el,i)=>{
                //console.log(i)
                return <>
   <Col md={i==1?4:8} className="hover hover-custom" key={i+20}>
            
            <Link to={`/institut/identity/${el?.id??el?.Titre??"#"}/${el?.slug??el?.Titre}`}  className="mr-2">
              <div
                className="iden-img1"
                style={{
                  backgroundImage: `url(${el?.srcImage?(el?.srcImage):featuredIMG})`,
                  textAlign: "initial",
                  position: "relative",
                  height: "13.5rem",
                  overflow: "hidden",
                }}
              >
                <div className="hover-overlay"></div>
                <div className="hover-custom-content">
                <span className="hover-custom-title"
                  style={{
                    textAlign: "initial",
                    position: "absolute",
                    bottom: "0",
                    left: "20px",
                    color: "white",
                    fontSize: "1.5rem",
                  }}
                >
                  {el?.Titre}
                </span>
                <p className="hover-custom-description small text-uppercase mb-0"></p>
                </div>
              </div>
            </Link>
          </Col>
                </> }):<>
          <Col md={4} className="hover hover-custom">
            
            <Link to="/institut/identity/Les francophonies" className="mr-2">
              <div
                className="iden-img4"
                style={{
                  textAlign: "initial",
                  position: "relative",
                  height: "13.5rem",
                  overflow: "hidden",
                }}
              >
                <div className="hover-overlay"></div>
                <div className="hover-custom-content">
                <span className="hover-custom-title"
                  style={{
                    textAlign: "initial",
                    position: "absolute",
                    bottom: "0",
                    left: "20px",
                    color: "white",
                    fontSize: "1.5rem",
                  }}
                >
                  Les francophonies
                </span>
                <p className="hover-custom-description small text-uppercase mb-0"></p>
                </div>
              </div>
            </Link>
          </Col>
            <Col md={8} className="hover hover-custom">
              <Link to={"/institut/identity/La musique"} className="mr-2">
                <div
                  className="iden-img6"
                  style={{
                    textAlign: "initial",
                    position: "relative",
                    height: "13.5rem",
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="hover-overlay"></div>
                  <div className="hover-custom-content">
                  <span className="hover-custom-title"
                    style={{
                      textAlign: "initial",
                      position: "absolute",
                      bottom: "0",
                      left: "20px",
                      color: "white",
                      fontSize: "1.5rem",
                    }}
                  >
                    La musique
                  </span>
                  <p className="hover-custom-description small text-uppercase mb-0"></p>
                  </div>
                </div>
              </Link>
            </Col>
            </>}
          </Row>
          <Row>{
          (times?.length>5)?
              times?.slice(5, times?.length)?.map((el,i)=>{
                //console.log(i)
                return <>
   <Col md={4} className="hover hover-custom" key={i+30}>
            
            <Link to={`/institut/identity/${el?.id??el?.Titre??"#"}/${el?.slug??el?.Titre}`}  className="mr-2">
              <div
                className="iden-img1"
                style={{
                  backgroundImage: `url(${el?.srcImage?(el?.srcImage):featuredIMG})`,
                  textAlign: "initial",
                  position: "relative",
                  height: "13.5rem",
                  overflow: "hidden",
                }}
              >
                <div className="hover-overlay"></div>
                <div className="hover-custom-content">
                <span className="hover-custom-title"
                  style={{
                    textAlign: "initial",
                    position: "absolute",
                    bottom: "0",
                    left: "20px",
                    color: "white",
                    fontSize: "1.5rem",
                  }}
                >
                  {el?.Titre}
                </span>
                <p className="hover-custom-description small text-uppercase mb-0"></p>
                </div>
              </div>
            </Link>
          </Col>
                </> }):<>
            <Col md={4} className="hover hover-custom">
              <Link to={`identity/${'L’environnement'}`} className="mr-2">
                <div
                  className="iden-img5"
                  style={{
                    textAlign: "initial",
                    position: "relative",
                    height: "13.5rem",
                    overflow: "hidden",
                  }}
                >
                  <div className="hover-overlay"></div>
                  <div className="hover-custom-content">
                  <span className="hover-custom-title"
                    style={{
                      textAlign: "initial",
                      position: "absolute",
                      bottom: "0",
                      left: "20px",
                      color: "white",
                      fontSize: "1.5rem",
                    }}
                  >
                    L’environnement
                  </span>
                  <p className="hover-custom-description small text-uppercase mb-0"></p>
                  </div>
                </div>
              </Link>
            </Col>
            <Col md={4} className="hover hover-custom">
              <Link to="identity/Le mois de la gastronomie" className="mr-2">
                <div
                  className="iden-img7"
                  style={{
                    textAlign: "initial",
                    position: "relative",
                    height: "13.5rem",
                    overflow: "hidden",
                  }}
                >
                  <div className="hover-overlay"></div>
                  <div className="hover-custom-content">
                  <span className="hover-custom-title"
                    style={{
                      textAlign: "initial",
                      position: "absolute",
                      bottom: "0",
                      left: "20px",
                      color: "white",
                      fontSize: "1.5rem",
                    }}
                  >
                    Le mois de la gastronomie
                  </span>
                  <p className="hover-custom-description small text-uppercase mb-0"></p>
                  </div>
                </div>
              </Link>
            </Col>
            <Col md={4} className="hover hover-custom">
              <Link to="identity/Ibn Khaldoun" className="mr-2">
                <div
                  className="iden-img8"
                  style={{
                    textAlign: "initial",
                    position: "relative",
                    height: "13.5rem",
                    overflow: "hidden",
                  }}
                >
                  <div className="hover-overlay"></div>
                  <div className="hover-custom-content">
                  <span className="hover-custom-title"
                    style={{
                      textAlign: "initial",
                      position: "absolute",
                      bottom: "0",
                      left: "20px",
                      color: "white",
                      fontSize: "1.5rem",
                    }}
                  >
                    Le patrimoine
                  </span>
                  <p className="hover-custom-description small text-uppercase mb-0"></p>
                  </div>
                </div>
              </Link>
            </Col>
            </>}
          </Row>
        </Col>
        </Row>
      </Row>
    </Row>
    </Container>
    </div>
  );
}
