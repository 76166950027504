export const initialState = {
    bg:"white"
}

// create + export global reducer
export const globalReducer = (state,action)=>{
    const {type,payload} = action
    switch (type) {
        case "CHANGE_BG":
            return{
                ...state,
                bg:payload.color
            }
            break;
    
        default:
            throw new Error(`No case for type ${type} found in globalReducer.`);

    } 
}

