const missions = [
    {
        title:"Mission de coopération éducative et linguistique",
        img:"https://images.unsplash.com/photo-1614107151491-6876eecbff89?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
        url:"Mission-de-cooperation-educative-et-linguistique",
        description:""
    },{
        title:"Mission de coopération institutionnelle",
        img:"https://images.unsplash.com/photo-1508361727343-ca787442dcd7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1003&q=80",
        url:"Mission-de-cooperation-institutionnelle",
        description:""

    },{
        title:"Mission de coopération culturelle",
        img:"https://images.unsplash.com/photo-1556031330-4536769f448f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=436&q=80",
        url:"Mission-de-cooperation-culturelle",
        description:""
    },{
        title:"Mission de coopération scientifique et universitaire",
        img:"https://images.unsplash.com/photo-1583373834259-46cc92173cb7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
        url:"Mission-de-cooperation-universitaire",
        description: `Axe majeur du partenariat algéro-français, la coopération scientifique et universitaire s’est vue donner un nouvel élan avec le Partenariat renforcé algéro-français pour l’enseignement supérieur et la recherche scientifique (PRAFESRS), signé entre les gouvernements algérien et français  le 25 août 2022.

        À travers cet accord, se sont vus renforcer différents pans de la coopération dans le champ universitaire :
        
        ·         La coopération entre les Instituts des Sciences et Techniques Appliquées (ISTA) algériens et les Instituts universitaires de technologie (IUT) français pour un échange de bonnes pratiques en matière de gouvernance, un développement d’une formation professionnalisante de qualité et une mise à niveau des laboratoires pédagogiques 
        
        ·         Le Réseau Mixte des Ecoles d’ingénieurs (RME) visant à renforcer les formations d’ingénieurs à triple compétence scientifique, technique et managériale ainsi que la relation école/entreprise
        
        ·         Le Partenariat Hubert Curien (PHC) Tassili+, programme de mobilité scientifique tourné vers des projets algéro-français dans le domaine de la sécurité alimentaire, de la sécurité énergétique, de la santé du citoyen, de l’intelligence artificielle, de la digitalisation, des changements environnementaux et du développement durable
        
        D’autres domaines d’excellence font l’objet d’un partenariat fort à travers des programmes financés sur Fonds de solidarité pour les projets innovants (FSPI). Actuellement, le FSPI ARCHEoPHY a pour objectif de former les archéologues algériens aux géosciences appliquées à l’archéologie, en s’appuyant sur la signature d’une convention de partenariat entre le Centre National de la Recherche en Archéologie (CNRA) algérien et Aix-Marseille Université (AMU).
        
        La coopération algéro-française dans le domaine universitaire a également donné lieu, depuis 2004, à la fondation d’un établissement mixte algéro-français d’enseignement supérieur : l’École Supérieure Algérienne des Affaires (ESAA), qui vise à doter l’Algérie de cadres de haut niveau et de dirigeants d’entreprise.`
    }
    ]

    export default missions