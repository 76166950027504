import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Agenda-style.css";
import { Link } from "react-router-dom";
import {
  Badge,
  Container,
  Card,
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  Button,
  Carousel,
  Row,
  Col,
  Tabs,
  Tab,
} from "react-bootstrap";
import img1 from "../../assets/img/ComponentTMP_4-image.png";
import img2 from "../../assets/img/ComponentTMP_4-image2.png";
import img3 from "../../assets/img/ComponentTMP_4-image3.png";
export default function Agenda() {
  const [item, setItem] = React.useState(-1);
  const onTabSelected = (index) => {
    setItem(index)
    //console.log(index);
  };
  const antenne=["1", "2","3",
  "4","5"];
  const eventFilters = [
    "14-Musique",
    "17-Expo",
    "15-theatre",
    "11-Cinma",
    "18-Confrencesetrencontres",
    "16-Danse",
    "19-Jeunesse",
    "28-ateliers"
  ]
  return (
    <Container fluid className="container-lg" style={{ marginBottom:"30px" }}>
      <Row>
        <Col style={{ position: "relative" }}>
        <Link to="culture/agenda" >
            <span
            role="button"
            variant="link"
            style={{
              color: "black",
              position: "absolute",
                top: "60px",
                right: "16px",
                fontSize: "20px",
                textDecoration: "underline",
                textDecorationColor: "#ffed00",
                textDecorationThickness: "3px",
            }}
          >
                Voir tous les événements {'>'}
            </span>
          </Link>
          
          <h1 style={{ fontSize: "2rem", marginBottom: "46px",marginTop: "28px", fontWeight:"600" }}> Agenda</h1>
        </Col>
      </Row>
      <Row>
      
      <Tab.Container id="tabs" defaultActiveKey="first">
      <Row>
        <Row>
          <Nav variant="pills"  style={{overflow:'auto'}} className=" d-flex flex-nowrap flex-row mt-2" justify>
            <Nav.Item className='d-flex  flex-column'>
              <Nav.Link eventKey="first">Toute l'Algérie</Nav.Link>
            </Nav.Item>
            <Nav.Item className='d-flex  flex-column'>
              <Nav.Link eventKey="Alger">Alger</Nav.Link>
            </Nav.Item>
            <Nav.Item className='d-flex  flex-column'>
              <Nav.Link eventKey="Annaba">Annaba</Nav.Link>
            </Nav.Item>
            <Nav.Item className='d-flex  flex-column'>
              <Nav.Link eventKey="Constantine">Constantine</Nav.Link>
            </Nav.Item>
            <Nav.Item className='d-flex  flex-column'>
              <Nav.Link eventKey="Oran">Oran</Nav.Link>
            </Nav.Item>
            <Nav.Item className='d-flex  flex-column'>
              <Nav.Link eventKey="Tlemcen">Tlemcen</Nav.Link>
            </Nav.Item>
          </Nav>
        </Row>
        <Row>
          <Tab.Content className="p-2">
            <Tab.Pane eventKey="first"><div className="arc-en-ciel container">
         <div data-module="events" data-action="events-next" data-param-quantity-column="4" data-param-event-type={eventFilters.join('|')} data-events-to-show="4" data-param-detail_link="/aec-event/"  data-show-loading="true" data-param-etablishment-branch-id={""}></div>
        </div></Tab.Pane>
            <Tab.Pane eventKey="Alger"><div className="arc-en-ciel container">
         <div data-module="events" data-action="events-next" data-param-quantity-column="4" data-param-event-type={eventFilters.join('|')} data-events-to-show="4" data-param-detail_link="/aec-event/"  data-show-loading="true" data-param-etablishment-branch-id={antenne[0]}></div>
        </div></Tab.Pane>
        <Tab.Pane eventKey="Annaba">
        <div className="arc-en-ciel container">
         <div data-module="events" data-action="events-next" data-param-quantity-column="4" data-param-event-type={eventFilters.join('|')} data-events-to-show="4" data-param-detail_link="/aec-event/"  data-show-loading="true" data-param-etablishment-branch-id={antenne[1]}></div>
        </div>
        </Tab.Pane>
        <Tab.Pane eventKey="Constantine">
        <div className="arc-en-ciel container">
         <div data-module="events" data-action="events-next" data-param-quantity-column="4" data-param-event-type={eventFilters.join('|')} data-events-to-show="4" data-param-detail_link="/aec-event/"  data-show-loading="true" data-param-etablishment-branch-id={antenne[2]}></div>
        </div>
        </Tab.Pane>
        <Tab.Pane eventKey="Oran">
        <div className="arc-en-ciel container">
         <div data-module="events" data-action="events-next" data-param-quantity-column="4" data-param-event-type={eventFilters.join('|')} data-events-to-show="4" data-param-detail_link="/aec-event/"  data-show-loading="true" data-param-etablishment-branch-id={antenne[3]}></div>
        </div>
        </Tab.Pane>
        <Tab.Pane eventKey="Tlemcen">
        <div className="arc-en-ciel container">
         <div data-module="events" data-action="events-next" data-param-quantity-column="4" data-param-event-type={eventFilters.join('|')} data-events-to-show="4" data-param-detail_link="/aec-event/"  data-show-loading="true" data-param-etablishment-branch-id={antenne[4]}></div>
        </div>
        </Tab.Pane>
          </Tab.Content>
        </Row>
      </Row>
    </Tab.Container>
      
      
      <div className="arc-en-ciel container">
          {/* <div data-module="events" data-action="events-list-grid" data-param-quantity-column="3" data-param-event-type={location.state.type} data-events-to-show="2" data-param-detail_link="/aec-event/"  data-show-loading="true"></div> */}
          {/* <div data-module="events" data-action="events-list-grid" data-param-quantity-column="3" data-param-event-type={location.state.type ?location.state.type : ""} data-events-to-show="2" data-param-detail_link="/aec-event/"  data-show-loading="true"></div> */}
          {/*14-Musique,9-theatre,16-Danse,17-Expo,11-Cinma,18-Confrencesetrencontres,19-Jeunesse,28-Ateliers*/}
          </div>
        {/*
        <Col md={4}>
          <Card className="card">
            {/* <Card.Img variant="top" src={img1} /> *}
            <span className="zoom-effect-container bg-warning" style={{height:"250px"}}>
              <h4 style={{ top: "10px", position: "absolute" }}>
                <Badge
                  style={{
                    position: "absolute",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                  bg="danger"
                >
                  ALGER
                </Badge>
              </h4>
              <img src={img1} className="card-img-top h-100"  style={{objectFit:"cover"}}/>
            </span>
            <Card.Body>
              <Card.Title
                style={{ textAlign: "initial", color: "rgb(20, 20, 20)" }}
              >
                Ouverture d’un nouvel espace café restaurant
              </Card.Title>
              <Card.Text
                style={{
                  textAlign: "initial",
                  marginLeft: "-3px",
                  color: "rgb(119, 117, 119)",
                }}
              >
                Bienvenue aux « saveurs du savoir » qui fait peau neuve et
                rouvre ses portes au public dimanche 6 février 2022.
              </Card.Text>
            </Card.Body>
            <Card.Body
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="link"
                style={{
                  color: "black",
                  textDecorationColor: "#ffed00",
                  textDecorationThickness: "3px",
                }}
              >
                Lire la suite
              </Button>
              <Button variant="outline-danger" style={{ color: "black" }}>
                Info
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            {/* <Card.Img variant="top" src={img1} /> *}
            <span className="zoom-effect-container" style={{height:"250px"}}
              
            >
              <h4 style={{ top: "10px", position: "absolute" }}>
                <Badge
                  bg="kk"
                  style={{
                    position: "absolute",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    backgroundColor: "#3558a2",
                  }}
                >
                  ALGER
                </Badge>
              </h4>
              <img src={img2} className="card-img-top h-100"  style={{objectFit:"cover"}} />
            </span>
            <Card.Body>
              <Card.Title
                style={{ textAlign: "initial", color: "rgb(20, 20, 20)" }}
              >
                Exposition « Abd el-Kader » du 6 avril au 22 août 2022 au Mucem
              </Card.Title>
              <Card.Text
                style={{
                  textAlign: "initial",
                  marginLeft: "-3px",
                  color: "rgb(119, 117, 119)",
                }}
              >
                Emir de la résistance, saint combattant, fondateur de l’Etat
                algérien, précurseur de la codification du droit...
              </Card.Text>
            </Card.Body>
            <Card.Body
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="link"
                style={{
                  color: "black",
                  textDecorationColor: "#ffed00",
                  textDecorationThickness: "3px",
                }}
              >
                Lire la suite
              </Button>
              <Button variant="outline-danger" style={{ color: "black" }}>
                WEB
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            {/* <Card.Img variant="top" src={img1} /> *}
            <span className="zoom-effect-container" style={{height:"250px"}}>
              <h4 style={{ top: "10px", position: "absolute" }}>
                <Badge
                  bg="kk"
                  style={{
                    backgroundColor: "#00b6ad",
                    position: "absolute",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                  }}
                >
                  CONSTANTINE
                </Badge>
              </h4>
              <img src={img3} className="card-img-top h-100"  style={{objectFit:"cover"}} />
            </span>
            <Card.Body>
              <Card.Title
                style={{ textAlign: "initial", color: "rgb(20, 20, 20)" }}
              >
                « Territoires parallèles » exposition photo de Hamid Rahiche
              </Card.Title>
              <Card.Text
                style={{
                  textAlign: "initial",
                  marginLeft: "-3px",
                  color: "rgb(119, 117, 119)",
                }}
              >
                Ce projet photographique voudrait questionner le regard sur ces
                deux villes emblématiques de...
              </Card.Text>
            </Card.Body>
            <Card.Body
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="link"
                style={{
                  color: "black",
                  textDecorationColor: "#ffed00",
                  textDecorationThickness: "3px",
                }}
              >
                Lire la suite
              </Button>
              <Button variant="outline-danger" style={{ color: "black" }}>
                EXPO
              </Button>
            </Card.Body>
          </Card>
              </Col>*/}
      </Row>
    </Container>
  );
}
