import React from 'react'
import { Container} from "react-bootstrap";
import Bandeau from 'components/Etudes/Bandeau';
import Breadcrumb from 'components/breadcrumb/breadcrumb';
import MainTitle from 'components/General/MainTitle';
import BgShape from 'components/General/BgShape';
import { useParams } from 'react-router-dom';
import samples from 'data/SamplePages';
import CoverImg from 'components/General/CoverImg';
import { fetchAPIHomeDetails } from 'services/home-page';
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails } from 'react-shimmer-effects';
function SamplePage3({region, title=null}) {
    const {slug,title_url} = useParams()
    const selectedAction = samples[0]
    const [data, setData] = React.useState();
    const [loading, setLoading] = React.useState(true);
    React.useEffect(()=>{
      fetchAPIHomeDetails(title_url).then((x)=>{
        //console.log(x)
        var z=x.data['TempsForts']?.iv?.filter(x=>x.slug==slug || x.Titre==slug);
        //console.log(z)
        setData(z[0])
        setLoading(false)
      });
        window.scrollTo(0, 0)
    },[]);

      try{var text = parse(data?.Description)}catch($){
        text = data?.Description
      }
  return loading? (<ShimmerPostDetails card cta variant="SIMPLE" />) :(
    <div className=" position-relative overflow-hidden">
            {/*<BgShape type="yellow"  right="-50px" top="400px" rotate="-150deg"/>
            <BgShape type="blue"  left="-80px" top="900px" rotate="140deg"/>
            <BgShape type="red"  right="-60px" top="1300px" rotate="-140deg"/>*/}
            <CoverImg img={selectedAction.img}/>
            <Breadcrumb/>
            <Container>
              <MainTitle className="my-4">{data?.Titre??slug??title??selectedAction.title}</MainTitle>
              <p className='text-start lh-lg text-black-50  mb-5'>
              {text}
              </p>
            </Container>

              <Bandeau link={data?.lienCitation?.iv??'#'} style={{margin:"5rem 0"}}>
                  <p className='fs-5 my-0'>{data?.Citation}</p>
              </Bandeau>

            
    </div>
    
  )
}

export default SamplePage3