import React, { useContext, useEffect, useState } from 'react'
import { useParams,useNavigate,useLocation } from 'react-router-dom';
import { Container, Row, Col} from "react-bootstrap";
import { renderTestInfo } from 'helpers/Test et Examens/RenderTestInfo';
import CoverImg from 'components/General/CoverImg'
import cover from "../../assets/img/cover_exams.jpg";
import Breadcrumb from 'components/breadcrumb/breadcrumb';
import MainTitle from 'components/General/MainTitle';
import FAQTest from 'components/TestEtExamens/FAQTest';
import BgShape from 'components/General/BgShape';
import InscriptionCard from 'components/General/InscriptionCard';
import ContactCard from 'components/General/ContactCard';
import CalendrierTestCard from '../../components/TestEtExamens/CalendrierTestCard';
import DossierInscriptionCard from 'components/General/DossierInscriptionCard';
import { Examens } from 'data/tests et examens/Examens-delf';
import {TCF_tests as tests} from "data/tests et examens/Tests-TCF"
import { getTests } from 'services/tests-exams'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList,ShimmerSectionHeader,ShimmerFeaturedGallery } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext';

function DetailsTestPage({region}) {
// get the test title from the params of the url
  const {test_title} =  useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = React.useState(true);
  const { language } = useContext(LanguageContext);
// search test in Delfs : if it doesn
  //const searchedTest = Examens.find(el=>el.name === test_title) || tests.find(el=>el.name === test_title)
  ////console.log(searchedTest)
// scroll the page to the top in the initial render
React.useEffect(()=> {
  // if the test name is note in the tests list ==> redirect the user to the previous page (ie: TCFpage or DelfDalfPage)
    //!searchedTest && navigate(-1);
    window.scrollTo(0, 0);
},[])
const [test,setTest] = useState()
useEffect( () => {
  function fetchData() {
    //console.log(location.pathname.split('/').reverse())
    var type=location.pathname.split('/').reverse()[1]
    //console.log(type)
    getTests({region:region}).then((results)=>{
      //console.log(region)
      //console.log(results)
      //console.log(test_title)
      //console.log(results?.items?.[0]?.data?.tests?.[language])
      if(type==='delf-et-dalf'){
      //console.log(results?.items?.[0]?.data?.tests?.[language][0])
      var xx=results?.items?.[0]?.data?.tests?.[language];}
      else{
        var xx=results?.items?.[0]?.data?.tests?.[language]?.filter(e=>e.title===type.toUpperCase());
      }
      //console.log(xx)
      xx[0]?.subtests?.filter(x=>x.title===test_title)?.map(e => {
          //console.log(e)
          var img = e?.Image?.iv? e?.Image?.iv[0]: e?.Image?.[language]?e?.Image?.[language][0]:e?.Image?e?.Image[0]:''; //todo default image
getAsset(img).then((srcImage)=>{
  e.srcImage=srcImage;
  setTest(e );
  setLoading(false)
});
  //setExams(results.items?.[0]?.data?.tests)

});});
      
  }
  //console.log("xxx")
  fetchData();
}, [region,test_title,location]);
const [banner, setBanner] = useState();
useEffect( () => {
  if(test?.banner?.[language])
  getAsset(test?.banner?.[language]).then((srcImage)=>{
    setBanner(srcImage);
  });
  
}, []);
//console.log(region)
//console.log(test)
  return loading? (<><ShimmerPostDetails card cta variant="SIMPLE" /><ShimmerPostList postStyle="STYLE_FOUR" col={3} row={1} gap={30} /></>) :(
    <div className="position-relative overflow-hidden bg-grey z-10" dir={language==='ar'?"rtl":""}>
        {/*<BgShape type="red"  right="-50px" top="800px" rotate="-150deg"/>*/}
        {/*<BgShape type="yellow"  left="-80px" top="500px" rotate="140deg"/>*/}
        {/*<BgShape type="blue"  left="-80px" top="2000px" rotate="140deg"/>*/}
        <CoverImg img={banner??test?.srcImage??cover} />
        <Breadcrumb/>
        <Container >
          <Row className='my-3 text-start'>
          <MainTitle>{test_title}</MainTitle>
            <Col lg={8} className='mb-3 mb-md-0 text-black-50'>
              {renderTestInfo({test:test,test_title})}
              {/*region!=="national"&&
                <FAQTest/>
              */}
            </Col>

            <Col lg={4}>
                {region==="national" ? (
                    // <InscriptionTestCard region={region} testTitle={test_title}/>
                    <InscriptionCard region={region} url={test_title} type='test'/>
                ) : (
                <>
                    <InscriptionCard region={region} url={test_title} type='test' title={test_title}/>
                    {/*<DossierInscriptionCard content={test?.dossier}/>*/}
                    {/*<CalendrierTestCard data={test?.calendrier}/>*/}
                    {test?.entrainement? <DossierInscriptionCard content={test?.entrainement?.[language]} title="Comment s’entraîner ?"/>:<></>}
                    <ContactCard data={test?.contact}/>
                </>
                )}
            </Col>
          </Row>
        </Container>
    </div>
  )
}

export default DetailsTestPage