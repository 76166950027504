import React, {useContext, useEffect, useState} from 'react'
import { Container,Col,Row } from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import CoverImg from 'components/General/CoverImg'
import alger from '../../assets/img/alger.jpg'
import MainTitle from 'components/General/MainTitle'
import MissionCard from 'components/Institut/MissionCard'
import { Link } from 'react-router-dom'
import styles from "./institut.module.css"
import SocialSection from 'components/General/SocialSection'
import CityMenuCard from 'components/Cities/CityMenuCard'
import missions from "data/institut/missions"
import { useGlobal } from 'contexts/GlobalContext'
import { fetchAPIMissions } from 'services/institut-national'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'
function InstitutMissionsPage({ville}) {
    const {changeBackgroundColor} = useGlobal()
    const [data, setData] = React.useState();  
  const [mission, setMission] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const { language } = useContext(LanguageContext);
    useEffect(()=>{
        changeBackgroundColor("white")
      },[])
      useEffect( () => {
        function fetchData(ville) {
            fetchAPIMissions({region:ville}).then((items)=>{
            //const res = await fetchAPIInstitutDetails(results.posts[0].id)
            var el=items.items[0]?.data;
            //console.log(el);
            setMission(el);
            var d=[]
            if(el?.Missions[language??'fr'].length>0){
                el?.Missions[language??'fr'].map(e => {
                    var img = e?.Image?.iv? e?.Image?.iv[0]: e?.Image?.[language]? e?.Image?.[language][0] : e.Image? e.Image[0]:undefined;
                    d.push({title:e.Title,img:undefined,url:`${e?.Title??"#"}/${e?.slug??e?.Title}`,description:e.description,src:img })

                    /*if(img)
          getAsset(img).then((srcImage)=>{
            e.srcImage=srcImage;
            e.id=el.id;

                    d.push({title:e.Title,img:srcImage,url:`${e?.Title??"#"}/${e?.slug??e?.Title}`,description:e.description,src:img })
                    
                });
                else {

                    d.push({title:e.Title,img:undefined,url:`${e?.Title??"#"}/${e?.slug??e?.Title}`,description:e.description,src:img })
                    
                }*/
            });
            setData(d)
            setLoading(false)
            }else if(ville!=='national'){
                fetchData('national');
            }
            else{
            setData(missions)
            setLoading(false)}
          });
        }
          
        fetchData(ville);
    }, [data?.every(x=>x.title!==undefined&&x.title!==null&&x.title!==""),language]);
    const [banner, setBanner] = useState();
useEffect( () => {
  if(mission?.banner?.[language])
  getAsset(mission?.banner?.[language]).then((srcImage)=>{
    setBanner(srcImage);
  });
  
}, [mission?.banner?.[language]]);
    try{var text = parse(mission?.Description[language??'fr'])}catch($){
        text = mission?.Description?.[language??'fr']??""
      }
  return loading? (<><ShimmerPostDetails card cta variant="SIMPLE" /><ShimmerPostList postStyle="STYLE_FOUR" col={3} row={1} gap={30} /></>) :(
   <>
    <CoverImg img={banner??alger}/>
        <Breadcrumb/>
        <Container dir={language==='ar'?"rtl":""}>
         <MainTitle>Nos Missions</MainTitle>
         <div className='text-start text-black-50  fs-5 my-4' >
            {
                text
                ??
                <>
              <p className='lh-lg'>
                  L’Institut français d’Algérie intervient dans des domaines aussi variés que la diffusion culturelle et le
                  soutien aux artistes algériens et aux projets innovants, l’enseignement du français et l’accompagnement
                  des étudiants dans leurs projets d’études en France via Campus France, la mise en place de projets de
                  coopération avec les ministères algériens et leurs opérateurs, le soutien aux projets portés par la société
                  civile.
              </p>
              <p  className='lh-lg '>
                  Ouvert à toutes et tous, l’Institut français d’Algérie est un espace d’apprentissage et de
                  rencontres, de transmission et d’´échange de savoirs, dans un esprit d’´épanouissement, de respect,
                  de bienveillance et de joies partagées.
              </p></>}
          </div>

         
        
        <div >
            <Row className={`${styles} justify-content-center gap-3  py-3`} center>
                
                {
                data?.map((el,index)=>{
                    return (
                            <Col  md={5}>
                                <Link to={`${el?.title??"#"}/${el?.url??el?.title}`}>
                                    <CityMenuCard key={index}  menu={el} />
                                </Link>
                            </Col>
                            )
                })}
            </Row>
        </div>
        </Container>
        {/* old design */}
        {/* <div style={{backgroundColor:'rgb(53, 88, 162)',marginBottom:"0"}}>
            <Row className={styles.missions} >
                
                {missions.map((el,index)=>{
                    return (
                            <Col lg={6}>
                                <Link to={el.url}>
                                    <MissionCard key={index}  type={el.title} />
                                </Link>
                            </Col>
                            )
                })}
            </Row>
        </div> */}
        <SocialSection institut={true} ville={ville}/>
   </>
  )
}

export default InstitutMissionsPage