import { FaCheckCircle } from "react-icons/fa";


const CourseFeature = (Text)=>{
  //console.log(Text)
  return (
    <li className="d-flex align-items-center mb-1 gap-3">
      <div>
        <FaCheckCircle
          style={{
            color: "rgb(53, 88, 162)",
            fontSize: "14px",
            transform: "translateY(-0.8px)",
          }}
        />
      </div>
      <span>{Text?.Text}</span>
    </li>
  );
}

export default CourseFeature