import React,{useState} from 'react'
import styles from "./menuItemsList.module.css"
import Dropdown from './Dropdown'
import { Link,useLocation } from 'react-router-dom'



function MenuItems({items,depthLevel,className,style}) {
  const location = useLocation()
  const pathArray = location.pathname.split('/')
  const villes = ["alger","annaba","oran","constantine","tlemcen"]

  const [dropdown, setDropdown] = useState(false);

  // show the Dropdown menu when hovering on the menu item
  const onMouseEnter = () => {
   setDropdown(true);
   };
   // hide Dropdown menu when the mouse leave the menu item
   const onMouseLeave = () => {
    setDropdown(false);
   };
   // close the Dropdown when clicking on a menu item
   const closeDropdown = () => {
    dropdown && setDropdown(false);
  };

  // display all the submenu items of etudes together
  const etudesItems = (menu)=>{
    menu.submenu.map((sub,index)=>{
      return sub.title
    })
  }

  // display many forms of MenuItem
  const displayMenuItem = (items)=>{
    if (items.submenu) {
      if (items.title!=="Médiathèques") {
        return (
          <>
            <button type="button" aria-haspopup="menu" aria-expanded={dropdown ? "true" : "false"} style={style} >
              {depthLevel ===0 ? <Link to={items.url} className="fw-bold">{items.title.toUpperCase()}</Link>:<Link to={items.url} >{items.title}</Link>}{' '}
              {depthLevel > 0 ? <span>&raquo;</span> : <span className={styles.arrow} />}
              {/* <Link to={items.url} className="fw-bold">{items.title.toUpperCase()}</Link> */}
            </button>
            <Dropdown title={items.title} submenus={items.submenu} dropdown={dropdown} depthLevel={depthLevel} />
          </>
        )
      } else if(villes.includes(pathArray[1])){
        return (
          <>
            <button type="button" aria-haspopup="menu" aria-expanded={dropdown ? "true" : "false"} style={style} >
              {depthLevel ===0 ? <Link to={items.url} className="fw-bold">{items.title.toUpperCase()}</Link>:items.title}{' '}
              {depthLevel > 0 ? <span>&raquo;</span> : <span className={styles.arrow} />}
            </button>
            <Dropdown title={items.title} submenus={items.submenu} dropdown={dropdown} depthLevel={depthLevel} />    
          </>
        )
      }else{
        return <Link to={items.submenuNational.url} className="fw-bold">{items.submenuNational.title.toUpperCase()}</Link>
      }
    } else if (items.title =="cours de français"){
      return <Link to={items.url} className="fw-bold">{items.title.toUpperCase()}</Link>
    } else{
      return <Link to={items.url} >{items.title}</Link>
    }
  }
 
  return (
    <li className={`nav-item ${styles.menu_items} ${className}`}  onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={closeDropdown}>
      {/* display the submenu items directly for "Etudes" */}
      {
        displayMenuItem(items)
      }
    </li>
  )

}

export default MenuItems