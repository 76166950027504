import './style.css';
export default ({
    
  }) => {
    
    return (
    <ol className="breadcrumb d-flex justify-content-start">
    <li><a href="/">ACCUEIL</a></li>
    <li className="active"><a href="/mediatheque">MÉDIATHÈQUES</a></li>
</ol>);
  }