import { Col, Row } from 'react-bootstrap';
import CoursePriceCard from './CoursePriceCard';

const CoursePrice = ({data}) => {
    const coursePackages = [
        {
            price:14_000,
            description: "Session de formation de 40 heures",
            features:["Test de niveau","Formation","livre","CD audio"]
        },
        {
            price:10_000,
            description: "20 de cours de conversation",
            features:["Test de niveau","Formation","livre","CD audio"]
        },
        {
            price:18_000,
            description: "Session de formation de 40 heures, écrits profesionnels",
            features:["Test de niveau","Formation","livre","CD audio"]
        },
        {
            price:12_000,
            description: "Tarif préférentiel pour la 3 ème inscription à une session de formation de cours FLE",
            features:["Test de niveau","Formation","livre","CD audio"]
        }
    ]
    
  return (
    <>
     <div className='my-3'>
                <h3 className='text-start blue-ifa fw-bold my-4'>Coûts  des  cours</h3>
                <Row>

                    {data?.map((pack,index)=>{
                        return (
                            <Col key={index} lg={6} className="mb-3 mx-auto">
                                <CoursePriceCard {...pack} />
                            </Col>
                        )
                    })}
                </Row>
    </div> 
</>
  )
}

export default CoursePrice