export const regions = [
    {
        id:16,
        name:"Alger"
    },
    {
        id:31,
        name:"Oran"
    },
    {
        id:25,
        name:"Constantine"
    },
    {
        id:23,
        name:"Annaba"
    },
    {
        id:13,
        name:"Tlemcen"
    }
  ]