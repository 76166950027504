//Regions
// - intitut
export const intitutRoutes=[
    {region:'alger', url:'api/content/ifa/institut-alger'},
    {region:'annaba', url:'api/content/ifa/institut-annaba'},
    {region:'constantine', url:'api/content/ifa/institut-constantitne'},
    {region:'oran', url:'api/content/ifa/institut-oran'},
    {region:'tlemcen', url:'api/content/ifa/institut-tlemecen'},
]
// - missions
export const missionsRoutes=[
    {region:'alger', url:'api/content/ifa/missions-alger'},
    {region:'annaba', url:'api/content/ifa/missions-annaba'},
    {region:'constantine', url:'api/content/ifa/missions-constantine'},
    {region:'oran', url:'api/content/ifa/missions-oran'},
    {region:'tlemcen', url:'api/content/ifa/missions-tlemcen'},
]
// - espaces
export const espacesRoutes=[
    {region:'alger', url:'api/content/ifa/nos-espaces-alger'},
    {region:'annaba', url:'api/content/ifa/nos-espaces-annaba'},
    {region:'constantine', url:'api/content/ifa/nos-espaces-constantine'},
    {region:'oran', url:'api/content/ifa/nos-espaces-oran'},
    {region:'tlemcen', url:'api/content/ifa/nos-espaces-tlemcen'},
] 

// - cours
export const coursRoutes=[
    {region:'alger', url:'api/content/ifa/cours-alger'},
    {region:'annaba', url:'api/content/ifa/cours-annaba'},
    {region:'constantine', url:'api/content/ifa/cours-constantine'},
    {region:'oran', url:'api/content/ifa/cours-oran'},
    {region:'tlemcen', url:'api/content/ifa/cours-tlemcen'},
    {region:'national', url:'api/content/ifa/cours-francais'},
] 

export const testsRoutes=[
    {region:'alger', url:'api/content/ifa/testsexams-alger'},
    {region:'annaba', url:'api/content/ifa/testsexams-annaba'},
    {region:'constantine', url:'api/content/ifa/testsexams-constantine'},
    {region:'oran', url:'api/content/ifa/testsexams-oran'},
    {region:'tlemcen', url:'api/content/ifa/testsexams-tlemcen'},
    {region:'national', url:'api/content/ifa/testsexams'},
] 

// - mediatheque
export const mediathequeRoutes=[
    {region:'alger', url:'api/content/ifa/mediatheque'},
    {region:'annaba', url:'api/content/ifa/mediatheque-annaba'},
    {region:'constantine', url:'api/content/ifa/mediatheque-constantine'},
    {region:'oran', url:'api/content/ifa/mediatheque-oran'},
    {region:'tlemcen', url:'api/content/ifa/mediatheque-tlemcen'},
    {region:'national', url:'api/content/ifa/mediathequenational'},
] 

// - equipe
export const equipeRoutes=[
    {region:'alger', url:'api/content/ifa/equipe-alger'},
    {region:'annaba', url:'api/content/ifa/equipe-annaba'},
    {region:'constantine', url:'api/content/ifa/equipe-constantine'},
    {region:'oran', url:'api/content/ifa/equipe-oran'},
    {region:'tlemcen', url:'api/content/ifa/equipe-tlemcen'},
    {region:'national', url:'api/content/ifa/equipe'},
] 