const samples = [
    {
      id:1,
      title:"Recrutement",
      img:"https://images.unsplash.com/photo-1617469165786-8007eda3caa7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
      url:"recrutement"
    },
    {
      id:2,
      title:"Action 2",
      img:"https://images.unsplash.com/photo-1595168318069-217391d91943?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=813&q=80",
      url:"action 2"
    },
    {
      id:3,
      title:"Action 3",
      img:"https://images.unsplash.com/photo-1612164617328-df5179f61327?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
      url:"action 3"
    },
    {
      id:4,
      title:"Action 4",
      img:"https://images.unsplash.com/photo-1572697151057-4c7393469530?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1102&q=80",
      url:"action 4",
    },
    {
      id:5,
      title:"Action 5",
      img:"https://images.unsplash.com/photo-1583658219752-9b6d62e0b83f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
      url:"action 5"
    },
  ]

  export default samples;