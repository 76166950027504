import React,{createContext,useEffect,useState,useRef} from 'react'
import CoverImg from 'components/General/CoverImg'
import { Container} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import culture from "../../assets/img/culture.jpg"
import BgShape from 'components/General/BgShape'
import SocialSection from 'components/General/SocialSection'

export const filterContext = createContext('')


function JeunessePage({type,city,brd}) {

  return (
    <div className=" position-relative overflow-hidden">
      {/*<BgShape type="yellow" right="-40px" top="80px" rotate="-140deg" />*/}
      {/*<BgShape type="blue" right="-50px" top="800px" rotate="-150deg" />*/}
      {/*<BgShape type="red" left="-80px" top="500px" rotate="140deg" />*/}
      <CoverImg img={culture} />
      <Breadcrumb brd={brd} />

        <Container>
          <div className="arc-en-ciel my-5">
              <div data-module="events" data-action="events-list" data-param-quanty-column="3" data-param-event-type="19-Jeunesse" data-events-to-show="1" data-param-detail_link="/aec-event/"></div>
          </div>
        </Container>
      <SocialSection institut={true} ville={city}/>
    </div>
  );
}

export default JeunessePage