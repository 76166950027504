import React, { useContext } from 'react'
import { Container} from "react-bootstrap";
import Bandeau from 'components/Etudes/Bandeau';
import Breadcrumb from 'components/breadcrumb/breadcrumb';
import MainTitle from 'components/General/MainTitle';
import BgShape from 'components/General/BgShape';
import { useParams } from 'react-router-dom';
import samples from 'data/SamplePages';
import CoverImg from 'components/General/CoverImg';
import { fetchAPIProjects } from 'services/institut-national'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import EventsGrid from 'components/General/EventsGrid';
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext';
function SamplePage1({region, title=null}) {
  const [institut, setInstitut] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const { language } = useContext(LanguageContext);
    React.useEffect(()=>{
        window.scrollTo(0, 0)
    },[])
    React.useEffect( () => {
      function fetchData() {
        fetchAPIProjects().then((items)=>{
          //const res = await fetchAPIInstitutDetails(results.posts[0].id)
          var el=items.items[0]?.data;
          //console.log(el);
          var img = el?.Image?.iv? el?.Image?.iv[0]: el?.Image?.[language][0];
          getAsset(img).then((url)=>{
            el.srcImage=url;
            el.id=items.items[0]?.id;
            setInstitut(el);
            setLoading(false)
        });
          
        });
      }
        
      fetchData();
  }, []);
  try{var text = parse(institut?.Description?.iv??institut?.Description[language??'fr'])}catch($){
    text = institut?.Description[language??'fr']
  }
  try{var citation = parse(institut?.Citation?.iv??institut?.Citation[language??'fr'])}catch($){
    citation = institut?.Citation[language??'fr']
  }
  return loading? (<><ShimmerPostDetails card cta variant="SIMPLE" /><ShimmerPostList postStyle="STYLE_FOUR" col={3} row={1} gap={30} /></>) :(
    <div className=" position-relative overflow-hidden" dir={language==='ar'?"rtl":""}>
            {/*<BgShape type="yellow"  right="-50px" top="400px" rotate="-150deg"/>
            <BgShape type="blue"  left="-80px" top="900px" rotate="140deg"/>
  <BgShape type="red"  right="-60px" top="1300px" rotate="-140deg"/>*/}
            <CoverImg img={institut?.srcImage??""}/>
            <Breadcrumb/>
            <Container >
              <MainTitle className="my-4">{title}</MainTitle>
              {text??
              <p className='text-start lh-lg text-black-50  mb-5'>
              L’Institut français d’Algérie a développé de nombreux instruments pour accompagner les artistes, associations, entrepreneurs du monde de la culture pour mener à bien leurs projets.
              L’Institut français d’Algérie lance régulièrement des appels à projet thématiques (musique, écriture, langue française, cinéma etc …) ouverts à des acteurs multiples.
              </p>}
            </Container>

              <Bandeau link={institut?.lienCitation?.iv??'#'} style={{margin:"5rem 0"}}>
                  {citation??<p className='fs-5 my-0'>Consultez nos appels à projets en cours !</p>}
              </Bandeau>
              {<Container>
              
              <EventsGrid title="En ce moment :" type="Appels à projets" region={region&&region}/>
  </Container>}
            
    </div>
    
  )
}

export default SamplePage1