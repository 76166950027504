import React,{useState,useEffect, useContext} from 'react'
import { Container , Row,Col} from 'react-bootstrap'
import essa from "../../assets/img/essa-img.jpg"
import doctorat from "../../assets/img/doctorat-img.jpg"
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'
import Bandeau from 'components/Etudes/Bandeau'
import EventsGrid from 'components/General/EventsGrid'
import EtudesCarousel from 'components/Etudes/EtudesCarousel'
import BgShape from 'components/General/BgShape'
import styles from "./EtudierEnFrance.module.css"
import { getEtude } from 'services/etudes'
import { getAsset } from 'services/assets'
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'
function EsaaPage() {
    const esaaLink = "https://www.esaa.dz/"
    const [data, setData] = useState()
    const [loading, setLoading] = React.useState(true);
    const { language } = useContext(LanguageContext);
    useEffect( () => {
      function fetchData() {
        getEtude('national').then((results)=>{
          //console.log(results?.items?.filter(x=>x.data?.slug.iv.toLowerCase()==='essa'))
setData(results?.items?.filter(x=>x.data?.slug.iv.toLowerCase()==='essa')?.[0]?.data);    
setLoading(false)
} )} 
      fetchData();
  }, []);
  const [srcImage,setSrcImage] = useState();
  React.useEffect( () => {

    function fetchData() {
              
            var img = data?.Image?.iv? data?.Image?.iv[0]: data?.Image?.[language]?data?.Image?.[language][0]:data?.Image?data?.Image[0]:''; //todo default image
            
      if(img)
  getAsset(img).then((srcImage)=>{
//console.log(srcImage)
    setSrcImage(srcImage);
    setLoading(false)
        })
      }
       fetchData() 
    },[data]);
  
//console.log(data)

try{var text = parse(data?.Description?.[language])}catch($){
  text = data?.Description?.[language]
}
  return loading? <ShimmerPostDetails card cta variant="EDITOR" /> :(
    <div className=" position-relative overflow-hidden" dir={language==='ar'?"rtl":""}>
       {/*<BgShape type="red"  right="-60px" top="1000px" rotate="-140deg"/>*/}
       {/*<BgShape type="yellow"  right="-50px" bottom="200px" rotate="-150deg"/>*/}
       {/*<BgShape type="blue"  left="-80px" bottom="500px" rotate="140deg"/>*/}

        <EtudesCarousel type="esaa"/>
        <Breadcrumb/>
        <Container >
        <MainTitle className="my-4">{data?.Titre?.[language]??'France alumni'}</MainTitle>
            {/* Programmes de coopération */}
            <div className='mt-3 text-start text-black-50'>
            {text??
            <><p className=''>Différents   programmes   permettent   de   soutenir   l'Enseignement   Supérieur   et   la Recherche sur le sol algérien :</p>
                
                <Row className={`text-start my-5 ${styles.essa}`}>
                        <Col md={6} className='mb-3 mb-md-0'>
                                <h4>L'Ecole Superieure Algerienne  des  Affaires </h4>
                                <p>
                                Née de la volonté des Présidents algérien et français en 2004, l’Ecole supérieure algérienne des affaires (ESAA) est le premier établissement supérieur mixte algéro-français d’enseignement supérieur, créé dans un contexte de transition économique en faveur d’une économie de marché en Algérie.

Depuis sa création, l’ESAA s’évertue à honorer l’excellence en s’appuyant sur des valeurs et des référents internationaux pour former les talents aux potentiels élevés. Reposant sur la double diplomation, l’établissement figure aujourd’hui parmi les meilleurs de sa catégorie à l’échelle africaine.

Retrouvez toutes les informations nécessaires concernant les formations disponibles sur le site de l’école.
                                </p>
                                <h4>La formation des étudiants et doctorants en Algérie </h4>
                                <p>
                                L’Institut français d’Algérie contribue à l’organisation annuelle par l’Institut de Recherche sur le Maghreb Contemporain (IRMC) de Tunis d’une masterclass ouverte aux étudiants inscrits en Master 2 ou en doctorat au sein d’une université algérienne, dans une discipline de sciences humaines et sociales SHS (anthropologie, géographie, histoire, sciences politiques, sociologie…). Par ses ressources locales de haut niveau, l’IRMC permet aux étudiants sélectionnés de faire mûrir leur sujet de recherche en même temps qu’ils consolideront leurs connaissances méthodologiques en SHS.
                                </p>
                                <h4>Soutien aux projets de coopération scientifique et universitaire entre établissements français et algériens à travers les deux programmes suivants : </h4>
                                <p>
                                o   Programme de l’Institut français d’Algérie pour le soutien des projets de coopération scientifique et universitaire entre des universités françaises, des établissements français d’enseignement supérieur, des centres français de recherche et leurs homologues en Algérie.

o   Le partenariat Hubert Curien PHC Tassili, qui apporte un soutien à des projets de recherche conjoints pour une durée de 3 ans, notamment sur la mobilité au sein de laboratoires français, de doctorants algériens, et de jeunes enseignants-chercheurs préparant une habilitation, d'une part, et sur la mobilité de chercheurs confirmés français et algériens entre la France et l'Algérie, d'autre part.
                                </p>

                        </Col>
                        <Col  md={6} className='mb-3 mb-md-0'>
                             <img src={essa} className="w-100" alt=''/>
                        </Col>
                </Row>
</>}
            </div>
        </Container>
        <Bandeau className="mb-5" link={esaaLink} >
            <p className='fs-5 my-0 text-center'>{data?.Citation?.[language]??"Retrouvez toutes les informations sur le site officiel de <span className='red-ifa fw-bold'> ESAA </span>"}</p>
        </Bandeau>
        {/*<Container>
            <MainTitle >Agenda</MainTitle>
            <EventsGrid type="ESAA"/>
        </Container>*/}
    </div>
  )
}

export default EsaaPage