import React from 'react'

import {Row,Col} from "react-bootstrap";
const CulturetecCard = () => {
  return (
    <a  href="https://www.culturetheque.com/DZA/" className=" col-sm-12 col-md-6" target="_blank" rel="noopener noreferrer">
                    <Col  style={{ marginBottom:"20px" }} className="hover hover-custom">
                      
                      
                        <div
                          className="cultureteque-img"
                          style={{
                            textAlign: "initial",
                            position: "relative",
                            height: "12.5rem",
                            overflow: "hidden",
                          }}
                        >
                          <div className="hover-overlay"></div>
                          <div className="hover-custom-content">
                          <span className="hover-custom-title"
                            style={{
                              textAlign: "initial",
                              position: "absolute",
                              bottom: "0",
                              left: "20px",
                              color: "white",
                              fontSize: "1.5rem",
                            }}
                          >
                            
                          </span>
                          <p className="hover-custom-description small text-uppercase mb-0"></p>
                          </div>
                        </div>
                    
                    </Col>
                  </a>
  )
}

export default CulturetecCard