import React from 'react'

function MissionLinks({children}) {
  return (
    <div className='p-2 bg-blue-ifa-light'>
            {children}
    </div>
  )
}

export default MissionLinks