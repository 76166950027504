const presentaions = [
    {
        region:"national",
        text :[
            {
                id:1,
                paragraph : "Créé en 2012 en regroupant les anciens CCF,   l’Institut  français  d’Algérie  constitue  la  principale  plateforme  de  dialogue  entre  la  France  et  l’Algérie dans les domaines de la culture et du savoir. Ses principales missions sont l’organisation d’évènements culturels et la coopération avec les institutions publiques algériennes et les acteurs de la société civile algérienne dans le domaine culturel, universitaire, éducatif, associatif."
            },
            {
                id:2,
                paragraph : "L’Institut français d’Algérie à son siège à Alger. Il est composé d’une direction générale et de cinq antennes, à Alger, Annaba, Constantine, Oran et Tlemcen."
            },
            {
                id:3,
                paragraph : "Chacune des cinq antennes propose des cours de français, accueille et accompagne les étudiants d´césireux  de  poursuivre  leur  formation  supérieure  en  France,  organise  des  évènements culturels et des projections de cinéma, organise des ateliers et des masters class et offre les services d’une médiathèque."
            }
        ]
    },
    {
        region:"alger",
        text :[
            {
                id:1,
                paragraph : `Dans une bâtisse historique du 19 ème siècle au cœur d’Alger,
                l’antenne d’Alger de l’Institut français d’Algérie a réouvert ses
                portes en mars 2000 après six ans de fermeture. Au sein d’une cour
                intérieure arborée et baignée de lumière, vous pourrez profiter
                d’espaces de convivialité et d’un café-restaurant qui vous
                accueille toute la journée.`
            },
            {
                id:2,
                paragraph : `Le bâtiment abrite une salle de spectacle et de cinéma, une
                salle d’exposition, une médiathèque ainsi que des salles de
                cours pour l’apprentissage du français.`
            },
            {
                id:3,
                paragraph : `L’antenne d’Alger dispose également d’une annexe située à Hydra
                abritant un espace Campus France, qui accueille les étudiants
                souhaitant en savoir plus sur les opportunités, les formations,
                les procédures et la vie étudiante en France, ainsi que des salles
                de cours pour le jeune public avec un mobilier adapté.`
            }
        ],
        img:"https://plus.unsplash.com/premium_photo-1664283661776-a61b119f6342?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
    },
    {
        region:"oran",
        text :[
            {
                id:1,
                paragraph : `Dans une bâtisse historique du 19 ème siècle au cœur d’Alger,
                l’antenne d’Alger de l’Institut français d’Algérie a réouvert ses
                portes en mars 2000 après six ans de fermeture. Au sein d’une cour
                intérieure arborée et baignée de lumière, vous pourrez profiter
                d’espaces de convivialité et d’un café-restaurant qui vous
                accueille toute la journée.`
            },
            {
                id:2,
                paragraph : `Le bâtiment abrite une salle de spectacle et de cinéma, une
                salle d’exposition, une médiathèque ainsi que des salles de
                cours pour l’apprentissage du français.`
            },
            {
                id:3,
                paragraph : `L’antenne d’Alger dispose également d’une annexe située à Hydra
                abritant un espace Campus France, qui accueille les étudiants
                souhaitant en savoir plus sur les opportunités, les formations,
                les procédures et la vie étudiante en France, ainsi que des salles
                de cours pour le jeune public avec un mobilier adapté.`
            }
        ],
        img:"https://images.unsplash.com/photo-1532531427586-3cf3d12585f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80"
    },
    {
        region:"constantine",
        text :[
            {
                id:1,
                paragraph : `Dans une bâtisse historique du 19 ème siècle au cœur d’Alger,
                l’antenne d’Alger de l’Institut français d’Algérie a réouvert ses
                portes en mars 2000 après six ans de fermeture. Au sein d’une cour
                intérieure arborée et baignée de lumière, vous pourrez profiter
                d’espaces de convivialité et d’un café-restaurant qui vous
                accueille toute la journée.`
            },
            {
                id:2,
                paragraph : `Le bâtiment abrite une salle de spectacle et de cinéma, une
                salle d’exposition, une médiathèque ainsi que des salles de
                cours pour l’apprentissage du français.`
            },
            {
                id:3,
                paragraph : `L’antenne d’Alger dispose également d’une annexe située à Hydra
                abritant un espace Campus France, qui accueille les étudiants
                souhaitant en savoir plus sur les opportunités, les formations,
                les procédures et la vie étudiante en France, ainsi que des salles
                de cours pour le jeune public avec un mobilier adapté.`
            }
        ],
        img:"https://images.unsplash.com/photo-1622164287441-7dc50ec3ee4d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
    },
    {
        region:"annaba",
        text :[
            {
                id:1,
                paragraph : `Dans une bâtisse historique du 19 ème siècle au cœur d’Alger,
                l’antenne d’Alger de l’Institut français d’Algérie a réouvert ses
                portes en mars 2000 après six ans de fermeture. Au sein d’une cour
                intérieure arborée et baignée de lumière, vous pourrez profiter
                d’espaces de convivialité et d’un café-restaurant qui vous
                accueille toute la journée.`
            },
            {
                id:2,
                paragraph : `Le bâtiment abrite une salle de spectacle et de cinéma, une
                salle d’exposition, une médiathèque ainsi que des salles de
                cours pour l’apprentissage du français.`
            },
            {
                id:3,
                paragraph : `L’antenne d’Alger dispose également d’une annexe située à Hydra
                abritant un espace Campus France, qui accueille les étudiants
                souhaitant en savoir plus sur les opportunités, les formations,
                les procédures et la vie étudiante en France, ainsi que des salles
                de cours pour le jeune public avec un mobilier adapté.`
            }
        ],
        img:"https://images.unsplash.com/photo-1646864052402-9c2b3d7d22e7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1034&q=80"
    },
    {
        region:"tlemcen",
        text :[
            {
                id:1,
                paragraph : `Dans une bâtisse historique du 19 ème siècle au cœur d’Alger,
                l’antenne d’Alger de l’Institut français d’Algérie a réouvert ses
                portes en mars 2000 après six ans de fermeture. Au sein d’une cour
                intérieure arborée et baignée de lumière, vous pourrez profiter
                d’espaces de convivialité et d’un café-restaurant qui vous
                accueille toute la journée.`
            },
            {
                id:2,
                paragraph : `Le bâtiment abrite une salle de spectacle et de cinéma, une
                salle d’exposition, une médiathèque ainsi que des salles de
                cours pour l’apprentissage du français.`
            },
            {
                id:3,
                paragraph : `L’antenne d’Alger dispose également d’une annexe située à Hydra
                abritant un espace Campus France, qui accueille les étudiants
                souhaitant en savoir plus sur les opportunités, les formations,
                les procédures et la vie étudiante en France, ainsi que des salles
                de cours pour le jeune public avec un mobilier adapté.`
            }
        ],
        img:"https://images.unsplash.com/photo-1547653872-052e3539decc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
    }
]

export default presentaions