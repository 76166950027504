import { Examens } from "data/tests et examens/Examens-delf"
import parse from 'html-react-parser'

const getDelf=(title)=>{
  const filtredDelf = Examens.find(exam=>exam.name.toLowerCase()===title)
  return (
    <div>
      <img src={filtredDelf.img} className="w-100" alt={filtredDelf.name} />
      <p className="lh-lg mt-3">{filtredDelf.intro}</p>
    </div>
  ) 
}

export  const renderTestInfo = (test,testName)=>{
  try{
    var text = parse(test?.test?.description)
  }catch($){
    text = test?.test?.description
  }
  //console.log(test.test)
    if(test){
      return (<div className="text-black-50" >
      <p>
          {
            text
          }
      </p></div>)
    }
    else
    switch (testName?.toLowerCase()) {
      case ('tcf dap'):
        return(
          <div className="text-black-50" >
            <p>
                Le TCF dans le cadre de la demande d'admission préalable (DAP)
                est destiné à toute personne étrangère de plus de 16 ans
                souhaitant intégrer un licence 1 ou une école d'architecture au
                sein d'un établissement d'enseignement supérieur en France. Il
                évalue le niveau de langue (de A1 à C1).
            </p>
          <p className="mt-4">
            Ce TCF est composé de 4 épreuves obligatoires :
          </p>
          <ul>
            <li className="mb-3">
              Trois QCM évaluant les compétences suivantes :
              <ul className="lh-lg ms-4">
                <li className="me-2">
                  <b>Compréhension orale </b>(25 minutes)
                </li>
                <li className="me-2" >
                  <b>Maîtrise des structures de la langue </b>(15 minutes)
                </li>
                <li className="me-2">
                  <b>Compréhension écrite</b> (45 minutes)
                </li>
              </ul>
            </li>
            <li>
              Une épreuve <b>d'expression écrite</b> (60 minutes)
            </li>
          </ul>
          <p>
            L'attestation a une{" "}
            <span
              style={{ fontWeight: "bold", color: "rgb(53, 88, 162)" }}
            >
              {" "}
              durée validité de 2 ans{" "}
            </span>{" "}
            à partir de la date de délivrance des résultats.
          </p>
          </div>
      ) 
      case('tcf tp tout public'):
      return(
        <div className="text-black-50">
        <p>
          L'objectif principal du TCF tout public est de permettre aux
          candidats de faire recon- naitre leur niveau de français dans
          le cadre de poursuite d'études en France et dans les pays
          francophones. Il est cependant également utilisable :
        </p>
        <ul className=" ms-md-4 lh-lg ">
          <li className="mb-2">
            <b>
              dans la cadre d'une demande de nationalité française ou de
              demande de carte de résident{" "}
            </b>{" "}
            en France (lorsque toutes les épreuves obligatoires et
            complémentaires sont présentées)
          </li>

          <li className="mb-2">
            dans le cadre d'un dossier de{" "}
            <b> Pratique d'autorisation de la médecine </b> (lorsque
            toutes les épreuves obligatoires et complémentaires sont
            présentées)
          </li>

          <li className="mb-2">
            {" "}
            dans le cadre d'une <b>demande d'immigration au Qu'bec</b>
          </li>

          <li className="mb-2">
            {" "}
            dans un <b>cadre professionnel</b>
          </li>
        </ul>
        <p>Ce TCF est composé de :</p>
        <ul className=" ms-md-4 lh-lg">
          <li>
            3 épreuves obligatoires (QCM) et de 2 épreuves
            complémentaires
          </li>

          <li>
            3 épreuves facultatives (exercices d'expressions écrites et
            orales)
          </li>
        </ul>
        </div>
    )
    case ('tcf canada'):
      return (
        <div className="text-black-50" >
            <p>
              L'objectif principal du TCF Canada est de permettre aux
              candidats de faire reconnaitre leur niveau de français dans le{" "}
              <b>
                cadre d'une demande d'immigration ou de citoyenneté au
                Canada
              </b>
              . Il est cependant également utilisable :
            </p>
            <ul className="ms-md-4 lh-lg">
              <li>
                dans la cadre d'une{" "}
                <b>
                  demande de nationalité française ou de demande de carte de
                  résident en France
                </b>
              </li>
              <li>
                dans le cadre d'un{" "}
                <b>dossier de Pratique d'autorisation de la médecine</b>
              </li>
              <li>
                dans le cadre d'une <b>demande d'immigration au Québec</b>
              </li>
              <li>
                dans un <b>cadre professionnel.</b>
              </li>
            </ul>
            Ce TCF est composé de 4 épreuves obligatoires et a une durée
            totale de 2 heures 47:
            <ul className="lh-lg ms-md-4 mt-3 mb-2">
                <li>
                  <b>Compréhension orale</b> (QCM, 35 minutes)
                </li>
                <li>
                  <b>Compréhension écrite </b> (QCM, 60 minutes)
                </li>             
                <li >
                  <b>Expression écrite </b>(3 exercice, 60 minutes)
                </li>
                <li >
                  <b>Expression orale </b> (3 exercices, 12 minutes)
                </li>
              </ul>
            <p className="mt-3">
              Dans votre dossier d'immigration, vous devrez reporter ces
              ésultats grâce au niveau de cométence linguistique canadien
              (NCLC) correspondant a` vos ésultats.
            </p>
          </div>
      )
    case('delf prim'):
    return getDelf("delf prim")
    case('delf junior'):
    return getDelf('delf junior')
    case('delf tout public'):
    return getDelf('delf tout public')
    case('delf pro'):
    return getDelf('delf pro')
    case('dalf'):
    return getDelf('dalf')
      default:
        return ""
        break;
    }
}