import Breadcrumb from 'components/breadcrumb/breadcrumb'
import CoverImg from 'components/General/CoverImg'
import MainTitle from 'components/General/MainTitle'
import alger from '../../assets/img/alger.jpg'
import React, {useContext, useEffect, useState} from 'react'
import mission from "../../assets/img/alger3.jpg"
import { Container,Card,Row,Col } from 'react-bootstrap'
import { getMentionsLegales } from 'services/footer'
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser'
import { getAsset } from 'services/assets'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'
const MentionsLegales = ({ville}) => {
  const [data, setData] = React.useState();  
  const [loading, setLoading] = React.useState(true);
  const { language } = useContext(LanguageContext);
    useEffect(()=>{
        window.scrollTo({top:0})
        getMentionsLegales().then((x)=>{
            //console.log(x)
            var z=x.items[0].data;
            //console.log(z)
            setData(z)
            setLoading(false)
          });
      },[])
      const [banner, setBanner] = useState();
useEffect( () => {
  if(data?.banner?.[language])
  getAsset(data?.banner?.[language]).then((srcImage)=>{
    setBanner(srcImage);
  });
  
}, [data?.banner?.[language]]);
      try{var text = parse(data?.Description?.[language])}catch($){
        text = data?.Description?.[language]
      }
  return loading? <ShimmerPostDetails card cta variant="SIMPLE" /> :(
    <>
        <CoverImg img={banner}/>
        <Breadcrumb/>
        <Container dir={language==='ar'?"rtl":""}>
            <MainTitle>{data?.Titre?.[language]??("Mentions Legales")}</MainTitle>
            <div>
            <p>
{text}
            </p>
            </div>
        </Container>
    </>
  )
}

export default MentionsLegales