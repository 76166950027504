
import React,{useContext} from 'react'
import { Dropdown,Button} from 'react-bootstrap'
import {filterContext} from "../../pages/Agenda pages/AgendaPage"



// filter styles
const SELECTED_FILTER = {
  backgroundColor: 'rgb(53, 88, 162)',
  color:'white'
}
const UNSELECTED_FILTER ={
  backgroundColor:"transparent",
  color:'rgb(53, 88, 162)',
  borderColor:'rgb(53, 88, 162)'
}

// filters data
const futurMonths = ["Septembre 2022","Octobre 2022","Novembre 2022","Décembre 2022","Janvier 2023","Février 2023","Mars 2023","Avril 2023","Mai 2023","Tous les mois"]
const pastMonths = ["Août 2022","Juillet 2022","Juin 2022","Mai 2022","Avril 2022","Mars 2022","Février 2022","Janvier 2022","Tous les mois"]
const activities = ["Musique","Théatre","Danse","Expo","Cinéma","Conférences et rencontres","Jeunesse","Toutes les activités"]
const regions = ["Alger","Annaba","Constantine","Oran","Tlemcen","Toute l'Algérie"]



function Filters({type,time,city}) {
    // get the value of the Fitler Context
    const {all,setAll,today,setToday,week,setWeek,month,setMonth,activity,setActivity,region,setRegion} = useContext(filterContext)

    // handle the Filter for the 03 btns : Tout, Ajourd'hui, cette semaine
    const handleFilter =(e)=>{
  
      switch (e.target.id) {
        case "all":
          setAll({...all,[time]:true})
          setToday(false)
          setWeek(false)
          setActivity({...activity,[time]:""})
          setRegion({...region,[time]:""})
          setMonth({...month,[time]:""})
          break;
        case "today":
          setToday(!today)
          setAll({...all,[time]:false})
          setWeek(false)
          break;
        case "week":
          setWeek(!week)
          setAll({...all,[time]:false})
          setToday(false)
          break
        default:
          break;
      }
    }

    // update the 'month' state
    const selectMonth =(selectedMonth,time)=>{
      if(selectedMonth==="Tous les mois"){
        setMonth({...month,[time]:""})
      }else if(selectedMonth==="Ce mois-ci"){
        const months = ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre"]
        const date = new Date()
        const year = date.getFullYear()
        const month = date.getMonth()
        const currentMonth = months[month]+' '+year
        setMonth({...month,[time]:currentMonth})
      }else{
        setMonth({...month,[time]:selectedMonth})
      }
    }

    // update the 'activity' state
    const selectActivity=(selectedActivity,time)=>{
      if(selectedActivity==="Toutes les activités"){
        setActivity({...activity,[time]:""})
      }else{
        setActivity({...activity,[time]:selectedActivity})
      }


    }

    // update the 'region' state
    const selectRegion=(selectedRegion)=>{
      if(selectedRegion==="Toute l'Algérie"){
        setRegion({...region,[time]:""})
      }else{
        setRegion({...region,[time]:selectedRegion})
      }
      // setSearchParams({region:selectedRegion})
      // filterEvents(selectedRegion.toLowerCase())
    }



    
  return (
         <div className='filter d-flex flex-wrap align-items-center gap-2 my-4'>
            <Button  onClick={(handleFilter)} id="all" style={all[time]?SELECTED_FILTER:UNSELECTED_FILTER}>Tout</Button>
            {time==="future"&&
            <>
                <Button  onClick={handleFilter} id="today" style={today?SELECTED_FILTER:UNSELECTED_FILTER}>Aujourd'hui</Button>
                <Button  onClick={handleFilter} id="week" style={week?SELECTED_FILTER:UNSELECTED_FILTER}>Cette semaine</Button>
            </>}
            
            {/* month filter */}
            <Dropdown>
                <Dropdown.Toggle style={SELECTED_FILTER} id="dropdown-basic">
                {month[time]?month[time]:"Tous les mois"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                {(time==="future"?futurMonths:pastMonths).map((mnth,idx)=>{
                    return (<Dropdown.Item key={idx} onClick={()=>selectMonth(mnth,time)}>{mnth}</Dropdown.Item>)
                })}
                </Dropdown.Menu>

            </Dropdown>
            {/* activity filter */}
            {!type? 
            <Dropdown>
                <Dropdown.Toggle style={SELECTED_FILTER} id="dropdown-basic">
                {activity[time]?activity[time]:"Toutes les activités"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                {activities.map((act,idx)=>{
                    return (<Dropdown.Item key={idx} onClick={()=>selectActivity(act,time)}>{act?act:"Toutes les activités"}</Dropdown.Item>)
                })}
                </Dropdown.Menu>
            </Dropdown>
            :null
            }
            
            {/* region filter */}
            {
              !city && 
              <Dropdown>
              <Dropdown.Toggle style={SELECTED_FILTER} id="dropdown-basic">
              {region[time]?region[time]:"Toute l'Algérie"}
              </Dropdown.Toggle>
              <Dropdown.Menu>
              {regions.map((region,idx)=>{
                  return (<Dropdown.Item key={idx} onClick={()=>selectRegion(region)}>{region}</Dropdown.Item>)
              })}
              </Dropdown.Menu>
          </Dropdown>
            }

        </div>
  )
}

export default Filters