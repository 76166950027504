import React from 'react'
import { Row,Col} from 'react-bootstrap'

function HistoiryCard({className,img,children}) {
  return (
    <>
        <Row className={`${className} my-5`} >
            <Col className="p-lg-0 mb-4 mb-lg-4"  lg={{ order: 'last' }}>
                <img src={img} style={{width:"100%"}} className="w-100" />
            </Col>
            <Col className='text-start text-black-50' lg={6}>
                {children}           
            </Col>
        </Row>
    </>
  )
}

export default HistoiryCard