import React,{useContext, useEffect,useState} from 'react'
import alger from "../../assets/img/alger.jpg"
import CoverImg from 'components/General/CoverImg'
import { Container,Row,Col,Accordion } from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import cultureteque from "../../assets/img/cultureteque.png";
import SideBanner from 'components/Mediatheque city/SideBanner'
import ContactCard from 'components/Mediatheque city/ContactCard'
import HorairesCard from 'components/Mediatheque city/HorairesCard'
import MainTitle from 'components/General/MainTitle'
import BgShape from 'components/General/BgShape'
import mediatheques from 'data/mediatheque/Mediatheque-villes'
import { getMediathequeRegion } from 'services/mediatheque'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import InscriptionCard from 'components/General/InscriptionCard';
import CoursHorairesCard from 'components/cours de francais/CoursHorairesCard';
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList,ShimmerSectionHeader,ShimmerFeaturedGallery } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'
function MediathequePresentationPage({ville}) {
  const cityInfo = mediatheques.find(el=>el.region===ville)
  
  // ==== we should use state later with the CMS =====
   const [presentation,setPresentation] = useState({})
   const [loading, setLoading] = React.useState(true);
   const { language } = useContext(LanguageContext);
  // useEffect(() => {
  //   setPresentation(presentationVille)
  // }, )
  //===================================================
  // vérifier si la ville actuelle commence par un voyelle 
  const accentCities = ["alger","oran","annaba"]
  const isAccent = ()=>accentCities.indexOf(ville) !== -1
  //const regionContent = mediatheques[0]
  useEffect( () => {
    function fetchData() {
      //console.log(ville)
      
      getMediathequeRegion(ville).then((results)=>{
            results?.items?.map(e => {
              //console.log(e)

            var img = e?.data.Image?.iv? e?.data.Image?.iv[0]: e?.data.Image?.[language]?e?.data.Image?.[language][0]:''??''; //todo default image
  getAsset(img).then((srcImage)=>{
    e.data.srcImage=srcImage;
    setPresentation(e.data);
    setLoading(false)  
  });
  
});
});
        }
    
    
    fetchData();
  }, [ville]);
  const [banner, setBanner] = useState();
useEffect( () => {
  if(presentation?.banner?.[language])
  getAsset(presentation?.banner?.[language]).then((srcImage)=>{
    setBanner(srcImage);
  });
  
}, [presentation?.banner?.[language]]);
  try{var text = parse(presentation?.Description?.[language??'fr'])}catch($){
    text = presentation?.Description?.[language??'fr']
  }
  //console.log(presentation?.horraires?.[language])
  //console.log("yy: "+ville)
  return loading? <ShimmerPostDetails card cta variant="EDITOR" /> :(
    <div className='position-relative overflow-hidden' dir={language==='ar'?"rtl":""}>
      {/*<BgShape type="blue"  right="-40px" top="100px" rotate="-140deg"/>*/}
      {/*<BgShape type="red"  right="-50px" top="800px" rotate="-150deg"/>*/}
      {/*<BgShape type="yellow"  left="-80px" top="500px" rotate="140deg"/>*/}
      <CoverImg img={banner??alger} />
      <Breadcrumb />
      <Container className="my-5" >
        <Row>
            <Col>
                {/* <h1 className="display-6 border-bottom pb-3 my-1 text-start ">Médiathèques d'{regionContent.ville}</h1>               */}
                <MainTitle >Médiathèques {isAccent() ? "d'" : 'de '}{ville[0].toUpperCase() + ville.slice(1)}</MainTitle>
                
                <Accordion  alwaysOpen>
                  <Accordion.Item>
                    <Accordion.Header>
                      <h3>Présentation</h3>
                    </Accordion.Header>
                    <Accordion.Body>
                    <img src={presentation?.srcImage??cityInfo.img} alt="" className="w-100 my-3"/>
                    <hr/>
                    <p className='text-start my-2 lh-lg text-black-50'>{text??cityInfo.description}</p>
                    <hr/>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
            </Col>
            <Col md={4}>
              <SideBanner link="https://www.culturetheque.com/DZA/" img={cultureteque}/>
              <SideBanner link={ville!=='constantine'?"https://www.decalog.net/":'https://ifconstantine.bibenligne.fr'} text="Catalogue en ligne" imgStyle={{width:"100%"}}/>
              <InscriptionCard region={ville} url={'mediatheque/inscription'} type='mediatheque'/>
              <ContactCard contact ={presentation?.contact?.[language]??cityInfo.contact}/>
              <CoursHorairesCard ville={ville} data={presentation?.horraires?.[language]} text={presentation?.Horraires?.[language]}/>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default MediathequePresentationPage