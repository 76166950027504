import React,{createContext,useEffect,useState,useRef} from 'react'
import CoverImg from 'components/General/CoverImg'
import { Button, Container} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'
import culture from "../../assets/img/culture.jpg"
import Filters from 'components/Agenda Culturel/Filters'
// import EventsGrid from 'components/General/EventsGrid'
import AgendaEventGrid from "../../components/Agenda Culturel/AgendaEventGrid"
import { Events } from 'data/culture/Events'
import BgShape from 'components/General/BgShape'
import SocialSection from 'components/General/SocialSection'
import { useNavigate,useLocation } from 'react-router-dom'
export const filterContext = createContext('')


function AgendaPage({type,city,brd}) {
  const filters = {
    musique : "14-Musique",
    expo : "17-Expo",
    théatre : "15-theatre",
    cinéma : "11-Cinma",
    conférencesetrencontres : "18-Confrencesetrencontres",
    danse : "16-Danse",
    jeunesse: "19-Jeunesse"
  }
  const [eventType,setEventType] = useState(type)
  const initialRender = useRef(true);
  // change the eventType according to the type prop
  useEffect(()=>{
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      // window.location.reload()
      setEventType(type)
    }
    
    //console.log("Event Type",filters[eventType])
    // window.location. reload()
  },[type])

  const displayEventGrid = (eventType)=>{
    return <div data-module="events" data-action="events-list" data-param-quanty-column="3" data-param-event-type={filters[eventType]} data-events-to-show="1" data-param-detail_link="/aec-event/"></div>
  }
  // handle filter
  const navigate = useNavigate()
  const handleFilter=()=>{
    //console.log("filteeeeeeeeeeeeer")
    navigate("",{
      state: {
        type:"14-Musique"
      }
    })
  }

  // recive data from useNavigate
  const location = useLocation()
  //location.state ? console.log(location.state.type) : console.log("No LOCATION")

    //states
    const [events,setEvents] = useState(Events)
    const [all,setAll] = useState({
      future:true,
      past:true
    })
    const [today,setToday] = useState(false)
    const [week,setWeek] = useState(false)
    const [month,setMonth] = useState({
      future:"Par mois",
      past:"Par mois"
    })
    const [activity,setActivity] = useState({
      future:"Par activité",
      past:"Par activité"
    })
    const [region,setRegion] = useState({
      future:"Par région",
      past: "Par région"
    })

    // the Filter context value
    const filterContextValue ={events,setEvents,all,setAll,today,setToday,week,setWeek,month,setMonth,activity,setActivity,region,setRegion}

    //function to pass the region value to the Event Grid cmp as props
    const handleRegion = (city,region,time)=>{
      if (city) {
        return city
      }else if(time==="future" && region.future!=="Par région"){
        return region.future?.toLowerCase()
      } else if (time==="past" && region.past!=="Par région"){
        return region.past?.toLowerCase()
      }
    }

    
  return (
    <div className=" position-relative overflow-hidden">
      {/*<BgShape type="yellow" right="-40px" top="80px" rotate="-140deg" />
      <BgShape type="blue" right="-50px" top="800px" rotate="-150deg" />
      <BgShape type="red" left="-80px" top="500px" rotate="140deg" />*/}
      <CoverImg img={culture} />
      <Breadcrumb brd={brd} />
      <filterContext.Provider value={filterContextValue}>
        <Container>
          {/* <MainTitle>Agenda</MainTitle> */}
          {/* Evenements a venir */}
          {/* <div className="my-5">
            <h2 className="my-4 text-start">Les évènements à venir</h2>
            <Filters time="future" type={type} city={city} />
            <AgendaEventGrid
              time="future"
              region={handleRegion(city, region, "future")}
              type={type && type}
              month={month.future}
            />
          </div> */}
        </Container>
        {/* Evenement passés */}
        <div className="mt-5 py-3" style={{ backgroundColor: "whitesmoke" }}>
          <Container>
            {/* <h2 className="my-4 text-start">Les évènements passés</h2>
            <Filters time="past" type={type} city={city} />
            <AgendaEventGrid
              time="past"
              region={handleRegion(city, region, "past")}
              type={type && type}
              month={month.past}
            /> */}
</Container>

        </div>
        {/* <h2>Test</h2> */}
        <div className="arc-en-ciel container">
          {/* ================ OLD USER ACCOUNT CONTROLS ===============*/}
        {/* <div data-module="student"
            data-action="student-controls"
            data-param-login-link="/connexion"
            data-param-logout-link="students/logout"
            data-param-account-link="/mon-compte"
            data-param-my-cart-link="carts/view"
            data-redirect-url-after-logout = "students/login"
            className='mb-5'></div>
          <div data-module="events" data-action="events-list" data-param-quanty-column="3" data-param-event-type="" data-events-to-show="1" data-param-detail_link="/aec-event/"></div> */}
          {/*=====================================================================  */}
        {/*======== NEW USER ACCOUNT CONTROLS =======  */}
        <h2>{eventType}</h2>
        {/* <div data-module="student"
            data-action="student-controls"
            data-param-login-link="/connexion"
            data-param-logout-link="students/logout"
            data-param-account-link="/mon-compte"
            data-param-my-cart-link="/panier"
            data-redirect-url-after-logout = "students/login"
            className='mb-5'></div> */}
            <Button onClick={handleFilter} className='text-lowercasse'>Musique</Button>
          {/* <div data-module="events" data-action="events-list-grid" data-param-quantity-column="3" data-param-event-type={location.state.type} data-events-to-show="2" data-param-detail_link="/aec-event/"  data-show-loading="true"></div> */}
          <div data-module="events" data-action="events-list-grid" data-param-quantity-column="3" data-param-event-type="" data-events-to-show="2" data-param-detail_link="/aec-event/"  data-show-loading="true"></div>

          {/* Cours */}
          {/* <div data-module="events" data-action="events-list-grid" data-param-quantity-column="3" data-event-type="0"></div>
          <div data-module="events" data-action="events-list-wide"  data-event-type="0"></div>
          <div data-module="event" data-action="events-next" data-param-quantity-column="4" data-param-detail_link="/event" ></div> */}
        
           {/* <h2>Classe Modules</h2>
          <div data-module="classes" data-action="courses-search-bar" ></div>

          <h3 className='bg-primary p-2 text-white fw-bold'>FILTER : Side bar</h3>
          <div data-module="classes" data-action="courses-catalogue" data-param-sidebar="true" data-show-loading="true"></div>

        <h3 className='bg-primary p-2 text-white fw-bold'>CLASSES with filters </h3>
          <div data-module="classes" data-action="view-classes-with-filters" data-param-class-type-id="0" data-param-classification-id="0"></div>
         <h4 className='bg-primary p-2 text-white fw-bold'>Display classe selection wizzard : data-action="select-class" </h4>
          <div data-module="classes" data-action="select-class" data-param-classification-id="3" data-param-subject-id="1"></div>
         <h4 className='bg-primary p-2 text-white fw-bold'>Display a panel with the detail of a class: data-action="course-detail" </h4>
         <div data-module="classes" data-action="course-detail" data-show-loading="true"></div>

         <h2>Contact Module : data-module="contact"</h2>
         <h4 className='bg-primary p-2 text-white fw-bold'>Display a Google Map : data-action="display-contact-info"</h4>
         <div data-module="contact" data-action="display-contact-info" data-param-height="250"></div>

         <h2>Cultural Events Module : data-module="events"</h2>
         <h4 className='bg-primary p-2 text-white fw-bold'>Display a list of cultural events : data-action="list"</h4>
         <div data-module="events" data-action="events-list" data-param-event-type="3" data-show-loading="true"></div>
         <div data-module="event" data-action="event-view" data-param-event-type="123" data-show-loading="true"></div>
         <div data-module="event" data-action="events-next" data-param-quantity-column="4" data-param-detail_link="/event" data-show-loading="true"></div>

         <h2>Private Tuition Module: data-module="private-tuition"</h2>
         <h4 className='bg-primary p-2 text-white fw-bold'>Display a list of cultural events : data-action="list"</h4>
         <div data-module="private-tuition" data-action="showPrivateTuitionForm"></div>

         <h2>Products Module: data-module="products"</h2>
         <h4 className='bg-primary p-2 text-white fw-bold'>Display a form to add donations: data-action="donation_form"</h4>
         <div data-module="products" data-action="donation_form" data-show-loading="true"></div>
         <h4 className='bg-primary p-2 text-white fw-bold'>Display a list with all the available products: data-action="show-products"</h4>
         <div data-module="products" data-action="show-products" data-param-product-type="20"></div>
        
        <h2>Exams Module: data-module="examination"</h2>
        <h4 className='bg-primary p-2 text-white fw-bold'>Display a list with all the available exams: data-action="list"</h4>
        <div data-module="examination" data-action="list" data-param-examination-type-ids="1|2"></div>

        <h2>Student Module: data-module="student"</h2>
        <h4 className='bg-primary p-2 text-white fw-bold'>Display a login form, for the user to access the system: data-action="login"</h4>
        <div data-module="student" data-action="login" data-param-redirect="/classes/view" data-param-register-url="/student" className='mx-auto bg-white w-50'></div>
        <h4 className='bg-primary p-2 text-white fw-bold'>Display a login and register form, for the user to access or register to the system: data-action="identify-panel"</h4>
        <div data-module="student" data-action="identify-panel" data-param-redirect="" data-param-tag-active="login" ></div>
        <h4 className='bg-primary p-2 text-white fw-bold'>Display the register form, so the users can create their accounts to enter the application: data-action="register"</h4>
        <div data-module="student" data-action="register" data-param-redirect-url="/student/account"></div>
        <h4 className='bg-primary p-2 text-white fw-bold'>Display all user account controls(login, register, my account, logout): data-action="student-controls"</h4>
        <div data-module="student"
            data-action="student-controls"
            data-param-login-link="students/login"
            data-param-logout-link="students/logout"
            data-param-account-link="/mon-compte"
            data-param-my-cart-link="carts/view"></div>
        <h4 className='bg-primary p-2 text-white fw-bold'>Display all user account dashboard(courses, information, my reservations): data-action="account-dashboard"</h4>
        Redirection to login page
        <p>==========To show the tab ¨My Classes¨ separately, just add the following in your page: ==========</p>
        <div data-module="student" data-action="student-courses" data-param-register-class-url="classes/view"></div>
        <p className='py-2'>==========For the tab ¨Information¨, just add the following:==========</p>
        <div data-module="student" data-action="account-information" data-param-update-password-url="{update-password-url}"></div>
        <p className='py-2'>==========For the tab ¨My bookings¨==========</p>
        <div data-module="student" data-action="student-reservations"></div>
        <p className='py-2'>==========For the tab ¨Accounting¨==========</p>
        <div data-module="student" data-action="student-accounting"></div>

        <h4 className='bg-primary p-2 text-white fw-bold'>Display form to update password for a logged user logged: data-action="password-update"</h4>
        <div data-module="student" data-action="password-update"></div>

        <h4 className='bg-primary p-2 text-white fw-bold'>Display form to send a reminder email with password reset URL: data-action="reminder"</h4>
        <div data-module="student" data-action="reminder" data-param-login-url="students/login"></div>

        <h4 className='bg-primary p-2 text-white fw-bold'>Display form to update user password after reminder email has been sent: data-action="password-reset"</h4>
        <div data-module="student" data-action="password-reset"></div>
        
        <h2>Newsletter Module: data-module="newsletter"</h2>
        <div data-module="newsletter" data-action="subscribe-form" data-show-loading="false"></div>
        
        <h2>Cart Module: data-module="cart"</h2>
        <div data-module="cart" data-action="cart-form" data-show-loading="true"></div> */}
        </div>
      </filterContext.Provider>
      <SocialSection institut={true} ville={city}/>
    </div>
  );
}

export default AgendaPage