import React,{useState} from 'react'
import SocialIcon from './SocialIcon'
import { Container } from 'react-bootstrap'
import NewsletterButton from './NewsletterButton';
import { MdLocationOn } from "react-icons/md";
import { FaDirections } from "react-icons/fa";
import { socialInfos,socials } from 'data/general/socialMedia';



const socialIconsList = socials.map((el)=>{
    return <SocialIcon key={el.id} type={el.type} link={el.link} size="small"/>
})



function SocialSection({className,children,institut,ville}) {
    const [isOpen,setIsOpen] = useState(false)
    // get the current city
    const currentCity = socialInfos.filter((item)=>{
        return item.region==ville
    })

  return (
    <div style={{backgroundColor:"whitesmoke",borderTop:"solid 1px rgb(196, 197, 197)",zIndex:10}}>
        <Container className='d-flex flex-column gap-3  align-items-between justify-content-center py-md-2 py-4 '>
        <div className='d-flex flex-column gap-3 flex-sm-row align-items-center justify-content-between py-md-2 py-4 '>
                <div>
                    <NewsletterButton size="md"/>
                </div>
                <div className={`${className} d-flex justify-content-center gap-2 gap-sm-3 fs-2`}>
                <SocialIcon type="Facebook" link={currentCity[0].fb} size="small"/>
                {socialIconsList}
                </div>
        </div>
        </Container>


        {/*institut&&
        <div style={{backgroundColor:"rgb(53, 88, 162)"}} className="py-3 text-white">
            <Container>
                <ul className=" list-unstyled flex-column gap-4 flex-lg-row d-flex align-items-center justify-content-between mb-0" >
                    <li><MdLocationOn className='mb-1'/> {currentCity[0].adresse}</li>
                    <li>{currentCity[0].acceuil!=""?<><FaDirections className='mb-1 me-1'/><b>ACCUEIL ET ORIENTATION :</b>{currentCity[0].acceuil}</>
                    :null} </li>
                </ul>
            </Container>
            </div>
        */}
    </div>
  )
}

export default SocialSection