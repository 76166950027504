import React from "react";
import SlideComponent from "../SlideComponent";
import { Container} from "react-bootstrap";
import Breadcrumb from "components/breadcrumb/breadcrumb";
import SocialSection from "components/General/SocialSection";
import CityMenuCardGrid from "components/Cities/CityMenuCardGrid";
import MainTitle from "components/General/MainTitle";
import EventsGrid from "components/General/EventsGrid";
import BgShape from "components/General/BgShape";




export default function CityHomePage({ville}) {
 
  return (
    <div className=" position-relative overflow-hidden bg-grey z-index-1" style={{zIndex:"1"}}>
        {/*<BgShape type="red"  right="-40px" top="100px" rotate="-140deg"/>*/}
        {/*<BgShape type="blue"  left="-80px" top="800px" rotate="140deg"/>*/}
        {/*<BgShape type="yellow"  right="-50px" top="1200px" rotate="-150deg"/>*/}
        {/*<BgShape type="red"  left="-80px" top="2000px" rotate="140deg"/>*/}
        <SlideComponent></SlideComponent>
        <Breadcrumb/>
        <Container>
        {/*  <div className="my-5">
            <MainTitle >Actualités</MainTitle>
            <EventsGrid time="future" region={ville} max={6}/>
  </div>*/}
          <CityMenuCardGrid />
        </Container>
        <SocialSection ville={ville}/>
    </div>
  );
}
