import React from 'react'
import CoverImg from 'components/General/CoverImg'
import {Container } from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import france from "../../assets/img/etudier-en-france.jpg"
import MainTitle from 'components/General/MainTitle'
import {etudes} from "../../data/etudes/etudes"
import EtudesCardsGrid from 'components/Etudes/EtudesCardsGrid'

function EtudierEnFrance() {
  const etudesRegion = etudes.find((el)=>el.region==="france")
  
  return (
    <>
      <CoverImg img={france} />
      <Breadcrumb />
      <Container className="my-5">
        <MainTitle>Etudier en France</MainTitle>
        <p className="text-start text-black-50 pb-3">
          {etudesRegion.description}
        </p>

        <EtudesCardsGrid etudes={etudesRegion.etudes}/>
      </Container>
    </>
  );
}

export default EtudierEnFrance