import React,{ useState,useRef,useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BsSearch } from 'react-icons/bs';
import { FaHome } from 'react-icons/fa';
import {
  Container,
  Navbar,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import FranceDropdownMenu from "components/General/FranceDropdownMenu";
// desktop  drop down menu
import {menuItems,mobileMenuItems} from "data/header/menuData";
import MenuItem from "components/headers/MenuItem";
// mobile drop down menu
import styles from "./mainHeader.module.css"
import MobileMenuItem from "components/headers/MobileMenuItem";
import FrFlag from "../../assets/img/icons/fr.png"
import DzFlag from "../../assets/img/icons/dz.png"
import fb from "../../assets/img/icons/fb.png"
import ig from "../../assets/img/icons/ig.png"
import tiktok from "../../assets/img/icons/tiktok.png"
import ytb from "../../assets/img/icons/ytb.png"


export default function MainHeader(props) {
  // get the pathname of the current page
  
  useEffect(() => {
      window.addEventListener('scroll', function() {
          if (window.scrollY > 50) {
            document.getElementById('brandMobile').classList="mr-2 d-none d-sm-none d-md-none d-lg-none d-xl-none";
            document.getElementById('brandMobileScroll').classList="mr-2 d-sm-block d-md-block d-lg-none d-xl-none";
          } else {
            document.getElementById('brandMobile').classList="mr-2 d-sm-block d-md-block d-lg-none d-xl-none";
            document.getElementById('brandMobileScroll').classList="mr-2 d-none d-sm-none d-md-none d-lg-none d-xl-none";
          } 
      });
  }, []);
// Dropdown menu effect for "Etudier en France"
const franceRef = useRef()
const [showMenu,setShowMenu] = useState(false)


// const menuItems = [
//   {
//       title:"institut",
//       submenu:[
//         {
//           title:"présentation",
//           url:"institut/presentation"
//         },
//         {
//           title:"Nos missions",
//           url:"institut/nos-missions",
//           hide:['alger','oran','constantine','tlemcen','annaba']
//         },
//         {
//           title:"L'équipe",
//           url:"institut/equipe"
//         }
          
          
//       ]
//   },
//   {
//       title:"test et examnes",
//       submenu:[
//         {
//           title:"Présentation",
//           url:"tests-et-examens/presentation"
//         },
//         {
//           title:"TCF",
//           url:"tests-et-examens/tcf"
//         },
//         {
//           title:"DELF & DALF",
//           url:"tests-et-examens/delf-et-dalf"
//         },
//         {
//           title:"Attestations & Diplômes",
//           url:"tests-et-examens/attestations-et-diplomes"
//         },
//         {
//           title:"FAQ",
//           url:"tests-et-examens/faq"
//         }
//       ]
//   },
//   {
//     title:"cours de français",
//     submenu:[
//       {
//         title:"Présentation",
//         url:"cours-de-francais/presentation"
//       },
//       {
//         title:"Inscription",
//         url:"cours-de-francais/inscription"
//       },
//       {
//         title:"Calendrier des Sessions",
//         url:"cours-de-francais/calendrier-des-sessions"
//       }
//     ]
//   },
//   {
//       title:"etudes",
//       submenu:[
        
//           {
//               title: 'France',
//               submenu: [
//                   {
//                       title:"Campus France",
//                       url:"etudes/en-france/campus-france"
//                   },
//                   {
//                       title:"France Alumni",
//                       url:"etudes/en-france/france-alumni"
//                   },
//                   {
//                       title:"Bourses",
//                       url:"etudes/en-france/bourses"
//                   }
                
//               ],
//           },
//           {
//               title: 'Algérie',
//               submenu:[
//                   {
//                       title:"LIAD",
//                       url:"etudes/en-algerie/liad"
//                   },
//                   {
//                       title:"PEH",
//                       url:"etudes/en-algerie/peh"
//                   },
//                   {
//                       title:"ESAA",
//                       url:"etudes/en-algerie/essa"
//                   }
//               ]
//           }
//       ]
//   },
//   {
//       title:"agenda",
//       submenu:[
        
//           {
//               title: 'Culturel',
//               submenu: [
//                   {
//                       title:"Arts de la scene",
//                       url:"agenda/culturel/arts-de-la-scene"
//                   },
//                   {
//                       title:"Arts visuels",
//                       url:"agenda/culturel/arts-visuels"
//                   },
//                   {
//                       title:"Cinéma",
//                       url:"agenda/culturel/cinema"
//                   },
//                   {
//                       title:"Débat d’idées",
//                       url:"agenda/culturel/debat-d-idees"
//                   },
//                   {
//                       title:"Jeunesse",
//                       url:"agenda/culturel/jeunesse"
//                   },
                
//               ],
//           },
//           {
//               title: 'Divers',
//               url:"agenda/divers"
//           }
//       ]
//   },
//   {
//     title:"mediathèque",
//     submenu:[
//       {
//         title:"Présentation",
//         url:"mediatheque/presentation"
//       },
//       {
//         title:"Inscription",
//         url:"mediatheque/inscription"
//       },
//       {
//         title:"Services offerts",
//         url:"mediatheque/services-offerts"
//       },
//       {
//         title:"Culturethèque",
//         url:"mediatheque/culturetheque"
//       },
//       {
//         title:"Espace Jeunesse",
//         url:"mediatheque/espace-jeunesse"
//       }
      
//     ]
//   },
// ]

// const MenuItemsList =MenuItems.map((item,index)=>{
//   return (
//     <li className="nav-item" key={index}>
//     <Dropdown className="mx-auto " >
//             <Dropdown.Toggle  className="bg-transparent border-0 pt-2" id="dropdown-basic" style={{
//             color: "#FFFFFF",
//             fontStyle: "normal",
//             fontWeight: "600",
//             }}>
//               {item.name.toUpperCase()}
//             </Dropdown.Toggle>
//             <Dropdown.Menu className="  border-0  text-white text-start" style={{backgroundColor:"rgb(53, 88, 162)"}}>
//               {item.submenu.map((el,index)=>{
//                 return (<Dropdown.Item key={index} className="text-white py-2" ><Link className="text-white" to={el.link}>{el.name}</Link></Dropdown.Item>)
//               })}
//             </Dropdown.Menu>
//     </Dropdown>

//   </li>
//   )
// })
  
  return (
    <>
      <Navbar
        // style={{ paddingTop: "0", paddingBottom: "0", zIndex: "98" }}
        collapseOnSelect
        // expand="lg"
        expand="xl"
        bg="dark"
        variant="dark"
        fixed="top"
        className={`${styles.navbar0}`}
      >
        <Container fluid style={{ margin: "0 0" }}>
          {/* <span style={{ width: "209px", marginRight: "15em" }} ></span> */}
          <span className={styles.space}></span>
          {/* <Navbar.Brand href="#home" style={{ position: "absolute", top: "-8px", width: "261px", zIndex: "99" }}> */}
         {/* ======= LOGO ========== */}
          <Navbar.Brand href="#home" className={styles.logo}>
            <Link to='/' className="mr-2 d-none d-sm-none d-md-none d-lg-block d-xl-block">
              {brandSVG}
            </Link>
            <Link id="brandMobile" to='/' className="mr-2 d-sm-block d-md-block d-lg-none d-xl-none">
              {brandSVGMobile}
            </Link>
            <Link id="brandMobileScroll" to='/' className="mr-2 d-none d-sm-none d-md-none d-lg-none d-xl-none">
              {brandSVGMobileScroll}
            </Link>
          </Navbar.Brand>
          

          <Navbar.Toggle aria-controls="responsive-navbar-nav"  style={{ zIndex: "100" }}/>
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
          <nav 
            // className="navbar navbar-expand-xl d-none d-sm-none d-md-none d-lg-flex d-xl-flex" /*justify-content-center*/
            className="navbar navbar-expand-xl d-none d-sm-none d-md-none  d-xl-flex" /*justify-content-center*/
            style={{
              //fontFamily: "Inter",
              color: "#FFFFFF",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              position: "absolute",
              // width:"100%",
              width:"100vw",
              top: "40px",
              //left: "266px",
              backgroundColor: "#3558a2",//"#3558a28c",
              paddingTop:"0rem",
              paddingLeft:"100px",
              marginLeft:"0px"
            }}
          >
            <ul className={`navbar-nav me-auto mb-2 mb-lg-0 col-auto  ${styles["menu-items-list"]}`}>

              <li className="nav-item active">
                <Link className="nav-link" to={props.home} style={{

                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "600",
                }}>
                  <FaHome/>{" "}
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link className="nav-link" to="institut" style={{
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "600",
                }}>
                  L’INSTITUT
                </Link>
              </li> */}
              {/* institut with drop down menu*/}
              <li className="nav-item">
                {/* <Link className="nav-link" to="institut" style={{
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "600",
                }}>
                  L’INSTITUT
                </Link> */}

                {/* drop down menu with bootstrap for institut */}
                
                {/* <Dropdown className="mx-auto" >
                        <Dropdown.Toggle  className="bg-transparent border-0 pt-2" id="dropdown-basic" style={{
                        color: "#FFFFFF",
                        fontStyle: "normal",
                        fontWeight: "600",
                        }}>
                          L’INSTITUT
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="  border-0  text-white text-start" style={{backgroundColor:"rgb(53, 88, 162)"}}>
                          <Dropdown.Item className="text-white py-2" ><Link className="text-white" to="institut">Présentaion</Link></Dropdown.Item>
                          <Dropdown.Item className="text-white py-2" ><Link className="text-white" to="nos-missions">Nos missions</Link></Dropdown.Item>
                          <Dropdown.Item className="text-white py-2" ><Link className="text-white" to="equipe">L'équipe</Link></Dropdown.Item>
                        </Dropdown.Menu>
                </Dropdown> */}
                


              </li>
              {/* {MenuItemsList} */}
              {
                menuItems.map((menu, index) => {
                    const depthLevel=0;
                    return <MenuItem items={menu} key={index} depthLevel={depthLevel}  style={{color: "#FFFFFF",fontStyle: "normal",fontWeight: "600",fontSize:"16px"}} className={styles["menu-item"]}/>;
                  })}

              {/* <li className="nav-item">
                <Link className="nav-link font-weight-bold" to='tests-et-examens' style={{

                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "600",
                }}>
                  TESTS & EXAMENS
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link font-weight-bold" to="cours-de-francais" style={{

                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "600",
                }}>
                  COURS DE FRANÇAIS 
                </Link>
              </li>
              <li className="nav-item relative" id='e-france'  onClick={()=>setShowMenu(!showMenu)}>
                <a className="nav-link font-weight-bold"  style={{
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "600",
                  cursor:"pointer"
                }}>
                  ÉTUDES
                </a>
                <div className={showMenu?"d-block":"d-none"} ref={franceRef}>
                  <FranceDropdownMenu />
                </div>
              </li>             
                
              <li className="nav-item">
                <Link className="nav-link font-weight-bold" to="agenda-culturel" style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "600",
                }}>
                  AGENDA 
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link font-weight-bold" to='mediatheque' style={{

                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "600",
                }}>
                  MÉDIATHÈQUE
                </Link>
              </li> */}
              
              <li className="nav-item">
                <a className="nav-link" href="#">
                <BsSearch/>
                </a>
              </li>
            </ul>
          </nav>

              {/* ====== Desktop : villes ======= */}
            {/* <Nav className={`d-flex col-auto d-none d-sm-none d-md-none d-lg-flex d-xl-flex ${styles.cityNav}`}navbarScroll> */}
            <Nav className={`d-flex col-auto d-none d-sm-none d-md-none  d-xl-flex ${styles.cityNav}`}navbarScroll>
             
                <Nav.Link /*href="#Fr"*/ className={`${styles.flags} ${styles.fr}`} >
                  <span >
                    <img src={FrFlag} width="20" ></img>
                  </span>
                </Nav.Link>
                <Nav.Link /*href="#Ar"*/ className={`${styles.flags} ${styles.ar}`} >
                  <img src={DzFlag} width="20" ></img>
                </Nav.Link>
          
              
              <span style={{ width: "48px" }}></span>
              <Link className="font-weight-bold p-2"
                style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
                to="/alger"
              >
                Alger
              </Link>
              <Nav.Link style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}>|</Nav.Link>
              <Link className="font-weight-bold p-2"
                style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
                to="/annaba"
              >
                Annaba 
              </Link>
              <Nav.Link style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}>|</Nav.Link>
              <Link className="font-weight-bold p-2"
                style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
                to="/constantine"
              >
               Constantine 
              </Link>
              <Nav.Link style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}>|</Nav.Link>
              <Link className="font-weight-bold p-2"
                style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
                to="/oran"
              >
                Oran
              </Link>
              <Nav.Link style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}>|</Nav.Link>
              <Link className="font-weight-bold p-2"
                style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
                to="/tlemcen"
              >
                 Tlemcen
              </Link>
              <Nav.Link style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}>|</Nav.Link>
              <Link className="font-weight-bold p-2"
                style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  display: "inline"
                }}
                to="/"
              >
                Toute l'Algérie
              </Link>
              {/* <span style={{ width: "48px" }}></span> */}
              <span  className={styles["sm-space"]}></span>
              <Nav.Link href="https://www.facebook.com/IFAlgerie/" target="_blank" className={styles['sm-icon']}>
                <span>
                  <img src={fb} width="20"></img>
                </span>
              </Nav.Link>
              <Nav.Link href="#Fr" className={styles['sm-icon']}>
                <span>
                  <img src={tiktok} width="20"></img>
                </span>
              </Nav.Link>
              <Nav.Link href=" https://instagram.com/ifalgerie" target="_blank" className={styles['sm-icon']}>
                <span>
                  <img src={ig} width="20"></img>
                </span>
              </Nav.Link>
              <Nav.Link href="https://youtube.com/@institutfrancaisdalgerie5536" target="_blank" className={styles['sm-icon']}>
                <span>
                  <img src={ytb} width="20"></img>
                </span>
              </Nav.Link>
            </Nav>

                {/* ====== for MOBILE ======== */}

            {/* <Nav id="nav-mobile" className="col-auto d-sm-block d-md-block d-lg-none d-xl-none "> */}
            <Nav id="nav-mobile" className="col-auto d-block d-sm-block d-md-block d-xl-none ">
            <ul className={`justify-content-center mb-2 mb-lg-0 col-auto mt-5 ${styles.menu}`} style={{
                  paddingLeft:"0px"
                }}>
                  {
                    mobileMenuItems.map((item,index)=>{
                      const depthLevel=0
                      return <MobileMenuItem key={index} item={item} title={item.title} submenu={item.submenu} depthLevel={depthLevel}/>
                    })
                  }
              
              <li className="nav-item">
                <a className="nav-link" href="#">
                Recherche
                </a>
              </li>
            </ul>

                {/* ===== OLD MOBILE MENU (without submenu + center) */}
                {/* <ul className="justify-content-center mb-2 mb-lg-0 col-auto mt-5" style={{
                  paddingLeft:"0px"
                }}>
                  
                  
              <li className="nav-item active">
                <Link className="nav-link" to="institut" style={{
                 
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "600",
                }}>
                  L’INSTITUT
                </Link>
              </li>
                     
              <li className="nav-item">
                <Link className="nav-link font-weight-bold" to="tests-et-examens" style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "600",
                }}>
                  TESTS & EXAMENS
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link font-weight-bold" to="cours-de-francais" style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "600",
                }}>
                      COURS DE FRANÇAIS
                </Link>
              </li>
              <li className="nav-item" id='e-france-m'  >
                <a className="nav-link font-weight-bold" style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "600",
                }}>
                  
                 
                      
                      <Dropdown className="mx-auto" style={{width:"200px"}}>
                          <Dropdown.Toggle className="bg-transparent border-0" id="dropdown-basic" style={{
                          //fontFamily: "Inter",
                          color: "#FFF",
                          fontStyle: "normal",
                          fontWeight: "600",
                          }}>
                             ÉTUDES
                          </Dropdown.Toggle>

                          <Dropdown.Menu className=" bg-transparent border-0  text-white text-center" style={{width:"200px"}}>
                            <Dropdown.Item className="text-white" ><Link className="text-white" to="etudier-en-france/campus-france">Campus France</Link></Dropdown.Item>
                            <Dropdown.Item className="text-white" ><Link className="text-white" to="etudier-en-france/alumni">France Alumni</Link></Dropdown.Item>
                            <Dropdown.Item className="text-white" ><Link className="text-white" to="etudier-en-france/bourses">Bourses</Link></Dropdown.Item>
                            <Dropdown.Item className="text-white" ><Link className="text-white" to="etudier-en-france/en-algerie">En Algérie</Link></Dropdown.Item>
                          </Dropdown.Menu>
                      </Dropdown>

                  
                </a>
               
              </li>
              <li className="nav-item">
                <Link className="nav-link font-weight-bold" to="agenda-culturel" style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "600",
                }}>
                  AGENDA
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link font-weight-bold" to="mediatheque" style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "600",
                }}>
                  MÉDIATHÈQUE
                </Link>
              </li>
              
              <li className="nav-item">
                <a className="nav-link" href="#">
                Recherche
                </a>
              </li>
                </ul> */}
                {/* ========= END OLD MOBILE MENU ====== */}
            

            {/* ====== Mobile : Villes ======= */}
            <ul className="justify-content-center mb-2 mb-lg-0 col-auto mt-5 list-inline" style={{
                  paddingLeft:"0px" 
                }}>
              <li className="font-weight-bold list-inline-item"
                style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
                
              >
                <Link to="/alger"> Alger</Link>
                
              </li>
              <li className="list-inline-item" style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}>|</li>
              <li className="font-weight-bold list-inline-item"
                style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                <Link to="/annaba"> Annaba</Link>
              </li>
              <li className="list-inline-item" style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}>|</li>
              <li className="font-weight-bold list-inline-item"
                style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
               <Link to="/constantine"> Constantine</Link>
              </li>
              <li className="list-inline-item" style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}>|</li>
              <li className="font-weight-bold list-inline-item"
                style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                <Link to="/oran"> Oran</Link>
              </li>
              <li className="list-inline-item" style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}>|</li>
              <li className="font-weight-bold list-inline-item"
                style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}
              >
                  <Link to="/tlemcen"> Tlemcen</Link>
              </li>
              </ul>
              <ul className="justify-content-center mb-lg-0 col-auto list-inline">
              <li className="font-weight-bold list-inline-item"
                style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  display: "inline"
                }}
              >
                <Link to="/">Toute l'Algérie</Link>
                
              </li>
            </ul>
              
            </Nav>
            {/* ======= Botton : Connexion ====== */}
            <Nav className={`me-auto ${styles.cnx}`}>
              <Nav.Link href="#Cnx" style={{ padding: "0" }}>
                <Button
                  variant="primary"
                  size="mg"
                  style={{
                    borderRadius: "0",
                    // marginRight: "16px",
                    backgroundColor: "#3558a2",
                  }}
                >
                  MON COMPTE
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>

        
      </Navbar>

      
    </>
  );
}

const brandSVG = (
  <svg
    // width="559"
    // height="321"
    width="159"
    viewBox="0 0 559 321"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M559 0H0V321H559V0Z" fill="white" />
    <path d="M90.043 57.707H78.4V114.864H90.043V57.707Z" fill="#3558a2" />
    <path
      d="M254.832 69.87V57.72H213.576V69.87H228.383V138.447H240.03V69.87H254.832Z"
      fill="#3558a2"
    />
    <path d="M278.352 57.707H266.709V114.864H278.352V57.707Z" fill="#3558a2" />
    <path
      d="M391.263 118.005V57.705H379.74V118.373C379.74 122.608 378.648 132.447 368.58 132.447C358.326 132.447 357.523 121.584 357.523 118.247V57.707H345.87V118.729C345.87 134.179 355.206 144.973 368.579 144.973C369.391 144.973 370.179 144.929 370.95 144.864L352.589 224.349H364.944L369.544 201.765H386.666L391.366 224.349H404.066L384.366 138.573C388.775 133.908 391.256 126.848 391.256 118.005H391.263ZM371.906 189.992L374.844 175.828C375.761 171.273 377.026 164.198 377.798 159.859H378.384C379.157 164.214 380.44 171.367 381.338 175.828L384.266 189.992H371.906Z"
      fill="#3558a2"
    />
    <path
      d="M441.554 57.707H400.293V69.846H415.1V144.253H426.748V69.846H441.555L441.554 57.707Z"
      fill="#3558a2"
    />
    <path
      d="M160.6 184.852C168.769 181.212 172.92 173.652 172.92 162.352C172.92 147.867 164.997 139.058 151.685 138.487V57.709H140.266V95.541C140.266 98.741 140.337 105.55 140.427 109.87L139.85 110L138.908 107.6C137.684 104.431 136.208 100.7 135.253 98.51L118.053 57.71H106.3V138.446H78.4V224.356H90.045V185.4H107.374V173.5H90.045V150.469H117.7V100.656C117.7 97.456 117.636 90.642 117.545 86.317L118.122 86.187L119.065 88.611C120.303 91.78 121.765 95.501 122.72 97.691L139.807 138.444H127.541V224.354H139.167V187.1L148.198 187.326L160.086 224.359H173.228L160.352 184.946L160.6 184.852ZM149.425 175.202H139.165V150.469H149.425C156.845 150.469 160.937 154.813 160.937 162.707C160.937 171.007 157.055 175.202 149.425 175.202Z"
      fill="#3558a2"
    />
    <path
      d="M200.551 138.443C205.088 133.852 207.634 127.659 207.637 121.205C207.637 107.41 197.885 100.146 188.046 93.405C178.927 87.19 176.374 84.684 176.374 78.496C176.374 73.196 180.395 68.746 185.53 68.746C189.782 68.746 193.383 71.317 196.004 75.451L204.73 68.016C202.927 64.4855 200.177 61.5265 196.788 59.4702C193.399 57.4139 189.504 56.3415 185.54 56.373C173.13 56.373 164.284 67.186 164.284 78.957C164.284 92.657 171.81 97.9 180.711 104.13C191.311 111.58 195.256 114.919 195.256 121.605C195.256 127.144 190.934 132.574 184.933 132.574C180.55 132.574 177.502 129.844 172.453 123.269L163.308 131.269C168.593 140.459 175.897 145.199 185.083 145.199C190.116 145.137 194.969 143.317 198.802 140.054L179.337 224.354H191.688L196.276 201.77H213.4L218.114 224.354H230.8L211.079 138.448L200.551 138.443ZM198.651 189.992L201.585 175.828C202.502 171.273 203.77 164.198 204.537 159.859H205.129C205.9 164.214 207.17 171.367 208.087 175.828L211.01 189.992H198.651Z"
      fill="#3558a2"
    />
    <path
      d="M274.007 176.263C274.007 179.484 274.072 186.294 274.157 190.612L273.58 190.747L272.643 188.333C271.404 185.173 269.943 181.439 268.982 179.233L251.794 138.446H240.027V224.352H251.444V181.409C251.444 178.199 251.374 171.389 251.289 167.064L251.86 166.925L252.803 169.339C254.041 172.51 255.495 176.239 256.463 178.439L275.717 224.353H285.417V138.446H274.007V176.263Z"
      fill="#3558a2"
    />
    <path
      d="M426.744 166.577H415.101V224.354H426.744V166.577Z"
      fill="#3558a2"
    />
    <path
      d="M463.406 174.142C454.3 167.917 451.739 165.432 451.739 159.228C451.739 153.952 455.765 149.488 460.899 149.488C465.147 149.488 468.752 152.053 471.368 156.188L480.094 148.754C478.294 145.223 475.546 142.262 472.159 140.205C468.771 138.147 464.877 137.073 460.914 137.103C448.499 137.103 439.655 147.938 439.655 159.717C439.655 173.392 447.175 178.636 456.08 184.866C466.68 192.325 469.588 195.44 469.588 202.126C469.588 207.656 465.271 213.08 459.269 213.08C454.882 213.08 451.839 210.356 446.785 203.78L437.638 211.78C442.923 220.959 450.238 225.68 459.425 225.68C471.389 225.68 481.967 214.945 481.967 201.712C481.967 187.912 473.267 180.867 463.405 174.133"
      fill="#3558a2"
    />
    <path
      d="M332.829 200.277C330.557 209.802 327.954 213.197 322.916 213.197C311.179 213.197 310.26 188.773 310.26 181.288C310.26 169.463 311.795 149.614 322.114 149.614C328.314 149.614 330.352 153.889 331.795 161.284L331.831 161.504L343.579 158.339L343.538 158.163C341.281 143.989 334.387 137.104 322.454 137.104C320.994 137.1 319.537 137.258 318.112 137.575V69.844H332.918V57.709H291.662V69.844H306.468V145.734C301.144 153.234 298.268 165.409 298.268 181.522C298.268 205.061 304.174 220.122 314.968 224.435L311.328 242.285H322.78L325.914 225.451C335.436 224.011 341.097 217.106 343.648 203.921L343.689 203.721L332.878 200.067L332.829 200.277Z"
      fill="#3558a2"
    />
    <path
      d="M80.7733 276.19H77.4495L87.0631 250.008H90.3358L99.9495 276.19H96.6256L88.8017 254.15H88.5972L80.7733 276.19ZM82.0006 265.963H95.3983V268.775H82.0006V265.963Z"
      fill="#3558a2"
    />
    <path d="M106.636 250.008V276.19H103.618V250.008H106.636Z" fill="#3558a2" />
    <path
      d="M120.088 283.963C118.63 283.963 117.377 283.775 116.329 283.4C115.281 283.034 114.407 282.548 113.708 281.943C113.018 281.346 112.468 280.707 112.059 280.025L114.463 278.338C114.735 278.696 115.08 279.105 115.498 279.565C115.916 280.034 116.487 280.439 117.211 280.779C117.944 281.129 118.903 281.304 120.088 281.304C121.673 281.304 122.981 280.92 124.012 280.153C125.044 279.386 125.559 278.184 125.559 276.548V272.559H125.304C125.082 272.917 124.767 273.36 124.357 273.889C123.957 274.409 123.377 274.873 122.619 275.282C121.869 275.683 120.855 275.883 119.576 275.883C117.991 275.883 116.568 275.508 115.306 274.758C114.054 274.008 113.061 272.917 112.328 271.485C111.603 270.054 111.241 268.315 111.241 266.27C111.241 264.258 111.595 262.507 112.302 261.015C113.009 259.515 113.994 258.356 115.255 257.538C116.517 256.711 117.974 256.298 119.627 256.298C120.906 256.298 121.92 256.511 122.67 256.937C123.429 257.355 124.008 257.832 124.409 258.369C124.818 258.897 125.133 259.332 125.355 259.673H125.661V256.554H128.576V276.753C128.576 278.44 128.193 279.812 127.426 280.869C126.667 281.934 125.644 282.714 124.357 283.208C123.079 283.711 121.656 283.963 120.088 283.963ZM119.985 273.173C121.196 273.173 122.218 272.896 123.054 272.342C123.889 271.788 124.524 270.991 124.958 269.951C125.393 268.912 125.61 267.667 125.61 266.218C125.61 264.804 125.397 263.555 124.971 262.473C124.545 261.39 123.914 260.542 123.079 259.929C122.244 259.315 121.213 259.008 119.985 259.008C118.707 259.008 117.642 259.332 116.789 259.98C115.946 260.627 115.311 261.497 114.884 262.588C114.467 263.679 114.258 264.889 114.258 266.218C114.258 267.582 114.471 268.788 114.897 269.836C115.332 270.876 115.971 271.694 116.815 272.291C117.667 272.879 118.724 273.173 119.985 273.173Z"
      fill="#3558a2"
    />
    <path
      d="M142.332 276.599C140.44 276.599 138.808 276.181 137.436 275.346C136.072 274.503 135.019 273.326 134.278 271.818C133.545 270.301 133.178 268.537 133.178 266.525C133.178 264.514 133.545 262.741 134.278 261.207C135.019 259.664 136.051 258.463 137.372 257.602C138.701 256.733 140.252 256.298 142.025 256.298C143.048 256.298 144.058 256.468 145.055 256.809C146.052 257.15 146.96 257.704 147.778 258.471C148.596 259.23 149.248 260.235 149.734 261.488C150.22 262.741 150.463 264.284 150.463 266.116V267.395H135.326V264.787H147.394C147.394 263.679 147.173 262.69 146.73 261.821C146.295 260.951 145.673 260.265 144.863 259.762C144.062 259.26 143.116 259.008 142.025 259.008C140.823 259.008 139.784 259.306 138.906 259.903C138.036 260.491 137.367 261.258 136.899 262.204C136.43 263.15 136.196 264.164 136.196 265.247V266.985C136.196 268.468 136.451 269.726 136.963 270.757C137.482 271.78 138.203 272.559 139.123 273.096C140.044 273.625 141.113 273.889 142.332 273.889C143.125 273.889 143.84 273.778 144.48 273.556C145.127 273.326 145.686 272.985 146.154 272.534C146.623 272.074 146.985 271.502 147.241 270.821L150.156 271.639C149.849 272.627 149.333 273.497 148.609 274.247C147.884 274.988 146.99 275.568 145.924 275.985C144.859 276.395 143.661 276.599 142.332 276.599ZM140.491 253.895L143.61 247.963H147.139L143.15 253.895H140.491Z"
      fill="#3558a2"
    />
    <path
      d="M155.052 276.19V256.554H157.967V259.52H158.171C158.529 258.548 159.177 257.76 160.115 257.154C161.052 256.549 162.109 256.247 163.285 256.247C163.507 256.247 163.784 256.251 164.116 256.26C164.448 256.268 164.7 256.281 164.87 256.298V259.366C164.768 259.341 164.534 259.302 164.167 259.251C163.809 259.191 163.43 259.162 163.029 259.162C162.075 259.162 161.223 259.362 160.473 259.762C159.731 260.154 159.143 260.7 158.708 261.399C158.282 262.089 158.069 262.877 158.069 263.764V276.19H155.052Z"
      fill="#3558a2"
    />
    <path
      d="M168.447 276.19V256.554H171.464V276.19H168.447ZM169.981 253.281C169.393 253.281 168.886 253.081 168.459 252.68C168.042 252.279 167.833 251.798 167.833 251.235C167.833 250.673 168.042 250.191 168.459 249.791C168.886 249.39 169.393 249.19 169.981 249.19C170.569 249.19 171.072 249.39 171.489 249.791C171.915 250.191 172.128 250.673 172.128 251.235C172.128 251.798 171.915 252.279 171.489 252.68C171.072 253.081 170.569 253.281 169.981 253.281Z"
      fill="#3558a2"
    />
    <path
      d="M185.223 276.599C183.33 276.599 181.698 276.181 180.326 275.346C178.963 274.503 177.91 273.326 177.169 271.818C176.436 270.301 176.069 268.537 176.069 266.525C176.069 264.514 176.436 262.741 177.169 261.207C177.91 259.664 178.941 258.463 180.262 257.602C181.592 256.733 183.143 256.298 184.916 256.298C185.938 256.298 186.948 256.468 187.946 256.809C188.943 257.15 189.85 257.704 190.669 258.471C191.487 259.23 192.139 260.235 192.625 261.488C193.11 262.741 193.353 264.284 193.353 266.116V267.395H178.217V264.787H190.285C190.285 263.679 190.063 262.69 189.62 261.821C189.186 260.951 188.563 260.265 187.754 259.762C186.953 259.26 186.007 259.008 184.916 259.008C183.714 259.008 182.674 259.306 181.796 259.903C180.927 260.491 180.258 261.258 179.789 262.204C179.321 263.15 179.086 264.164 179.086 265.247V266.985C179.086 268.468 179.342 269.726 179.853 270.757C180.373 271.78 181.093 272.559 182.014 273.096C182.934 273.625 184.004 273.889 185.223 273.889C186.015 273.889 186.731 273.778 187.37 273.556C188.018 273.326 188.576 272.985 189.045 272.534C189.514 272.074 189.876 271.502 190.132 270.821L193.046 271.639C192.74 272.627 192.224 273.497 191.5 274.247C190.775 274.988 189.88 275.568 188.815 275.985C187.75 276.395 186.552 276.599 185.223 276.599Z"
      fill="#3558a2"
    />
  </svg>
);
const brandSVGMobile = (
  <svg
    // width="559"
    // height="321"
    width="110"
    viewBox="0 0 559 321"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M559 0H0V321H559V0Z" fill="white" />
    <path d="M90.043 57.707H78.4V114.864H90.043V57.707Z" fill="#3558a2" />
    <path
      d="M254.832 69.87V57.72H213.576V69.87H228.383V138.447H240.03V69.87H254.832Z"
      fill="#3558a2"
    />
    <path d="M278.352 57.707H266.709V114.864H278.352V57.707Z" fill="#3558a2" />
    <path
      d="M391.263 118.005V57.705H379.74V118.373C379.74 122.608 378.648 132.447 368.58 132.447C358.326 132.447 357.523 121.584 357.523 118.247V57.707H345.87V118.729C345.87 134.179 355.206 144.973 368.579 144.973C369.391 144.973 370.179 144.929 370.95 144.864L352.589 224.349H364.944L369.544 201.765H386.666L391.366 224.349H404.066L384.366 138.573C388.775 133.908 391.256 126.848 391.256 118.005H391.263ZM371.906 189.992L374.844 175.828C375.761 171.273 377.026 164.198 377.798 159.859H378.384C379.157 164.214 380.44 171.367 381.338 175.828L384.266 189.992H371.906Z"
      fill="#3558a2"
    />
    <path
      d="M441.554 57.707H400.293V69.846H415.1V144.253H426.748V69.846H441.555L441.554 57.707Z"
      fill="#3558a2"
    />
    <path
      d="M160.6 184.852C168.769 181.212 172.92 173.652 172.92 162.352C172.92 147.867 164.997 139.058 151.685 138.487V57.709H140.266V95.541C140.266 98.741 140.337 105.55 140.427 109.87L139.85 110L138.908 107.6C137.684 104.431 136.208 100.7 135.253 98.51L118.053 57.71H106.3V138.446H78.4V224.356H90.045V185.4H107.374V173.5H90.045V150.469H117.7V100.656C117.7 97.456 117.636 90.642 117.545 86.317L118.122 86.187L119.065 88.611C120.303 91.78 121.765 95.501 122.72 97.691L139.807 138.444H127.541V224.354H139.167V187.1L148.198 187.326L160.086 224.359H173.228L160.352 184.946L160.6 184.852ZM149.425 175.202H139.165V150.469H149.425C156.845 150.469 160.937 154.813 160.937 162.707C160.937 171.007 157.055 175.202 149.425 175.202Z"
      fill="#3558a2"
    />
    <path
      d="M200.551 138.443C205.088 133.852 207.634 127.659 207.637 121.205C207.637 107.41 197.885 100.146 188.046 93.405C178.927 87.19 176.374 84.684 176.374 78.496C176.374 73.196 180.395 68.746 185.53 68.746C189.782 68.746 193.383 71.317 196.004 75.451L204.73 68.016C202.927 64.4855 200.177 61.5265 196.788 59.4702C193.399 57.4139 189.504 56.3415 185.54 56.373C173.13 56.373 164.284 67.186 164.284 78.957C164.284 92.657 171.81 97.9 180.711 104.13C191.311 111.58 195.256 114.919 195.256 121.605C195.256 127.144 190.934 132.574 184.933 132.574C180.55 132.574 177.502 129.844 172.453 123.269L163.308 131.269C168.593 140.459 175.897 145.199 185.083 145.199C190.116 145.137 194.969 143.317 198.802 140.054L179.337 224.354H191.688L196.276 201.77H213.4L218.114 224.354H230.8L211.079 138.448L200.551 138.443ZM198.651 189.992L201.585 175.828C202.502 171.273 203.77 164.198 204.537 159.859H205.129C205.9 164.214 207.17 171.367 208.087 175.828L211.01 189.992H198.651Z"
      fill="#3558a2"
    />
    <path
      d="M274.007 176.263C274.007 179.484 274.072 186.294 274.157 190.612L273.58 190.747L272.643 188.333C271.404 185.173 269.943 181.439 268.982 179.233L251.794 138.446H240.027V224.352H251.444V181.409C251.444 178.199 251.374 171.389 251.289 167.064L251.86 166.925L252.803 169.339C254.041 172.51 255.495 176.239 256.463 178.439L275.717 224.353H285.417V138.446H274.007V176.263Z"
      fill="#3558a2"
    />
    <path
      d="M426.744 166.577H415.101V224.354H426.744V166.577Z"
      fill="#3558a2"
    />
    <path
      d="M463.406 174.142C454.3 167.917 451.739 165.432 451.739 159.228C451.739 153.952 455.765 149.488 460.899 149.488C465.147 149.488 468.752 152.053 471.368 156.188L480.094 148.754C478.294 145.223 475.546 142.262 472.159 140.205C468.771 138.147 464.877 137.073 460.914 137.103C448.499 137.103 439.655 147.938 439.655 159.717C439.655 173.392 447.175 178.636 456.08 184.866C466.68 192.325 469.588 195.44 469.588 202.126C469.588 207.656 465.271 213.08 459.269 213.08C454.882 213.08 451.839 210.356 446.785 203.78L437.638 211.78C442.923 220.959 450.238 225.68 459.425 225.68C471.389 225.68 481.967 214.945 481.967 201.712C481.967 187.912 473.267 180.867 463.405 174.133"
      fill="#3558a2"
    />
    <path
      d="M332.829 200.277C330.557 209.802 327.954 213.197 322.916 213.197C311.179 213.197 310.26 188.773 310.26 181.288C310.26 169.463 311.795 149.614 322.114 149.614C328.314 149.614 330.352 153.889 331.795 161.284L331.831 161.504L343.579 158.339L343.538 158.163C341.281 143.989 334.387 137.104 322.454 137.104C320.994 137.1 319.537 137.258 318.112 137.575V69.844H332.918V57.709H291.662V69.844H306.468V145.734C301.144 153.234 298.268 165.409 298.268 181.522C298.268 205.061 304.174 220.122 314.968 224.435L311.328 242.285H322.78L325.914 225.451C335.436 224.011 341.097 217.106 343.648 203.921L343.689 203.721L332.878 200.067L332.829 200.277Z"
      fill="#3558a2"
    />
    <path
      d="M80.7733 276.19H77.4495L87.0631 250.008H90.3358L99.9495 276.19H96.6256L88.8017 254.15H88.5972L80.7733 276.19ZM82.0006 265.963H95.3983V268.775H82.0006V265.963Z"
      fill="#3558a2"
    />
    <path d="M106.636 250.008V276.19H103.618V250.008H106.636Z" fill="#3558a2" />
    <path
      d="M120.088 283.963C118.63 283.963 117.377 283.775 116.329 283.4C115.281 283.034 114.407 282.548 113.708 281.943C113.018 281.346 112.468 280.707 112.059 280.025L114.463 278.338C114.735 278.696 115.08 279.105 115.498 279.565C115.916 280.034 116.487 280.439 117.211 280.779C117.944 281.129 118.903 281.304 120.088 281.304C121.673 281.304 122.981 280.92 124.012 280.153C125.044 279.386 125.559 278.184 125.559 276.548V272.559H125.304C125.082 272.917 124.767 273.36 124.357 273.889C123.957 274.409 123.377 274.873 122.619 275.282C121.869 275.683 120.855 275.883 119.576 275.883C117.991 275.883 116.568 275.508 115.306 274.758C114.054 274.008 113.061 272.917 112.328 271.485C111.603 270.054 111.241 268.315 111.241 266.27C111.241 264.258 111.595 262.507 112.302 261.015C113.009 259.515 113.994 258.356 115.255 257.538C116.517 256.711 117.974 256.298 119.627 256.298C120.906 256.298 121.92 256.511 122.67 256.937C123.429 257.355 124.008 257.832 124.409 258.369C124.818 258.897 125.133 259.332 125.355 259.673H125.661V256.554H128.576V276.753C128.576 278.44 128.193 279.812 127.426 280.869C126.667 281.934 125.644 282.714 124.357 283.208C123.079 283.711 121.656 283.963 120.088 283.963ZM119.985 273.173C121.196 273.173 122.218 272.896 123.054 272.342C123.889 271.788 124.524 270.991 124.958 269.951C125.393 268.912 125.61 267.667 125.61 266.218C125.61 264.804 125.397 263.555 124.971 262.473C124.545 261.39 123.914 260.542 123.079 259.929C122.244 259.315 121.213 259.008 119.985 259.008C118.707 259.008 117.642 259.332 116.789 259.98C115.946 260.627 115.311 261.497 114.884 262.588C114.467 263.679 114.258 264.889 114.258 266.218C114.258 267.582 114.471 268.788 114.897 269.836C115.332 270.876 115.971 271.694 116.815 272.291C117.667 272.879 118.724 273.173 119.985 273.173Z"
      fill="#3558a2"
    />
    <path
      d="M142.332 276.599C140.44 276.599 138.808 276.181 137.436 275.346C136.072 274.503 135.019 273.326 134.278 271.818C133.545 270.301 133.178 268.537 133.178 266.525C133.178 264.514 133.545 262.741 134.278 261.207C135.019 259.664 136.051 258.463 137.372 257.602C138.701 256.733 140.252 256.298 142.025 256.298C143.048 256.298 144.058 256.468 145.055 256.809C146.052 257.15 146.96 257.704 147.778 258.471C148.596 259.23 149.248 260.235 149.734 261.488C150.22 262.741 150.463 264.284 150.463 266.116V267.395H135.326V264.787H147.394C147.394 263.679 147.173 262.69 146.73 261.821C146.295 260.951 145.673 260.265 144.863 259.762C144.062 259.26 143.116 259.008 142.025 259.008C140.823 259.008 139.784 259.306 138.906 259.903C138.036 260.491 137.367 261.258 136.899 262.204C136.43 263.15 136.196 264.164 136.196 265.247V266.985C136.196 268.468 136.451 269.726 136.963 270.757C137.482 271.78 138.203 272.559 139.123 273.096C140.044 273.625 141.113 273.889 142.332 273.889C143.125 273.889 143.84 273.778 144.48 273.556C145.127 273.326 145.686 272.985 146.154 272.534C146.623 272.074 146.985 271.502 147.241 270.821L150.156 271.639C149.849 272.627 149.333 273.497 148.609 274.247C147.884 274.988 146.99 275.568 145.924 275.985C144.859 276.395 143.661 276.599 142.332 276.599ZM140.491 253.895L143.61 247.963H147.139L143.15 253.895H140.491Z"
      fill="#3558a2"
    />
    <path
      d="M155.052 276.19V256.554H157.967V259.52H158.171C158.529 258.548 159.177 257.76 160.115 257.154C161.052 256.549 162.109 256.247 163.285 256.247C163.507 256.247 163.784 256.251 164.116 256.26C164.448 256.268 164.7 256.281 164.87 256.298V259.366C164.768 259.341 164.534 259.302 164.167 259.251C163.809 259.191 163.43 259.162 163.029 259.162C162.075 259.162 161.223 259.362 160.473 259.762C159.731 260.154 159.143 260.7 158.708 261.399C158.282 262.089 158.069 262.877 158.069 263.764V276.19H155.052Z"
      fill="#3558a2"
    />
    <path
      d="M168.447 276.19V256.554H171.464V276.19H168.447ZM169.981 253.281C169.393 253.281 168.886 253.081 168.459 252.68C168.042 252.279 167.833 251.798 167.833 251.235C167.833 250.673 168.042 250.191 168.459 249.791C168.886 249.39 169.393 249.19 169.981 249.19C170.569 249.19 171.072 249.39 171.489 249.791C171.915 250.191 172.128 250.673 172.128 251.235C172.128 251.798 171.915 252.279 171.489 252.68C171.072 253.081 170.569 253.281 169.981 253.281Z"
      fill="#3558a2"
    />
    <path
      d="M185.223 276.599C183.33 276.599 181.698 276.181 180.326 275.346C178.963 274.503 177.91 273.326 177.169 271.818C176.436 270.301 176.069 268.537 176.069 266.525C176.069 264.514 176.436 262.741 177.169 261.207C177.91 259.664 178.941 258.463 180.262 257.602C181.592 256.733 183.143 256.298 184.916 256.298C185.938 256.298 186.948 256.468 187.946 256.809C188.943 257.15 189.85 257.704 190.669 258.471C191.487 259.23 192.139 260.235 192.625 261.488C193.11 262.741 193.353 264.284 193.353 266.116V267.395H178.217V264.787H190.285C190.285 263.679 190.063 262.69 189.62 261.821C189.186 260.951 188.563 260.265 187.754 259.762C186.953 259.26 186.007 259.008 184.916 259.008C183.714 259.008 182.674 259.306 181.796 259.903C180.927 260.491 180.258 261.258 179.789 262.204C179.321 263.15 179.086 264.164 179.086 265.247V266.985C179.086 268.468 179.342 269.726 179.853 270.757C180.373 271.78 181.093 272.559 182.014 273.096C182.934 273.625 184.004 273.889 185.223 273.889C186.015 273.889 186.731 273.778 187.37 273.556C188.018 273.326 188.576 272.985 189.045 272.534C189.514 272.074 189.876 271.502 190.132 270.821L193.046 271.639C192.74 272.627 192.224 273.497 191.5 274.247C190.775 274.988 189.88 275.568 188.815 275.985C187.75 276.395 186.552 276.599 185.223 276.599Z"
      fill="#3558a2"
    />
  </svg>
);
const brandSVGMobileScroll = (
  <svg
    // width="559"
    // height="321"
    width="75"
    viewBox="0 0 559 321"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M559 0H0V321H559V0Z" fill="white" />
    <path d="M90.043 57.707H78.4V114.864H90.043V57.707Z" fill="#3558a2" />
    <path
      d="M254.832 69.87V57.72H213.576V69.87H228.383V138.447H240.03V69.87H254.832Z"
      fill="#3558a2"
    />
    <path d="M278.352 57.707H266.709V114.864H278.352V57.707Z" fill="#3558a2" />
    <path
      d="M391.263 118.005V57.705H379.74V118.373C379.74 122.608 378.648 132.447 368.58 132.447C358.326 132.447 357.523 121.584 357.523 118.247V57.707H345.87V118.729C345.87 134.179 355.206 144.973 368.579 144.973C369.391 144.973 370.179 144.929 370.95 144.864L352.589 224.349H364.944L369.544 201.765H386.666L391.366 224.349H404.066L384.366 138.573C388.775 133.908 391.256 126.848 391.256 118.005H391.263ZM371.906 189.992L374.844 175.828C375.761 171.273 377.026 164.198 377.798 159.859H378.384C379.157 164.214 380.44 171.367 381.338 175.828L384.266 189.992H371.906Z"
      fill="#3558a2"
    />
    <path
      d="M441.554 57.707H400.293V69.846H415.1V144.253H426.748V69.846H441.555L441.554 57.707Z"
      fill="#3558a2"
    />
    <path
      d="M160.6 184.852C168.769 181.212 172.92 173.652 172.92 162.352C172.92 147.867 164.997 139.058 151.685 138.487V57.709H140.266V95.541C140.266 98.741 140.337 105.55 140.427 109.87L139.85 110L138.908 107.6C137.684 104.431 136.208 100.7 135.253 98.51L118.053 57.71H106.3V138.446H78.4V224.356H90.045V185.4H107.374V173.5H90.045V150.469H117.7V100.656C117.7 97.456 117.636 90.642 117.545 86.317L118.122 86.187L119.065 88.611C120.303 91.78 121.765 95.501 122.72 97.691L139.807 138.444H127.541V224.354H139.167V187.1L148.198 187.326L160.086 224.359H173.228L160.352 184.946L160.6 184.852ZM149.425 175.202H139.165V150.469H149.425C156.845 150.469 160.937 154.813 160.937 162.707C160.937 171.007 157.055 175.202 149.425 175.202Z"
      fill="#3558a2"
    />
    <path
      d="M200.551 138.443C205.088 133.852 207.634 127.659 207.637 121.205C207.637 107.41 197.885 100.146 188.046 93.405C178.927 87.19 176.374 84.684 176.374 78.496C176.374 73.196 180.395 68.746 185.53 68.746C189.782 68.746 193.383 71.317 196.004 75.451L204.73 68.016C202.927 64.4855 200.177 61.5265 196.788 59.4702C193.399 57.4139 189.504 56.3415 185.54 56.373C173.13 56.373 164.284 67.186 164.284 78.957C164.284 92.657 171.81 97.9 180.711 104.13C191.311 111.58 195.256 114.919 195.256 121.605C195.256 127.144 190.934 132.574 184.933 132.574C180.55 132.574 177.502 129.844 172.453 123.269L163.308 131.269C168.593 140.459 175.897 145.199 185.083 145.199C190.116 145.137 194.969 143.317 198.802 140.054L179.337 224.354H191.688L196.276 201.77H213.4L218.114 224.354H230.8L211.079 138.448L200.551 138.443ZM198.651 189.992L201.585 175.828C202.502 171.273 203.77 164.198 204.537 159.859H205.129C205.9 164.214 207.17 171.367 208.087 175.828L211.01 189.992H198.651Z"
      fill="#3558a2"
    />
    <path
      d="M274.007 176.263C274.007 179.484 274.072 186.294 274.157 190.612L273.58 190.747L272.643 188.333C271.404 185.173 269.943 181.439 268.982 179.233L251.794 138.446H240.027V224.352H251.444V181.409C251.444 178.199 251.374 171.389 251.289 167.064L251.86 166.925L252.803 169.339C254.041 172.51 255.495 176.239 256.463 178.439L275.717 224.353H285.417V138.446H274.007V176.263Z"
      fill="#3558a2"
    />
    <path
      d="M426.744 166.577H415.101V224.354H426.744V166.577Z"
      fill="#3558a2"
    />
    <path
      d="M463.406 174.142C454.3 167.917 451.739 165.432 451.739 159.228C451.739 153.952 455.765 149.488 460.899 149.488C465.147 149.488 468.752 152.053 471.368 156.188L480.094 148.754C478.294 145.223 475.546 142.262 472.159 140.205C468.771 138.147 464.877 137.073 460.914 137.103C448.499 137.103 439.655 147.938 439.655 159.717C439.655 173.392 447.175 178.636 456.08 184.866C466.68 192.325 469.588 195.44 469.588 202.126C469.588 207.656 465.271 213.08 459.269 213.08C454.882 213.08 451.839 210.356 446.785 203.78L437.638 211.78C442.923 220.959 450.238 225.68 459.425 225.68C471.389 225.68 481.967 214.945 481.967 201.712C481.967 187.912 473.267 180.867 463.405 174.133"
      fill="#3558a2"
    />
    <path
      d="M332.829 200.277C330.557 209.802 327.954 213.197 322.916 213.197C311.179 213.197 310.26 188.773 310.26 181.288C310.26 169.463 311.795 149.614 322.114 149.614C328.314 149.614 330.352 153.889 331.795 161.284L331.831 161.504L343.579 158.339L343.538 158.163C341.281 143.989 334.387 137.104 322.454 137.104C320.994 137.1 319.537 137.258 318.112 137.575V69.844H332.918V57.709H291.662V69.844H306.468V145.734C301.144 153.234 298.268 165.409 298.268 181.522C298.268 205.061 304.174 220.122 314.968 224.435L311.328 242.285H322.78L325.914 225.451C335.436 224.011 341.097 217.106 343.648 203.921L343.689 203.721L332.878 200.067L332.829 200.277Z"
      fill="#3558a2"
    />
    <path
      d="M80.7733 276.19H77.4495L87.0631 250.008H90.3358L99.9495 276.19H96.6256L88.8017 254.15H88.5972L80.7733 276.19ZM82.0006 265.963H95.3983V268.775H82.0006V265.963Z"
      fill="#3558a2"
    />
    <path d="M106.636 250.008V276.19H103.618V250.008H106.636Z" fill="#3558a2" />
    <path
      d="M120.088 283.963C118.63 283.963 117.377 283.775 116.329 283.4C115.281 283.034 114.407 282.548 113.708 281.943C113.018 281.346 112.468 280.707 112.059 280.025L114.463 278.338C114.735 278.696 115.08 279.105 115.498 279.565C115.916 280.034 116.487 280.439 117.211 280.779C117.944 281.129 118.903 281.304 120.088 281.304C121.673 281.304 122.981 280.92 124.012 280.153C125.044 279.386 125.559 278.184 125.559 276.548V272.559H125.304C125.082 272.917 124.767 273.36 124.357 273.889C123.957 274.409 123.377 274.873 122.619 275.282C121.869 275.683 120.855 275.883 119.576 275.883C117.991 275.883 116.568 275.508 115.306 274.758C114.054 274.008 113.061 272.917 112.328 271.485C111.603 270.054 111.241 268.315 111.241 266.27C111.241 264.258 111.595 262.507 112.302 261.015C113.009 259.515 113.994 258.356 115.255 257.538C116.517 256.711 117.974 256.298 119.627 256.298C120.906 256.298 121.92 256.511 122.67 256.937C123.429 257.355 124.008 257.832 124.409 258.369C124.818 258.897 125.133 259.332 125.355 259.673H125.661V256.554H128.576V276.753C128.576 278.44 128.193 279.812 127.426 280.869C126.667 281.934 125.644 282.714 124.357 283.208C123.079 283.711 121.656 283.963 120.088 283.963ZM119.985 273.173C121.196 273.173 122.218 272.896 123.054 272.342C123.889 271.788 124.524 270.991 124.958 269.951C125.393 268.912 125.61 267.667 125.61 266.218C125.61 264.804 125.397 263.555 124.971 262.473C124.545 261.39 123.914 260.542 123.079 259.929C122.244 259.315 121.213 259.008 119.985 259.008C118.707 259.008 117.642 259.332 116.789 259.98C115.946 260.627 115.311 261.497 114.884 262.588C114.467 263.679 114.258 264.889 114.258 266.218C114.258 267.582 114.471 268.788 114.897 269.836C115.332 270.876 115.971 271.694 116.815 272.291C117.667 272.879 118.724 273.173 119.985 273.173Z"
      fill="#3558a2"
    />
    <path
      d="M142.332 276.599C140.44 276.599 138.808 276.181 137.436 275.346C136.072 274.503 135.019 273.326 134.278 271.818C133.545 270.301 133.178 268.537 133.178 266.525C133.178 264.514 133.545 262.741 134.278 261.207C135.019 259.664 136.051 258.463 137.372 257.602C138.701 256.733 140.252 256.298 142.025 256.298C143.048 256.298 144.058 256.468 145.055 256.809C146.052 257.15 146.96 257.704 147.778 258.471C148.596 259.23 149.248 260.235 149.734 261.488C150.22 262.741 150.463 264.284 150.463 266.116V267.395H135.326V264.787H147.394C147.394 263.679 147.173 262.69 146.73 261.821C146.295 260.951 145.673 260.265 144.863 259.762C144.062 259.26 143.116 259.008 142.025 259.008C140.823 259.008 139.784 259.306 138.906 259.903C138.036 260.491 137.367 261.258 136.899 262.204C136.43 263.15 136.196 264.164 136.196 265.247V266.985C136.196 268.468 136.451 269.726 136.963 270.757C137.482 271.78 138.203 272.559 139.123 273.096C140.044 273.625 141.113 273.889 142.332 273.889C143.125 273.889 143.84 273.778 144.48 273.556C145.127 273.326 145.686 272.985 146.154 272.534C146.623 272.074 146.985 271.502 147.241 270.821L150.156 271.639C149.849 272.627 149.333 273.497 148.609 274.247C147.884 274.988 146.99 275.568 145.924 275.985C144.859 276.395 143.661 276.599 142.332 276.599ZM140.491 253.895L143.61 247.963H147.139L143.15 253.895H140.491Z"
      fill="#3558a2"
    />
    <path
      d="M155.052 276.19V256.554H157.967V259.52H158.171C158.529 258.548 159.177 257.76 160.115 257.154C161.052 256.549 162.109 256.247 163.285 256.247C163.507 256.247 163.784 256.251 164.116 256.26C164.448 256.268 164.7 256.281 164.87 256.298V259.366C164.768 259.341 164.534 259.302 164.167 259.251C163.809 259.191 163.43 259.162 163.029 259.162C162.075 259.162 161.223 259.362 160.473 259.762C159.731 260.154 159.143 260.7 158.708 261.399C158.282 262.089 158.069 262.877 158.069 263.764V276.19H155.052Z"
      fill="#3558a2"
    />
    <path
      d="M168.447 276.19V256.554H171.464V276.19H168.447ZM169.981 253.281C169.393 253.281 168.886 253.081 168.459 252.68C168.042 252.279 167.833 251.798 167.833 251.235C167.833 250.673 168.042 250.191 168.459 249.791C168.886 249.39 169.393 249.19 169.981 249.19C170.569 249.19 171.072 249.39 171.489 249.791C171.915 250.191 172.128 250.673 172.128 251.235C172.128 251.798 171.915 252.279 171.489 252.68C171.072 253.081 170.569 253.281 169.981 253.281Z"
      fill="#3558a2"
    />
    <path
      d="M185.223 276.599C183.33 276.599 181.698 276.181 180.326 275.346C178.963 274.503 177.91 273.326 177.169 271.818C176.436 270.301 176.069 268.537 176.069 266.525C176.069 264.514 176.436 262.741 177.169 261.207C177.91 259.664 178.941 258.463 180.262 257.602C181.592 256.733 183.143 256.298 184.916 256.298C185.938 256.298 186.948 256.468 187.946 256.809C188.943 257.15 189.85 257.704 190.669 258.471C191.487 259.23 192.139 260.235 192.625 261.488C193.11 262.741 193.353 264.284 193.353 266.116V267.395H178.217V264.787H190.285C190.285 263.679 190.063 262.69 189.62 261.821C189.186 260.951 188.563 260.265 187.754 259.762C186.953 259.26 186.007 259.008 184.916 259.008C183.714 259.008 182.674 259.306 181.796 259.903C180.927 260.491 180.258 261.258 179.789 262.204C179.321 263.15 179.086 264.164 179.086 265.247V266.985C179.086 268.468 179.342 269.726 179.853 270.757C180.373 271.78 181.093 272.559 182.014 273.096C182.934 273.625 184.004 273.889 185.223 273.889C186.015 273.889 186.731 273.778 187.37 273.556C188.018 273.326 188.576 272.985 189.045 272.534C189.514 272.074 189.876 271.502 190.132 270.821L193.046 271.639C192.74 272.627 192.224 273.497 191.5 274.247C190.775 274.988 189.88 275.568 188.815 275.985C187.75 276.395 186.552 276.599 185.223 276.599Z"
      fill="#3558a2"
    />
  </svg>
);

const miniFlag = (
  <svg
    // width="60"
    // height="41"
    width="22.5"
    viewBox="0 0 60 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.9996 39.9997V1H1V39.9997H58.9996ZM58.9996 40.9997H1C0.447693 40.9997 0 40.552 0 39.9997V1C0 0.44772 0.447693 0 1 0H58.9996C59.5519 0 59.9996 0.44772 59.9996 1V39.9997C59.9996 40.552 59.5519 40.9997 58.9996 40.9997Z"
      fill="#E5E5E5"
    />
  </svg>
);


