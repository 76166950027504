import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { dropdownContext } from './MobileMenuItem';



//======= MY MobileDropdown cmp ========== 
function MobileDropdown({submenu,className}) {
  const {dropdown,setDropdown} = useContext(dropdownContext)

// close the drop down when clicking on an item.
const burger = document.getElementById('burger')
const closeMenu = ()=>{
  burger.click()
  setDropdown(false)
}

const submenuList = submenu.map((sub,index)=>{
  return <li key={index}><Link className="text-white lh-4" to={sub.url} onClick={closeMenu}>{sub.title}</Link></li>
})

return (
  <ul className={`${className} text-white list-unstyled ps-3 lh-lg ${!dropdown?"d-none":""}`}  >
          {submenuList}
  </ul>
)
}
export default MobileDropdown