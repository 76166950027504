const socials=[
    {
        id:1,
        type:"Instagram",
        link:" https://instagram.com/ifalgerie"
    },
    {
        id:2,
        type:"Youtube",
        link:"https://youtube.com/@institutfrancaisdalgerie5536"
    },
    {
        id:3,
        type:"Tiktok",
        link:"https://www.tiktok.com/@ifalgerie"
    },
    {
        id:4,
        type:"LinkedIn",
        link:"#"
    }

]

const socialInfos=[
    {
        id:58,
        region:"national",
        fb:"https://www.facebook.com/IFAlgerie/",
        adresse:"Institut français d'Alger - 7, rue Hassani Issad - 16000 Alger - Algérie.",
        acceuil:"Du Samedi au Jeudi de 8h30 à 16h30",
    },
    {
        id:16,
        region:"alger",
        fb:"https://www.facebook.com/algerifa",
        adresse:"Institut français d'Alger - 7, rue Hassani Issad - 16000 Alger - Algérie.",
        acceuil:"Du Samedi au Jeudi de 8h30 à 16h30",
    },
    {
        id:31,
        region:"oran",
        fb:"https://www.facebook.com/institutfrancaisoran",
        adresse:"Institut Français d'Oran - 112, rue Larbi ben M'Hidi - 31009 - Oran, Algérie.",
        acceuil:"Du samedi au jeudi, de 9h à 17h30",
    },
    {
        id:25,
        region:"constantine",
        fb:"https://www.facebook.com/IFConstantine",
        adresse:"Institut français de Constantine - 1 boulevard de l'Indépendance –25 000 Constantine",
        acceuil:"",
    },
    {
        id:23,
        region:"annaba",
        fb:"https://www.facebook.com/institutfrancaisannaba",
        adresse:"Institut français d'Annaba - 8 Bd du 1er Novembre 1954 Annaba",
        acceuil:"Du samedi au jeudi : 8h30 à 17h00",
    },
    {
        id:13,
        region:"tlemcen",
        fb:"https://www.facebook.com/IFTlemcen",
        adresse:"Institut Français Tlemcen, 1 rue Commandant Djaber - 13000, Tlemcen.",
        acceuil:"",
    }
]

export {socialInfos,socials}