import React,{useState,useEffect, useContext} from 'react'
import { Card, Container , Row,Col} from 'react-bootstrap'
import styles from "./EtudierEnFrance.module.css"
import CoverImg from 'components/General/CoverImg'
import france from "../../assets/img/etudier-en-france.jpg"
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'
import BourseBtn from 'components/Etudes/BourseBtn'
import BgShape from 'components/General/BgShape'
import { getEtude } from 'services/etudes'
import { getAsset } from 'services/assets'
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList,ShimmerSectionHeader,ShimmerFeaturedGallery } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'
function Bourses() {
  const [data, setData] = useState()
  const [loading, setLoading] = React.useState(true);
  const { language } = useContext(LanguageContext);
    useEffect( () => {
      function fetchData() {
        getEtude('national').then((results)=>{
          //console.log(results?.items?.filter(x=>x.data?.slug.iv.toLowerCase()==='bourses'))
setData(results?.items?.filter(x=>x.data?.slug.iv.toLowerCase()==='bourses')?.[0]?.data);  
setLoading(false)  
} )} 
      fetchData();
  }, []);
  const [srcImage,setSrcImage] = useState();
  React.useEffect( () => {

    function fetchData() {
              
            var img = data?.Image?.iv? data?.Image?.iv[0]: data?.Image?.[language]?data?.Image?.[language][0]:data?.Image?data?.Image[0]:''; //todo default image
            
      if(img)
  getAsset(img).then((srcImage)=>{
//console.log(srcImage)
    setSrcImage(srcImage);
    setLoading(false)
        })
      }
       fetchData() 
    },[data]);
  
//console.log(data)
const [banner, setBanner] = useState();
useEffect( () => {
  if(data?.banner?.[language])
  getAsset(data?.banner?.[language]).then((srcImage)=>{
    setBanner(srcImage);
  });
  
}, [data?.banner?.[language]]);
try{var text = parse(data?.Description?.[language])}catch($){
  text = data?.Description?.[language]
}
  return loading? (<><ShimmerSectionHeader/> <ShimmerSimpleGallery card imageHeight={300} caption /></> ):(
     <div className=" position-relative overflow-hidden" dir={language==='ar'?"rtl":""}>
            {/*<BgShape type="red"  right="-40px" top="90px" rotate="-140deg"/>*/}
            {/*<BgShape type="yellow"  right="-50px" bottom="100px" rotate="-150deg"/>*/}
            {/*<BgShape type="blue"  left="-80px" top="500px" rotate="140deg"/>*/}
      
      <CoverImg img={banner??srcImage} />
      <Breadcrumb/>
      <Container >
        <MainTitle>{data?.Titre?.[language]??'Bourses'}</MainTitle>
        {text??
            <><p className=" text-black-50 mb-4">
          Il existe plusieurs bourses encourager la coopération
          franco-algérienne dans le domaine de la recherche.
        </p>
        <Row className="mb-5">
          <Col md={6} className="text-start">
              <h4 className="fw-bold mb-4">Bourses d’études :</h4>
              <Card className={`lh-lg ${styles.bourseCard} text-black-50`}>
                <p>
                </p>
                
              </Card>
            
          </Col>
          <Col md={6}>
              <h4 className="fw-bold mb-4">Programme de bourses France Excellence IFA</h4>
              <Card className={`lh-lg text-black-50 ${styles.bourseCard}`}>
                <div>
                  <p>
                  Tous les ans, l’Institut français d’Algérie accompagne financièrement des candidats étudiant en Algérie dans leur poursuite d’études en France selon des critères d’excellence académique et des champs d’études prioritaires. L’attribution de cette bourse visa à valoriser les profils d’excellence et favoriser l’employabilité professionnelle des jeunes lauréats à leur retour en Algérie.
                  </p>
                  
                </div>
                
              </Card>
          </Col>
          <Col md={6}>
              <h4 className="fw-bold mb-4">Programmes de bourses Jean Sénac</h4>
              <Card className={`lh-lg text-black-50 ${styles.bourseCard}`}>
                <div>
                  <p>
                  À l’occasion du Cinquantenaire de la disparition du poète Jean SENAC en 2023, l’Institut français d’Algérie attribue 6 bourses doctorales à des étudiants algériens inscrits en thèse depuis moins de 2 ans dans une université algérienne. Les lauréats obtiendront 18 mois de bourses de mobilité doctorale répartis sur 36 mois, ce qui leur permettra une inscription en cotutelle de thèse auprès d’une école doctorale française.

Domaines d’études concernés : didactique du français, littérature française et francophone, sciences du langage</p>
                  
                </div>
                
              </Card>
          </Col>
        </Row>
        <Row className="mb-5">
          <Col md={6} className="text-start">
              <h4 className="fw-bold mb-4">Bourses scientifiques :</h4>
              <Card className={`lh-lg ${styles.bourseCard} text-black-50`}>
                <p>
                </p>
                
              </Card>
            
          </Col>
          <Col md={6}>
              <h4 className="fw-bold mb-4">Programme de bourses André Mandouze</h4>
              <Card className={`lh-lg text-black-50 ${styles.bourseCard}`}>
                <div>
                  <p>
                  L’Institut français d’Algérie a lancé un programme annuel de séjours scientifiques de haut niveau en sciences humaines et sociales pour des chercheurs algériens souhaitant travailler sur des fonds d’archives en France, des recueils de témoignages ou des questions mémorielles relatives à la colonisation, à la guerre d’Algérie, à l’indépendance et à la décolonisation. Le comité de sélection des lauréats regroupe quatre experts (France, Tunisie, Algérie).

Les lauréats de la bourse sont accueillis dans une équipe de recherche française, rattachée à un établissement d’enseignement supérieur ou un organisme de recherche pour une durée de 1 à 2 mois. Un séminaire annuel réunit ensuite les lauréats.</p>
                  
                </div>
                
              </Card>
          </Col>
          
        </Row></>}
      </Container>
      </div>
  );
}

export default Bourses