import React from 'react'
import {Container} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'
import CoverImg from 'components/General/CoverImg'
import culture from "../../assets/img/culture.jpg"

const AccountPage = () => {
  return (
    <>
    <CoverImg img={culture}/>
    <Breadcrumb/>
    <Container>
        {/* <MainTitle>Account page</MainTitle> */}
        <div className="arc-en-ciel  mt-4">
        <div data-module="student"
            data-action="student-controls"
            data-param-login-link="/connexion"
            data-param-logout-link="/students/logout"
            data-param-account-link="/mon-compte"
            data-param-my-cart-link="/panier"
            data-redirect-url-after-logout = "students/login"
            className='mb-5'></div>
            <div data-module="student" data-action="account-dashboard" data-param-register-class-url="classes/view" data-param-update-password-url="changer-le-mot-de-passe"></div>
        </div>
    </Container>
    </>
  )
    
}

export default AccountPage