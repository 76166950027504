 
 import portrait from "../../assets/img/portrait.jpg";

 const equipes = [
   {
     region: "national",
     equipe: [
      {
        id: 0,
        name: "Mme Ahlem Gharbi",
        role: "Directrice Générale",
        img: portrait,
      },
       {
         id: 1,
         name: "Mme Emmanuelle Talon",
         role: "Directrice Générale Adjointe",
         img: portrait,
       },
       {
         id: 2,
         name: "Mme Nathalie Pisko",
         role: "Secrétaire Générale",
         img: portrait,
       },
       {
         id: 3,
         name: "Mme Amina Mahmoudi",
         role: "Secrétaire Générale Ajointe",
         img: portrait,
       },
       {
         id: 4,
         name: "M. Nicolas Robida",
         role: "Responsable du pôle culturel et audiovisuel",
         img: portrait,
       },
       /*{
         id: 5,
         name: "",
         role: "Chargé de missions culturelles",
         img: portrait,
       },
       {
         id: 6,
         name: "",
         role: "Chargé de missions culturelles",
         img: portrait,
       },
       {
         id: 7,
         name: "",
         role: "Chargé de missions culturelles",
         img: portrait,
       },
       {
         id: 8,
         name: "",
         role: "Chargé de missions culturelles",
         img: portrait,
       },*/
       {
         id: 9,
         name: "Mme Emmanuelle Deschamps",
         role: "Responsable du pôle recherche et enseignement supérieur",
         img: portrait,
       },
       {
         id: 10,
         name: "Yacine Hadjeb",
         role: "Responsable Adjoint Campus France",
         img: portrait,
       },
       {
         id: 11,
         name: "M. Rémi Veyrier",
         role: "Responsable du pôle linguistique",
         img: portrait,
       },
       {
         id: 12,
         name: "Mme Valérie Le Galcher-Baron",
         role: "Responsable du pôle éducatif",
         img: portrait,
       },
       {
         id: 13,
         name: "Mme Inas de Brion",
         role: "Responsable du pôle gouvernance et société civile",
         img: portrait,
       },
       {
        id: 14,
        name: "Mme Najoua Medroumi",
        role: "Responsable du pôle communication",
        img: portrait,
      },
     ],
   },
   {
     region: "alger",
     equipe: [
       {
        id:1, 
        departement: "direction",
         membres: [
           {
             nom: "Philippe Moneste",
             role: "Directeur",
             email: "philippe.monestes@if-algerie.com",
             img: portrait,
           },
           {
             nom: "Abdelbasset Saoula",
             role: "Adjoint au directeur",
             email: " philippe.monestes@if-algerie.com",
           },
         ],
       },
       {
        id:2, 
        departement: "administration",
         membres: [
           {
             nom: "Mourad Bachire",
             role: "Secrétaire général adjoint",
             email: "mourad.bachir@if-algerie.com",
           },
           {
             nom: "Bilel Manaa",
             role: "Responsable informatique",
             email: "bilel.manaa@if-algerie.com",
           },
           {
             nom: "Lyes Bassata",
             role: "Comptable",
             email: "lyes.bassata@if-algerie.com",
           },
         ],
       },
       {
        id:3, 
        departement: "comptabilité",
         membres: [
           {
             nom: "Karim Mahour",
             role: "Régisseur",
             email: "karim.mahour@if-algerie.com",
           },
         ],
       },
       {
        id:4, 
        departement: "Département de Langue Française",
         membres: [
           {
             nom: "Nawel Braik",
             role: "Responsable",
             email: "nawel.braik@if-algerie.com",
           },
         ],
       },
       {
        id:5, 
        departement: "Communication et Relations publiques",
         membres: [
           {
             nom: "Hachemi Mokrane",
             role: "Responsable",
             email: "hachemi.mokrane@if-algerie.com",
           },
         ],
       },
       {
        id:6, 
        departement: "Programmation culturelle",
         membres: [
           {
             nom: "Kenza Souffi-Bachir",
             role: "Arts de la scène / Arts plastiques",
             email: "kenza.souffi-bachir@if- algerie.com",
           },
           {
             nom: "Abdenour Hochiche",
             role: "Cinéma",
             email: "abdenour.hochiche@if-algerie.com",
           },
           {
             nom: "Nassim Rouchiche",
             role: "Bureau du livre",
             email: "nassim.rouchiche@if-algerie.com",
           },
           {
             nom: "Anys Mezzaour",
             role: "Débat d'idées",
             email: "anys.mezzaour@if-algerie.com",
           },
         ],
       },
       {
        id:7, 
        departement: "Logistique",
         membres: [
           {
             nom: "Fatima Zohra Chaib",
             role: "Responsable",
             email: "logistique@if-algerie.com",
           },
         ],
       },
       {
        id:8, 
        departement: "Régie technique",
         membres: [
           {
             nom: "Samy Moh Boudarene",
             role: "Régisseur",
             email: "samy.boudarene@if-algerie.com",
           },
         ],
       },
       {
        id:9, 
        departement: "Campus France -site d’Hydra-",
         membres: [
           {
             nom: "Nabila Amrani-Abdelaziz",
             role: "Responsable",
             email: "nabila.amrani@if-algerie.com",
           },
         ],
       },
       {
        id:10, 
        departement: "Médiathèque",
         membres: [
           {
             nom: "Bouthaina Benmohamed",
             role: "Responsable",
             email: "bouthaina.benmohamed@if-algerie.com",
           },
         ],
       },
     ],
   },
   {
    region: "oran",
    equipe: [
      {
       id:1, 
       departement: "direction",
        membres: [
          {
            nom: "Philippe Moneste",
            role: "Directeur",
            email: "philippe.monestes@if-algerie.com",
            img: portrait,
          },
          {
            nom: "Abdelbasset Saoula",
            role: "Adjoint au directeur",
            email: " philippe.monestes@if-algerie.com",
          },
        ],
      },
      {
       id:2, 
       departement: "administration",
        membres: [
          {
            nom: "Mourad Bachire",
            role: "Secrétaire général adjoint",
            email: "mourad.bachir@if-algerie.com",
          },
          {
            nom: "Bilel Manaa",
            role: "Responsable informatique",
            email: "bilel.manaa@if-algerie.com",
          },
          {
            nom: "Lyes Bassata",
            role: "Comptable",
            email: "lyes.bassata@if-algerie.com",
          },
        ],
      },
      {
       id:3, 
       departement: "comptabilité",
        membres: [
          {
            nom: "Karim Mahour",
            role: "Régisseur",
            email: "karim.mahour@if-algerie.com",
          },
        ],
      },
      {
       id:4, 
       departement: "Département de Langue Française",
        membres: [
          {
            nom: "Nawel Braik",
            role: "Responsable",
            email: "nawel.braik@if-algerie.com",
          },
        ],
      },
      {
       id:5, 
       departement: "Communication et Relations publiques",
        membres: [
          {
            nom: "Hachemi Mokrane",
            role: "Responsable",
            email: "hachemi.mokrane@if-algerie.com",
          },
        ],
      },
      {
       id:6, 
       departement: "Programmation culturelle",
        membres: [
          {
            nom: "Kenza Souffi-Bachir",
            role: "Arts de la scène / Arts plastiques",
            email: "kenza.souffi-bachir@if- algerie.com",
          },
          {
            nom: "Abdenour Hochiche",
            role: "Cinéma",
            email: "abdenour.hochiche@if-algerie.com",
          },
          {
            nom: "Nassim Rouchiche",
            role: "Bureau du livre",
            email: "nassim.rouchiche@if-algerie.com",
          },
          {
            nom: "Anys Mezzaour",
            role: "Débat d'idées",
            email: "anys.mezzaour@if-algerie.com",
          },
        ],
      },
      {
       id:7, 
       departement: "Logistique",
        membres: [
          {
            nom: "Fatima Zohra Chaib",
            role: "Responsable",
            email: "logistique@if-algerie.com",
          },
        ],
      },
      {
       id:8, 
       departement: "Régie technique",
        membres: [
          {
            nom: "Samy Moh Boudarene",
            role: "Régisseur",
            email: "samy.boudarene@if-algerie.com",
          },
        ],
      },
      {
       id:9, 
       departement: "Campus France -site d’Hydra-",
        membres: [
          {
            nom: "Nabila Amrani-Abdelaziz",
            role: "Responsable",
            email: "nabila.amrani@if-algerie.com",
          },
        ],
      },
      {
       id:10, 
       departement: "Médiathèque",
        membres: [
          {
            nom: "Bouthaina Benmohamed",
            role: "Responsable",
            email: "bouthaina.benmohamed@if-algerie.com",
          },
        ],
      },
    ],
  },
  {
    region: "constantine",
    equipe: [
      {
       id:1, 
       departement: "direction",
        membres: [
          {
            nom: "Philippe Moneste",
            role: "Directeur",
            email: "philippe.monestes@if-algerie.com",
            img: portrait,
          },
          {
            nom: "Abdelbasset Saoula",
            role: "Adjoint au directeur",
            email: " philippe.monestes@if-algerie.com",
          },
        ],
      },
      {
       id:2, 
       departement: "administration",
        membres: [
          {
            nom: "Mourad Bachire",
            role: "Secrétaire général adjoint",
            email: "mourad.bachir@if-algerie.com",
          },
          {
            nom: "Bilel Manaa",
            role: "Responsable informatique",
            email: "bilel.manaa@if-algerie.com",
          },
          {
            nom: "Lyes Bassata",
            role: "Comptable",
            email: "lyes.bassata@if-algerie.com",
          },
        ],
      },
      {
       id:3, 
       departement: "comptabilité",
        membres: [
          {
            nom: "Karim Mahour",
            role: "Régisseur",
            email: "karim.mahour@if-algerie.com",
          },
        ],
      },
      {
       id:4, 
       departement: "Département de Langue Française",
        membres: [
          {
            nom: "Nawel Braik",
            role: "Responsable",
            email: "nawel.braik@if-algerie.com",
          },
        ],
      },
      {
       id:5, 
       departement: "Communication et Relations publiques",
        membres: [
          {
            nom: "Hachemi Mokrane",
            role: "Responsable",
            email: "hachemi.mokrane@if-algerie.com",
          },
        ],
      },
      {
       id:6, 
       departement: "Programmation culturelle",
        membres: [
          {
            nom: "Kenza Souffi-Bachir",
            role: "Arts de la scène / Arts plastiques",
            email: "kenza.souffi-bachir@if- algerie.com",
          },
          {
            nom: "Abdenour Hochiche",
            role: "Cinéma",
            email: "abdenour.hochiche@if-algerie.com",
          },
          {
            nom: "Nassim Rouchiche",
            role: "Bureau du livre",
            email: "nassim.rouchiche@if-algerie.com",
          },
          {
            nom: "Anys Mezzaour",
            role: "Débat d'idées",
            email: "anys.mezzaour@if-algerie.com",
          },
        ],
      },
      {
       id:7, 
       departement: "Logistique",
        membres: [
          {
            nom: "Fatima Zohra Chaib",
            role: "Responsable",
            email: "logistique@if-algerie.com",
          },
        ],
      },
      {
       id:8, 
       departement: "Régie technique",
        membres: [
          {
            nom: "Samy Moh Boudarene",
            role: "Régisseur",
            email: "samy.boudarene@if-algerie.com",
          },
        ],
      },
      {
       id:9, 
       departement: "Campus France -site d’Hydra-",
        membres: [
          {
            nom: "Nabila Amrani-Abdelaziz",
            role: "Responsable",
            email: "nabila.amrani@if-algerie.com",
          },
        ],
      },
      {
       id:10, 
       departement: "Médiathèque",
        membres: [
          {
            nom: "Bouthaina Benmohamed",
            role: "Responsable",
            email: "bouthaina.benmohamed@if-algerie.com",
          },
        ],
      },
    ],
  },
  {
    region: "annaba",
    equipe: [
      {
       id:1, 
       departement: "direction",
        membres: [
          {
            nom: "Philippe Moneste",
            role: "Directeur",
            email: "philippe.monestes@if-algerie.com",
            img: portrait,
          },
          {
            nom: "Abdelbasset Saoula",
            role: "Adjoint au directeur",
            email: " philippe.monestes@if-algerie.com",
          },
        ],
      },
      {
       id:2, 
       departement: "administration",
        membres: [
          {
            nom: "Mourad Bachire",
            role: "Secrétaire général adjoint",
            email: "mourad.bachir@if-algerie.com",
          },
          {
            nom: "Bilel Manaa",
            role: "Responsable informatique",
            email: "bilel.manaa@if-algerie.com",
          },
          {
            nom: "Lyes Bassata",
            role: "Comptable",
            email: "lyes.bassata@if-algerie.com",
          },
        ],
      },
      {
       id:3, 
       departement: "comptabilité",
        membres: [
          {
            nom: "Karim Mahour",
            role: "Régisseur",
            email: "karim.mahour@if-algerie.com",
          },
        ],
      },
      {
       id:4, 
       departement: "Département de Langue Française",
        membres: [
          {
            nom: "Nawel Braik",
            role: "Responsable",
            email: "nawel.braik@if-algerie.com",
          },
        ],
      },
      {
       id:5, 
       departement: "Communication et Relations publiques",
        membres: [
          {
            nom: "Hachemi Mokrane",
            role: "Responsable",
            email: "hachemi.mokrane@if-algerie.com",
          },
        ],
      },
      {
       id:6, 
       departement: "Programmation culturelle",
        membres: [
          {
            nom: "Kenza Souffi-Bachir",
            role: "Arts de la scène / Arts plastiques",
            email: "kenza.souffi-bachir@if- algerie.com",
          },
          {
            nom: "Abdenour Hochiche",
            role: "Cinéma",
            email: "abdenour.hochiche@if-algerie.com",
          },
          {
            nom: "Nassim Rouchiche",
            role: "Bureau du livre",
            email: "nassim.rouchiche@if-algerie.com",
          },
          {
            nom: "Anys Mezzaour",
            role: "Débat d'idées",
            email: "anys.mezzaour@if-algerie.com",
          },
        ],
      },
      {
       id:7, 
       departement: "Logistique",
        membres: [
          {
            nom: "Fatima Zohra Chaib",
            role: "Responsable",
            email: "logistique@if-algerie.com",
          },
        ],
      },
      {
       id:8, 
       departement: "Régie technique",
        membres: [
          {
            nom: "Samy Moh Boudarene",
            role: "Régisseur",
            email: "samy.boudarene@if-algerie.com",
          },
        ],
      },
      {
       id:9, 
       departement: "Campus France -site d’Hydra-",
        membres: [
          {
            nom: "Nabila Amrani-Abdelaziz",
            role: "Responsable",
            email: "nabila.amrani@if-algerie.com",
          },
        ],
      },
      {
       id:10, 
       departement: "Médiathèque",
        membres: [
          {
            nom: "Bouthaina Benmohamed",
            role: "Responsable",
            email: "bouthaina.benmohamed@if-algerie.com",
          },
        ],
      },
    ],
  },
  {
    region: "tlemcen",
    equipe: [
      {
       id:1, 
       departement: "direction",
        membres: [
          {
            nom: "Philippe Moneste",
            role: "Directeur",
            email: "philippe.monestes@if-algerie.com",
            img: portrait,
          },
          {
            nom: "Abdelbasset Saoula",
            role: "Adjoint au directeur",
            email: " philippe.monestes@if-algerie.com",
          },
        ],
      },
      {
       id:2, 
       departement: "administration",
        membres: [
          {
            nom: "Mourad Bachire",
            role: "Secrétaire général adjoint",
            email: "mourad.bachir@if-algerie.com",
          },
          {
            nom: "Bilel Manaa",
            role: "Responsable informatique",
            email: "bilel.manaa@if-algerie.com",
          },
          {
            nom: "Lyes Bassata",
            role: "Comptable",
            email: "lyes.bassata@if-algerie.com",
          },
        ],
      },
      {
       id:3, 
       departement: "comptabilité",
        membres: [
          {
            nom: "Karim Mahour",
            role: "Régisseur",
            email: "karim.mahour@if-algerie.com",
          },
        ],
      },
      {
       id:4, 
       departement: "Département de Langue Française",
        membres: [
          {
            nom: "Nawel Braik",
            role: "Responsable",
            email: "nawel.braik@if-algerie.com",
          },
        ],
      },
      {
       id:5, 
       departement: "Communication et Relations publiques",
        membres: [
          {
            nom: "Hachemi Mokrane",
            role: "Responsable",
            email: "hachemi.mokrane@if-algerie.com",
          },
        ],
      },
      {
       id:6, 
       departement: "Programmation culturelle",
        membres: [
          {
            nom: "Kenza Souffi-Bachir",
            role: "Arts de la scène / Arts plastiques",
            email: "kenza.souffi-bachir@if- algerie.com",
          },
          {
            nom: "Abdenour Hochiche",
            role: "Cinéma",
            email: "abdenour.hochiche@if-algerie.com",
          },
          {
            nom: "Nassim Rouchiche",
            role: "Bureau du livre",
            email: "nassim.rouchiche@if-algerie.com",
          },
          {
            nom: "Anys Mezzaour",
            role: "Débat d'idées",
            email: "anys.mezzaour@if-algerie.com",
          },
        ],
      },
      {
       id:7, 
       departement: "Logistique",
        membres: [
          {
            nom: "Fatima Zohra Chaib",
            role: "Responsable",
            email: "logistique@if-algerie.com",
          },
        ],
      },
      {
       id:8, 
       departement: "Régie technique",
        membres: [
          {
            nom: "Samy Moh Boudarene",
            role: "Régisseur",
            email: "samy.boudarene@if-algerie.com",
          },
        ],
      },
      {
       id:9, 
       departement: "Campus France -site d’Hydra-",
        membres: [
          {
            nom: "Nabila Amrani-Abdelaziz",
            role: "Responsable",
            email: "nabila.amrani@if-algerie.com",
          },
        ],
      },
      {
       id:10, 
       departement: "Médiathèque",
        membres: [
          {
            nom: "Bouthaina Benmohamed",
            role: "Responsable",
            email: "bouthaina.benmohamed@if-algerie.com",
          },
        ],
      },
    ],
  }
 ];


const directeurDelegues = [

    { 
        id:16,
        name: "Philippe Moneste",
        city: "alger"
    },
    { 
        id:23,
        name: "Philippe Laleu",
        city: "annaba"
    },
    { 
        id:25,
        name: "Charlotte Aillet",
        city: "constantine" 
    },
    { 
        id:31,
        name: "Romain de Tarlé ",
        city: "oran"
    },
    { 
        id:13,
        name: "Mme Emmanuelle Ravot ",
        city: "tlemcen"
    },
]
export {equipes,directeurDelegues}