import { createContext, useEffect, useState,useContext } from 'react';
import { RiArrowDownSFill, RiArrowUpSFill } from "react-icons/ri";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { Link, useLocation } from 'react-router-dom';
import EtudesMobileMenu from './EtudesMobileMenu';
import MobileDropdown from './MobileDropdown';
import { globalDropdownContext } from 'pages/header/MainHeader2';


//======= MY MobileMenuItem cmp =========
// create a drop down context
export const dropdownContext = createContext(false)

function MobileMenuItem({item}) {
    const [dropdown,setDropdown] =useState(false)
    const {globalDropdown,setGolbalDropdown} = useContext(globalDropdownContext)
    // close all drop downs when clicking on the burger icon
    useEffect(()=>{
        if (!globalDropdown) {
            setDropdown(false)
        }
    },[globalDropdown])
    const location = useLocation()
    const pathArray = location.pathname.split('/')
    const villes = ["alger","annaba","oran","constantine","tlemcen"]
    const burger = document.getElementById("burger");
    const closeMenu = () => {
      burger.click();
    };
    

    const displayMobileMenuItem = (item)=>{
        if(item.title ==="études"){

            return (
              <>
                <button className="nav-link d-flex justify-content-between align-items-center w-100 text-uppercase font-weight-bold"  style={MENU_ITEM} onClick={handleClick}>
                        {item.title} {dropdown?<TiArrowSortedUp/>:<TiArrowSortedDown/>}
                </button>
                <EtudesMobileMenu item={item} style={MENU_ITEM}/>
              </>
            );
        }
        else if (!item.url) {
            return (
                <>
                    <button className="nav-link d-flex justify-content-between align-items-center w-100 text-uppercase font-weight-bold"  style={MENU_ITEM} onClick={handleClick}>
                        {item.title} {dropdown?<RiArrowUpSFill/>:<RiArrowDownSFill/>}
                    </button>
                    <MobileDropdown title={item.title} dropdown={dropdown} submenu={item.submenu}/>
                </>
            )
        }else if(item.url && item.title==="Médiathèques"){
            
            
            return !villes.includes(pathArray[1]) ?(
                <button className="nav-link d-flex justify-content-between align-items-center w-100 text-uppercase font-weight-bold"  style={MENU_ITEM} onClick={closeMenu}>
                    <Link className="text-white lh-4" to={item.url}>{item.title}</Link>
                </button>)
            
            :(
            <>
                <button className="nav-link d-flex justify-content-between align-items-center w-100 text-uppercase font-weight-bold"  style={MENU_ITEM} onClick={handleClick}>
                    {item.title} {dropdown?<TiArrowSortedUp/>:<TiArrowSortedDown/>}
                </button>
                <MobileDropdown title={item.title} dropdown={dropdown} submenu={item.submenu}/>
            </>
                )
        } else {
            return(
            <button className="nav-link d-flex justify-content-between align-items-center w-100 text-uppercase font-weight-bold"  style={MENU_ITEM} onClick={closeMenu}>
                <Link className="text-white lh-4" to={item.url}>{item.title}</Link>
            </button>

            )
        }
    }
    
    const MENU_ITEM ={
        color: "#FFFFFF",
        fontStyle: "normal",
        fontWeight: "600",
        background:"none",
        border:"none"
    }

    const handleClick=()=>{
        setDropdown(!dropdown)
    }


  return (
    <dropdownContext.Provider value={{dropdown,setDropdown}}>
      <li className="nav-item">
          {displayMobileMenuItem(item)}
      </li>
    </dropdownContext.Provider>
  )
}
export default MobileMenuItem