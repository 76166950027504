import React from 'react'
import { Table } from 'react-bootstrap'
import parse from 'html-react-parser'
const CoursHorairesCard = ({data,text}) => {
    //console.log(data)
    try{var xxx = parse(text)}catch($){
        xxx = text
      }
  return (
    <div className="bg-light p-3">
        
                    <Table borderless className="fs-5 ">
                        <thead>
                        <tr>
                            <th colSpan={2}>
                            <h5 className="text-start blue-ifa fw-bold p-0">
                                Horaire
                            </h5>
                            </th>
                        </tr>
                        </thead>
                        <tbody className="fs-6">
                            {data?.map((e,i)=>
                        <tr>
                            <td className="text-start">{e.Jour}</td>
                            <td className="text-end">{e.Heure}</td>
                        </tr>
                        )}
                        </tbody>
                    </Table>
                    {xxx}
                    </div>
  )
}

export default CoursHorairesCard