import styles from "./MemberCard.module.css"
import portrait from "../../assets/img/portrait.jpg";
import {MdMailOutline} from "react-icons/md"
import { Card } from 'react-bootstrap';
import React,{useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import { getAsset } from "services/assets";
import { ShimmerTitle,ShimmerSimpleGallery } from 'react-shimmer-effects';
function MemberCard({img,children,className,membre}) {
  const [image,setImage] = useState();
  const [loading, setLoading] = React.useState(true);
  useEffect( () => {
    if(membre?.Photo?.[0])
    getAsset(membre?.Photo?.[0]).then((zz)=>{
      img=zz;
      setImage(zz);
      ////console.log(zz)
      setLoading(false)
  });
else{
  if(img)
  setImage(img)
  setLoading(false)
}});
  return loading? <ShimmerSimpleGallery imageType="circular" imageHeight={200} caption /> :(
    // <div className={`${styles.card} ${className}`}>
    //     {membre.img
    //     &&<div>
    //         <img src={portrait} className="me-2"></img>
    //     </div>}
                        
    //     <div className='text-start'>
    //         {children}
    //         <h5 className="fw-bold" >{membre.nom}</h5>
    //         <span className="fs-6">{membre.role} </span>
    //         <a href={`mailto:${membre.email}`} className="d-block blue-ifa" style={{fontSize:"14px"}}>
    //           <MdMailOutline className='me-1'/> {membre.email}
    //         </a>
    //     </div>
    // </div>
    <Card className="rounded-0">
        {membre?.Photo?.[0]
        &&<Card.Img variant="bottom" src={image??portrait} className="rounded-0 mb-2"/>}
      <Card.Body>
            {/* <img src={portrait} className="me-2"></img>
        </Card.Img>} */}
                        
        <div className='text-start'>
            {children}
            <h6 className="fw-bold" >{membre?.nom??membre?.NomComplet}</h6>
            <small className="">{membre?.role??membre?.Fonction} </small>
            <a href={`mailto:${membre?.email}`} className="d-block blue-ifa" style={{fontSize:"14px"}}>
              <MdMailOutline className='me-1'/> {membre?.email}
            </a>
        </div>
        </Card.Body>
    </Card>
  )
}

export default MemberCard