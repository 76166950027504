import React, { useContext, useEffect, useState } from 'react'
import cover from "../../assets/img/cover_exams.jpg";
import { Container, Row, Col} from "react-bootstrap";
import Breadcrumb from "components/breadcrumb/breadcrumb";
import CoverImg from 'components/General/CoverImg';
import {useNavigate} from "react-router-dom";
import MainTitle from 'components/General/MainTitle';
import BgShape from 'components/General/BgShape';
import { Examens } from 'data/tests et examens/Examens-delf';
import TestCard from 'components/TestEtExamens/TestCard';
import { getTests } from 'services/tests-exams'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import EventsGrid from 'components/General/EventsGrid';
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList,ShimmerSectionHeader,ShimmerFeaturedGallery } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext';
function DelfDalfPage({region}) {
  const navigate = useNavigate()
  const { language } = useContext(LanguageContext);
    const handleCardClick = (testName)=>{
        navigate(testName)
    }
    const [data, setData] = useState()
    const [loading, setLoading] = React.useState(true);
    useEffect( () => {
      function fetchData() {
        getTests({region:region}).then((results)=>{
          results?.items?.[0]?.data?.tests?.[language]?.filter(x=>x.title.includes("DALF")).map(e => {
              
              var img = e?.Image?.iv? e?.Image?.iv[0]: e?.Image?.[language]?e?.Image?.[language][0]:e?.Image?e?.Image[0]:''; //todo default image
    getAsset(img).then((srcImage)=>{
      e.srcImage=srcImage;
      setData(e);
      setLoading(false)
  });
});
          
} )} 
      fetchData();
  }, []);
  const [banner, setBanner] = useState();
useEffect( () => {
  if(data?.banner?.[language])
  getAsset(data?.banner?.[language]).then((srcImage)=>{
    setBanner(srcImage);
  });
  
}, []);
  //console.log(data)
  try{var text = parse(data?.description)}catch($){
      text = data?.description
    }
  return loading? (<><ShimmerPostDetails card cta variant="SIMPLE" /><ShimmerPostList postStyle="STYLE_FOUR" col={3} row={1} gap={30} /></>) :(
    <div className=" position-relative overflow-hidden bg-grey z-index-1 pb-xxl-5" style={{zIndex:"1"}} dir={language==='ar'?"rtl":""}>
    {/*<BgShape type="red"  right="-40px" top="100px" rotate="-140deg"/>*/}
    {/*<BgShape type="yellow"  right="-50px" top="1400px" rotate="-150deg"/>*/}
    {/*<BgShape type="blue"  left="-80px" top="700px" rotate="140deg"/>*/}
        <CoverImg img={banner??data?.srcImage??cover}/>
        <Breadcrumb/>
        <Container >
          <MainTitle>Diplômes</MainTitle>
          {text??
          <p className="lh-lg text-start text-black-50">
            le Diplôme d'études en langue française (DELF) et le Diplôme
            approfondi en langue française (DALF) sont des diplômes valables à
            vie. Ils sont délivrés par le Minist`ere français de l'Education
            Nationale et bénéficient d'une reconnaissance internationale. il
            comporte 7 niveaux de maitrise de la langue allant du niveau A1.1
            `au niveau C2.
            <br />
            Ces diplômes constituent une reconnaissance de votre parcours
            scolaire, universitaire et/ou professionnel en langue française. De
            plus, les DELF et les DALF peuvent vous permettre d'étudier, de
            travailler et d'immigrer dans un pays francophone.
          </p>}
          {/* Les examens */}
          <Row gap={2} className="mt-5 w-100 mx-auto">
          {data?.subtests?.length>0?data?.subtests?.map((test,i)=>{
                    //console.log(test.title)
                    return (
                    <Col onClick={()=>handleCardClick(test.title)} key={i} lg={4} md={6} className="mb-4 mb-lg-0 cursor-pointer">
                            <TestCard test = {test}/>
                    </Col>)
                }):
            Examens.map((examen)=>{
                return (<Col key={examen.id} md={6} lg={4} className="mb-4 cursor-pointer" onClick={()=>handleCardClick(examen.url)}>
                              <TestCard  test={examen} />
                        </Col>)
            })}
          </Row>
        </Container>
        {<Container>
              
              <EventsGrid title="Informations" type="DELF et DALF" region={region&&region}/>
  </Container>}
      </div>
  )
}

export default DelfDalfPage