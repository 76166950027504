import React, { useContext, useEffect,useState } from 'react'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import CoverImg from 'components/General/CoverImg'
import MemberCard from 'components/Institut/MemberCard'
import alger from '../../assets/img/alger.jpg'
import { Container } from 'react-bootstrap'
import MainTitle from 'components/General/MainTitle'
import styles from "./institut.module.css"
import SocialSection from 'components/General/SocialSection'
import BgShape from 'components/General/BgShape'
import {equipes,directeurDelegues} from "data/institut/equipes"
import { useGlobal } from 'contexts/GlobalContext'
import { getEquipe } from 'services/equipe'

import TeamGrid from "components/Cities/TeamGrid";
import { getAsset } from 'services/assets'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'
function InstitutEquipePage() {
    // TEST OF CHANGING THE BG COLOR USING STATE
    /*
    const {changeBackgroundColor} = useGlobal()
    useEffect(()=>{
        // changeBackgroundColor("grey")
    },[])
    */
    const equipeNational = equipes.filter(equipe=>equipe.region === "national")[0].equipe
    const DGenerale = equipeNational.find(member=>member.role ==="Directrice Générale")
    const {bg,changeBackgroundColor} = useGlobal()
    const [loading, setLoading] = React.useState(true);
    const { language } = useContext(LanguageContext);
    useEffect(()=>{
        changeBackgroundColor("white")
      },[])
      const [data, setData] = useState()
      useEffect( () => {
        function fetchData() {
          getEquipe('national').then((results)=>{
            console?.log(results)
            results?.items?.[0]?.data?.equipe?.[language]?.map(e => {
                
                var img = e?.Image?.iv? e?.Image?.iv[0]: e?.Image?.[language]?e?.Image?.[language][0]:e?.Image?e?.Image[0]:''; //todo default image
                e.src=img;
                
                
});
setData(results?.items?.[0]?.data?.equipe?.[language]);    
setLoading(false)
 } )} 
        fetchData();
    }, []);
    //console.log(data)
    const [banner, setBanner] = useState();
useEffect( () => {
  if(data?.banner?.[language])
  getAsset(data?.banner?.[language]).then((srcImage)=>{
    setBanner(srcImage);
  });
  
}, []);
  return loading? <ShimmerSimpleGallery imageType="circular" imageHeight={200} caption /> :(
    <div className="position-relative" dir={language==='ar'?"rtl":""}>
        {/*<BgShape type="red"  right="-50px" top="100px"  rotate="-140deg"/>*/}
        {/*<BgShape type="blue"  left="-80px" top="800px" rotate="140deg"/>*/}
        {/*<BgShape type="yellow"  right="-50px" top="1200px" rotate="-150deg"/>*/}
        {/* <BgShape type="red"  left="-80px" top="2000px" rotate="140deg"/> */}
        <CoverImg img={banner??alger}/>
        <Breadcrumb/>
        <Container >
           
            {/* Notre equipe */}    
            <MainTitle >Notre équipe</MainTitle>
            <div className={`${styles.section} `}>
                {
                    data?.map((x,i)=>{
                        return <div key={i} className="text-center">
                                    <h4 className={styles.title}>{x.Titre}</h4>
                                    <TeamGrid membres={x.members}/>
                                </div>
                    })
                }
                </div>
                {/*
                <MemberCard member={DGenerale} role={"Directrice Générale"} rounded={true} />
                
                <div className='row mt-4 gap-3'>
                    {
                        equipeNational.map((el)=>{
                            if (el.id!==DGenerale.id)  return < MemberCard key ={el.id}  member={el} className="col-1" rounded={true} /> 
                        })
                    }
                </div>
            </div>
            
            <div className={`${styles.section} py-5 mb-0 bg-grey`}>
                    <div>
                        <h3 className ={` mb-5 text-center container fw-bold`} >Directeurs délégués</h3>
                        <Container className='row gap-3 mx-auto'>
                            {directeurDelegues.map((el)=>{
                                return (<MemberCard key={el.id}  member={el} className='col-lg-2 col-sm-4 col-6' rounded={false}/>)
                            })}
                        </Container> 
                    </div>
            </div>*/}
            </Container>
            <SocialSection institut={true} ville="national"/>
            
        

    </div>
  )
}

export default InstitutEquipePage