import {login} from "./auth"
import { baseUrl } from "./consts"
import { testsRoutes } from "./apiRoutes"

export async function getTest({region,id}) {
  //console.log(region)
  //console.log(id)
    const response=await login();
if (response.ok) {
    var data = await response.json();
    //console.log(data)
    const res = await fetch(`${baseUrl}${testsRoutes.filter(x=>x.region===region)[0].url}/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+data['access_token']
      },
      
    });

    const json = await res.json();
  
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    //console.log(json);
    return json;
}
}
export async function getTests({region='national'}) {
  //console.log(region)
  if(region !=='national')
    return await getTestsRegion(region)
    const response=await login();
if (response.ok) {
    var data = await response.json();
    //console.log(data)
    const res = await fetch("https://altadmin.if-algerie.com/api/content/ifa/testsexams", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+data['access_token']
      },
      
    });

    const json = await res.json();
  
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    //console.log(json);
    return json;
  }
}

export async function getTestsRegion(region,id) {
  //console.log(region)
  const response=await login();
if (response.ok) {
  var data = await response.json();
  //console.log(data)
  const res = await fetch(`${baseUrl}${testsRoutes.filter(x=>x.region===region)[0].url}${id?'/'+id:''}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"Bearer "+data['access_token']
    },
    
  });

  const json = await res.json();

  if (json.errors) {
    console.error(json.errors);
    throw new Error("Failed to fetch API");
  }
  //console.log(json);
  return json;
}
}



