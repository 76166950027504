import {login} from "./auth"
import { baseUrl } from "./consts"
import { equipeRoutes } from "./apiRoutes"


export async function getEquipe({region='national'}) {
  //console.log(region)
  if(region !=='national')
    return await getEquipeRegion(region)
    const response=await login();
if (response.ok) {
    var data = await response.json();
    //console.log(data)
    const res = await fetch("https://altadmin.if-algerie.com/api/content/ifa/equipe", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+data['access_token']
      },
      
    });

    const json = await res.json();
  
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    //console.log(json);
    return json;
  }
}

export async function getEquipeRegion(region) {
  //console.log(region)
  const response=await login();
if (response.ok) {
  var data = await response.json();
  //console.log(data)
  const res = await fetch(`${baseUrl}${equipeRoutes.filter(x=>x.region===region?.region)[0].url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"Bearer "+data['access_token']
    },
    
  });

  const json = await res.json();

  if (json.errors) {
    console.error(json.errors);
    throw new Error("Failed to fetch API");
  }
  //console.log(json);
  return json;
}
}



