import React,{useState,useEffect, useContext} from 'react'
import {Container , Row,Col} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'
import peh from "../../assets/img/peh-img.jpg"
import Bandeau from 'components/Etudes/Bandeau'
import EtudesCarousel from 'components/Etudes/EtudesCarousel'
import EventsGrid from 'components/General/EventsGrid'
import BgShape from 'components/General/BgShape'
import styles from "./EtudierEnFrance.module.css"
import { getEtude } from 'services/etudes'
import { getAsset } from 'services/assets'
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'
function PehPage() {
    const pehLink = "https://ee.mlfmonde.org/alger-peh/"
    const [data, setData] = useState()
    const [loading, setLoading] = React.useState(true);
    const { language } = useContext(LanguageContext);
    useEffect( () => {
      function fetchData() {
        getEtude('national').then((results)=>{
          //console.log(results?.items?.filter(x=>x.data?.slug.iv.toLowerCase()==='peh'))
          results?.items?.filter(x=>x.data?.slug.iv.toLowerCase()==='peh').map(e => {
              
              var img = e?.Image?.iv? e?.Image?.iv[0]: e?.Image?.[language]?e?.Image?.[language][0]:e?.Image?e?.Image[0]:''; //todo default image
              e.src=img;
              //console.log(img)
              
});
setData(results?.items?.filter(x=>x.data?.slug.iv.toLowerCase()==='peh')?.[0]?.data);   
setLoading(false) 
} )} 
      fetchData();
  }, []);
  const [srcImage,setSrcImage] = useState();
  React.useEffect( () => {

    function fetchData() {
              
            var img = data?.Image?.iv? data?.Image?.iv[0]: data?.Image?.[language]?data?.Image?.[language][0]:data?.Image?data?.Image[0]:''; //todo default image
            
      if(img)
  getAsset(img).then((srcImage)=>{
//console.log(srcImage)
    setSrcImage(srcImage);
    setLoading(false)
        })
      }
       fetchData() 
    },[data]);

  
//console.log(data)

try{var text = parse(data?.Description?.[language])}catch($){
  text = data?.Description?.[language]
}
  return loading? <ShimmerPostDetails card cta variant="EDITOR" /> :(
    <div className=" position-relative overflow-hidden" dir={language==='ar'?"rtl":""}>
      {/*<BgShape type="red"  right="-60px" top="700px" rotate="-140deg"/>*/}
      {/*<BgShape type="yellow"  right="-50px" bottom="100px" rotate="-150deg"/>*/}
      {/*<BgShape type="blue"  left="-80px" bottom="400px" rotate="140deg"/>*/}
    <EtudesCarousel type={"peh"}/>
      <Breadcrumb />
      <Container >
        <MainTitle>La petite école d'Hydra</MainTitle>

          <Row className="text-start my-5">
            <Col lg={6} className={`text-black-50 h-100 ${styles.liad}`}>
                <p>
                  <a href={pehLink} target="_blank" rel="noreferrer">
                    {data?.Titre?.[language]??"La petite école d'Hydra (PEH)"}
                  </a>
                  {text??
                  "Appartenant au réseau de la Mission laïque française et homologué par le Ministère de l’éducation nationale et de la jeunesse français, la Petite Ecole d’Hydra (PEH) a été ouverte à Alger en 2005 "
                  +"Elle accueille les élèves de la petite section maternelle au CM2. "
}  
                </p>
            </Col>
            <Col lg={6}>
              <img src={srcImage} className="w-100" alt="PEH" />
            </Col>
          </Row>
      </Container>
      <Bandeau className="mb-5" link={pehLink} style={{ margin: "5rem 0" }} >
        <p className='fs-5  my-0 text-center'>{data?.Citation?.[language]??'Retrouvez toutes les informations sur le site officiel de <span className="red-ifa fw-bold">PEH</span>'}</p>
      </Bandeau>
    {/*<Container>
        <MainTitle >Agenda</MainTitle>
        <EventsGrid type="PEH"/>
    </Container>*/}
    </div>
  );
}

export default PehPage