import React,{useContext, useState} from 'react'
import { Container} from "react-bootstrap";
import Bandeau from 'components/Etudes/Bandeau';
import Breadcrumb from 'components/breadcrumb/breadcrumb';
import MainTitle from 'components/General/MainTitle';
import EventsGrid from 'components/General/EventsGrid';
import BgShape from 'components/General/BgShape';
import { useParams } from 'react-router-dom';
import actions from 'data/culture/Action-culturelles';
import CoverImg from 'components/General/CoverImg';
import { fetchAPIActionsCulturelles, fetchAPIEspaces } from 'services/institut-national'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext';
function EspacePage({region}) {
    const {action_url, slug} = useParams()
    //const selectedAction = actions.filter(action=>action.url === action_url)[0]
    const [data, setData] = useState();
    const [banner, setBanner] = useState();
    const [loading, setLoading] = React.useState(true);
    const { language } = useContext(LanguageContext);
    React.useEffect(()=>{
        window.scrollTo(0, 0)
        fetchAPIEspaces({region:region}).then((x)=>{
          //console.log(x)
          //console.log(x.items[0].data['Espaces']?.[language])
          var z=x.items[0].data['Espaces']?.[language]?.filter(x=>x.Titre.trim()===slug.trim());
          //console.log(slug)
          //console.log(z)
          setData(z[0])
          setLoading(false)
        });
    },[])
    React.useEffect( () => {
      if(data?.Image?.[0])
      getAsset(data?.Image?.[0]).then((srcImage)=>{
        setBanner(srcImage);
      });
      
    }, [data?.Image]);
    try{var text = parse(data?.Description)}catch($){
      text = data?.Description
    }
  return loading? (<><ShimmerPostDetails card cta variant="SIMPLE" /><ShimmerPostList postStyle="STYLE_FOUR" col={3} row={1} gap={30} /></>) :(

    <div className=" position-relative overflow-hidden" dir={language==='ar'?"rtl":""}>
            {/*<BgShape type="yellow"  right="-50px" top="400px" rotate="-150deg"/>
            <BgShape type="blue"  left="-80px" top="900px" rotate="140deg"/>
            <BgShape type="red"  right="-60px" top="1300px" rotate="-140deg"/>*/}
            <CoverImg img={banner}/>
            <Breadcrumb/>
            <Container>
              <MainTitle className="my-4" dir={language==='ar'?"rtl":""}>{data?.Titre}</MainTitle>
              <p className='text-start lh-lg text-black-50  mb-5'>
              {text??`
              Lorem ipsum dolor sit amet. Aut sunt sint qui facere consequuntur non ipsa quia 
              rem omnis culpa quo quam voluptatum non facere iure. Quo aspernatur delectus ut placeat nostrum 
              cum quam quam ut consequatur iste ea ducimus esse qui minima tempore ea dolore quia.
              Est impedit aliquam ut odio itaque aut sequi reprehenderit eum commodi aliquid est labore alias qui eligendi rerum.`}
              </p>
            </Container>

              <Bandeau link={data?.lienCitation?.iv??'#'} style={{margin:"5rem 0"}}>
                  <p className='fs-5 my-0'>{data?.Citation ??"Lorem ipsum dolor sit amet. Aut sunt sint qui facere consequuntur non ipsa" }</p>
              </Bandeau>

            {<Container>
              
              <EventsGrid time="future" type="actions culturelles" region={region&&region}/>
  </Container>}
    </div>
    
  )
}

export default EspacePage