import React, { useState } from 'react'
import { Row,Col } from 'react-bootstrap'
import { Dropdown } from 'react-bootstrap'
import { Link,useLocation } from 'react-router-dom'
import { TiArrowSortedDown} from "react-icons/ti";
import { regions } from 'data/general/regions';
import styles from "../General/general-cmp.module.css"
const CitiesDropdown = ({region,url, updateCity}) => {
    const location =useLocation()
    const testLink = ()=>{
      const path = location.pathname
      const pathArray = path.split("/")
      pathArray.splice(-1,1,"reserver-un-test")
      return pathArray.join('/')
    }
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <button
          className='w-100 bg-red-ifa p-2 border-0 text-white d-flex justify-content-between'
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children}
          
        </button>
      ));
    // for initial render : the state take the value of the "ville" props passed from CampusPage
    const [city,setCity] = useState(region ==="national" ? "alger" : region)
    // after every click from the child cmp (SvgAlgeriaMap) =>the "city" state will be updated
    
  return (<>
        {(
          /* =============== National =============== */
          <Dropdown>
            <div>
              <Dropdown.Toggle
                id="dropdown-basic"
                className="w-100 text-start d-flex justify-content-between align-items-center border-0 p-2"
                as={CustomToggle} 
              >
                <span>Selectionner une ville</span>
                <TiArrowSortedDown className="mt-1" />
              </Dropdown.Toggle>
            </div>
            <Dropdown.Menu className="w-100 py-0">
              {regions.map((reg) => {
                return (
                  <Link to={`#`} onClick={()=>{
                    
                    setCity(reg.name.toLowerCase()) 
                    //console.log(reg.name.toLowerCase())
                    // update the parent state (for updating the data in the card after every click)
                    updateCity(reg.name.toLowerCase())}}>
                    <div
                      key={reg.name}
                      className={`text-black p-2 ${styles.select_item}`}
                    >
                      {reg.name}
                    </div>
                  </Link>); }
                  )}
                  </Dropdown.Menu>
                  </Dropdown>)}
                </>);
                }
                export default CitiesDropdown