import { baseUrl } from "./consts";

export async function login(){
    const response=await fetch(baseUrl+"identity-server/connect/token",{
      contentType: "application/x-www-form-urlencoded",
      body: new URLSearchParams({
          grant_type: "client_credentials",
          client_id: "ifa:default",
          client_secret: "iwruhktybm9nci4efz2okuwibldlottkxgztjgfgbnux",
          scope: "squidex-api",
      }),
      method: "POST",
  });
return response;}