import React,{useContext, useEffect, useState} from 'react'
import {Col,Card,Button,Badge} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext';
function EventCard({event}) {
  //const [loading, setLoading] = React.useState(true);
    // Change the color of the badge according to the region
    const backgrounds = (region)=>{
        switch (region) {
            case "alger":
                return "danger"
                break;
            case "constantine":
                return "warning"
                break;
            case "oran":
                return "success"
                break;
            case "annaba":
                return "black"
                break;
            case "telemcen":
                return "secondary"
                break;
    
        
            default:
                break;
        }
    }
    var {title,img,url,srcImage,src,} = event?.data
    const [image,setImage] = useState();
    const [loading, setLoading] = React.useState(true);
    const { language } = useContext(LanguageContext);
    useEffect( () => {
      if(!img && !srcImage)
      getAsset(event?.data.Image?.iv? event?.data.Image?.iv[0]: event?.data.Image?.[language]?event?.data.Image?.[language][0]:''??'').then((zz)=>{
        srcImage=zz;
        img=zz;
        setImage(zz);
        //console.log(zz)
        setLoading(false)
    });
  else{
    if(img && !srcImage)
    setImage(img)
  else
  setImage(srcImage)
  setLoading(false)
  }});
    //setLoading(false)
  return /*loading? <ShimmerSimpleGallery card imageHeight={300} caption /> :*/(
    <Link to={`/blog/${event?.id}/${event?.data?.slug?.iv}`}>
          <Card className="card mb-3">
            <span className="zoom-effect-container">
              <h4 style={{ top: "10px", position: "absolute" }}>
                <Badge
                  style={{
                    position: "absolute",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    textTransform:"uppercase"
                  }}
                  bg={backgrounds(event?.data?.Antenne?.iv[0])}
                >
                  {event?.data?.Antenne?.iv[0]}
                </Badge>
              </h4>
              <h5 style={{ bottom: "15px", position: "absolute" }}>
                <Badge  
                    style={{
                    position: "absolute",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    color:"black"
                  }}
                  bg="white">
                    {/*event.date*/}
                </Badge>
              </h5>
              <img src={image??""} className="card-img-top" />
            </span>
            <Card.Body>
              <Card.Title
                style={{ textAlign: "initial", color: "rgb(20, 20, 20)" }}
              >
                {event?.data?.title?.iv}
              </Card.Title>
              <Card.Text
                style={{
                  textAlign: "initial",
                  marginLeft: "-3px",
                  color: "rgb(119, 117, 119)",
                }}
              >
                 {event?.data?.description?.iv}
              </Card.Text>
            </Card.Body>
            <Card.Body
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Link to={`/blog/${event?.id}/${event?.data?.slug?.iv}`}>
                <Button
                  variant="link"
                  style={{
                    color: "black",
                    textDecorationColor: "#ffed00",
                    textDecorationThickness: "3px",
                  }}
                >
                  Lire la suite
                </Button>
              </Link>
              <Button variant="outline-danger" style={{ color: "black",transform:"capitalize" }}>
                {event?.data?.Type?.iv}
              </Button>
            </Card.Body>
          </Card>
          </Link>
  )
}

export default EventCard