import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Post } from './Post.js';
import { getPost } from '../../services/posts.js';
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext.js';
export const PostSite = () => {
    const { id } = useParams();
    const { language } = useContext(LanguageContext);
    const [post, setPost] = React.useState(undefined);

    React.useEffect(() => {
         function fetchData() {
            try {
                getPost(id).then((e)=>{
                   
                
                        var img = e?.data.Image?.iv? e?.data.Image?.iv[0]: e?.data.Image?.[language][0];
              getAsset(img).then((srcImage)=>{
                e.data.srcImage=srcImage;
                setPost(e);});
            });
            } catch (ex) {
                setPost(null);
            }
        }

        fetchData();
    }, [id]);
    if (post) {
        return <Post post={post} />
    } else if (post === null) {
        return <div>Post not found.</div>
    } else {
        return <div><ShimmerSimpleGallery card imageHeight={300} caption /></div>
    }
}