/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React, { useState,useRef,useEffect, useContext } from "react";
import FirstSlide from "./slides/FirstSlide";
import SimpleSlide from "./slides/SimpleSlide";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  Button,
  Carousel,
} from "react-bootstrap";
import SecondSlide from "./slides/SecondSlide";
import img from '../assets/img/slide.png'
import Mobileimg from '../assets/img/mslide.png'
import fSlide from '../assets/img/vague.png'
import MobilefSlide from '../assets/img/mvague.png'
import { fetchAPIHome } from 'services/home-page'
import { LanguageContext } from "services/LanguageContext";

const SlideComponent = () => {
  const [index, setIndex] = useState(0);
  const [slides, setSlides] = useState();
  const currentImageIndexRef = useRef();
  const [width, setWidth] = useState(window.innerWidth);
  const [loading, setLoading] = React.useState(true);
  const { language } = useContext(LanguageContext);
  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
useEffect(() => {
  // cache the current state value
  currentImageIndexRef.current = index;
}, [index]);

const increment = () => {
  setIndex((index + 1) % slides?.length)
}
const isMobile = width <= 768;
useEffect(() => {
  const id = setInterval(increment, 5000);
  return () => clearInterval(id);
}, [currentImageIndexRef.current]);
useEffect( () => {
  function fetchData() {
    fetchAPIHome().then((items)=>{
      //const res = await fetchAPIInstitutDetails(results.posts[0].id)
      var el=items.items[0]?.data;
      //console.log(el.Slides);
      setSlides(el.Slides[language]);
      setLoading(false)
      //setData(d)
      
      //setData(missions)
      
    });
  }
    
  fetchData();
}, []);
//slides?.map((x,i)=>//console.log(x?.Interval))
  return (
    <span dir={language==='ar'?"rtl":""}
      style={{
        position: "relative",
        width: "max-content",
        height: "max-content",
      }}
    >
      <Carousel touch={true} fade activeIndex={index} controls={false} >
        { slides?.map((x,i)=>
        (x.Type==='Simple')?
      <Carousel.Item key={i} interval={x?.Interval??5000}>
          <SimpleSlide next={() => setIndex((index+1)%slides?.length)} element={x} img={isMobile?x?.MediaMobile?.[0]:x?.Media?.[0]} />
        </Carousel.Item> :(
          (x.Type==='Vague')? <Carousel.Item interval={x?.Interval?? 5000}>
          <FirstSlide next={() => setIndex((index+1)%slides?.length)} element={x} img={isMobile?x?.MediaMobile?.[0]:x?.Media?.[0]}/>
        </Carousel.Item> : 
       (x.Type==='Video')?
       <Carousel.Item key={i} interval={x?.Interval??12000}>
          <SecondSlide next={() => setIndex((index+1)%slides?.length)} element={x} img={isMobile?x?.MediaMobile?.[0]:x?.Media?.[0]}/>
        </Carousel.Item> :<></> )
        )
}

        

        
      </Carousel>
    </span>
  );
};

export default SlideComponent;
