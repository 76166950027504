import React from 'react'
import {Container} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'
import CoverImg from 'components/General/CoverImg'
import culture from "../../assets/img/culture.jpg"
import { useParams } from 'react-router-dom';

const EventAecPage = () => {
  const {eventId} = useParams()
  const queryParameters = new URLSearchParams(window.location.search)
  const id = queryParameters.get("eventId")
  //console.log(eventId)
  //console.log(id)
  return (
    <>
    <CoverImg img={culture}/>
    <Breadcrumb/>
    <Container>
        {/* <MainTitle>Cart page</MainTitle> */}
        <div class="arc-en-ciel">
        <div data-module="events" data-action="event-view" data-show-loading="true" data-param-event-type={eventId??id}></div>
        </div>
    </Container>
    </>
  )
    
}

export default EventAecPage