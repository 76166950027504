import React from 'react'
import { Row,Col } from 'react-bootstrap'


function HorairesCard({horaires,ville}) {

  const horairesList=(horaires.ouvert).map((el, index) => {
    return (
      <div key={index}>
        {/* <h5 className="text-start my-2 fw-bold fs-6">{el.periode}</h5> */}
        <h5 className="text-start my-2 fw-bold fs-6">{el.periode}</h5>
        {el.days.map((el, idx) => {
          return (
            <Row className=" mb-1" key={idx} style={{fontSize:"14px"}}>
              <Col xs className="text-start">
                {el.day}
              </Col>
              <Col xs className="text-end">
                {el.time}
              </Col>
            </Row>
          );
        })}
      </div>
    );
  })

  return (
    <div className=" mb-3 p-4 bg-light" >
                <h5 className="fw-bold mb-3">Horaires d’ouverture </h5>
                {horaires.fermé && <p className="text-black-50 text-start px-3 fs-6">{horaires.fermé}</p>}
                <div className='pb-1' style={ville==="Alger"?{maxHeight:"238px",overflowY:"scroll"}:{}}>
                  {horairesList}
                </div>
    </div>
  )
}

export default HorairesCard