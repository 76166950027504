import React from 'react'
import MainHeader from 'pages/header/MainHeader'
import MainHeader2 from 'pages/header/MainHeader2'
import Footer from 'pages/footer/Footer'
import { Outlet } from 'react-router-dom'
import { useGlobal } from 'contexts/GlobalContext'

function Layout(props) {
  const {bg} = useGlobal()
  const grey = bg==="grey"?"bg-grey":""
  // const grey = bg==="grey"?"bg-danger":""
  return (
    <div className='app'>
    {/* <MainHeader home ={props.home}/> */}
    <MainHeader2 home ={props.home}/>
    {/* <div className={`${grey} main `}> */}
    <div className={`${grey} main overflow-hidden`} style={{zIndex:"1"}}>
      <Outlet/>
    </div>
    <Footer/>
    </div>
  )
}

export default Layout