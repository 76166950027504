import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Button,
  Row,
  Col,
} from "react-bootstrap";
import "./slide.css"
import { getAsset } from "services/assets";
import { Link } from "react-router-dom/umd/react-router-dom.development";

export default function SimpleSlide(prop) {
  var {img,element} = prop;
  const [srcImage,setSrcImage] = useState();
  React.useEffect( () => {

    function fetchData() {
      if(img?.length>0)
  getAsset(img).then((srcImage)=>{
//console.log(srcImage)
    setSrcImage(srcImage);
    
        })
      }
       fetchData() 
    },[img,element]);
    //console.log(img)
  return (
    <div
      className="simple-slide"
      style={{ width: "100%", height: "732px", position: "relative",backgroundImage: `url(${srcImage??img?.[0]})` }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "732px",
          //backgroundColor: "#3558a28c",
        }}
      ></div>
      <span xs={3}>
      </span>
      <Row
        style={{
          alignContent: "center",
          height: "100%",
          paddingLeft: "36px",
          position: "relative",
        }}
      >
        <Col md={6} xs>
          <Row style={{ alignContent: "center", height: "100%" }}>
            <Col 
              md={2} xs={2}
              className="d-none d-sm-none d-md-none d-lg-flex d-xl-flex"
              style={{
                justifyContent: "center",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {" "}
              <Button
                onClick={prop.next}
                style={{                  
                  height: "60px",
                  width: "60px",
                  marginBottom: "16px",
                  fontSize: "22px",
                }}
                variant="outline-light"
              >
                {"<"}
              </Button>{" "}
              <Button
                onClick={prop.next}
                style={{
                  height: "60px",
                  width: "60px",
                  marginBottom: "16px",
                  fontSize: "22px",
                }}
                variant="outline-light"
              >
                {">"}
              </Button>
            </Col>
            <a href={element?.lienboutton??"#"}>
            <Col md={10} xs={10} style={{ textAlign: "initial" }}>
              <h1
                style={{
                  //fontSize: "2.5rem",
                  color: "white",
                  textAlign: "initial",
                }}
              >
                {element?.Title ??'Tests et examens'}
              </h1>
              <h1
                style={{
                  //fontSize: "2.5rem",
                  color: "white",
                  textAlign: "initial",
                }}
              >
              </h1>
              <p
                style={{
                  //fontSize: "1.2rem",
                  color: "white",
                  textAlign: "initial",
                  marginBottom: 0,
                }}
              >
                {element?.Description ??'Les sessions TCF sont ouvertes'}
              </p>
              <p
                style={{
                  //fontSize: "1.2rem",
                  color: "white",
                  textAlign: "initial",
                }}
              >
              </p>{" "}
              <br></br>
              {element?.avecBoutton?
              <Button
                style={{
                  borderRadius: "0",
                  padding: "8px 16px",
                  //fontSize: "1.6rem",
                }}
                variant="outline-light"
              >
                <a href={element?.lienboutton??"#"}>
                {element?.textboutton ??"En savoir +"}
                </a>
              </Button>:<></>}
            </Col>
            </a>
          </Row>
        </Col>
      </Row>
    </div>
  );
}


