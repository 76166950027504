import React, { useContext, useEffect, useState } from 'react'
import { Container , Row,Col,Button,Badge,ListGroup,Table} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import { Link,useLocation } from 'react-router-dom'
import BgShape from 'components/General/BgShape'
import MainTitle from 'components/General/MainTitle'
import { getTests } from 'services/tests-exams'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'

import cover from "../../assets/img/cover_exams.jpg";
import CityMenuCard from 'components/Cities/CityMenuCard';

import { FaCheckCircle } from "react-icons/fa";
import CoverImg from 'components/General/CoverImg';
import styles from "./Examens.module.css";
import { useNavigate} from "react-router-dom";
import {TCF_tests as tests}  from "../../data/tests et examens/Tests-TCF"
import TestCard from 'components/TestEtExamens/TestCard';
import EventsGrid from 'components/General/EventsGrid'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList,ShimmerSectionHeader,ShimmerFeaturedGallery } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'
// const tests=[
//     {
//       name:"TCF DAP",
//       intro:"Le TCF dans le cadre de la demande d'admission préalable (DAP) est destiné à toute personne étrangère de plus de 16 ans souhaitant intégrer un licence 1 ou une école d'architecture au sein d'un établissement d'enseignement supérieur en France. Il évalue le niveau de langue (de A1 à C1)."
//     },
//     {
//       name:"TCF TP tout public",
//       intro:"L'objectif principal du TCF tout public est de permettre aux candidats de faire recon- naitre leur niveau de français dans le cadre de poursuite d'études en France et dans les pays francophones"
//     },{
//       name:"TCF Canada",
//       intro:"L'objectif principal du TCF Canada est de permettre aux candidats de faire reconnaitre leur niveau de français dans le cadre d'une demande d'immigration ou de citoyenneté au Canada"
//     }
//   ]
  
function TCFpage({region}) {
    const navigate = useNavigate()
    const handleCardClick = (testName)=>{
        navigate(testName)
    }
    const [data, setData] = useState()
    const [loading, setLoading] = React.useState(true);
    const { language } = useContext(LanguageContext);
    useEffect( () => {
        function fetchData() {
          getTests({region:region}).then((results)=>{
            results?.items?.[0]?.data?.tests?.[language]?.filter(x=>x.title==='TCF').map(e => {
                
                var img = e?.Image?.iv? e?.Image?.iv[0]: e?.Image?.[language]?e?.Image?.[language][0]:e?.Image?e?.Image[0]:''; //todo default image
      getAsset(img).then((srcImage)=>{
        e.srcImage=srcImage;
        setData(e);
        setLoading(false)
    });
});
            
 } )} 
        fetchData();
    }, []);
    //console.log(data)
    const [banner, setBanner] = useState();
useEffect( () => {
  if(data?.banner?.[language])
  getAsset(data?.banner?.[language]).then((srcImage)=>{
    setBanner(srcImage);
  });
  
}, []);
    try{var text = parse(data?.description)}catch($){
        text = data?.description
      }
  return loading? (<><ShimmerPostDetails card cta variant="SIMPLE" /><ShimmerPostList postStyle="STYLE_FOUR" col={3} row={1} gap={30} /></>) :(
    <div className=" position-relative overflow-hidden bg-grey z-index-1 h-100" style={{zIndex:"1"}} dir={language==='ar'?"rtl":""}>
    {/*<BgShape type="red"  right="-40px" top="100px" rotate="-140deg"/>*/}
    {/*<BgShape type="yellow"  right="-50px" top="800px" rotate="-150deg"/>*/}
    {/*<BgShape type="blue"  left="-80px" top="500px" rotate="140deg"/>*/}
        <CoverImg img={banner??data?.srcImage??cover}  />
            <Breadcrumb />
            <Container >
            <section>
            <div className="mt-3 text-start ">
                <MainTitle>TCF</MainTitle>

                {text??<p className="lh-lg text-black-50">
                le Test de connaissance du français (TCF), valable 2 ans, est
                demandé dans tout dossier d'inscription universitaire en France et
                dans tout dossier de demande d'immigration au Canada. Les
                avantages du TCF sont :
                <Row className="mt-4">
                    <Col md={6} className={styles.col} >
                        <div>
                            <FaCheckCircle className={styles.check}/>
                        </div>
                    <span>Délai d'inscription réduit</span>
                    </Col>
                    <Col md={6} className={styles.col}>
                        <div>
                            <FaCheckCircle className={styles.check}/>
                        </div>
                        <span>Délai de délivrance des résultats accéléré</span>
                    </Col>
                </Row>
                <Row className="my-3 ">
                    <Col md={6} className={styles.col}>
                        <div>
                            <FaCheckCircle className={styles.check}/>
                        </div>
                        <span className="">
                        Moins de manipulation de documents (livret et feuille de
                        réponses) puisque questions et réponses apparaissent en même
                        temps sur l'écran
                    </span>
                    </Col>
                    <Col md={6} className={styles.col}>
                        <div>
                            <FaCheckCircle className={styles.check}/>
                        </div>
                        <span>
                        Compréhension orale passée avec un casque audio permettant
                        une meilleure concentration pour l'écoute des items
                    </span>
                    </Col>
                </Row>
                </p>}
            </div>
            <Row className="py-5 ">
                {data?.subtests?.length>0?data?.subtests?.map((test,i)=>{
                    //console.log(test.title)
                    return (
                    <Col onClick={()=>handleCardClick(test.title)} key={i} lg={4} md={6} className="mb-4 mb-lg-0 cursor-pointer">
                            <TestCard test = {test}/>
                    </Col>)
                }):
                tests.map((test)=>{
                    return (
                    <Col onClick={()=>handleCardClick(test.name)} key={test.id} lg={4} md={6} className="mb-4 mb-lg-0 cursor-pointer">
                            <TestCard test = {test}/>
                    </Col>)
                })}
            </Row>
            </section>
            </Container>
            {<Container>
              
              <EventsGrid title="Informations" type="TCF" region={region&&region}/>
  </Container>}
    </div>

  )
}

export default TCFpage