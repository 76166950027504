import Breadcrumb from 'components/breadcrumb/breadcrumb';
import BgShape from 'components/General/BgShape';
import CoverImg from 'components/General/CoverImg';
import MainTitle from 'components/General/MainTitle';
import { Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import cover from '../../assets/img/cours-de-français.jpg';
import { useState } from 'react';


function ReservationPageTest({region}) {
  const antenne={alger:"1", annaba:   "2",constantine:"3",
  oran:"4",tlemcen:"5"};
    const location = useLocation()
    //console.log(location.state.aecid.iv)
  return (
    <div
      className="position-relative overflow-hidden pb-1 bg-grey z-10"
    >
      {/*<BgShape type="red"  right="-50px" top="800px" rotate="-150deg"/>*/}
      {/*<BgShape type="yellow"  left="-80px" top="500px" rotate="140deg"/>*/}
      {/*<BgShape type="blue"  left="-80px" top="2000px" rotate="140deg"/>*/}
      <CoverImg img={cover} />
      <Breadcrumb/>
      <Container style={{ zIndex: "2" }}>
        <Row className='my-3'>
            <MainTitle>Réservation des tests de niveau</MainTitle>
            <h2>{location.state.title}{/*: {location.state.course}*/}</h2>
        </Row>
        <div className="arc-en-ciel container">
        <div data-module="events" data-action="events-list-grid" data-param-quantity-column="4" data-param-event-type={location.state.aecid?.iv?.toString()} data-events-to-show="2" data-param-detail_link="/aec-event/"  data-show-loading="true" data-param-etablishment-branch-id={region==="national"?"":antenne[region]}></div>

            {/*<div data-module="classes" data-action="courses-catalogue"  data-param-levels-to-filter-by="" data-param-etablishment-branch-id={ville==="national"?"":antenne[ville]}  data-show-loading="true"></div>  */}
            {/* <div data-module="classes" data-action="courses-catalogue" data-param-sidebar="true"></div> */}
            {/* <div data-module="classes" data-action="view-classes-with-filters" data-param-class-type-id="0" ></div> */}
            {/* <div data-module="classes" data-action="courses-catalogue" data-show-loading="true" data-param-etablishment-branch-id="0" data-param-classification-id="3" data-param-class-type-id="14|18|53" data-param-level-id="0" data-param-main-level-id="0" data-param-subject-id="1" data-param-period-id="0" data-param-id-student="" data-param-class-location="" data-param-class-format-id="0" id="kiosque-app-21670515823879" className="" data-ng-kiosque-app="true" data-module-initialized="true"></div>    */}
        </div>
      </Container>
    </div>
  );
}

export default ReservationPageTest