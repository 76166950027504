import { createContext, useReducer,useContext } from "react";
import { initialState,globalReducer } from "reducers/globalReducer";

// create context
const GlobalContext = createContext(initialState)

// create provider
export const GlobalProvider = ({children})=>{
    const [state,dispatch] = useReducer(globalReducer,initialState)
    
    const changeBackgroundColor = (newColor)=>{
        dispatch({
            type:"CHANGE_BG",
            payload: {
                color:newColor
            }
        })
    }
    // create value
    const value ={
        bg:state.bg,
        changeBackgroundColor
    }
    return (
        <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
    )
}

// create a context hook
const useGlobal = ()=>{
    const context = useContext(GlobalContext)
    if (context === undefined) {
        throw new Error("useGlobal must be used within GlobalContext")
    }
    return context
}
export {useGlobal}