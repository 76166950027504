import React, { useState } from 'react'
import { Row,Col } from 'react-bootstrap'
import CampusCard from './CampusCard'
// import SvgAlgeriaMap from './SvgAlgeriaMap'
import SvgMap58 from "./SvgMap58"
import CitiesDropdown from 'components/Cities/CitiesDropdown'

const data = {
  Alger: `Alger`,
  Annaba: "Annaba",
  Constantine: "Contantine",
  Oran: "Oran",
  Tlemcen: "Tlemcen",
};
const InteractiveMapGrid = ({ville}) => {
    // for initial render : the state take the value of the "ville" props passed from CampusPage
    const [city,setCity] = useState(ville ==="national" ? "alger" : ville)
    // after every click from the child cmp (SvgAlgeriaMap) =>the "city" state will be updated
    const updateCity = (cityName)=>{
        setCity(cityName)
    }
  return (
    <Row className="align-items-center justify-content-evenly my-4 gap-5">
        <Col xs={10} md={8} lg={5} > 
        <div className="p-4 mb-3 bg-light rounded ">
        <h5 className="text-start blue-ifa fw-bold mb-3">Villes</h5>
                <CitiesDropdown region={city} updateCity = {updateCity}></CitiesDropdown>
                </div>
            <CampusCard city={city}/>
        </Col>
        <Col  lg={{ order: "first",span:5 }}>
        <SvgMap58
        color="rgb(7, 167, 241)"
        HoverColor="blue"
        stroke="#fff"
        hoverStroke="#218c74"
        height="400px"
        width="400px"
        data={data}
        updateCity = {updateCity}
        //onWilayaClick={(wilaya, data) => console.log(wilaya, data)}
      />
            {/* <SvgAlgeriaMap updateCity = {updateCity} ville ={city}/> */}
        </Col>
    </Row>
    
  )
}


export default InteractiveMapGrid


