import CourseFeature from "./CourseFeature"

const CoursePriceCard = ({Prix,Descriptions,Titre}) => {

  return (
    <div className="card mb-4 rounded-3 shadow-sm f-100" >
    
    <div className='p-2'>
        <div className="py-3">
            <p className="card-title pricing-card-title text-center " >{Titre} </p>
        </div>
        
        <div>
            <ul className='text-black-50'>
                 {Descriptions?.map((feature,index)=><CourseFeature key={index} Text={feature.Text}/>)}
            </ul>
        </div>
    </div>
    <div className="card-footer py-3 text-center">
        <h4 className="my-0 fw-bold blue-ifa" >{Prix} DA</h4>
    </div>
</div>
  )
}

export default CoursePriceCard