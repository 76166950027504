import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  Button,
  Carousel,
  Row,
  Col,
} from "react-bootstrap";
import "./slide.css"
import { getAsset } from "services/assets";
export default function SecondSlide(prop) {
  var {element,img} = prop;
  const [srcImage,setSrcImage] = useState();
  React.useEffect( () => {

    function fetchData() {
//console.log(element)
  getAsset(img).then((srcImage)=>{
//console.log(srcImage)
    setSrcImage(srcImage);
    
        })
      }
       fetchData() 
    },[img,element]);
  //console.log(img)
  //console.log(srcImage)
  return (
    
    <div className="video-slide">
      <Row
        style={{
          //alignContent: "center",
          height: "100%",
          //paddingLeft: "36px",
          position: "relative",
        }}
      >
        {srcImage?
        <video className="assets/img-fluid" autoPlay loop muted style={{ position: "absolute", width: "100%" }}>
        <source src={srcImage /*??"https://mdbcdn.b-cdn.net/img/video/Tropical.mp4"*/} type="video/mp4" />
      </video>:<></>}
        
        <Col md={6} style={{ position: "relative" }}>
          <Row style={{ alignContent: "center", height: "100%" }}>
            <Col
            className="d-none d-sm-none d-md-none d-lg-flex d-xl-flex"
              md={2}
              style={{
                justifyContent: "center",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                paddingLeft: "36px",
              }}
            >
              {" "}
              <Button
                onClick={prop.next}
                style={{
                  height: "60px",
                  width: "60px",
                  marginBottom: "16px",
                  fontSize: "22px",
                }}
                variant="outline-light"
              >
                {"<"}
              </Button>{" "}
              <Button
                onClick={prop.next}
                style={{
                  height: "60px",
                  width: "60px",
                  marginBottom: "16px",
                  fontSize: "22px",
                }}
                variant="outline-light"
              >
                {">"}
              </Button>
            </Col>
            <Col md={10} style={{ textAlign: "initial" }}></Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
