import React from 'react'
import { Container, Row, Col} from "react-bootstrap";
import MemberCard from './MemberCard';

const TeamGrid = ({membres}) => {
    const membersList = membres?.map(membre=>{
        return (
            <Col md={3} sm={6} xs={10} key={membre.email} className="mb-3">
                <MemberCard membre={membre} img={membre?.Photo?.[0]}/>
            </Col>
        )}
        )
    
  return (
    <Row className="my-4 align-items-end justify-content-center justify-content-sm-start">
        {membersList}
    </Row>
  )
}

export default TeamGrid