import React from 'react'
import CityMenuCard from 'components/Cities/CityMenuCard'
import { Row,Col} from 'react-bootstrap'

const EtudesCardsGrid = ({etudes}) => {

    const displayEtudesCards = ()=>{
        // const etudesRegion = etudes.find((el)=>el.region===region)
        return (
          etudes.map((el, index) => {
            return (
              <Col key={index} lg={4} sm={6} xs={9} className="mb-4 mx-auto mx-sm-0">
                <CityMenuCard menu={el} />
              </Col>
            );
          })
        )
      }

  return <Row className="py-1">{displayEtudesCards()}</Row>;
}

export default EtudesCardsGrid