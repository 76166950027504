import React, {useState} from 'react'
import {Form,Button,Row,Col} from 'react-bootstrap';
import { MdClose } from "react-icons/md";
import { BsEnvelopeOpen } from "react-icons/bs";


function NewsLetterForm({open,onClose}) {

const [email,setEmail] =useState("")
const [emailError,setEmailError]=useState("")

  const handleChange=(e)=>{
    setEmail(e.target.value)
  }

  
  // email validation
  const validateEmail = (email)=>{
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if(!email){
      setEmailError('Ce champ est obligatoire')
    }else if(!emailRegex.test(email)){
      setEmailError("L' adresse email n'est pas valide")
    }
  }

  // Submit
  const handleSubmit =(e)=>{
    e.preventDefault()
    validateEmail(email)
  }



  return (
    // <Form
    //   className=" mx-auto text-center px-3 py-4 bg-white  relative"
    //   onSubmit={handleSubmit}
    // >
    //   <span
    //     className="bg-transparent text-secondary border-0 px-2 py-1"
    //     role="button"
    //     style={{ position: "absolute", right: "2px", top: "2px" }}
    //     onClick={onClose}
    //   >
    //     <MdClose />
    //   </span>
    //   <div className='p-2 d-flex justify-content-center align-items-center'>
    //     <BsEnvelopeOpen className='blue-ifa fs-1'/>
    //   </div>
    //   <h4
    //     className=" my-2 fw-bold"
    //     style={{ color: "rgb(53, 88, 162)" }}
    //   >
    //     S'abonner à la newsletter
    //   </h4>

    //   {/* <Form.Group className="mt-4 mb-3" controlId="formBasicEmail">
    //     <Form.Label>Adresse email</Form.Label>
    //     <Form.Control
    //       type="email"
    //       value={email}
    //       placeholder="Entrez votre adresse email"
    //       onChange={handleChange}
    //     />
    //     {emailError && <small className="my-1 text-danger">{emailError}</small>}
    //   </Form.Group>
    //   <Button
    //     style={{ backgroundColor: "rgb(53, 88, 162)" }}
    //     type="submit"
    //     className="ms-auto  d-flex align-items-end rounded-0"
    //   >
    //     Confirmez l'inscription
    //   </Button> */}
      
    //   <Row className="mt-4 gap-3 gap-md-0">
    //     <Col md={8} className=" pe-0">
    //       <Form.Group className="w-100 " controlId="formBasicEmail">
    //         <Form.Control
    //           type="email"
    //           value={email}
    //           placeholder="Entrez votre adresse email"
    //           onChange={handleChange}
    //           className="w-100 rounded-0 "
    //         />
    //         {emailError && (
    //           <small className="my-1 text-danger text-start d-block">{emailError}</small>
    //         )}
    //       </Form.Group>
    //     </Col>
    //     <Col md={4}>
    //       <Button
    //         style={{ backgroundColor: "rgb(53, 88, 162)" }}
    //         type="submit"
    //         className="w-100  rounded-0"
    //       >
    //         S'abonner
    //       </Button>
    //     </Col>
    //   </Row>
    // </Form>
    <div className=" mx-auto px-3 py-4 bg-white relative">
      <div className="arc-en-ciel container">
        <div data-module="newsletter" data-action="subscribe-form" data-show-loading="false"></div>
      </div>   
    </div>
  );
}

export default NewsLetterForm

