import React, { useContext, useEffect, useState } from 'react'
import CoverImg from 'components/General/CoverImg'
import annaba from '../../assets/img/annaba1.jpg'
import { Container} from 'react-bootstrap'
import MainTitle from 'components/General/MainTitle'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import HistoiryCard from 'components/Institut/HistoiryCard'
import SocialSection from 'components/General/SocialSection'
import ifa from "../../assets/img/ComponentTMP_3-image3.webp"
import BgShape from 'components/General/BgShape'
import presentations from "../../data/institut/presentations"
import { useGlobal } from 'contexts/GlobalContext'
import { fetchAPIInstitut, fetchAPIInstitutDetails } from 'services/institut-national'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList,ShimmerSectionHeader,ShimmerFeaturedGallery } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'
function InstitutPresentationPage({ville}) {
  const l = localStorage.getItem('lang')
  const {changeBackgroundColor} = useGlobal()
  const [institut, setInstitut] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const { language } = useContext(LanguageContext);
    useEffect( () => {
      //console.log(language)
        function fetchData() {
            fetchAPIInstitut().then((items)=>{
            //const res = await fetchAPIInstitutDetails(results.posts[0].id)
            var el=items.items[0]?.data;
            //console.log(el);
            getAsset(el?.Image.iv[0]).then((url)=>{
              el.srcImage=url;
              el.id=items.items[0]?.id;
              setInstitut(el);
              setLoading(false)
          });
            
          });
        }
          
    setPresentation(nationalPresentation.text)
    changeBackgroundColor("white")
        fetchData();
    }, []);
  // get the presentation data & update the state
  const nationalPresentation = presentations.filter(el=>el.region === ville)[0]
  const [presentation,setPresentation] = useState()
  useEffect(()=>{
  },[])
  const [banner, setBanner] = useState();
useEffect( () => {
  //console.log('loading banner')
  //console.log(institut)
  if(institut?.banner?.[language])
  getAsset(institut?.banner?.[language]).then((srcImage)=>{
    //console.log('loaded banner')
    setBanner(srcImage);
  });
  
}, [institut?.banner?.[language]]);
  try{var text = parse(institut?.Description[language??'fr'])}catch($){
    text = institut?.Description[language??'fr']
  }
  return loading? <ShimmerPostDetails card cta variant="EDITOR" />  :(
    <div className=" position-relative " dir={language==='ar'?"rtl":""}>
      {/*<BgShape type="red"  right="-70px" top="160px" rotate="-140deg"/>*/}
      {/*<BgShape type="yellow"  left="-50px" bottom="200px" rotate="150deg"/>*/}
    
      <CoverImg img={banner??annaba}/>
      <Breadcrumb/>
      <Container >
          <MainTitle >{institut?.Title[language??'fr']??"L'Institut français d'Algérie"}</MainTitle>
          <HistoiryCard className="mb-3" img={institut?.srcImage??ifa}>
            {
              text??
              presentation?.map(el=><p key={el.id}>{el.paragraph}</p>)
            }   
          </HistoiryCard>
          {/* <MainTitle>L'institut En Images</MainTitle>
          <InstitutCarousel className="mb-5 "/> */}
      </Container>
      <SocialSection institut={true} ville={ville}/>
    </div>
  )
}

export default InstitutPresentationPage