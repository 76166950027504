import React, { useContext } from 'react'
import { Container} from "react-bootstrap";
import Bandeau from 'components/Etudes/Bandeau';
import Breadcrumb from 'components/breadcrumb/breadcrumb';
import MainTitle from 'components/General/MainTitle';
import BgShape from 'components/General/BgShape';
import { useParams } from 'react-router-dom';
import samples from 'data/SamplePages';
import CoverImg from 'components/General/CoverImg';
import { fetchAPIBourses } from 'services/institut-national'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import EventsGrid from 'components/General/EventsGrid';
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext';
function SamplePage2({region, title=null}) {
    const {action_url,title_url} = useParams()
    const selectedAction = samples[0]
    const [institut, setInstitut] = React.useState();
    const [loading, setLoading] = React.useState(true);
    const { language } = useContext(LanguageContext);
    React.useEffect(()=>{
        window.scrollTo(0, 0)
    },[])
    React.useEffect( () => {
      function fetchData() {
        fetchAPIBourses().then((items)=>{
          //const res = await fetchAPIInstitutDetails(results.posts[0].id)
          //console.log(items)
          var el=items.items[0]?.data;
          //console.log(el);
          var img = el?.Image?.iv? el?.Image?.iv[0]: el?.Image?.[language]?el?.Image?.[language][0]:el?.Image??'';
          getAsset(img).then((url)=>{
            el.srcImage=url;
            el.id=items.items[0]?.id;
            setInstitut(el);
            setLoading(false)
        });
          
        });
      }
        
      fetchData();
  }, []);
  try{var text = parse(institut?.Description?.iv??institut?.Description[language??'fr'])}catch($){
    text = institut?.Description?institut?.Description[language??'fr']:""??""
  }
  try{var citation = parse(institut?.Citation?.iv??institut?.Citation[language??'fr'])}catch($){
    citation = institut?.Citation?institut?.Citation[language??'fr']:""??''
  }
  return loading? (<><ShimmerPostDetails card cta variant="SIMPLE" /><ShimmerPostList postStyle="STYLE_FOUR" col={3} row={1} gap={30} /></>) :(

    <div className=" position-relative overflow-hidden" dir={language==='ar'?"rtl":""}>
            {/*<BgShape type="yellow"  right="-50px" top="400px" rotate="-150deg"/>
            <BgShape type="blue"  left="-80px" top="900px" rotate="140deg"/>
            <BgShape type="red"  right="-60px" top="1300px" rotate="-140deg"/>*/}
            <CoverImg img={institut?.srcImage??selectedAction.img}/>
            <Breadcrumb/>
            <Container >
              <MainTitle className="my-4">{title_url??title??selectedAction.title}</MainTitle>
              {text??<p className='text-start lh-lg text-black-50  mb-5'>
              Lorem ipsum dolor sit amet. Aut sunt sint qui facere consequuntur non ipsa quia 
              rem omnis culpa quo quam voluptatum non facere iure. Quo aspernatur delectus ut placeat nostrum 
              cum quam quam ut consequatur iste ea ducimus esse qui minima tempore ea dolore quia.
              Est impedit aliquam ut odio itaque aut sequi reprehenderit eum commodi aliquid est labore alias qui eligendi rerum.
              </p>}
            </Container>

              <Bandeau link={institut?.lienCitation?.iv??'#'} style={{margin:"5rem 0"}}>
                  {citation??<p className='fs-5 my-0'>Lorem ipsum dolor sit amet. Aut sunt sint qui facere consequuntur non ipsa </p>}
              </Bandeau>

              {<Container>
              
              <EventsGrid title="En ce moment :" type="Appels à candidatures" region={region&&region}/>
  </Container>}
    </div>
    
  )
}

export default SamplePage2