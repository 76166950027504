import React,{useContext, useEffect, useState} from 'react'
import { Card} from "react-bootstrap";
import { Link } from "react-router-dom";
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext';

function CityMenuCard({menu,height}) {
    var {title,img,url,srcImage,src,} = menu
    const [image,setImage] = useState();
    const [loading, setLoading] = React.useState(true);
    const { language } = useContext(LanguageContext);
    const BgImg=(xx)=>{
      ////console.log(xx)
        return {
          height: height?height+"px":"200px",
          backgroundImage:`url(${xx})`,
          backgroundSize:"cover",
          backgroundPosition:"Center",
          backgroundRepeat:"no-repeat"
          }
      }
      useEffect( () => {
      console.log(url);
      ////console.log(src);
      if(!img && !srcImage)
      getAsset(src).then((zz)=>{
        srcImage=zz;
        img=zz;
        setImage(zz);
        ////console.log(zz)
        setLoading(false)
    });
  else{
    if(img && !srcImage)
    setImage(img)
  else
  setImage(srcImage)
  setLoading(false)
  }});
  try{var text = parse(menu?.['short-description'])}catch($){
    text = menu?.['short-description']
  }
  return loading? <ShimmerSimpleGallery card imageHeight={300} caption /> :(
        <Link to={url}>
          <Card className="card ">
            <span  style={BgImg(image)}>
            </span>
            <Card.Title
              className="mb-0"
              style={{ color: "rgba(5, 131, 189, 0.932)" }}
            >
              <h6 className="mb-0 p-3 fw-bold">{title}</h6>
              
            </Card.Title>
           
          </Card>
        </Link>
  )
}

export default CityMenuCard