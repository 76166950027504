import React from 'react'

const CourseDescription = ({description}) => {
  return (
    <div>
        <p className='p-1 lh-lg text-start text-black-50 fs-16'>{description}</p>
    </div>
    )
}

export default CourseDescription