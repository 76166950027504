import React from 'react'
import {Container} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import CoverImg from 'components/General/CoverImg'
import culture from "../../assets/img/culture.jpg"

const ReminderUpdatePasswordPage = () => {
  return (
    <>
    <CoverImg img={culture}/>
    <Breadcrumb/>
    <Container>
        <div className="arc-en-ciel">
          <div data-module="student" data-action="password-reset" className='password-reset-form mb-5'></div>        
        </div>
    </Container>
    </>
  )
    
}

export default ReminderUpdatePasswordPage