
import { Container } from "../../components/misc/Layouts";
import { Link } from "react-router-dom";
import {Row,Col} from "react-bootstrap";

import Breadcrumb from "components/breadcrumb/breadcrumbtest";
import img1 from "../../assets/img/media.png";
import BgShape from 'components/General/BgShape'
import MainTitle from "components/General/MainTitle.js";
import SocialSection from "components/General/SocialSection.js";
import CoverImg from "components/General/CoverImg.js";
import MediathequeCityCard from "components/Mediatheque/MediathequeCityCard.js";
import { villes } from "data/mediatheque/villes";
import DecalogCard from "components/Mediatheque/DecalogCard";
import CulturetecCard from "components/Mediatheque/CulturetecCard";

import React, { useContext, useEffect, useState } from 'react'
import { getMediathequeRegion } from "services/mediatheque";
import parse from 'html-react-parser'

import cover from "../../assets/img/cover_exams.jpg";
import CityMenuCard from 'components/Cities/CityMenuCard';
import { getAsset } from "services/assets";
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList,ShimmerSectionHeader,ShimmerFeaturedGallery } from 'react-shimmer-effects';
import { LanguageContext } from "services/LanguageContext";
export default () => {
  const [presentation,setPresentation] = useState()
  const [data,setData] = useState()
  const [loading, setLoading] = React.useState(true);
  const { language } = useContext(LanguageContext);
  useEffect( () => {
    function fetchData() {
      
      getMediathequeRegion('national').then((results)=>{
            results?.items?.map(e => {
              //console.log(e)

            
    setPresentation(e.data.Description);
    setData(e.data);});
    setLoading(false)
  
});
        
    }
    fetchData();
}, []);
  try{var text = parse(presentation?.[language])}catch($){
    text = presentation?.[language??'fr']
  }
  const [banner, setBanner] = useState();
  useEffect( () => {
    if(data?.banner?.[language])
    getAsset(data?.banner?.[language]).then((srcImage)=>{
      setBanner(srcImage);
    });
    
  }, [data?.banner?.[language]]);
  return loading? <ShimmerFeaturedGallery row={2} col={2} card frameHeight={600} /> :(
  <div className=" position-relative overflow-hidden">
    {/*<BgShape type="red"  right="-40px" top="80px" rotate="-140deg"/>*/}
    {/*<BgShape type="yellow"  right="-50px" top="800px" rotate="-150deg"/>*/}
    {/*<BgShape type="blue"  left="-80px" top="500px" rotate="140deg"/>*/}
  
        <CoverImg img={banner??img1}/>
        <Breadcrumb/>
        <Container className="mb-3 container" style={{"text-decoration": "none"}} dir={language==='ar'?"rtl":""}>
          <MainTitle>Offre numérique</MainTitle>
          
              <Row className="mt-5">
              <Col md={4}>
                 <DecalogCard/>
                </Col>
                <Col md={4}>
                 <CulturetecCard/>
                </Col>
              </Row>
              <MainTitle>Médiathèques</MainTitle>
              <div>
          <p className="lh-lg text-black-50 text-start ">
            {text??``}
          </p>
        </div>
              <Row className="mt-4">
                
                {villes.map(ville=>{
                  return (
                  <Col key={ville.id} md={4} className="mb-3">
                    <MediathequeCityCard contain={!ville.name && "contain"} ville={ville}/>
                  </Col>)
                })}
                
                  {/* <Col md={4}>
                    <a  href="https://www.culturetheque.com/DZA/" className=" col-sm-12 col-md-6" target="_blank" rel="noopener noreferrer">
                      <Col  style={{ marginBottom:"20px" }} className="hover hover-custom">
                        
                        
                          <div
                            className="cultureteque-img"
                            style={{
                              textAlign: "initial",
                              position: "relative",
                              height: "12.5rem",
                              overflow: "hidden",
                            }}
                          >
                            <div className="hover-overlay"></div>
                            <div className="hover-custom-content">
                            <span className="hover-custom-title"
                              style={{
                                textAlign: "initial",
                                position: "absolute",
                                bottom: "0",
                                left: "20px",
                                color: "white",
                                fontSize: "1.5rem",
                              }}
                            >
                              
                            </span>
                            <p className="hover-custom-description small text-uppercase mb-0"></p>
                            </div>
                          </div>
                      
                      </Col>
                    </a>
                  </Col>
                  <Col md={4} style={{ marginBottom:"20px" }} className="hover hover-custom">
                      <Link to="/alger/mediatheque" className="">
                          <div
                            className="region-img-alger"
                            style={{
                              textAlign: "initial",
                              position: "relative",
                              height: "200px",
                              overflow: "hidden",
                            }}
                          >
                            <div className="hover-overlay"></div>
                            <div className="hover-custom-content">
                            <span className="hover-custom-title"
                              style={{
                                textAlign: "initial",
                                position: "absolute",
                                bottom: "0",
                                left: "20px",
                                color: "white",
                                fontSize: "1.5rem",
                              }}
                            >
                              Alger
                            </span>
                            <p className="hover-custom-description small text-uppercase mb-0"></p>
                            </div>
                          </div>
                    </Link>
                  </Col>
                  <Col md={4} className="mb-3"><MediathequeCityCard img={img1}/></Col>
                  <Col md={4} style={{ marginBottom:"20px" }} className="hover hover-custom">
                      <Link to="/annaba/mediatheque" className="">
                      
                        
                          <div
                            className="region-img-annaba"
                            style={{
                              textAlign: "initial",
                              position: "relative",
                              height: "200px",
                              overflow: "hidden",
                            }}
                          >
                            <div className="hover-overlay"></div>
                            <div className="hover-custom-content">
                            <span className="hover-custom-title"
                              style={{
                                textAlign: "initial",
                                position: "absolute",
                                bottom: "0",
                                left: "20px",
                                color: "white",
                                fontSize: "1.5rem",
                              }}
                            >
                              Annaba
                            </span>
                            <p className="hover-custom-description small text-uppercase mb-0"></p>
                            </div>
                          </div>
                        
                      </Link>
                  </Col>
                  <Col md={4} style={{ marginBottom:"20px" }} className="hover hover-custom">
                    <Link to="/constantine/mediatheque" className="">
                        <div
                          className="region-img-constantine"
                          style={{
                            textAlign: "initial",
                            position: "relative",
                            height: "200px",
                            overflow: "hidden",
                          }}
                        >
                          <div className="hover-overlay"></div>
                          <div className="hover-custom-content">
                          <span className="hover-custom-title"
                            style={{
                              textAlign: "initial",
                              position: "absolute",
                              bottom: "0",
                              left: "20px",
                              color: "white",
                              fontSize: "1.5rem",
                            }}
                          >
                            Constantine
                          </span>
                          <p className="hover-custom-description small text-uppercase mb-0"></p>
                          </div>
                        </div>
                    </Link>
                  </Col>
                  <Col md={4} style={{ marginBottom:"20px" }} className="hover hover-custom">
                    <Link to="/oran/mediatheque" className="">
                        <div
                          className="region-img-oran"
                          style={{
                            textAlign: "initial",
                            position: "relative",
                            height: "200px",
                            overflow: "hidden",
                          }}
                        >
                          <div className="hover-overlay"></div>
                          <div className="hover-custom-content">
                          <span className="hover-custom-title"
                            style={{
                              textAlign: "initial",
                              position: "absolute",
                              bottom: "0",
                              left: "20px",
                              color: "white",
                              fontSize: "1.5rem",
                            }}
                          >
                            Oran
                          </span>
                          <p className="hover-custom-description small text-uppercase mb-0"></p>
                          </div>
                        </div>
                      
                    </Link>
                  </Col>
                  <Col md={4} style={{ marginBottom:"20px" }} className="hover hover-custom">
                    <Link to="/tlemcen/mediatheque" className="">
                        <div
                          className="region-img-tlemcen"
                          style={{
                            textAlign: "initial",
                            position: "relative",
                            height: "200px",
                            overflow: "hidden",
                          }}
                        >
                          <div className="hover-overlay"></div>
                          <div className="hover-custom-content">
                          <span className="hover-custom-title"
                            style={{
                              textAlign: "initial",
                              position: "absolute",
                              bottom: "0",
                              left: "20px",
                              color: "white",
                              fontSize: "1.5rem",
                            }}
                          >
                            Tlemcen
                          </span>
                          <p className="hover-custom-description small text-uppercase mb-0"></p>
                          </div>
                        </div>
                      
                    </Link>
                  </Col>
                  <Col>
                    <a  href="https://www.decalog.net/" className=" col-sm-12 col-md-6" target="_blank" rel="noopener noreferrer">
                      <Col style={{ marginBottom:"20px" }} className="hover hover-custom">
                            <div
                              style={{
                                textAlign: "initial",
                                position: "relative",
                                height: "12.5rem",
                                overflow: "hidden",
                              }}
                            >
                              <div className="hover-overlay"></div>
                              <div className="hover-custom-content d-flex align-items-center justify-content-center">
                              <h2 className="hover-custom-title fw-bold "
                                style={{
                                  position:'absolute',
                                  top:'-100px',
                                  fontSize:"2.8rem"
                                }}
                              >
                                CATALOGUE EN LIGNE
                              </h2>
                              <p className="hover-custom-description small text-uppercase mb-0"></p>
                              </div>
                            </div>
                          
                      </Col>
                    </a>
                  </Col>     */}
              </Row>
        </Container>
        <SocialSection institut={true} ville="national"/>
  </div>
  );
};
