import React, { useContext, useEffect, useState } from 'react'
import CoverImg from 'components/General/CoverImg'
import { Container , Row,Col,Button,Badge,ListGroup,Table} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import cover from '../../assets/img/cours-de-français.jpg'
import { Link,useLocation } from 'react-router-dom'
import BgShape from 'components/General/BgShape'
import MainTitle from 'components/General/MainTitle'
import CourseCard from 'components/cours de francais/CourseCard'
import styles from "./CoursDeFrançais.module.css"
import { getCours } from 'services/cours'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import EventsGrid from 'components/General/EventsGrid'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList,ShimmerSectionHeader } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'
function CoursPresentationPage({ville}) {
  const [cours,setCours] = useState([]);
  //const regionCourses = cours?.filter(el=>el?.region ===ville)[0]?.cours
  const [loading, setLoading] = React.useState(true);
  const { language } = useContext(LanguageContext);
  useEffect( () => {
    function fetchData(ville) {
      //console.log(ville)
      getCours({region:ville}).then((results)=>{
        if(results.items.length>0){
        results?.items?.reverse()?.map(e => {
            
            var img = e?.data.Image?.iv? e?.data.Image?.iv[0]: e?.data.Image?.[language]?e?.data.Image?.[language][0]:''??''; //todo default image
            e.data.src=img
  /*getAsset(img).then((srcImage)=>{
    e.data.srcImage=srcImage;
    setCours(results.items);});*/
  
});
setCours(results.items);
setLoading(false)
}else if(ville!=='national'){
  fetchData('national');
}
});
        
    }
    //console.log(ville)
    fetchData(ville);
}, [ville]);
const [banner, setBanner] = useState();
useEffect( () => {
  if(cours?.banner?.[language])
  getAsset(cours?.banner?.[language]).then((srcImage)=>{
    setBanner(srcImage);
  });
  
}, [cours?.banner?.[language]]);
  return loading? (<><ShimmerSectionHeader /><ShimmerPostList postStyle="STYLE_FOUR" col={3} row={2} gap={30} /></>) :(
    // <div
    //   style={{ backgroundColor: "whitesmoke", zIndex: "1" }}
    //   className=" position-relative overflow-hidden"
    // >
      <div
     
      className=" position-relative overflow-hidden" dir={language==='ar'?"rtl":""}
    >
      {/*<BgShape type="blue" right="-40px" top="100px" rotate="-140deg" />*/}
      {/*<BgShape type="red" right="-50px" top="800px" rotate="-150deg" />*/}
      {/*<BgShape type="yellow" left="-80px" top="500px" rotate="140deg" />*/}
      {/*<BgShape type="blue" left="-80px" top="2000px" rotate="140deg" />*/}
      <CoverImg img={banner??cover} />
      <Breadcrumb />
      <Container >
        <div className="mt-5">
          <MainTitle>Nos cours</MainTitle>
          <p className="">
          Les Espaces Langue et Savoirs des vos Instituts français vous proposent des cours adaptés à
tous les âges, à tous les niveaux, et à tous les besoins.
Nous proposons des cours pour les enfants dès le plus jeune âge, aux adolescents, aux adultes
et aux professionnels. Vous pourrez également suivre des ateliers de conversation ou une
préparation efficace au DELF-DALF.</p>

<h5>Une équipe pédagogique qualifiée et habilitée</h5>
<p>Les équipes pédagogiques sont constituées d’enseignants professionnels qualifiés possédant
tous une solide expérience dans l'enseignement du FLE.</p>
<h5>Une pédagogie communicative et actionnelle</h5>
<p>
La pédagogie mise en œuvre s'inscrit dans une démarche communicative. Elle fait largement
appel à des techniques d'animation active et interactive : jeux de rôles, simulations, études de
cas. Les outils multimédia font partie intégrante de la méthode d'apprentissage. La démarche
actionnelle favorise l'autonomie de l'apprenant, la pratique de l'oral, l'interaction et le suivi de
chaque stagiaire.
Découvrez vite notre catalogue pour progresser rapidement quelque soit votre niveau.
          </p>
        </div>

        <Row className="my-5">
          {/*!villes.includes(pathArray[1]) &&*/ (
            <Col md={4} className="mb-4">
              <Link to="tous-les-cours">
                <div
                  className={`text-white h-100 d-flex align-items-center justify-content-center bg-blue-ifa ${styles["all-courses-card"]}`}
                >
                  <h3 className="fs-1 fw-bold">Tous les cours</h3>
                </div>
              </Link>
            </Col>
          )}

          {/* <Col  className="hover hover-custom">
                          <div
                            style={{
                              textAlign: "initial",
                              position: "relative",
                              height: "12.5rem",
                              overflow: "hidden",
                            }}
                          >
                            <div className="hover-overlay"></div>
                            <div className="hover-custom-content d-flex align-items-center justify-content-center">
                            <h2 className="hover-custom-title fw-bold "
                              style={{
                                position:'absolute',
                                top:'-100px',
                                fontSize:"2.8rem"
                              }}
                            >
                              Tous les cours
                            </h2>
                            <p className="hover-custom-description small text-uppercase mb-0"></p>
                            </div>
                          </div>
                        
            </Col> */}
          {cours?.map((el,i) => {
            const availableCities = el.cities ? el.cities : null;
            //console.log(el)
            return (
              <>
                <Col md={4} className="mb-4" key={i}>
                  <Link to={ville==='national'?el?.id:el?.data?.Titre?.[language]??'#'} state={availableCities}>
                    <CourseCard cours={el} />
                  </Link>
                </Col>
              </>
            );
          })}
        </Row>
      </Container>
      {<Container>
              
              <EventsGrid title="Informations" type="Cours de français" region={ville&&ville}/>
  </Container>}
    </div>
  );
}

export default CoursPresentationPage