import { useEffect, useRef, useState } from 'react'

const Contact = ({onClose}) => {
    const [emailError,setEmailError]=useState("")
    const [email,setEmail] =useState("")
    
    const initialRender = useRef(false)

    // if there is no email error ==> close the modal
    useEffect((()=>{
      if (initialRender.current && !emailError) {
        onClose()
      }else{
        initialRender.current = true
      }
    }),[emailError])

    const handleChange=(e)=>{
        setEmail(e.target.value)
      }

    // email validation
    const validateEmail = (email)=>{
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        if(!email){
          setEmailError('Ce champ est obligatoire')
          }else if(!emailRegex.test(email)){
          setEmailError("L' adresse email n'est pas valide")
          }else{
            setEmailError("")
          }
    }

     // Submit
    const handleSubmit =(e)=>{
        e.preventDefault()
        validateEmail(email)
    }

  return (
    <div className='bg-white py-4 px-4' >
        <h2 className='text-uppercase blue-ifa fw-bold fs-3'>formulaire de contact</h2>
        <p className='fs-15 mb-4'>Remplissez ce formulaire pour contacter les responsable du site</p>
  
        <form onSubmit={handleSubmit}>
        <div className="form-group mb-2">
            <label className='fs-14 fw-bold' style={{fontSize:"14px"}} for="nameInput">Nom</label>
            <span className='d-block fs-13 mb-1'>Veuillez saisir votre nom complet</span>
            <input type="text" className="form-control rounded-0" id="nameInput"/>
        </div>

        <div className="form-group mb-2">
            <label className='fs-14 fw-bold' style={{fontSize:"14px"}} for="emailInput">Courriel<sup className='red-ifa'> *</sup></label>
            <span className='d-block fs-13 mb-1'>Veuillez saisir votre addresse courriel</span>
            <input type="email" value={email} onChange={handleChange} className="form-control rounded-0" id="emailInput" />
            {emailError && (
              <small className="my-1 text-danger text-start d-block">{emailError}</small>
            )}
        </div>

        <div className="form-group mb-2">
            <label className='fs-14 fw-bold' style={{fontSize:"14px"}} for="subjectInput">Sujet<sup className='red-ifa'> *</sup></label>
            <input type="text" className="form-control rounded-0" id="subjectInput" required="true"/>
        </div>

        <div className="form-group mb-2">
            <label className='fs-14 fw-bold' style={{fontSize:"14px"}} for="messageInput">Message<sup className='red-ifa'> *</sup></label>
            <span className='d-block fs-13 mb-1'>Veuillez saisir le message que vous souhaiter envoyer</span>
            <textarea type="text" className="form-control rounded-0" id="messageInput" required="true"/>
        </div>
                <button type="submit" className="d-block text-center text-white w-25 p-2 border-0 fw-bold bg-blue-ifa mt-4 ms-auto">
                  Envoyer
                </button>
        </form>
    </div>
  )
}

export default Contact