import {login} from "./auth"
import { baseUrl } from "./consts"


export async function getEtude({region='national'}) {
  //console.log(region)
    const response=await login();
if (response.ok) {
    var data = await response.json();
    //console.log(data)
    const res = await fetch("https://altadmin.if-algerie.com/api/content/ifa/etudes", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+data['access_token']
      },
      
    });

    const json = await res.json();
  
    if (json.errors) {
      console.error(json.errors);
      throw new Error("Failed to fetch API");
    }
    //console.log(json);
    return json;
  }
}




