import React from 'react'
import {Container} from 'react-bootstrap';
import styles from "./etudes.module.css"

function Bandeau({children,className,link}) {
  return (
    <>
        <div className={`${className} my-5 ${styles["bandeau"]}`} >
            <Container>
                <a href={link} target="_blank" rel="noreferrer" className={`${className}  text-black d-flex align-items-center justify-content-center py-4`}  >
                    {children}
                </a>
            </Container>
        </div>
    </>
  )
}

export default Bandeau