import React from 'react'
import { Container } from 'react-bootstrap'
import CoverImg from 'components/General/CoverImg'
import france from "../../assets/img/etudier-en-france.jpg"
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import EtudesCardsGrid from 'components/Etudes/EtudesCardsGrid'
import MainTitle from 'components/General/MainTitle'
import {etudes} from "../../data/etudes/etudes"


function EtudierEnAlgerie() {
  const etudesRegion = etudes.find((el)=>el.region==="algérie")
  return (
    <>
        <CoverImg img={france}/>
        <Breadcrumb/>
        <Container >
        <MainTitle>Etudier en Algérie</MainTitle>
        <p className="text-start text-black-50 pb-3">
        {etudesRegion.description}
        </p>

        <EtudesCardsGrid etudes={etudesRegion.etudes}/>
        </Container>
    </>
  )
}

export default EtudierEnAlgerie