import React from 'react'
import {FaPhoneSquareAlt}from 'react-icons/fa';
import {MdMailOutline,MdLocationOn} from "react-icons/md"
import styles from "./general-cmp.module.css"
function ContactCard({data}) {
  //console.log(data)
  return (
    <div className="bg-light p-3 my-4">
      <div className={styles.contact}>
        <h5 className="fw-bold text-start blue-ifa">Contacts</h5>
        <ul className="list-unstyled text-start" >
        <p>
              <MdLocationOn className="blue-ifa  fs-6 me-1" />
              {data?.Adresse}
            </p>
          <li>
            <FaPhoneSquareAlt className="blue-ifa me-2" />
            {data?.phone ??'021 73 73 86'}
          </li>
          <li>
            <MdMailOutline className="blue-ifa me-2" />
            <a href="mailto:infos.dlf-alger@if-algerie.com ">
                {data?.email??'email@if-algerie.com'}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ContactCard