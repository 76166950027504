//import { Events } from 'data/culture/Events';
import { Col, Row } from "react-bootstrap";
import EventCard from './EventCard';
import { useContext, useState } from "react";
import React from 'react'
import { getPosts } from '../../services/posts.js';
import { getAsset } from "services/assets";
import MainTitle from 'components/General/MainTitle';
import { ShimmerTitle,ShimmerSimpleGallery } from 'react-shimmer-effects';
import { LanguageContext } from "services/LanguageContext";

function EventsGrid({time,region,type,month,max,title}) {
    const [Events, SetEvents] = useState([]);  
    const [loading, setLoading] = React.useState(true);
    const { language } = useContext(LanguageContext);
    React.useEffect(()=>{
        function fetchData() {
            getPosts().then((results)=>{
            results.items.map(e => {
                
                var img = e?.data.Image?.iv? e?.data.Image?.iv[0]: e?.data.Image?.[language]?e?.data.Image?.[language][0]:''??''; //todo default image
      getAsset(img).then((srcImage)=>{
        e.data.srcImage=srcImage;
        SetEvents(results.items);
        setLoading(false)
    });
      })
    })}
        //console.log("getting articles : ")
        fetchData();
    },[])

    const eventsByMonth = month ? Events.filter(event=>event.date.toLowerCase().includes(month.toLowerCase())):Events
    const filtredEvents = eventsByMonth.filter(event=>{
        
            if (!time && !region && !type) {
                return event
            }else if(time && !region && !type){
                return event.time === time
            }else if(!time && region && !type){
                return event?.data?.Antenne?.iv[0] === region
            }else if(time && region && !type){
                return event.time === time && event?.data?.Antenne?.iv[0].toLowerCase() === region
            }else if(!time && !region && type){
                return event?.data?.Type?.iv[0] ===type
            }else if(time && !region && type){
                return event.time===time && event?.data?.Type?.iv[0] ===type
            }else if(!time && region==='national' && type){
                //console.log("getting type and natonal")
                return event?.data?.Type?.iv[0] ===type
            }else if(!time && region && type){
                //console.log("getting type and ville"+region+" "+event?.data?.Antenne?.iv[0].toLowerCase())
                return event?.data?.Antenne?.iv[0].toLowerCase() === region && event?.data?.Type?.iv[0] ===type
            }else if(time && region && type){
                return event.time===time && event?.data?.Antenne?.iv[0].toLowerCase() === region && event?.data?.Type?.iv[0] ===type
            }

            // if (!time && !region && !newType) {
            //     return event
            // }else if(time && !region && !newType){
            //     return event.time === time
            // }else if(!time && region && !newType){
            //     return event?.data?.Antenne?.iv[0] === region
            // }else if(time && region && !newType){
            //     return event.time === time && event?.data?.Antenne?.iv[0] === region
            // }else if(!time && !region && newType){
            //     return event?.data?.Type?.iv[0] ===type
            // }else if(time && !region && newType){
            //     return event.time===time && event?.data?.Type?.iv[0] ===type
            // }else if(!time && region && newType){
            //     return event?.data?.Antenne?.iv[0] === region && event?.data?.Type?.iv[0] ===type
            // }else if(time && region && newType){
            //     return event.time===time && event?.data?.Antenne?.iv[0] === region && event?.data?.Type?.iv[0] ===type
            // }
        
    })
    const finalEvents = max ? filtredEvents.slice(0,max) : filtredEvents
    const EventList = finalEvents.map((event,index)=>{
        return (
          <Col lg={4} md={6} key={index}>
            <EventCard key={index} event={event} />
          </Col>
        );
    })

  return loading? <ShimmerSimpleGallery card imageHeight={300} caption /> :(
    EventList.length>0?<>
    <MainTitle className="my-5">{title}</MainTitle>
    <Row className="mb-4">
        {EventList}
    </Row></>:''
  )
}

export default EventsGrid