import React from 'react'
import {FaPhoneSquareAlt}from 'react-icons/fa';
import {ImLocation2} from 'react-icons/im'
import { MdOutlineMail } from "react-icons/md";
import { MdPhone } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";
import styles from './mediatheque.module.css'


const ContactCard = ({contact})=>{
    return (
      <div className={`p-4 mb-3 bg-light  ${styles.contact}`}>
        <h5 className="text-start blue-ifa fw-bold p-0">Contact & accès </h5>
        <div className='text-black-50'>
            <p>
              <MdLocationOn className="blue-ifa  fs-6 me-1" />
              {contact.Adresse}
            </p>
            <p>
              <MdPhone className='blue-ifa me-1'/> 
              {contact.phone}
            </p>
            <p>
              <MdOutlineMail className='blue-ifa me-1'/>
              {contact.email}
            </p>
        </div>
      </div>
    );
}

export default ContactCard