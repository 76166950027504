import React, { useContext, useEffect, useState } from 'react'
import CoverImg from 'components/General/CoverImg'
import annaba from '../../assets/img/annaba1.jpg'
import { Container} from 'react-bootstrap'
import MainTitle from 'components/General/MainTitle'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import HistoiryCard from 'components/Institut/HistoiryCard'
import SocialSection from 'components/General/SocialSection'
import ifa from "../../assets/img/ComponentTMP_3-image3.webp"
import BgShape from 'components/General/BgShape'
import recrutements from "../../data/institut/recrutement"
import { useGlobal } from 'contexts/GlobalContext'
import { fetchAPIRecrutement } from 'services/institut-national'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import EventsGrid from 'components/General/EventsGrid';
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList,ShimmerSectionHeader,ShimmerFeaturedGallery } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'

function InstitutRecrutementPage({ville}) {
  const {changeBackgroundColor} = useGlobal()
 
  // get the presentation data & update the state
  const nationalPresentation = recrutements.filter(el=>el.region === ville)[0]
  const [presentation,setPresentation] = useState([])
  const [institut, setInstitut] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const { language } = useContext(LanguageContext);
  useEffect(()=>{
    setPresentation(nationalPresentation.text)
    changeBackgroundColor("white")
  },[])
  useEffect( () => {
    function fetchData() {
      fetchAPIRecrutement().then((items)=>{
        //const res = await fetchAPIInstitutDetails(results.posts[0].id)
        var el=items.items[0]?.data;
        //console.log(el);
        var img = el?.Image?.iv? el?.Image?.iv[0]: el?.Image?.[language]? el?.Image?.[language][0]:null??null;
        if(img)
        getAsset(img).then((url)=>{
          el.srcImage=url;
          el.id=items.items[0]?.id;
          setInstitut(el);
          setLoading(false)
      });
        
      });
    }
      
    fetchData();
}, []);
const [banner, setBanner] = useState();
useEffect( () => {
  if(institut?.banner?.[language])
  getAsset(institut?.banner?.[language]).then((srcImage)=>{
    setBanner(srcImage);
  });
  
}, [institut?.banner?.[language]]);
try{var text = parse(institut?.Description[language??'fr'])}catch($){
  text = institut?.Description[language??'fr']
}
  return loading? (<><ShimmerSectionHeader/><ShimmerPostList postStyle="STYLE_FOUR" col={3} row={1} gap={30} /></>) :(
    <div className=" position-relative " dir={language==='ar'?"rtl":""}>
      {/*<BgShape type="red"  right="-70px" top="160px" rotate="-140deg"/>*/}
      {/*<BgShape type="yellow"  left="-50px" bottom="200px" rotate="150deg"/>*/}
    
      <CoverImg img={banner??annaba}/>
      <Breadcrumb/>
      <Container >
          <MainTitle>Recrutement</MainTitle>
          <HistoiryCard className="mb-3" img={institut?.srcImage??ifa}>
            {
              text??presentation.map(el=><p key={el.id}>{el.paragraph}</p>)
            }   
          </HistoiryCard>
          {/* <MainTitle>L'institut En Images</MainTitle>
          <InstitutCarousel className="mb-5 "/> */}
      </Container>
      {<Container>
              
              <EventsGrid title="En ce moment :" type="Recrutement" region={ville&&ville}/>
  </Container>}
      <SocialSection institut={true} ville={ville}/>
    </div>
  )
}

export default InstitutRecrutementPage