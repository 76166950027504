const recrutements = [
    {
        region:"national",
        text :[
            {
                id:1,
                paragraph : "L’Institut français d’Algérie recrute tout au long de l’année. Retrouvez nos offres sur la page d’accueil."
            },
            {
                id:2,
                paragraph : ""
            },
            {
                id:3,
                paragraph : ""
            }
        ]
    },
    {
        region:"alger",
        text :[
            {
                id:1,
                paragraph : `L’Institut français d’Algérie recrute tout au long de l’année. Retrouvez nos offres sur la page d’accueil.`
            },
            {
                id:2,
                paragraph : ``
            },
            {
                id:3,
                paragraph : ``
            }
        ],
        img:"https://plus.unsplash.com/premium_photo-1664283661776-a61b119f6342?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
    },
    {
        region:"oran",
        text :[
            {
                id:1,
                paragraph : `L’Institut français d’Algérie recrute tout au long de l’année. Retrouvez nos offres sur la page d’accueil.`
            },
            {
                id:2,
                paragraph : ``
            },
            {
                id:3,
                paragraph : ``
            }
        ],
        img:"https://images.unsplash.com/photo-1532531427586-3cf3d12585f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=886&q=80"
    },
    {
        region:"constantine",
        text :[
            {
                id:1,
                paragraph : ``
            },
            {
                id:2,
                paragraph : ``
            },
            {
                id:3,
                paragraph : ``
            }
        ],
        img:"https://images.unsplash.com/photo-1622164287441-7dc50ec3ee4d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80"
    },
    {
        region:"annaba",
        text :[
            {
                id:1,
                paragraph : `L’Institut français d’Algérie recrute tout au long de l’année. Retrouvez nos offres sur la page d’accueil.`
            },
            {
                id:2,
                paragraph : ``
            },
            {
                id:3,
                paragraph : ``
            }
        ],
        img:"https://images.unsplash.com/photo-1646864052402-9c2b3d7d22e7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1034&q=80"
    },
    {
        region:"tlemcen",
        text :[
            {
                id:1,
                paragraph : `L’Institut français d’Algérie recrute tout au long de l’année. Retrouvez nos offres sur la page d’accueil.`
            },
            {
                id:2,
                paragraph : ``
            },
            {
                id:3,
                paragraph : ``
            }
        ],
        img:"https://images.unsplash.com/photo-1547653872-052e3539decc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
    }
]

export default recrutements