import React, {useState,useEffect, useContext} from 'react'

import alger from "../../assets/img/alger.jpg"
import CoverImg from 'components/General/CoverImg'
import { Container,Row,Col,Accordion } from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import cultureteque from "../../assets/img/cultureteque.png";
import decalog from "../../assets/img/decalog.png";
import SideBanner from 'components/Mediatheque city/SideBanner'
import ContactCard from 'components/Mediatheque city/ContactCard'
import HorairesCard from 'components/Mediatheque city/HorairesCard'
import MainTitle from 'components/General/MainTitle'
import BgShape from 'components/General/BgShape'
import mediatheques from 'data/mediatheque/Mediatheque-villes'
import styles from "./mediatheque.module.css"
import { getMediathequeRegion } from 'services/mediatheque'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList,ShimmerSectionHeader,ShimmerFeaturedGallery } from 'react-shimmer-effects';
import CoursHorairesCard from 'components/cours de francais/CoursHorairesCard';
import { LanguageContext } from 'services/LanguageContext'
function MediathequeInscriptionPage({ville}) {
  const cityInfo = mediatheques.filter(el=>el.region===ville)[0]
  const inscriptionVille = cityInfo.inscription
  const [presentation,setPresentation] = useState({})
  const [loading, setLoading] = React.useState(true);
  const { language } = useContext(LanguageContext);
  useEffect( () => {
    function fetchData() {
      //console.log(ville)
      
      getMediathequeRegion(ville).then((results)=>{
            results?.items?.map(e => {
              //console.log(e)

            var img = e?.data.Image?.iv? e?.data.Image?.iv[0]: e?.data.Image?.[language]?e?.data.Image?.[language][0]:''??''; //todo default image
  getAsset(img).then((srcImage)=>{
    e.data.srcImage=srcImage;
    setPresentation(e.data);});
    setLoading(false)
});
});
        }
    
    
    fetchData();
  }, [ville]);
  try{var text = parse(presentation?.Inscription?.[language??'fr'])}catch($){
    text = presentation?.Inscription?.[language??'fr']
  }
  //console.log(presentation?.horraires?.[language])
  //console.log("yy: "+ville)
  // vérifier si la ville actuelle commence par un voyelle 
  const accentCities = ["alger","oran","annaba"]
  const isAccent = ()=>accentCities.indexOf(ville) !== -1

     // documents d'inscription
     const documentList = inscriptionVille.documents.map((el,index)=>{
        if (typeof(el)==="object") {
          return <div key={index}>
          <span className='d-block fw-bold'>{el.category}</span>
          <ul>
            {el.documents.map((doc,index)=>{
                return <li className='py-1'>{doc}</li>
            })}
          </ul>
          </div>
          
        }
        return <li className='py-1' key={index}>{el}</li>
        
      })
      
      const TarifsList = inscriptionVille.tarifs.map((tarif,index)=>{
        if (typeof(tarif)==="object") {
          return <div key={index} className="my-2">
            <h6 className='fw-bold'>{tarif.type}</h6>
            <span>{tarif.montant}</span>
          </div>
        }
        return <li className='py-1'>{tarif}</li>
      })
  
      const carte = inscriptionVille.carte&&inscriptionVille.carte.map((el,index)=>{
        return <p className='text-start fs-6 lh-lg my-1'>{el}</p>
      })
      const politiquesDePret = inscriptionVille.pret&&inscriptionVille.pret.map((el,index)=>{
        return <li key={index} className='py-1'>{el}</li>
      })
      const [banner, setBanner] = useState();
      useEffect( () => {
        if(presentation?.banner?.[language])
        getAsset(presentation?.banner?.[language]).then((srcImage)=>{
          setBanner(srcImage);
        });
        
      }, [presentation?.banner?.[language]]);
  return loading? <ShimmerPostDetails card cta variant="EDITOR" /> :(
    <div className='position-relative overflow-hidden' dir={language==='ar'?"rtl":""}>
      {/*<BgShape type="blue"  right="-40px" top="100px" rotate="-140deg"/>*/}
      {/*<BgShape type="red"  right="-50px" top="800px" rotate="-150deg"/>*/}
      {/*<BgShape type="yellow"  left="-80px" top="500px" rotate="140deg"/>*/}
        <CoverImg img={banner??alger} />
        <Breadcrumb />
        <Container className="my-5" >
        <Row>
            <Col>
                <MainTitle >Médiathèques {isAccent() ? "d'" : 'de '}{ville[0].toUpperCase() + ville.slice(1)}</MainTitle>
                <Accordion  alwaysOpen>
                  <Accordion.Item>
                    <Accordion.Header>
                      <h3>Inscription</h3>
                    </Accordion.Header>
                    <Accordion.Body>
                    <div className={`text-start text-black-50 ${styles.inscription}`}>
                        <p >{text??inscriptionVille.intro}</p>
                        {/*<ul>{documentList}</ul>
                        {inscriptionVille.acquittement && (
                        <p className="text-left mb-2">{inscriptionVille.acquittement}</p>
                        )}

                        {inscriptionVille.adhesion && (
                        <p className="text-left">{inscriptionVille.adhesion}</p>
                    )}
                        <div>
                            <h5 className="fw-bold blue-ifa">
                                Tarifs
                            </h5>
                        {TarifsList} 
                        </div>*/}

                        <div>{inscriptionVille.carte && carte}</div>

                        {inscriptionVille.pret&&
                        <div>
                            <h5 className="fw-bold blue-ifa">Politique de prêts</h5>
                            {politiquesDePret}
                        </div>
                        }
                </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
            </Col>
            <Col md={4}>
              <SideBanner link="https://www.culturetheque.com/DZA/" img={cultureteque}/>
              <SideBanner link={ville!=='constantine'?"https://www.decalog.net/":'https://ifconstantine.bibenligne.fr'} text="Catalogue en ligne" imgStyle={{width:"100%"}}/>
              <ContactCard contact ={presentation?.contact?.[language]??cityInfo.contact}/>
              {/*<CoursHorairesCard ville={ville} data={presentation?.horraires?.[language]}/>*/}
            </Col>
        </Row>
        </Container>
    </div>
  )
}

export default MediathequeInscriptionPage