import React from 'react';
import { Link } from 'react-router-dom';
import MainTitle from 'components/General/MainTitle'
import { Container} from 'react-bootstrap';
import CoverImg from 'components/General/CoverImg'
import { useParams } from 'react-router-dom';
import Breadcrumb from 'components/breadcrumb/breadcrumb';
import { Events } from 'data/culture/Events';
export const Post = ({ post, withLink }) => {
    return (
        <>
      <CoverImg
        img={
          post?.data?.srcImage??""
        }
      />
      <Breadcrumb brd={post?.data?.title?.iv}/>
      <Container className="w-md-75">
        <MainTitle className="mt-5">{post?.data?.title?.iv}</MainTitle>
            {/*{withLink ? (
                <Link to={`/blog/${post.id}/${post.data.slug.iv}`}>
                    <h2>{post?.data?.title?.iv}</h2>
                </Link>
            ) : (
                <h2>{post?.data?.title?.iv}</h2>
            )}*/}
            <div className='p-4 bg-blue-ifa-light d-flex justify-content-between fs-5 my-3'>
            <div className='text-start'>
                <span className="fw-bold me-2 blue-ifa">Antenne:</span>
                <span className="mt-2">{post?.data?.Antenne?.iv[0]}</span>
            </div>
            <div className='text-start'>
                <span className="fw-bold me-2 blue-ifa">Type:</span>
                <span className="mt-2">{post?.data?.Type.iv[0]}</span>
            </div>
        </div>
            <div>
          <img
            className="w-100 mb-4"
            src={post?.data?.srcImage??""}
          ></img>
        </div>
            <div dangerouslySetInnerHTML={{ __html: post?.data?.text?.iv }} />
        
      </Container>
    </>
    );
};