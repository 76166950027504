// imgs for events
import img1 from "../../assets/img/event-sila.jpg";
import img2 from "../../assets/img/ComponentTMP_4-image.png";
import img3 from "../../assets/img/event-photographie.jpg";
import img4 from "../../assets/img/ComponentTMP_4-image3.png";
import img5 from "../../assets/img/event-liad.jpeg";
import img6 from "../../assets/img/event-writing.jpg";
import img7 from "../../assets/img/ComponentTMP_4-image2.png";
import pImg1 from "../../assets/img/event-p-stage.jpg";
import pImg2 from "../../assets/img/event-p-socio.jpeg";
import pImg3 from "../../assets/img/event-p-concour.jpg";

const futurEvents = [
  {
    region: "alger",
    date: "01 Octobre 2022",
    title:
      "L'Institut français participe au Salon International du Livre d'Alger (SILA 2022)  !",
    description:
      "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression",
    image: img1,
    type: "arts de la scène",
  },
  {
    region: "alger",
    date: "22 Octobre 2022",
    title: "Ouverture d’un nouvel espace café restaurant",
    description:
      "Bienvenue aux « saveurs du savoir » qui fait peau neuve et rouvre ses portes au public dimanche 6 février 2022.",
    image: img2,
    type: "Arts visuels",
  },
  {
    region: "oran",
    date: "29 Octobre 2022",
    title:
      "Exposition photographique IQBAL/ARRIVEES, pour une jeune photographie algérienne.",
    description:
      "En proposant un regard croisé sur l'Algérie, cette exposition a pour vocation de révéler au grand public les talents de la jeune photographie algérienne.",
    image: img3,
    type: "cinéma",
  },

  {
    region: "constantine",
    date: "30 Aout 2022",
    title: "« Territoires parallèles » exposition photo de Hamid Rahiche",
    description:
      "Ce projet photographique voudrait questionner le regard sur ces deux villes emblématiques de...",
    image: img4,
    type: "Débat d'idées",
  },
  {
    region: "annaba",
    date: "18 Novembre 2022",
    title: "LANCEMENT DU PROJET IMMOBILIER « GRAND LIAD »",
    description:
      "L’Ambassade de France à Alger est heureuse d’annoncer le lancement officiel du projet immobilier «Grand LIAD»,",
    image: img5,
    type: "Jeunesse",
  },
  {
    region: "constantine",
    date: "25 décembre 2022",
    title:
      "LANCEMENT DU PRIX 2022 DE LA NOUVELLE FANTASTIQUE - APPEL À CANDIDATURES",
    description:
      "L'IFA lance sa quatrième édition du Prix de la Nouvelle Fantastique d'expression française en Algérie. Candidatures ouvertes jusqu'au 25 janvier 2023",
    image: img6,
    type: "Jeunesse",
  },
  {
    region: "tlemcen",
    date: "15 janvier 2023",
    title:
      "Exposition « Abd el-Kader » du 15 janvier au 22 février 2023 au Mucem",
    description:
      "Emir de la résistance, saint combattant, fondateur de l’Etat algérien, précurseur de la codification du droit...",
    image: img7,
    type: "divers",
  },
];

const pastEvents = [
  {
    region: "alger",
    date: "13 mars 2020",
    title:
      "Stage d’ete pour futurs professeurs de français (étudiants ENS d’Algérie) centre de linguistique appliquee (CLA) – université de franche-comté",
    description:
      "Dans le cadre de la coopération algéro-française, l'Ambassade de France appuie la formation pédagogique des futurs enseignants de français dans les écoles, ...",
    image: pImg1,
    type: "divers",
  },
  {
    region: "alger",
    date: "25 janvier 2016",
    title:
      'la sociolinguistique urbaine en algérie : états des lieux et perspectives" colloque en hommage à thierry bulot, sociolinguiste',
    description:
      "Un an après sa disparition, La France et l'Algérie rendent hommage à Thierry Bulot et ses recherches en organisant un colloque à Alger 2 les 25 et 26 janvier 2016.",
    image: pImg2,
    type: "Cinéma",
  },
  {
    region: "oran",
    date: "17 juin 2021",
    title: "concours d'écriture - semaine de la langue française en algérie",
    description:
      "Le Prix International du Jeune Écrivain de Langue Française est un prix annuel destiné à récompenser des oeuvres d’imagination inédites",
    image: pImg3,
    type: "Arts visuels",
  },
];

const Events = [
  {
    region: "alger",
    date: "01 Octobre 2022",
    title:
      "L'Institut français participe au Salon International du Livre d'Alger (SILA 2022)  !",
    description:
      "Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression",
    image: img1,
    time: "future",
    type: "expo",
  },
  {
    region: "alger",
    date: "22 Octobre 2022",
    title: "Ouverture d’un nouvel espace café restaurant",
    description:
      "Bienvenue aux « saveurs du savoir » qui fait peau neuve et rouvre ses portes au public dimanche 6 février 2022.",
    image: img2,
    time: "future",
    type: "théatre",
  },
  {
    region: "alger",
    date: "02 Décembre 2022",
    title: "Ouverture d’un nouvel théatre à Alger ",
    description:
      "Bienvenue aux « saveurs du savoir » qui fait peau neuve et rouvre ses portes au public ",
    image: "https://images.unsplash.com/photo-1562329265-95a6d7a83440?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=770&q=80",
    time: "future",
    type: "théatre", 
  },
  {
    region: "alger",
    date: "15 Novembre 2022",
    title: "Atelier bd jeune public « max et lili cherchent leur métier »",
    description:
      "Ils fêtent leurs 30 ans ! 30 ans que Max et Lili découvrent le plaisir de grandir en faisant face aux épreuves de la vie avec leur famille et leurs copains.",
    image:
      "https://www.if-algerie.com/alger/agenda-culturel/atelier-bd-jeune-public/image_preview",
    time: "future",
    type: "jeunesse",
  },
  {
    region: "oran",
    date: "29 Octobre 2022",
    title:
      "Exposition photographique IQBAL/ARRIVEES, pour une jeune photographie algérienne.",
    description:
      "En proposant un regard croisé sur l'Algérie, cette exposition a pour vocation de révéler au grand public les talents de la jeune photographie algérienne.",
    image: img3,
    time: "future",
    type: "cinéma",
  },
  {
    region: "alger",
    date: "15 Décembre 2022",
    title:
      "Cum maiores sint ut doloremque cumque aut voluptates eius est dolor ",
    description:
      "En proposant un regard croisé sur l'Algérie, cette exposition a pour vocation de révéler au grand public les talents de la jeune photographie algérienne.",
    image: "https://images.unsplash.com/photo-1597424877488-affe47c580aa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "future",
    type: "cinéma",
  },
  {
    region: "alger",
    date: "03 Janvier 2023",
    title:
      "Cum maiores sint ut doloremque cumque aut voluptates eius est dolor",
    description:
      "En proposant un regard croisé sur l'Algérie, cette exposition a pour vocation de révéler au grand public les talents de la jeune photographie algérienne.",
    image: "https://images.unsplash.com/photo-1595769816263-9b910be24d5f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=879&q=80",
    time: "future",
    type: "cinéma",
  },
  {
    region: "constantine",
    date: "30 Aout 2022",
    title: "« Territoires parallèles » exposition photo de Hamid Rahiche",
    description:
      "Ce projet photographique voudrait questionner le regard sur ces deux villes emblématiques de...",
    image: img4,
    time: "future",
    type: "conférences et rencontres",
  },
  {
    region: "alger",
    date: "12 novembre 2022",
    title: "Eos vero doloribus id obcaecati autem quo Quis possimus",
    description:
      "Ce projet photographique voudrait questionner le regard sur ces deux villes emblématiques de...",
    image: "https://images.unsplash.com/photo-1560439514-e960a3ef5019?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "future",
    type: "conférences et rencontres",
  },
  {
    region: "alger",
    date: "27 mars 2023",
    title: "Eos vero doloribus id obcaecati autem quo Quis possimuse",
    description:
      "Ce projet photographique voudrait questionner le regard sur ces deux villes emblématiques de...",
    image: "https://images.unsplash.com/photo-1527261834078-9b37d35a4a32?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "future",
    type: "conférences et rencontres",
  },
  {
    region: "annaba",
    date: "18 Novembre 2022",
    title: "LANCEMENT DU PROJET IMMOBILIER « GRAND LIAD »",
    description:"L’Ambassade de France à Alger est heureuse d’annoncer le lancement officiel du projet immobilier «Grand LIAD»,",
    image: "https://images.unsplash.com/photo-1532649538693-f3a2ec1bf8bd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "future",
    type: "jeunesse",
  },
  {
    region: "alger",
    date: "01 Novembre 2022",
    title: "A animi consequatur eum facere molestiae est voluptatibus expedita",
    description:"L’Ambassade de France à Alger est heureuse d’annoncer le lancement officiel du projet immobilier «Grand LIAD»,",
    image: "https://images.unsplash.com/photo-1502086223501-7ea6ecd79368?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=838&q=80",
    time: "future",
    type: "jeunesse",
  },
  {
    region: "constantine",
    date: "25 Décembre 2022",
    title:
      "LANCEMENT DU PRIX 2022 DE LA NOUVELLE FANTASTIQUE - APPEL À CANDIDATURES",
    description:
      "L'IFA lance sa quatrième édition du Prix de la Nouvelle Fantastique d'expression française en Algérie. Candidatures ouvertes jusqu'au 25 janvier 2023",
    image: img6,
    time: "future",
    type: "danse",
  },
  {
    region: "alger",
    date: "01 Février 2023",
    title:
      "a At repellat reprehenderit. Sed obcaecati quia  delectus totam sed",
    description:
      "L'IFA lance sa quatrième édition du Prix de la Nouvelle Fantastique d'expression française en Algérie. Candidatures ouvertes jusqu'au 25 janvier 2023",
    image: "https://images.unsplash.com/photo-1664993981856-2076348ed8f9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "future",
    type: "danse",
  },
  {
    region: "alger",
    date: "28 Décembre 2022",
    title:
      "a At repellat reprehenderit. Sed obcaecati quia  delectus totam ",
    description:
      "L'IFA lance sa quatrième édition du Prix de la Nouvelle Fantastique d'expression française en Algérie. Candidatures ouvertes jusqu'au 25 janvier 2023",
    image: "https://images.unsplash.com/photo-1441974231531-c6227db76b6e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
    time: "future",
    type: "danse",
  },
  {
    region: "tlemcen",
    date: "15 Janvier 2023",
    title:
      "Exposition « Abd el-Kader » du 15 janvier au 22 février 2023 au Mucem",
    description:
      "Emir de la résistance, saint combattant, fondateur de l’Etat algérien, précurseur de la codification du droit...",
    image: img7,
    time: "future",
    type: "expo",
  },
  {
    region: "alger",
    date: "10 avril 2023",
    title:
      "Aut quos neque eos reiciendis asperiores non repellendus suscipit ut labore quasi",
    description:
      "Emir de la résistance, saint combattant, fondateur de l’Etat algérien, précurseur de la codification du droit...",
    image: "https://images.unsplash.com/photo-1511578314322-379afb476865?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80",
    time: "future",
    type: "expo",
  },
  {
    region: "oran",
    date: "11 Mai 2023",
    title:
      " Passage classique «Lorem ipsum dolor sit amet…»",
    description:
      "Selon le professeur latin Richard McClintock, l'homme reconnu pour avoir découvert les racines de Lorem Ipsum...",
    image: "https://images.unsplash.com/photo-1556379118-7034d926d258?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1132&q=80",
    time: "future",
    type: "musique",
  },
  {
    region: "alger",
    date: "02 Mars 2023",
    title:" Passage classique «Lorem ipsum dolor site amet…»",
    description:"Selon le professeur latin Richard McClintock, l'homme reconnu pour avoir découvert les racines de Lorem Ipsum...",
    image: "https://images.unsplash.com/photo-1511379938547-c1f69419868d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "future",
    type: "musique",
  },
  {
    region: "alger",
    date: "13 janvier 2023",
    title:" Passage classique «Lorem ipsum doloor site amet…»",
    description:"Selon le professeur latin Richard McClintock, l'homme reconnu pour avoir découvert les racines de Lorem Ipsum...",
    image: "https://images.unsplash.com/photo-1485579149621-3123dd979885?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1031&q=80",
    time: "future",
    type: "musique",
  },
  {
    region: "alger",
    date: "03 Janvier 2023",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1507537509458-b8312d35a233?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "future",
    type: "Campus France",
  },
  {
    region: "alger",
    date: "13 Janvier 2023",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit1",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1491308056676-205b7c9a7dc1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=873&q=80",
    time: "future",
    type: "Campus France",
  },
  {
    region: "alger",
    date: "07 Mars 2023",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit2",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1571193161738-deaba9b6cc26?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=445&q=80",
    time: "future",
    type: "Campus France",
  },
  {
    region: "oran",
    date: "11 Décembre 2022",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit3",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1541178735493-479c1a27ed24?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80",
    time: "future",
    type: "Campus France",
  },
  {
    region: "constantine",
    date: "17 Novembre 2022",
    title: "Lorem ipsum dolor sit amet, consectetur adipiscin elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1596496050860-b48b170fc584?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "future",
    type: "Campus France",
  },
  {
    region: "alger",
    date: "18 Octobre 2022",
    title: "Lorem ipsum dolor sit amet, consectetur adipscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1536300099515-6c61b290b654?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "future",
    type: "France alumni",
  },
  {
    region: "alger",
    date: "01 Décembre 2022",
    title: "Lorem ipsum dolor sit amet, consectetur adipscng elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1596495868845-63031cb496da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "future",
    type: "France alumni",
  },
  {
    region: "alger",
    date: "03 Novembre 2022",
    title: "Lorem ipsum dolor sit amet, consectetur adpiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1537202108838-e7072bad1927?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=446&q=80",
    time: "future",
    type: "France alumni",
  },
  {
    region: "annaba",
    date: "12 Novembre 2022",
    title: "Lorem ipsum dolor sit amet, consectetur dipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1596496357130-e24a50408378?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "future",
    type: "France alumni",
  },
  {
    region: "tlemcen",
    date: "04 Décembre 2022",
    title: "Lorem ipsum dolor sit amet, consectetur amdipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1507537417841-81e85feb9bd2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    time: "future",
    type: "France alumni",
  },
  {
    region: "alger",
    date: "07 Novembre 2022",
    title: "Lorem ipsum dolor sit amet, consectetr adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.pexels.com/photos/2781814/pexels-photo-2781814.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    time: "future",
    type: "LIAD",
  },
  {
    region: "alger",
    date: "02 Décembre 2022",
    title: "Lorem ipsum dolor sit amet, consecteur adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.pexels.com/photos/2982449/pexels-photo-2982449.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    time: "future",
    type: "LIAD",
  },
  {
    region: "oran",
    date: "19 Décembre 2022",
    title: "Lorem ipsum dolor sit amet, consecttur adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.pexels.com/photos/1755685/pexels-photo-1755685.jpeg?auto=compress&cs=tinysrgb&w=600",
    time: "future",
    type: "LIAD",
  },
  {
    region: "annaba",
    date: "17 Octobre 2022",
    title: "Lorem ipsum dolor sit amet, consetetur adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.pexels.com/photos/8500422/pexels-photo-8500422.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    time: "future",
    type: "LIAD",
  },
  {
    region: "alger",
    date: "18 Janvier 2022",
    title: "Lorem ipsum dolor sit amet, conectetur adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.pexels.com/photos/1370298/pexels-photo-1370298.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    time: "future",
    type: "LIAD",
  },
  {
    region: "alger",
    date: "19 Octobre 2022",
    title: "Lorem ipsum dolor sit amet, cosectetur adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.pexels.com/photos/261895/pexels-photo-261895.jpeg?auto=compress&cs=tinysrgb&w=600",
    time: "future",
    type: "PEH",
  },
  {
    region: "alger",
    date: "02 Novembre 2022",
    title: "Lorem ipsum dolor sit amet, cnsectetur adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.pexels.com/photos/10643463/pexels-photo-10643463.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    time: "future",
    type: "PEH",
  },
  {
    region: "alger",
    date: "23 Décembre 2022",
    title: "Lorem ipsum dolor sit amet, onsectetur adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.pexels.com/photos/8363029/pexels-photo-8363029.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    time: "future",
    type: "PEH",
  },
  {
    region: "alger",
    date: "02 Octobre 2022",
    title: "Lorem ipsum dolor sit amet, cionsectetur adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.pexels.com/photos/207684/pexels-photo-207684.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    time: "future",
    type: "ESAA",
  },
  {
    region: "alger",
    date: "18 Novembre 2022",
    title: "Lorem ipsum dolor sit amet, cronsectetur adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1574958269340-fa927503f3dd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=748&q=80",
    time: "future",
    type: "ESAA",
  },
  {
    region: "alger",
    date: "22 Mars 2023",
    title: "Lorem ipsum dolor sit ame, consectetur adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1527891751199-7225231a68dd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "future",
    type: "ESAA",
  },
  //-------------------- Actions culturelle ------------------
  {
    region: "alger",
    date: "02 Novembre 2022",
    title: "Lorem ipsum dolor sit ame, consectetur adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1527489377706-5bf97e608852?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=959&q=80",
    time: "future",
    type: "actions culturelles",
  },
  {
    region: "alger",
    date: "14 Décembre 2022",
    title: "Lorem ipsum dolor sit ame, consectetur adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1475924156734-496f6cac6ec1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "future",
    type: "actions culturelles",
  },
  {
    region: "alger",
    date: "14 Décembre 2022",
    title: "Lorem ipsum dolor sit ame, consectetur adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1475924156734-496f6cac6ec1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "future",
    type: "actions culturelles",
  },
  {
    region: "alger",
    date: "07 Février 2022",
    title: "Lorem ipsum dolor sit ame, consectetur adipiscing elit",
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image:
      "https://images.unsplash.com/photo-1552083375-1447ce886485?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "future",
    type: "actions culturelles",
  },
  // ==================== past events ======================
  {
    region: "alger",
    date: "13 Mars 2020",
    title:
      "Stage d’éte pour futurs professeurs de français (étudiants ENS d’Algérie) centre de linguistique appliquee (CLA) – université de franche-comté",
    description:
      "Dans le cadre de la coopération algéro-française, l'Ambassade de France appuie la formation pédagogique des futurs enseignants de français dans les écoles, ...",
    image: pImg1,
    time: "past",
    type: "Conférences et rencontres",
  },
  {
    region: "alger",
    date: "25 avril 2022",
    title:
      'Lorem ipsum dolor sit ame,',
    description:
      "oluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo",
    image: "https://images.unsplash.com/photo-1614014783958-369b9429da66?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "past",
    type: "théatre",
  },
  {
    region: "oran",
    date: "17 Juin 2022",
    title: "concours d'écriture - semaine de la langue française en algérie",
    description:
      "Le Prix International du Jeune Écrivain de Langue Française est un prix annuel destiné à récompenser des oeuvres d’imagination inédites",
    image: pImg3,
    time: "past",
    type: "expo",
  },
  {
    region: "alger",
    date: "04 janvier 2022",
    title:
      "a At repellat reprehenderit. Sed obcaecati quia  delectus totam sed",
    description:
      "L'IFA lance sa quatrième édition du Prix de la Nouvelle Fantastique d'expression française en Algérie. Candidatures ouvertes jusqu'au 25 janvier 2023",
    image: "https://images.unsplash.com/photo-1547595964-00ce56a24b66?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    time: "past",
    type: "danse",
  },
  {
    region: "alger",
    date: "28 mars 2022",
    title:
      "a At repellat reprehenderit. Sed obcaecati quia  delectus totam ",
    description:
      "L'IFA lance sa quatrième édition du Prix de la Nouvelle Fantastique d'expression française en Algérie. Candidatures ouvertes jusqu'au 25 janvier 2023",
    image: "https://images.unsplash.com/photo-1477959858617-67f85cf4f1df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=944&q=80",
    time: "past",
    type: "danse",
  },
  {
    region: "Annaba",
    date: "10 Février 2022",
    title:
      "Exposition « Abd el-Kader » du 15 janvier au 22 février 2023 au Mucem",
    description:
      "Emir de la résistance, saint combattant, fondateur de l’Etat algérien, précurseur de la codification du droit...",
    image: "https://images.unsplash.com/photo-1549383028-df014fa3a325?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80",
    time: "past",
    type: "expo",
  },
  {
    region: "alger",
    date: "17 Mai 2022",
    title:
      "Aut quos neque eos reiciendis asperiores non repellendus suscipit ut labore quasi",
    description:
      "Emir de la résistance, saint combattant, fondateur de l’Etat algérien, précurseur de la codification du droit...",
    image: "https://images.unsplash.com/photo-1575438922789-6a13602c790e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "past",
    type: "expo",
  },
  {
    region: "tlemcen",
    date: "03 Avril 2022",
    title:
      " Passage classique «Lorem ipsum dolor sit amet…»",
    description:
      "Selon le professeur latin Richard McClintock, l'homme reconnu pour avoir découvert les racines de Lorem Ipsum...",
    image: "https://images.unsplash.com/photo-1458560871784-56d23406c091?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
    time: "past",
    type: "musique",
  },
  {
    region: "alger",
    date: "28 Juillet 2022",
    title:" Passage classique «Lorem ipsum dolor site amet…»",
    description:"Selon le professeur latin Richard McClintock, l'homme reconnu pour avoir découvert les racines de Lorem Ipsum...",
    image: "https://images.unsplash.com/photo-1505740420928-5e560c06d30e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "past",
    type: "musique",
  },
  {
    region: "alger",
    date: "10 Septembre 2022",
    title:" Passage classique «Lorem ipsum doloor site amet…»",
    description:"Selon le professeur latin Richard McClintock, l'homme reconnu pour avoir découvert les racines de Lorem Ipsum...",
    image: "https://images.unsplash.com/photo-1516280440614-37939bbacd81?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "past",
    type: "musique",
  },
  {
    region: "annaba",
    date: "18 Novembre 2022",
    title: "LANCEMENT DU PROJET IMMOBILIER « GRAND LIAD »",
    description:"L’Ambassade de France à Alger est heureuse d’annoncer le lancement officiel du projet immobilier «Grand LIAD»,",
    image: "https://images.unsplash.com/photo-1448749927985-5565d99c10ae?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    time: "past",
    type: "jeunesse",
  },
  {
    region: "alger",
    date: "01 Novembre 2022",
    title: "A animi consequatur eum facere molestiae est voluptatibus expedita",
    description:"L’Ambassade de France à Alger est heureuse d’annoncer le lancement officiel du projet immobilier «Grand LIAD»,",
    image: "https://images.unsplash.com/photo-1567168544813-cc03465b4fa8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
    time: "past",
    type: "jeunesse",
  },
];
export { futurEvents, pastEvents, Events };
