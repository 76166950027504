import React from 'react';
import { Post } from './Post.js';
import { getPosts } from '../../services/posts.js';
import {Col,Row} from "react-bootstrap";
import EventCard from 'components/General/EventCard';
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import CoverImg from 'components/General/CoverImg'
import { Card, Container ,Button,Badge} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'
import culture from "../../assets/img/culture.jpg"
import Filters from 'components/Agenda Culturel/Filters'
import EventsGrid from 'components/Agenda Culturel/EventsGrid'
import { ShimmerTitle,ShimmerSimpleGallery } from 'react-shimmer-effects';
export const PostsSite = () => {
    const [posts, setPosts] = React.useState();

    React.useEffect(() => {
        function fetchData() {
            getPosts().then((results)=>{
            
      setPosts(results.items);
                
      
    })}
        
        fetchData();
    }, []);

    if (!posts) {
        return <div><ShimmerSimpleGallery card imageHeight={300} caption /></div>
    }

    return (
        <>
            {posts.length === 0 ? (
                <>No Post found</>
            ) : (
                <>
    <CoverImg img={culture}/>
    <Breadcrumb/>
    <Container>
    <MainTitle>Articles</MainTitle>
    {/* Evenements a venir */}
    <div className='my-5'>
      <h2 className='my-4 text-start'>Tous les articles</h2>
                    <Row >
                    {posts.map(post => (
                            <>
          <Col lg={4} md={6} key={post.id}>
            <EventCard key={post.id} event={post} />
          </Col>
                            {//<Post key={post.id} post={post} withLink={true} />
                            }
</>
                            
                    ))}
                    </Row>
                    </div>
    </Container>
    </>
            )}
        </>
    );
}