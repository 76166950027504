import React, { useState } from "react";
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import { Container } from "../../components/misc/Layouts";
import { SectionHeading } from "../../components/misc/Headings";
import { PrimaryButton } from "../../components/misc/Buttons";
import MainHeader from "../header/MainHeader";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
// import img1 from "../../assets/img/alger4.jpg";
import {
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
  Button,
  Carousel,
  Row,
  Col,
  Image,
} from "react-bootstrap";

import Breadcrumb from "components/breadcrumb/breadcrumbtest";
// import "./cover.css";
// import './gallery.scss';
import img1 from "../../assets/img/media.png";
import BgShape from 'components/General/BgShape'
import MainTitle from "components/General/MainTitle.js";
import SocialSection from "components/General/SocialSection.js";
import CoverImg from "components/General/CoverImg.js";
import MediathequeCityCard from "components/Mediatheque/MediathequeCityCard.js";
export default ({
  headingText = "MÉDIATHÈQUES",
}) => {
  
  return (
  <div className=" position-relative overflow-hidden">
    {/*<BgShape type="red"  right="-40px" top="80px" rotate="-140deg"/>*/}
    {/*<BgShape type="yellow"  right="-50px" top="800px" rotate="-150deg"/>*/}
    {/*<BgShape type="blue"  left="-80px" top="500px" rotate="140deg"/>*/}
  
        <CoverImg img={img1}/>
        <Breadcrumb/>
      <Container className="mb-3 container" style={{"text-decoration": "none"}}>
        <MainTitle>MEDIATHEQUES</MainTitle>
        <Col className="mt-5" md={12} style={{ textAlign: "initial" }}>
            {/* <Row style={{ marginBottom: "24px" }}>
              
              <a  href="https://www.decalog.net/" className=" col-sm-12 col-md-6" target="_blank" rel="noopener noreferrer">
                <Col style={{ marginBottom:"20px" }} className="hover hover-custom">
                      <div
                        style={{
                          textAlign: "initial",
                          position: "relative",
                          height: "12.5rem",
                          overflow: "hidden",
                        }}
                      >
                        <div className="hover-overlay"></div>
                        <div className="hover-custom-content d-flex align-items-center justify-content-center">
                        <h2 className="hover-custom-title fw-bold "
                          style={{
                            position:'absolute',
                            top:'-100px',
                            fontSize:"2.8rem"
                          }}
                        >
                          CATALOGUE EN LIGNE
                        </h2>
                        <p className="hover-custom-description small text-uppercase mb-0"></p>
                        </div>
                      </div>
                    
                </Col>
              </a>
            </Row> */}
            <Row>
            <Col md={4}>
                  <a  href="https://www.culturetheque.com/DZA/" className=" col-sm-12 col-md-6" target="_blank" rel="noopener noreferrer">
                    <Col  style={{ marginBottom:"20px" }} className="hover hover-custom">
                      
                      
                        <div
                          className="cultureteque-img"
                          style={{
                            textAlign: "initial",
                            position: "relative",
                            height: "12.5rem",
                            overflow: "hidden",
                          }}
                        >
                          <div className="hover-overlay"></div>
                          <div className="hover-custom-content">
                          <span className="hover-custom-title"
                            style={{
                              textAlign: "initial",
                              position: "absolute",
                              bottom: "0",
                              left: "20px",
                              color: "white",
                              fontSize: "1.5rem",
                            }}
                          >
                            
                          </span>
                          <p className="hover-custom-description small text-uppercase mb-0"></p>
                          </div>
                        </div>
                    
                    </Col>
                  </a>
                </Col>
                <Col>
                  <a  href="https://www.decalog.net/" className=" col-sm-12 col-md-6" target="_blank" rel="noopener noreferrer">
                    <Col style={{ marginBottom:"20px" }} className="hover hover-custom">
                          <div
                            style={{
                              textAlign: "initial",
                              position: "relative",
                              height: "12.5rem",
                              overflow: "hidden",
                            }}
                          >
                            <div className="hover-overlay"></div>
                            <div className="hover-custom-content d-flex align-items-center justify-content-center">
                            <h2 className="hover-custom-title fw-bold "
                              style={{
                                position:'absolute',
                                top:'-100px',
                                fontSize:"2.8rem"
                              }}
                            >
                              CATALOGUE EN LIGNE
                            </h2>
                            <p className="hover-custom-description small text-uppercase mb-0"></p>
                            </div>
                          </div>
                        
                    </Col>
                  </a>
                </Col>
            </Row>
            <Row>
                
                <Col md={4} style={{ marginBottom:"20px" }} className="hover hover-custom">
                    <Link to="/alger/mediatheque" className="">
                        <div
                          className="region-img-alger"
                          style={{
                            textAlign: "initial",
                            position: "relative",
                            height: "200px",
                            overflow: "hidden",
                          }}
                        >
                          <div className="hover-overlay"></div>
                          <div className="hover-custom-content">
                          <span className="hover-custom-title"
                            style={{
                              textAlign: "initial",
                              position: "absolute",
                              bottom: "0",
                              left: "20px",
                              color: "white",
                              fontSize: "1.5rem",
                            }}
                          >
                            Alger
                          </span>
                          <p className="hover-custom-description small text-uppercase mb-0"></p>
                          </div>
                        </div>
                  </Link>
                </Col>
                <Col md={4} style={{ marginBottom:"20px" }} className="hover hover-custom">
                    <Link to="/annaba/mediatheque" className="">
                    
                      
                        <div
                          className="region-img-annaba"
                          style={{
                            textAlign: "initial",
                            position: "relative",
                            height: "200px",
                            overflow: "hidden",
                          }}
                        >
                          <div className="hover-overlay"></div>
                          <div className="hover-custom-content">
                          <span className="hover-custom-title"
                            style={{
                              textAlign: "initial",
                              position: "absolute",
                              bottom: "0",
                              left: "20px",
                              color: "white",
                              fontSize: "1.5rem",
                            }}
                          >
                            Annaba
                          </span>
                          <p className="hover-custom-description small text-uppercase mb-0"></p>
                          </div>
                        </div>
                      
                    </Link>
                </Col>
                <Col md={4} style={{ marginBottom:"20px" }} className="hover hover-custom">
                  <Link to="/constantine/mediatheque" className="">
                      <div
                        className="region-img-constantine"
                        style={{
                          textAlign: "initial",
                          position: "relative",
                          height: "200px",
                          overflow: "hidden",
                        }}
                      >
                        <div className="hover-overlay"></div>
                        <div className="hover-custom-content">
                        <span className="hover-custom-title"
                          style={{
                            textAlign: "initial",
                            position: "absolute",
                            bottom: "0",
                            left: "20px",
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        >
                          Constantine
                        </span>
                        <p className="hover-custom-description small text-uppercase mb-0"></p>
                        </div>
                      </div>
                  </Link>
                </Col>
                <Col md={4} style={{ marginBottom:"20px" }} className="hover hover-custom">
                  <Link to="/oran/mediatheque" className="">
                      <div
                        className="region-img-oran"
                        style={{
                          textAlign: "initial",
                          position: "relative",
                          height: "200px",
                          overflow: "hidden",
                        }}
                      >
                        <div className="hover-overlay"></div>
                        <div className="hover-custom-content">
                        <span className="hover-custom-title"
                          style={{
                            textAlign: "initial",
                            position: "absolute",
                            bottom: "0",
                            left: "20px",
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        >
                          Oran
                        </span>
                        <p className="hover-custom-description small text-uppercase mb-0"></p>
                        </div>
                      </div>
                    
                  </Link>
                </Col>
                <Col md={4} style={{ marginBottom:"20px" }} className="hover hover-custom">
                  <Link to="/tlemcen/mediatheque" className="">
                      <div
                        className="region-img-tlemcen"
                        style={{
                          textAlign: "initial",
                          position: "relative",
                          height: "200px",
                          overflow: "hidden",
                        }}
                      >
                        <div className="hover-overlay"></div>
                        <div className="hover-custom-content">
                        <span className="hover-custom-title"
                          style={{
                            textAlign: "initial",
                            position: "absolute",
                            bottom: "0",
                            left: "20px",
                            color: "white",
                            fontSize: "1.5rem",
                          }}
                        >
                          Tlemcen
                        </span>
                        <p className="hover-custom-description small text-uppercase mb-0"></p>
                        </div>
                      </div>
                    
                  </Link>
                </Col>
                
                
            </Row>
        </Col>
      </Container>
      <SocialSection institut={true} ville="national"/>
  </div>
  );
};
