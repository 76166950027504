import HomeFirstSlide from './HomeFirstSlide';
import HomeSimpleSlide from './HomeSimpleSlide';
import HomeSecondSlide from "./HomeSecondSlide"
import Carousel from 'react-bootstrap/Carousel';
import styles from "./homeSlider.module.css"

function HomeSlide() {
    const carouselImgs = [
        
        {
          img:"https://images.unsplash.com/photo-1507537509458-b8312d35a233?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
          type:"campus france"
        },
        {
          img:"https://images.unsplash.com/photo-1491308056676-205b7c9a7dc1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=873&q=80",
          type:"campus france"
        },
        {
          img:"https://images.unsplash.com/photo-1536300099515-6c61b290b654?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
          type:"france alumni"
        },
        {
          img:"https://images.unsplash.com/photo-1596495868845-63031cb496da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
          type:"france alumni"
        },
        {
          img:"https://images.pexels.com/photos/2781814/pexels-photo-2781814.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          type:"liad"
        },
        {
          img:"https://images.pexels.com/photos/2982449/pexels-photo-2982449.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          type:"liad"
        },
        {
          img:"https://images.pexels.com/photos/10643463/pexels-photo-10643463.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          type:"peh"
        },
        {
          img:"https://images.pexels.com/photos/8363029/pexels-photo-8363029.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          type:"peh"
        },
        {
          img:"https://images.unsplash.com/photo-1574958269340-fa927503f3dd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=748&q=80",
          type:"esaa"
        },
        {
          img:"https://images.unsplash.com/photo-1527891751199-7225231a68dd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
          type:"esaa"
        }
    
    
                            
    ]
    const carouselList = carouselImgs.map((item,index)=>{
        return (
          <Carousel.Item className={styles.carousel}>
            <img
              className={` ${styles.carousel_img}` } 
              src={item.img}
              alt={`slide ${index}`}
            
            />
          </Carousel.Item>
        );
    })
  return (
    <Carousel fade>
      <Carousel.Item>
        <HomeSimpleSlide/>
      </Carousel.Item>
      <Carousel.Item>
        {/* <img
          className="d-block w-100"
          src="https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=974&q=80"
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
        <HomeFirstSlide/>
      </Carousel.Item>
      <Carousel.Item>
        <HomeSecondSlide/>
      </Carousel.Item>
      {/* <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://images.unsplash.com/photo-1444464666168-49d633b86797?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"
          alt="Second slide"
        />

        <Carousel.Caption>
          <h3>Second slide label</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="https://images.unsplash.com/photo-1446329813274-7c9036bd9a1f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          alt="Third slide"
        />

        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item> */}
    </Carousel>
    // <Carousel fade className='h-100'>
    //     {carouselList}
    // </Carousel>
  );
}

export default HomeSlide;