import React from 'react'
import mediatheques from 'data/mediatheque/Mediatheque-villes'
import alger from "../../assets/img/alger.jpg"
import CoverImg from 'components/General/CoverImg'
import { Container,Row,Col,Accordion } from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import cultureteque from "../../assets/img/cultureteque.png";
import SideBanner from 'components/Mediatheque city/SideBanner'
import ContactCard from 'components/Mediatheque city/ContactCard'
import HorairesCard from 'components/Mediatheque city/HorairesCard'
import MainTitle from 'components/General/MainTitle'
import BgShape from 'components/General/BgShape'


function MediathequeCulturethequePage({ville}) {
    const regionContent = mediatheques[0]
    const cityInfo = mediatheques.filter(el=>el.region===ville)[0]
    const culturethequeVille = cityInfo.culturetheque

     // vérifier si la ville actuelle commence par un voyelle 
    const accentCities = ["alger","oran","annaba"]
    const isAccent = ()=>accentCities.indexOf(ville) !== -1

  return (
    <div className='position-relative overflow-hidden'>
      {/*<BgShape type="blue"  right="-40px" top="100px" rotate="-140deg"/>*/}
      {/*<BgShape type="red"  right="-50px" top="800px" rotate="-150deg"/>*/}
      {/*<BgShape type="yellow"  left="-80px" top="500px" rotate="140deg"/>*/}
      <CoverImg img={alger} />
      <Breadcrumb />
      <Container className="my-5">
        <Row>
            <Col>
                <MainTitle >Médiathèques {isAccent() ? "d'" : 'de '}{ville[0].toUpperCase() + ville.slice(1)}</MainTitle>
                <Accordion  alwaysOpen>
                  <Accordion.Item>
                    <Accordion.Header>
                      <h3>Culturethèque</h3>
                    </Accordion.Header>
                    <Accordion.Body>
                    <div className='text-start text-black-50 lh-lg' style={{fontSize:"1.1rem"}} >
                      <p className='lh-lg'>
                          {culturethequeVille}<a href="https://www.culturetheque.com/DZA/" target="_blank"> www.culturetheque.com</a>
                      </p>
                    </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
            </Col>
            <Col md={4}>
              <SideBanner link="https://www.culturetheque.com/DZA/" img={cultureteque}/>
              <SideBanner link={ville!=='constantine'?"https://www.decalog.net/":'https://ifconstantine.bibenligne.fr'} text="Catalogue en ligne" imgStyle={{width:"100%"}}/>
              <ContactCard contact ={cityInfo.contact}/>
              <HorairesCard ville={ville} horaires={cityInfo.horaires}/>
            </Col>
        </Row>
      </Container>
    </div>
  )
}

export default MediathequeCulturethequePage