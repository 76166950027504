import React from 'react'

const DecalogCard = () => {
  return (
    <a  href="https://www.decalog.net/" className=" col-sm-12 col-md-6" target="_blank" rel="noopener noreferrer">
    <div style={{ marginBottom:"20px" }} className="hover hover-custom">
          <div
            style={{
              textAlign: "initial",
              position: "relative",
              height: "12.5rem",
              overflow: "hidden",
            }}
          >
            <div className="hover-overlay"></div>
            <div className="hover-custom-content d-flex align-items-center justify-content-center">
            <h2 className="hover-custom-title fw-bold "
              style={{
                position:'absolute',
                top:'-120px',
                fontSize:"2.8rem",
                textAlign:'center'
              }}
            >
              CATALOGUE EN LIGNE
            </h2>
            <p className="hover-custom-description small text-uppercase mb-0"></p>
            </div>
          </div>
        
    </div>
  </a>
  )
}

export default DecalogCard