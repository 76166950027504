import React, { useContext, useEffect, useState } from 'react'
import { Container} from "react-bootstrap";
import Bandeau from 'components/Etudes/Bandeau';
import Breadcrumb from 'components/breadcrumb/breadcrumb';
import MainTitle from 'components/General/MainTitle';
import BgShape from 'components/General/BgShape';
import { useParams } from 'react-router-dom';
import samples from 'data/SamplePages';
import CoverImg from 'components/General/CoverImg';
import { getTests } from 'services/tests-exams';
import { getAsset } from 'services/assets';
import { useLocation, useNavigate } from 'react-router-dom/umd/react-router-dom.development';
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext';
function ResultatPage({region}) {
    const {action_url} = useParams()
    const selectedAction = samples[0]
    const {test_title} =  useParams()
    const { language } = useContext(LanguageContext);
  const navigate = useNavigate()
  const location = useLocation()
  const [loading, setLoading] = React.useState(true);
    React.useEffect(()=>{
        window.scrollTo(0, 0)
    },[])
    const [test,setTest] = useState()
    useEffect( () => {
      function fetchData() {
        //console.log(location.pathname.split('/').reverse())
        var type=location.pathname.split('/').reverse()[2]
        //console.log(type)
        getTests({region:region}).then((results)=>{
          //console.log(region)
          //console.log(results)
          //console.log(test_title)
          //console.log(results?.items?.[0]?.data?.tests?.[language])
          if(type==='delf-et-dalf'){
          //console.log(results?.items?.[0]?.data?.tests?.[language][0])
          var xx=results?.items?.[0]?.data?.tests?.[language];}
          else{
            var xx=results?.items?.[0]?.data?.tests?.[language]?.filter(e=>e.title===type.toUpperCase());
          }
          //console.log(xx)
          xx[0]?.subtests?.filter(x=>x.title===test_title)?.map(e => {
              //console.log(e)
              var img = e?.Image?.iv? e?.Image?.iv[0]: e?.Image?.[language]?e?.Image?.[language][0]:e?.Image?e?.Image[0]:''; //todo default image
              setTest(e );
              setLoading(false)
      //setExams(results.items?.[0]?.data?.tests)
    
    });});
          
      }
      //console.log("xxx")
      fetchData();
    }, [region,test_title,location]);
    try{
      var text = parse(test?.resultats)
    }catch($){
      text = test?.resultats
    }
    //console.log(test?.resultats)
  return loading? <ShimmerPostDetails card cta variant="SIMPLE" /> :(
    <div className=" position-relative overflow-hidden" dir={language==='ar'?"rtl":""}>
            {/*<BgShape type="yellow"  right="-50px" top="400px" rotate="-150deg"/>
            <BgShape type="blue"  left="-80px" top="900px" rotate="140deg"/>
            <BgShape type="red"  right="-60px" top="1300px" rotate="-140deg"/>*/}
            <CoverImg img={selectedAction.img}/>
            <Breadcrumb/>
            <Container >
              <MainTitle className="my-4">{`Inscription - ${test_title}`??'Resultats'}</MainTitle>
              {text?text:<></>}
            </Container>

            
    </div>
    
  )
}

export default ResultatPage