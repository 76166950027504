import React,{useState} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Image, Row, Button,Container } from "react-bootstrap";
import hml from "../../assets/img/homolo.png";
import liad from "../../assets/img/liad.png";
import embassade from "../../assets/img/Ambassade.png";
import compus from "../../assets/img/Campus.png";
import { HiMail } from 'react-icons/hi';
import { MdWork } from 'react-icons/md';
import { Link } from "react-router-dom";
import NewsLetterForm from "./NewsLetterForm";
import Modal from "./Modal";
import SocialMediaIcons from "../../components/General/SocialMediaIcons"
import NewsLetterModal from "./NewsLetterModal";

export default function NewsLetter() {

  const [isOpen,setIsOpen] = useState(false)

  return (
    <div style={{backgroundColor: "whitesmoke"}}>
    <Container  className=" pb-3"  >
    <Row
    
      style={{
        padding: "60px 36px 30px ",
        backgroundColor: "whitesmoke",
        alignItems: "end",
      }}
      
    className="gap-3 mb-2 flex-column flex-md-row align-items-center justify-content-center">
    

      <Col className="d-flex justify-content-center">
        <Row>
        <Button
          variant="primary"
          size="lg"
          style={{
            borderRadius: "0",
            backgroundColor: "#3558a2",
            whiteSpace: "nowrap",
            // width: "100%",
            height: "80px",
            padding:"0.5rem",
            margin:"0 auto"
          }}
          onClick={()=>setIsOpen(true)}
        >
           <HiMail/> S’abonner à la newsletter
        </Button>
        </Row>
      </Col>
      <Col className="d-flex justify-content-center">
      <Row>
        <a href="/institut/recrutement">
        <Button
          variant="primary"
          size="lg"
          style={{
            borderRadius: "0",
            backgroundColor: "#3558a2",
            whiteSpace: "nowrap",
            // width: "100%",
            height: "80px",
            padding:"0.5rem",
            margin:"0 auto"
          }}
        >
           <MdWork/> Rejoignez-nous !
        </Button>
        </a>
        </Row>
      </Col>
      
      
    </Row>
      <NewsLetterModal open={isOpen} onClose={()=>setIsOpen(false)}>
          <NewsLetterForm onClose={()=>setIsOpen(false)}/>
      </NewsLetterModal>
    </Container>
    </div>
  );
}
