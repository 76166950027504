import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Image, Row,Container } from "react-bootstrap";
import {FaPhoneSquareAlt}from 'react-icons/fa';
import {ImLocation2} from 'react-icons/im';
import { Link } from "react-router-dom";
import {Nav} from "react-bootstrap";
import fb from "../../assets/img/icons/lfb.png"
import ig from "../../assets/img/icons/insta.png"
import tiktok from "../../assets/img/icons/ltiktok.png"
import ytb from "../../assets/img/icons/lytb.png"
import styles from "./footer.module.css"
import NewsLetterModal from "pages/newsletter/NewsLetterModal";
import Contact from "pages/Credit/Contact";


export default function Footer() {
  const [isOpen,setIsOpen] = useState(false)
  // get the width of the srcreen to change the logo for tablet and mobile 
  const [windowSize, setWindowSize] = useState(getWindowSize());
  // redirect to the credit page
  const location = useLocation()
  const cities = ["alger","annaba","oran","constantine","tlemcen"]
  const currentCity = location.pathname.split('/')[1]

  const footerPage = (page)=>{
    if (cities.includes(currentCity)) {
      return `/${currentCity}/${page}`
    } else{
      return `/${page}`
    }
  }
  useEffect(() => {
    
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowSize() {
    return window.innerWidth ;
  }

  return (
    
   // ========== NEW IMPROVED FOOTER ============
   <div style={{ backgroundColor: "#141414" }}>
   
   <Container fluid className="container-fluid container-xxl">
    <Row className={styles["wrapper"]}>
      {/* logo */}
      <Col lg={3} className="text-start mb-4">
          <Link to="/">
            {footerSvgMobile(windowSize)}
          </Link>
      </Col>
      {/* links + social media */}
      <Col lg={9} style={{ textAlign: "initial" }} className="my-3 my-lg-0">
        <Row className="align-items-center ">
          {/* links */}
          <Col  lg={8} className="px-0 ">
            <Nav className=" d-flex flex-column flex-md-row ">
                <span onClick={()=>setIsOpen(true)} className="px-0  px-md-3 px-xxl-2" style={{
                    //fontFamily: "Inter",
                    color: "#FFFFFF",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "18px",
                    cursor:"pointer"
                  }}>
                  Nous contacter
                </span>
                <Nav.Link style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}>|</Nav.Link>
                <Link to={footerPage("mentions-legales")} className="px-0  px-md-3 px-xxl-2" style={{
                    //fontFamily: "Inter",
                    color: "#FFFFFF",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "18px",
                  }}
                >
                  Mentions Légales

                </Link>
                <Nav.Link style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}>|</Nav.Link>
                <Link to={footerPage("partenaires")} className="px-0  px-md-3 px-xxl-2" style={{
                    //fontFamily: "Inter",
                    color: "#FFFFFF",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "18px",
                  }}
                >
                  Partenaires
                  </Link>
                  <Nav.Link style={{
                  //fontFamily: "Inter",
                  color: "#FFFFFF",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                }}>|</Nav.Link>
                <Link to={footerPage("liens-utiles")} className="px-0  px-md-3 px-xxl-2" style={{
                    //fontFamily: "Inter",
                    color: "#FFFFFF",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "18px",
                  }}
                >
                  Liens utiles
                
                </Link>
            </Nav>
        </Col>
        {/* social media */}
        <Col className="d-none d-lg-block px-0" lg={4}>
          <Nav className="float-lg-end">
        <Nav.Link href="https://www.facebook.com/IFAlgerie/" target="_blank">
                <span>
                  <img src={fb} width="30"></img>
                </span>
              </Nav.Link>
              <Nav.Link href="https://www.tiktok.com/@ifalgerie">
                <span>
                  <img src={tiktok} width="30"></img>
                </span>
              </Nav.Link>
              <Nav.Link href=" https://instagram.com/ifalgerie" target="_blank">
                <span>
                  <img src={ig} width="30"></img>
                </span>
              </Nav.Link>
              <Nav.Link href="https://youtube.com/@institutfrancaisdalgerie5536" target="_blank">
                <span>
                  <img src={ytb} width="30"></img>
                </span>
              </Nav.Link>
          </Nav>
        </Col>
        </Row>
        {/* addresse + telephone*/}
        <Row className="mt-2 d-flex align-items-center">
          {/* adresse */}
          <Col lg={8} className="px-0 px-lg-3">  
        <p
          style={{
            //fontFamily: "Inter",
            color: "#FFFFFF",
            fontStyle: "normal",
            fontWeight: "300",
            fontSize: "18px",
          }}
        ><ImLocation2 className="me-1"/>
          Institut français d'Algerie - 7, rue Hassani Issad - 16000 Alger -
          ALGÉRIE 
        </p>
        </Col>
        {/* telephone */}
        <Col  lg={4} className="px-0 px-lg-1" >
        <p className="float-lg-end" style={{
            //fontFamily: "Inter",
            color: "#FFFFFF",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "20px",
          }}>
        <FaPhoneSquareAlt/> 213 (0) 21 73 78 20/21
        </p>
        </Col>
        </Row>
        {/* social media hidden */}
        <Row className="d-lg-none text-white">
        <Col className=" d-lg-none px-0 mt-2  d-flex" >
          <Nav className="mx-auto">
        <Nav.Link href="https://www.facebook.com/IFAlgerie/" target="_blank">
                <span>
                  <img src={fb} width="30" ></img>
                </span>
              </Nav.Link>
              <Nav.Link href="https://www.tiktok.com/@ifalgerie">
                <span>
                  <img src={tiktok} width="30"></img>
                </span>
              </Nav.Link>
              <Nav.Link href=" https://instagram.com/ifalgerie" target="_blank">
                <span>
                  <img src={ig} width="30"></img>
                </span>
              </Nav.Link>
              <Nav.Link href="https://youtube.com/@institutfrancaisdalgerie5536" target="_blank">
                <span>
                  <img src={ytb} width="30"></img>
                </span>
              </Nav.Link>
          </Nav>
        </Col>
        </Row>
      </Col>
    </Row>
      <NewsLetterModal open={isOpen} onClose={()=>setIsOpen(false)}>
          <Contact onClose={()=>setIsOpen(false)}/>
      </NewsLetterModal>
    </Container>
    </div>
  );
}


const footerSvgMobile = (windowSize)=>{
  const setLogoWidth = (windowSize)=>{
    if (windowSize <400 ) {
      return "250px"
    } else if(windowSize<992) {
      return "360px"
    } else{
      return "95%"
    }
  }
  return (
    <svg  width={setLogoWidth(windowSize)} viewBox="0 0 861 260" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M860.998 260L860.882 0H413.59V260H860.998Z" fill="white" />
    <path d="M485.663 46.7412H476.34V93.0342H485.663V46.7412Z" fill="#040405" />
    <path
      d="M617.554 56.593V46.746H584.534V56.592H596.382V112.134H605.702V56.593H617.554Z"
      fill="#040405"
    />
    <path
      d="M636.377 46.7412H627.054V93.0342H636.377V46.7412Z"
      fill="#040405"
    />
    <path
      d="M726.751 95.5753V46.7363H717.525V95.8773C717.525 99.3053 716.655 107.277 708.592 107.277C700.392 107.277 699.742 98.4773 699.742 95.7773V46.7363H690.422V96.1683C690.422 108.682 697.892 117.422 708.592 117.422C709.246 117.422 709.872 117.377 710.492 117.333L695.802 181.717H705.688L709.368 163.426H723.068L726.825 181.717H737.002L721.237 112.239C724.767 108.459 726.751 102.744 726.751 95.5753ZM711.263 153.886L713.613 142.412C714.344 138.727 715.359 132.99 715.985 129.478H716.445C717.066 133.006 718.096 138.8 718.811 142.412L721.161 153.886H711.263Z"
      fill="#040405"
    />
    <path
      d="M767.001 46.7383H733.982V56.5713H745.834V116.835H755.151V56.5713H767.006L767.001 46.7383Z"
      fill="#040405"
    />
    <path
      d="M542.136 149.72C548.675 146.773 551.994 140.645 551.994 131.496C551.994 119.764 545.655 112.624 534.994 112.165V46.7422H525.856V77.3792C525.856 79.9742 525.917 85.4932 525.989 88.9882L525.535 89.1002L524.776 87.1482C523.79 84.5762 522.615 81.5562 521.845 79.7842L508.082 46.7422H498.673V112.132H476.338V181.716H485.664V150.173H499.534V140.527H485.664V121.873H507.802V81.5232C507.802 78.9402 507.752 73.4092 507.675 69.9142L508.14 69.8022L508.888 71.7702C509.888 74.3372 511.055 77.3512 511.814 79.1232L525.496 112.131H515.671V181.715H524.982V151.543L532.202 151.722L541.722 181.716H552.239L541.927 149.792L542.136 149.72ZM533.192 141.908H524.982V121.873H533.189C539.129 121.873 542.404 125.39 542.404 131.787C542.404 138.503 539.295 141.908 533.189 141.908"
      fill="#040405"
    />
    <path
      d="M574.109 112.132C577.748 108.395 579.784 103.385 579.782 98.1696C579.782 86.9916 571.974 81.1146 564.1 75.6516C556.8 70.6186 554.757 68.5896 554.757 63.5786C554.757 59.2896 557.976 55.6786 562.088 55.6786C565.488 55.6786 568.366 57.7646 570.466 61.1136L577.448 55.0916C576.024 52.2421 573.832 49.8479 571.118 48.1798C568.405 46.5117 565.278 45.6363 562.093 45.6526C552.157 45.6526 545.076 54.4206 545.076 63.9526C545.076 75.0466 551.099 79.2966 558.226 84.3456C566.71 90.3796 569.868 93.0916 569.868 98.4986C569.868 102.989 566.41 107.384 561.606 107.384C558.098 107.384 555.666 105.175 551.615 99.8456L544.3 106.321C548.528 113.769 554.374 117.605 561.728 117.605C565.765 117.552 569.655 116.077 572.711 113.439L557.129 181.72H567.009L570.683 163.429H584.383L588.151 181.72H598.319L582.532 112.136L574.109 112.132ZM572.591 153.886L574.935 142.412C575.672 138.727 576.692 132.99 577.301 129.478H577.772C578.393 133.006 579.412 138.8 580.144 142.412L582.488 153.886H572.591Z"
      fill="#040405"
    />
    <path
      d="M632.897 142.764C632.897 145.375 632.952 150.889 633.019 154.389L632.559 154.495L631.811 152.543C630.819 149.982 629.644 146.957 628.885 145.173L615.12 112.131H605.705V181.715H614.843V146.936C614.843 144.336 614.782 138.817 614.716 135.316L615.17 135.21L615.924 137.156C616.924 139.723 618.085 142.756 618.855 144.532L634.271 181.717H642.034V112.132H632.896L632.897 142.764Z"
      fill="#040405"
    />
    <path
      d="M755.152 134.921H745.832V181.721H755.152V134.921Z"
      fill="#040405"
    />
    <path
      d="M784.492 141.047C777.2 136.003 775.155 133.996 775.155 128.969C775.155 124.697 778.374 121.079 782.486 121.079C785.886 121.079 788.77 123.159 790.865 126.503L797.853 120.481C796.428 117.633 794.234 115.241 791.521 113.574C788.808 111.907 785.682 111.032 782.498 111.048C772.557 111.048 765.481 119.822 765.481 129.361C765.481 140.438 771.504 144.694 778.636 149.732C787.114 155.777 789.447 158.293 789.447 163.717C789.447 168.196 785.989 172.586 781.185 172.586C777.672 172.586 775.239 170.386 771.185 165.054L763.865 171.535C768.093 178.972 773.95 182.802 781.304 182.802C790.879 182.802 799.347 174.102 799.347 163.382C799.347 152.21 792.376 146.506 784.491 141.048"
      fill="#040405"
    />
    <path
      d="M679.977 162.218C678.165 169.935 676.077 172.68 672.047 172.68C662.647 172.68 661.923 152.902 661.923 146.835C661.923 137.262 663.148 121.18 671.404 121.18C676.363 121.18 677.993 124.647 679.151 130.636L679.184 130.809L688.584 128.248L688.562 128.114C686.75 116.628 681.231 111.048 671.677 111.048C670.51 111.046 669.346 111.176 668.208 111.434V56.5723H680.055V46.7363H647.033V56.5723H658.882V118.043C654.621 124.116 652.321 133.974 652.321 147.025C652.321 166.093 657.053 178.294 665.692 181.784L662.777 196.244H671.942L674.442 182.606C682.067 181.443 686.6 175.851 688.642 165.176L688.675 165.008L680.025 162.044L679.977 162.218Z"
      fill="#040405"
    />
    <path
      d="M478.267 223.504H475.59L483.334 202.413H485.97L493.715 223.504H491.037L484.734 205.75H484.57L478.267 223.504ZM479.256 215.265H490.048V217.531H479.256V215.265Z"
      fill="#040405"
    />
    <path d="M499.101 202.413V223.504H496.67V202.413H499.101Z" fill="#040405" />
    <path
      d="M509.937 229.765C508.763 229.765 507.754 229.614 506.909 229.312C506.065 229.017 505.361 228.626 504.798 228.138C504.242 227.658 503.799 227.143 503.47 226.593L505.406 225.234C505.625 225.522 505.903 225.852 506.24 226.223C506.576 226.6 507.036 226.926 507.62 227.201C508.21 227.483 508.983 227.623 509.937 227.623C511.214 227.623 512.268 227.314 513.098 226.696C513.929 226.079 514.345 225.111 514.345 223.792V220.579H514.139C513.96 220.868 513.706 221.225 513.377 221.65C513.054 222.069 512.587 222.443 511.976 222.773C511.372 223.095 510.555 223.257 509.525 223.257C508.248 223.257 507.101 222.955 506.085 222.351C505.076 221.746 504.276 220.868 503.686 219.714C503.102 218.561 502.81 217.16 502.81 215.513C502.81 213.892 503.095 212.481 503.665 211.28C504.235 210.072 505.028 209.138 506.044 208.479C507.06 207.813 508.234 207.48 509.566 207.48C510.596 207.48 511.413 207.651 512.017 207.995C512.628 208.331 513.095 208.716 513.418 209.148C513.747 209.574 514.001 209.924 514.18 210.199H514.427V207.686H516.775V223.957C516.775 225.316 516.466 226.422 515.848 227.273C515.237 228.131 514.413 228.76 513.377 229.158C512.347 229.563 511.2 229.765 509.937 229.765ZM509.854 221.074C510.829 221.074 511.653 220.85 512.326 220.404C512.999 219.958 513.51 219.316 513.861 218.478C514.211 217.641 514.386 216.638 514.386 215.471C514.386 214.332 514.214 213.326 513.871 212.454C513.528 211.582 513.02 210.899 512.347 210.405C511.674 209.91 510.843 209.663 509.854 209.663C508.825 209.663 507.966 209.924 507.28 210.446C506.6 210.968 506.089 211.668 505.745 212.547C505.409 213.425 505.241 214.4 505.241 215.471C505.241 216.57 505.413 217.541 505.756 218.386C506.106 219.223 506.621 219.882 507.301 220.363C507.987 220.837 508.838 221.074 509.854 221.074Z"
      fill="#040405"
    />
    <path
      d="M527.856 223.834C526.332 223.834 525.017 223.497 523.912 222.824C522.813 222.145 521.965 221.197 521.368 219.982C520.778 218.76 520.482 217.339 520.482 215.718C520.482 214.098 520.778 212.67 521.368 211.434C521.965 210.192 522.796 209.224 523.86 208.53C524.931 207.83 526.181 207.48 527.609 207.48C528.433 207.48 529.246 207.617 530.049 207.892C530.853 208.166 531.584 208.613 532.243 209.231C532.902 209.842 533.427 210.652 533.819 211.661C534.21 212.67 534.406 213.913 534.406 215.389V216.419H522.212V214.318H531.934C531.934 213.425 531.756 212.629 531.399 211.929C531.048 211.228 530.547 210.676 529.895 210.271C529.25 209.866 528.488 209.663 527.609 209.663C526.641 209.663 525.803 209.903 525.096 210.384C524.396 210.858 523.857 211.476 523.479 212.238C523.102 213 522.913 213.817 522.913 214.689V216.089C522.913 217.284 523.119 218.296 523.531 219.127C523.949 219.951 524.53 220.579 525.271 221.012C526.013 221.437 526.874 221.65 527.856 221.65C528.494 221.65 529.071 221.561 529.586 221.383C530.108 221.197 530.558 220.923 530.935 220.559C531.313 220.188 531.605 219.728 531.81 219.179L534.159 219.838C533.911 220.634 533.496 221.334 532.912 221.939C532.329 222.536 531.608 223.003 530.75 223.339C529.892 223.669 528.927 223.834 527.856 223.834ZM526.373 205.544L528.886 200.765H531.728L528.515 205.544H526.373Z"
      fill="#040405"
    />
    <path
      d="M538.103 223.504V207.686H540.451V210.075H540.616C540.904 209.292 541.426 208.657 542.181 208.17C542.936 207.682 543.787 207.439 544.735 207.439C544.913 207.439 545.136 207.442 545.404 207.449C545.672 207.456 545.875 207.466 546.012 207.48V209.951C545.929 209.931 545.741 209.9 545.445 209.859C545.157 209.811 544.852 209.787 544.529 209.787C543.76 209.787 543.073 209.948 542.469 210.271C541.872 210.587 541.398 211.026 541.048 211.589C540.705 212.145 540.533 212.78 540.533 213.494V223.504H538.103Z"
      fill="#040405"
    />
    <path
      d="M548.893 223.504V207.686H551.323V223.504H548.893ZM550.129 205.049C549.655 205.049 549.246 204.888 548.903 204.565C548.567 204.243 548.398 203.855 548.398 203.402C548.398 202.949 548.567 202.561 548.903 202.238C549.246 201.915 549.655 201.754 550.129 201.754C550.602 201.754 551.007 201.915 551.344 202.238C551.687 202.561 551.859 202.949 551.859 203.402C551.859 203.855 551.687 204.243 551.344 204.565C551.007 204.888 550.602 205.049 550.129 205.049Z"
      fill="#040405"
    />
    <path
      d="M562.407 223.834C560.883 223.834 559.568 223.497 558.462 222.824C557.364 222.145 556.516 221.197 555.919 219.982C555.328 218.76 555.033 217.339 555.033 215.718C555.033 214.098 555.328 212.67 555.919 211.434C556.516 210.192 557.347 209.224 558.411 208.53C559.482 207.83 560.732 207.48 562.16 207.48C562.983 207.48 563.797 207.617 564.6 207.892C565.403 208.166 566.135 208.613 566.794 209.231C567.453 209.842 567.978 210.652 568.369 211.661C568.761 212.67 568.956 213.913 568.956 215.389V216.419H556.763V214.318H566.485C566.485 213.425 566.306 212.629 565.949 211.929C565.599 211.228 565.098 210.676 564.446 210.271C563.8 209.866 563.038 209.663 562.16 209.663C561.192 209.663 560.354 209.903 559.647 210.384C558.947 210.858 558.408 211.476 558.03 212.238C557.652 213 557.464 213.817 557.464 214.689V216.089C557.464 217.284 557.669 218.296 558.081 219.127C558.5 219.951 559.08 220.579 559.822 221.012C560.563 221.437 561.425 221.65 562.407 221.65C563.045 221.65 563.622 221.561 564.137 221.383C564.659 221.197 565.108 220.923 565.486 220.559C565.864 220.188 566.155 219.728 566.361 219.179L568.709 219.838C568.462 220.634 568.047 221.334 567.463 221.939C566.88 222.536 566.159 223.003 565.301 223.339C564.442 223.669 563.478 223.834 562.407 223.834Z"
      fill="#040405"
    />
    <path
      d="M164.5 59.7328L155.02 85.3691H151.014L141.534 59.7328H145.807L152.884 80.1618H153.151L160.227 59.7328H164.5Z"
      fill="white"
    />
    <path
      d="M194.178 85.3691V59.7328H198.117V85.3691H194.178ZM196.181 55.46C195.413 55.46 194.751 55.1986 194.195 54.6756C193.65 54.1526 193.377 53.524 193.377 52.7896C193.377 52.0552 193.65 51.4265 194.195 50.9036C194.751 50.3806 195.413 50.1191 196.181 50.1191C196.949 50.1191 197.605 50.3806 198.15 50.9036C198.707 51.4265 198.985 52.0552 198.985 52.7896C198.985 53.524 198.707 54.1526 198.15 54.6756C197.605 55.1986 196.949 55.46 196.181 55.46Z"
      fill="white"
    />
    <path
      d="M252.988 59.7328L243.508 85.3691H239.502L230.022 59.7328H234.295L241.372 80.1618H241.639L248.715 59.7328H252.988Z"
      fill="white"
    />
    <path
      d="M281.73 85.3691V59.7328H285.536V63.6049H285.803C286.27 62.3365 287.116 61.3072 288.34 60.5172C289.563 59.7272 290.943 59.3322 292.479 59.3322C292.768 59.3322 293.13 59.3378 293.564 59.3489C293.998 59.36 294.326 59.3767 294.548 59.399V63.4047C294.415 63.3713 294.109 63.3212 293.63 63.2544C293.163 63.1765 292.668 63.1376 292.145 63.1376C290.899 63.1376 289.786 63.3991 288.807 63.9221C287.839 64.4339 287.071 65.146 286.504 66.0584C285.947 66.9597 285.669 67.9889 285.669 69.1461V85.3691H281.73Z"
      fill="white"
    />
    <path
      d="M337.378 85.9032C334.908 85.9032 332.777 85.358 330.985 84.2676C329.205 83.166 327.831 81.6305 326.863 79.661C325.906 77.6805 325.427 75.3772 325.427 72.7512C325.427 70.1253 325.906 67.8109 326.863 65.8081C327.831 63.7941 329.177 62.2252 330.902 61.1014C332.638 59.9664 334.663 59.399 336.977 59.399C338.312 59.399 339.631 59.6215 340.933 60.0666C342.235 60.5117 343.42 61.2349 344.488 62.2363C345.556 63.2266 346.407 64.5396 347.041 66.1752C347.676 67.8109 347.993 69.8249 347.993 72.2171V73.8862H328.231V70.4814H343.987C343.987 69.0349 343.698 67.7441 343.119 66.6092C342.552 65.4743 341.739 64.5785 340.682 63.9221C339.636 63.2656 338.401 62.9373 336.977 62.9373C335.408 62.9373 334.051 63.3268 332.905 64.1056C331.77 64.8734 330.896 65.8748 330.284 67.1099C329.672 68.345 329.366 69.6691 329.366 71.0822V73.3521C329.366 75.2882 329.7 76.9294 330.368 78.2757C331.047 79.611 331.987 80.6291 333.188 81.3301C334.39 82.0199 335.787 82.3649 337.378 82.3649C338.413 82.3649 339.347 82.2202 340.182 81.9309C341.027 81.6305 341.756 81.1854 342.368 80.5957C342.98 79.9949 343.453 79.2494 343.787 78.3592L347.592 79.4274C347.192 80.7181 346.518 81.853 345.573 82.8322C344.627 83.8002 343.459 84.5569 342.068 85.1021C340.677 85.6362 339.114 85.9032 337.378 85.9032Z"
      fill="white"
    />
    <path d="M242.128 106.68V140.861H238.189V106.68H242.128Z" fill="white" />
    <path
      d="M286.732 141.395C284.262 141.395 282.131 140.85 280.339 139.76C278.559 138.658 277.185 137.123 276.217 135.153C275.26 133.173 274.781 130.869 274.781 128.243C274.781 125.617 275.26 123.303 276.217 121.3C277.185 119.286 278.531 117.717 280.256 116.594C281.992 115.459 284.017 114.891 286.331 114.891C287.666 114.891 288.985 115.114 290.287 115.559C291.589 116.004 292.774 116.727 293.842 117.729C294.91 118.719 295.761 120.032 296.395 121.667C297.03 123.303 297.347 125.317 297.347 127.709V129.378H277.585V125.974H293.341C293.341 124.527 293.052 123.236 292.473 122.101C291.906 120.966 291.093 120.071 290.036 119.414C288.99 118.758 287.755 118.43 286.331 118.43C284.762 118.43 283.405 118.819 282.259 119.598C281.124 120.366 280.25 121.367 279.638 122.602C279.026 123.837 278.72 125.161 278.72 126.574V128.844C278.72 130.78 279.054 132.422 279.722 133.768C280.401 135.103 281.341 136.121 282.542 136.822C283.744 137.512 285.141 137.857 286.732 137.857C287.767 137.857 288.701 137.712 289.536 137.423C290.381 137.123 291.11 136.678 291.722 136.088C292.334 135.487 292.807 134.742 293.141 133.851L296.946 134.92C296.546 136.21 295.872 137.345 294.927 138.324C293.981 139.292 292.813 140.049 291.422 140.594C290.031 141.128 288.468 141.395 286.732 141.395Z"
      fill="white"
    />
    <path
      d="M347.261 120.966L343.723 121.968C343.5 121.378 343.172 120.805 342.738 120.249C342.315 119.681 341.737 119.214 341.002 118.847C340.268 118.48 339.328 118.296 338.182 118.296C336.613 118.296 335.305 118.658 334.259 119.381C333.225 120.093 332.707 121 332.707 122.101C332.707 123.081 333.063 123.854 333.775 124.421C334.488 124.989 335.6 125.462 337.114 125.84L340.919 126.775C343.211 127.331 344.919 128.182 346.043 129.328C347.167 130.463 347.729 131.926 347.729 133.718C347.729 135.187 347.306 136.5 346.46 137.657C345.626 138.814 344.457 139.726 342.955 140.394C341.453 141.062 339.706 141.395 337.714 141.395C335.1 141.395 332.935 140.828 331.222 139.693C329.508 138.558 328.423 136.9 327.967 134.719L331.706 133.785C332.062 135.164 332.735 136.199 333.725 136.889C334.727 137.579 336.034 137.924 337.648 137.924C339.484 137.924 340.941 137.534 342.02 136.756C343.111 135.965 343.656 135.02 343.656 133.918C343.656 133.028 343.345 132.282 342.721 131.682C342.098 131.07 341.141 130.613 339.851 130.313L335.578 129.312C333.23 128.755 331.506 127.893 330.404 126.725C329.314 125.545 328.768 124.071 328.768 122.302C328.768 120.855 329.174 119.576 329.987 118.463C330.81 117.35 331.928 116.477 333.341 115.843C334.766 115.208 336.379 114.891 338.182 114.891C340.719 114.891 342.71 115.448 344.157 116.56C345.614 117.673 346.649 119.142 347.261 120.966Z"
      fill="white"
    />
    <path
      d="M12.0199 196.887C9.61651 196.887 7.54691 196.319 5.81111 195.184C4.07532 194.049 2.74009 192.486 1.80543 190.494C0.870772 188.503 0.403442 186.227 0.403442 183.668C0.403442 181.064 0.881899 178.766 1.83881 176.775C2.80685 174.772 4.15321 173.209 5.87787 172.085C7.61367 170.95 9.63876 170.382 11.9532 170.382C13.7557 170.382 15.3802 170.716 16.8267 171.384C18.2732 172.051 19.4582 172.986 20.3818 174.188C21.3053 175.389 21.8783 176.791 22.1009 178.394H18.162C17.8615 177.225 17.1939 176.191 16.1591 175.289C15.1355 174.377 13.7557 173.921 12.0199 173.921C10.4844 173.921 9.13805 174.321 7.98086 175.122C6.83479 175.912 5.93907 177.031 5.29371 178.477C4.65948 179.913 4.34236 181.598 4.34236 183.534C4.34236 185.515 4.65392 187.24 5.27702 188.708C5.91126 190.177 6.80141 191.318 7.94748 192.13C9.10467 192.942 10.4622 193.348 12.0199 193.348C13.0436 193.348 13.9727 193.17 14.8072 192.814C15.6417 192.458 16.3483 191.946 16.9269 191.279C17.5055 190.611 17.9172 189.81 18.162 188.875H22.1009C21.8783 190.389 21.3276 191.752 20.4485 192.964C19.5806 194.166 18.429 195.123 16.9936 195.835C15.5694 196.536 13.9115 196.887 12.0199 196.887Z"
      fill="white"
    />
    <path
      d="M70.9724 185.871V170.716H74.9113V196.353H70.9724V192.013H70.7054C70.1045 193.315 69.1698 194.422 67.9014 195.334C66.6329 196.236 65.0306 196.686 63.0946 196.686C61.4923 196.686 60.068 196.336 58.8218 195.635C57.5756 194.923 56.5964 193.855 55.8843 192.43C55.1722 190.995 54.8161 189.187 54.8161 187.006V170.716H58.7551V186.739C58.7551 188.608 59.278 190.099 60.324 191.212C61.381 192.325 62.7274 192.881 64.363 192.881C65.3422 192.881 66.338 192.631 67.3506 192.13C68.3743 191.629 69.231 190.861 69.9209 189.827C70.6219 188.792 70.9724 187.473 70.9724 185.871Z"
      fill="white"
    />
    <path d="M109.677 162.171V196.353H105.738V162.171H109.677Z" fill="white" />
    <path
      d="M154.729 170.716V174.054H141.444V170.716H154.729ZM145.316 164.574H149.255V189.009C149.255 190.121 149.416 190.956 149.739 191.512C150.073 192.058 150.496 192.425 151.007 192.614C151.53 192.792 152.081 192.881 152.66 192.881C153.094 192.881 153.45 192.859 153.728 192.814C154.006 192.759 154.229 192.714 154.395 192.681L155.197 196.219C154.93 196.319 154.557 196.419 154.078 196.519C153.6 196.631 152.993 196.686 152.259 196.686C151.146 196.686 150.056 196.447 148.988 195.969C147.931 195.49 147.052 194.761 146.351 193.782C145.661 192.803 145.316 191.568 145.316 190.077V164.574Z"
      fill="white"
    />
    <path
      d="M203.656 185.871V170.716H207.595V196.353H203.656V192.013H203.389C202.788 193.315 201.854 194.422 200.585 195.334C199.317 196.236 197.715 196.686 195.779 196.686C194.176 196.686 192.752 196.336 191.506 195.635C190.26 194.923 189.28 193.855 188.568 192.43C187.856 190.995 187.5 189.187 187.5 187.006V170.716H191.439V186.739C191.439 188.608 191.962 190.099 193.008 191.212C194.065 192.325 195.411 192.881 197.047 192.881C198.026 192.881 199.022 192.631 200.035 192.13C201.058 191.629 201.915 190.861 202.605 189.827C203.306 188.792 203.656 187.473 203.656 185.871Z"
      fill="white"
    />
    <path
      d="M234.682 196.353V170.716H238.488V174.588H238.755C239.222 173.32 240.068 172.291 241.292 171.501C242.515 170.711 243.895 170.316 245.431 170.316C245.72 170.316 246.082 170.321 246.516 170.332C246.95 170.343 247.278 170.36 247.5 170.382V174.388C247.367 174.355 247.061 174.305 246.582 174.238C246.115 174.16 245.62 174.121 245.097 174.121C243.851 174.121 242.738 174.382 241.759 174.905C240.791 175.417 240.023 176.129 239.456 177.042C238.899 177.943 238.621 178.972 238.621 180.13V196.353H234.682Z"
      fill="white"
    />
    <path
      d="M286.78 196.887C284.31 196.887 282.179 196.341 280.387 195.251C278.607 194.149 277.233 192.614 276.265 190.644C275.308 188.664 274.829 186.361 274.829 183.735C274.829 181.109 275.308 178.794 276.265 176.791C277.233 174.777 278.579 173.209 280.304 172.085C282.04 170.95 284.065 170.382 286.379 170.382C287.714 170.382 289.033 170.605 290.335 171.05C291.637 171.495 292.822 172.218 293.89 173.22C294.958 174.21 295.809 175.523 296.443 177.159C297.078 178.794 297.395 180.808 297.395 183.201V184.87H277.633V181.465H293.389C293.389 180.018 293.1 178.728 292.521 177.593C291.954 176.458 291.141 175.562 290.084 174.905C289.038 174.249 287.803 173.921 286.379 173.921C284.81 173.921 283.453 174.31 282.307 175.089C281.172 175.857 280.298 176.858 279.686 178.093C279.074 179.328 278.768 180.652 278.768 182.066V184.335C278.768 186.272 279.102 187.913 279.77 189.259C280.448 190.594 281.389 191.612 282.59 192.313C283.792 193.003 285.189 193.348 286.78 193.348C287.814 193.348 288.749 193.204 289.584 192.914C290.429 192.614 291.158 192.169 291.77 191.579C292.382 190.978 292.855 190.233 293.189 189.343L296.994 190.411C296.594 191.702 295.92 192.836 294.975 193.816C294.029 194.784 292.861 195.54 291.47 196.085C290.079 196.62 288.515 196.887 286.78 196.887Z"
      fill="white"
    />
    <path
      d="M347.261 176.458L343.723 177.459C343.5 176.869 343.172 176.296 342.738 175.74C342.315 175.173 341.737 174.705 341.002 174.338C340.268 173.971 339.328 173.787 338.182 173.787C336.613 173.787 335.305 174.149 334.259 174.872C333.225 175.584 332.707 176.491 332.707 177.593C332.707 178.572 333.063 179.345 333.775 179.913C334.488 180.48 335.6 180.953 337.114 181.331L340.919 182.266C343.211 182.822 344.919 183.673 346.043 184.82C347.167 185.954 347.729 187.418 347.729 189.209C347.729 190.678 347.306 191.991 346.46 193.148C345.626 194.305 344.457 195.218 342.955 195.885C341.453 196.553 339.706 196.887 337.714 196.887C335.1 196.887 332.935 196.319 331.222 195.184C329.508 194.049 328.423 192.391 327.967 190.21L331.706 189.276C332.062 190.656 332.735 191.69 333.725 192.38C334.727 193.07 336.034 193.415 337.648 193.415C339.484 193.415 340.941 193.026 342.02 192.247C343.111 191.457 343.656 190.511 343.656 189.409C343.656 188.519 343.345 187.774 342.721 187.173C342.098 186.561 341.141 186.105 339.851 185.804L335.578 184.803C333.23 184.246 331.506 183.384 330.404 182.216C329.314 181.036 328.768 179.562 328.768 177.793C328.768 176.346 329.174 175.067 329.987 173.954C330.81 172.841 331.928 171.968 333.341 171.334C334.766 170.699 336.379 170.382 338.182 170.382C340.719 170.382 342.71 170.939 344.157 172.051C345.614 173.164 346.649 174.633 347.261 176.458Z"
      fill="white"
    />
  </svg>
  )
}  
  
;
