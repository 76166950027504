import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link,useLocation } from 'react-router-dom'
import { TiArrowSortedDown} from "react-icons/ti";
import { regions } from 'data/general/regions';
import styles from "./general-cmp.module.css"

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      className='w-100 bg-red-ifa p-2 border-0 text-white d-flex justify-content-between'
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      
    </button>
  ));

const InscriptionCard = ({region,url,type,course,title,aecid}) => {
  const location =useLocation()
    const testLink = ()=>{
      const path = location.pathname
      const pathArray = path.split("/")
      pathArray.splice(-1,1,"reserver-un-test")
      return type === "cours" ? 'https://algerie.extranet-aec.com/':'' //'https://online.vfsglobal.dz/FrenchInstitute'
    }
    return (
      <>
      <div className="p-4 mb-3 bg-light rounded ">
        <h5 className="text-start blue-ifa fw-bold mb-3">Inscription</h5>
        {region === "national" ? (
          /* =============== National =============== */
          <Dropdown>
            <div>
              <Dropdown.Toggle
                id="dropdown-basic"
                className="w-100 text-start d-flex justify-content-between align-items-center border-0 p-2"
                as={CustomToggle}
              >
                <span>Selectionner une ville</span>
                <TiArrowSortedDown className="mt-1" />
              </Dropdown.Toggle>
            </div>
            <Dropdown.Menu className="w-100 py-0">
              {regions?.map((reg,i) => {
                return type === "cours" ? (
                  <Link to={`/${reg.name.toLocaleLowerCase()}/cours-de-francais/${url}`}>
                    <div
                      key={i}
                      className={`text-black p-2 ${styles.select_item}`}
                    >
                      {reg.name}
                    </div>
                  </Link>
                ) : type === "test"?(
                  <Link
                    to={`/${reg.name.toLocaleLowerCase()}/tests-et-examens/${location.pathname.split('/').reverse()[1]}/${url}`}
                    // state={url}
                  >
                    <div
                      key={i}
                      className={`text-black p-2 ${styles.select_item}`}
                    >
                      {reg.name}
                    </div>
                  </Link>
                ):(
                  <Link
                    to={`/${reg.name.toLocaleLowerCase()}/${url}`}
                    // state={url}
                  >
                    <div
                      key={i}
                      className={`text-black p-2 ${styles.select_item}`}
                    >
                      {reg.name}
                    </div>
                  </Link>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          /* =============== Region =============== */
          <div className={`text-start my-3 ${styles.inscription}`}>
            {(type==='test')?<>
            {/*<div>
              <p>
                Votre première inscription aux cours de langue française se fait
                sur rendez-vous en ligne.
              </p>
              <button
                className="rounded-0 w-100 p-2 border-0 fw-bold bg-red-ifa "
              >
                <Link to="/mon-compte" className="text-white">
                  <span>Créer mon compte</span>
                </Link>
              </button>
            </div>*/}</>:<></>}
            {/* ======== boutton de test ======== */}
            {(type!=='mediatheque')?<>
            <div className='mt-3'>
              
              {(type === "cours")?(
                <><p>
                Pour vous inscrire au cours de votre choix, cliquez sur le bouton “M’inscrire”.
                Remplissez ensuite le formulaire {region==='alger'||region==='Constantine' ?'et choisissez votre date de rendez-vous':''}
              </p>
                <a href={testLink()} className="d-block text-center text-white w-100 p-2 border-0 fw-bold bg-blue-ifa">
                <span>M'inscrire</span>
                </a></>)
              : (location.pathname.split('/').reverse()[1]!=='delf-et-dalf'?<><p>
              </p>
              <a href='https://online.vfsglobal.dz/FrenchInstitute/Account/RegisteredLogin?q=shSA0YnE4pLF9Xzwon/x/BGxVUxGuaZP3eMAtGHiEL2OHa0cghQbd9qtRdrxxlTqkCR7NTgcMIMC4K5EYc7hPyttZTNnjdchqZMRSm+cXEjl51FDNGbD89cdUgDpemJyg/7WGS1YSQjp9CBJsNcDz/nS/N+5phFPp1a7sYqdr0M=' className="d-block text-center text-white w-100 p-2 border-0 fw-bold bg-blue-ifa">
              <span>M'inscrire au {title}</span></a></>:<><a href={`${location.pathname}/inscription`} className="d-block text-center text-white w-100 p-2 border-0 fw-bold bg-blue-ifa">
              <span>M'inscrire au {title}</span></a></>)}
            </div></>:<>
            <div className='mt-3'>
              <p>
                
              </p>
              {(<a href={`/${region.toLocaleLowerCase()}/${url}`} className="d-block text-center text-white w-100 p-2 border-0 fw-bold bg-blue-ifa">
              <span>Pour s'inscrire</span></a>)}
            </div>
            </>
}
          </div>
        )}
      </div>
      {/*type === "test" ? (
      <div className="p-4 mb-3 bg-light rounded ">
        <h5 className="text-start blue-ifa fw-bold mb-3">Consultation des résultats</h5>
        <button
                className="rounded-0 w-100 p-2 border-0 fw-bold bg-red-ifa "
              >
                <Link to="resultats" className="text-white">
                  <span>Consulter les resultats</span>
                </Link>
              </button>
      </div>):""*/}</>
    );
}

export default InscriptionCard