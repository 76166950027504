import React from 'react'
import styles from "./general-cmp.module.css"
import parse from 'html-react-parser'
function DossierInscriptionCard(price,content,title) {
  try{
    var text = parse(price?.content??content?.content)
  }catch($){
    text = price?.content ?? content?.content
  }
  return (
    <div className="text-start my-4 bg-light p-3 ">
      <div className={styles.dossier}>
        <h5 className="text-start blue-ifa fw-bold p-0">
          {title??"Dossier d'inscription"}
        </h5>
        {text??
        <ul className="list-unstyled lh-lg">
          <li >
          Pour s’inscrire vous aurez besoin de : 
          </li>
          <li >Une CNI</li>
          <li >
          Frais d’inscription : <strong>{price?.price?.toString()} DA</strong>
          </li>
        </ul>
        }
      </div>
    </div>
  );
}

export default DossierInscriptionCard