import React from 'react'
import styles from "./general-cmp.module.css"

function MainTitle( {children}) {

  return (
    <h1 className= {`${styles.title} mb-4 mt-lg-5 mt-4`}> {children}</h1>
  )
}

export default MainTitle