import React, {useContext, useEffect,useState} from 'react'
import CoverImg from 'components/General/CoverImg'
import { Card, Container , Row,Col,Button,Badge} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'
import culture from "../../assets/img/culture.jpg"
import CityMenuCard from 'components/Cities/CityMenuCard'
import BgShape from 'components/General/BgShape'
import { Link } from 'react-router-dom'
import { fetchAPIActionsCulturelles } from 'services/institut-national'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import actionsCultes from "data/culture/Action-culturelles"
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'
function ActionsCulturellesPage() {
  const [dataCultes, setDataCultes] = useState();  
  const [actions, setActions] = useState();
  const [loading, setLoading] = React.useState(true);
  const { language } = useContext(LanguageContext);
  useEffect( () => {
    function fetchData() {
      fetchAPIActionsCulturelles().then((items)=>{
        //const res = await fetchAPIInstitutDetails(results.posts[0].id)
        var el=items.items[0]?.data;
        ////console.log(el);
        setActions(el);
        var d=[]
        if(el?.Actions && el?.Actions[language??'fr']?.length>0){
            el?.Actions[language??'fr']?.map(e => {
                var img = e?.Image?.iv? e?.Image?.iv[0]: e?.Image?.[language]? e?.Image?.[language][0] : e.Image? e.Image[0]:"";
                //console.log(img)
                d.push({title:e.Titre,img:undefined,url:`${e?.Titre??"#"}/${e?.slug??e?.Titre}`,description:e.description,src:img })
                
      /*getAsset(img).then((srcImage)=>{
        e.srcImage=srcImage;
        e.id=el.id;
                d.push({title:e.Titre,img:srcImage,url:`${e?.Titre??"#"}/${e?.slug??e?.Titre}`,description:e.description,src:img })
                setDataCultes(d);
                
            });
            */
            
        });
        setDataCultes(d);
        setLoading(false)
        }
      });
    }
      
    fetchData();
}, [dataCultes?.every(x=>x.title!==undefined&&x.title!==null&&x.title!=="")]);
const [banner, setBanner] = useState();
useEffect( () => {
  if(actions?.banner?.[language])
  getAsset(actions?.banner?.[language]).then((srcImage)=>{
    setBanner(srcImage);
  });
  
}, []);
try{var text = parse(actions?.Introduction[language??'fr'])}catch($){
  text = actions?.Introduction[language??'fr']
}
//console.log(dataCultes??"empty")
  return loading? (<><ShimmerPostDetails card cta variant="SIMPLE" /><ShimmerPostList postStyle="STYLE_FOUR" col={3} row={1} gap={30} /></>) :(
    <div className=" position-relative overflow-hidden" dir={language==='ar'?"rtl":""}>
        {/*<BgShape type="yellow"  right="-40px" top="80px" rotate="-140deg"/>
        <BgShape type="blue"  right="-50px" top="800px" rotate="-150deg"/>
        <BgShape type="red"  left="-80px" top="500px" rotate="140deg"/>*/}
        <CoverImg img={banner}/>
        <Breadcrumb/>
        <Container >
            <MainTitle>Nos actions culturelles</MainTitle>
            {text??
            <p className='text-start lh-lg text-black-50'>
            L’Institut français d’Algérie déploie un cycle annuel de programmation culturelle et artistique,
désormais ponctué de temps forts réguliers. De nombreux rendez-vous incontournables
d’animations, de rencontres, de spectacles et de conférences sont à découvrir simultanément dans le
réseau des Instituts français à Alger, à Annaba, à Constantine, à Oran et à Tlemcen.
            </p>}
         
            <Row className='my-5'>
                {dataCultes?.map((action,i)=>{
                  //console.log(i+' '+action)
                 return (
                <Col md={4} className="mb-4" key={i}>
                  <Link to={action.url}>
                    <CityMenuCard menu = {action}/>
                  </Link>
                </Col>
                )
                })}
            </Row>
        </Container>
    </div>
  )
}

export default ActionsCulturellesPage