import React,{useState,useEffect, useContext} from 'react'
import { Container} from "react-bootstrap";
import Bandeau from 'components/Etudes/Bandeau';
import Breadcrumb from 'components/breadcrumb/breadcrumb';
import MainTitle from 'components/General/MainTitle';
import EtudesCarousel from 'components/Etudes/EtudesCarousel';
import EventsGrid from 'components/General/EventsGrid';
import BgShape from 'components/General/BgShape';
import styles from "./EtudierEnFrance.module.css"
import { getEtude } from 'services/etudes'
import { getAsset } from 'services/assets'
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext';
function AlumniFrance({region}) {

  const [data, setData] = useState()
  const { language } = useContext(LanguageContext);
    useEffect( () => {
      function fetchData() {
        getEtude('national').then((results)=>{
          //console.log(results?.items?.filter(x=>x.data?.slug.iv.toLowerCase()==='france-alumni'))
setData(results?.items?.filter(x=>x.data?.slug.iv.toLowerCase()==='france-alumni')?.[0]?.data);    
setLoading(false)
} )} 
      fetchData();
  }, []);
  const [srcImage,setSrcImage] = useState();
  const [loading, setLoading] = React.useState(true);
  React.useEffect( () => {

    function fetchData() {
              
            var img = data?.Image?.iv? data?.Image?.iv[0]: data?.Image?.[language]?data?.Image?.[language][0]:data?.Image?data?.Image[0]:''; //todo default image
            
      if(img)
  getAsset(img).then((srcImage)=>{
//console.log(srcImage)
    setSrcImage(srcImage);
    setLoading(false)
        })
      }
       fetchData() 
    },[data]);
  
//console.log(data)

try{var text = parse(data?.Description?.[language])}catch($){
  text = data?.Description?.[language]
}
  return loading? <ShimmerPostDetails card cta variant="EDITOR" /> :(

    <div className=" position-relative overflow-hidden" dir={language==='ar'?"rtl":""}>
             {/*<BgShape type="yellow"  right="-50px" top="400px" rotate="-150deg"/>*/}
             {/*<BgShape type="blue"  left="-80px" top="900px" rotate="140deg"/>*/}
             {/*<BgShape type="red"  right="-60px" top="1300px" rotate="-140deg"/>*/}
            <EtudesCarousel type='france alumni'/>
            <Breadcrumb/>
            <Container >
              <MainTitle className="my-4">{data?.Titre?.[language]??'France alumni'}</MainTitle>
              {text??<p className='text-start lh-lg text-black-50 mb-5'>
              France Alumni Algérie est le premier réseau social et professionnel à destination des profils algériens formés en France. Lancé en 2015, le réseau vise à promouvoir l’excellence académique française et à valoriser le retour des compétences et l’insertion professionnelle en Algérie.

Le réseau France Alumni Algérie concerne :

-        - les diplômés algériens de l’enseignement supérieur français

-        -  les étudiants, chercheurs ou enseignants ayant effectué une mobilité dans le cadre d’un programme en France

-        - les étudiants algériens inscrits dans un établissement français, en France ou à l’étranger

-        - les personnes ayant effectué un stage de longue durée en France

-        - les anciens élèves des Lycées français à l’étranger

Les Alumni membres du réseau France Alumni Algérie sont invités à plusieurs événements organisés par l’Ambassade de France, l’Institut français ou les organisations partenaires du réseau, en faveur des Alumni en Algérie ou en France : forum, tables rondes, conférences thématiques, sorties culturelles, salons, déjeuners, afterworks, etc.


              </p>}
            </Container>

              <Bandeau link='https://www.francealumni.fr/fr/poste/algerie/' className={styles.bandeau}>
                  <p className='fs-5 my-0 text-center'>{data?.Citation?.[language]??"Rejoignez la plateforme France Alumni Algérie et valorisez votre expérience ! sur <span className='red-ifa fw-bold'>France Alumni</span> "}</p>
              </Bandeau>

            {/*<Container>
              <MainTitle className="my-5">Agenda</MainTitle>
              <EventsGrid time="future" type="France alumni" region={region&&region}/>
            </Container>*/}
    </div>
    
  )
}

export default AlumniFrance