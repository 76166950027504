import React from 'react'
import {Container} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'
import CoverImg from 'components/General/CoverImg'
import culture from "../../assets/img/culture.jpg"

const CartPage = () => {
  return (
    <>
    <CoverImg img={culture}/>
    <Breadcrumb/>
    <Container>
        {/* <MainTitle>Cart page</MainTitle> */}
        <div className="arc-en-ciel ">
        <div data-module="student"
            data-action="student-controls"
            data-param-login-link="/connexion"
            data-param-logout-link="students/logout"
            data-param-account-link="/mon-compte"
            data-param-my-cart-link="/panier"
            data-redirect-url-after-logout = "students/login"
            className='mb-5'></div>

            <div data-module="cart" data-action="cart-form" data-show-loading="true"></div>
        </div>
    </Container>
    </>
  )
    
}

export default CartPage