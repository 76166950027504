import React from 'react'
import CityMenuCard from './CityMenuCard'
import { Row,Col} from "react-bootstrap";
import institut from "../../assets/img/ComponentTMP_3-image3.png";
import culture from "../../assets/img/culture-img.jpg";
import exams from "../../assets/img/cover_exams.jpg"
import cours from "../../assets/img//cours-11ans.jpg"
import etudes from "../../assets/img/alumni-img.jpg"
import mediatheque from "../../assets/img/mediathequeAlger.jpg"


const cardData = [
    {
        title:"L’antenne",
        url:"institut/presentation",
        img:institut
      },
      {
        title:"Tests et examens",
        url:"tests-et-examens/presentation",
        img:exams
      },
      {
        title:"Cours de français",
        url:"cours-de-francais/",
        img:cours
      },
      
    {
      title:"Études",
      url:"etudes/",
      img:etudes
    },{
      title:"Culture",
      url:"culture/agenda",
      img:culture
    },
    {
      title:"Médiathèque",
      url:"mediatheque/presentation",
      img:mediatheque
    }
  ]

function CityMenuCardGrid() {
  return (
    <Row className="py-5">
            {cardData.map((el,index)=>{
    return (
      <Col key={index} lg={3} md={6}  className="mb-4">
        <CityMenuCard menu={el}/>
      </Col>
    );
  })}
    </Row>
  )
}

export default CityMenuCardGrid