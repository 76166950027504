import React from 'react'

const SideBanner = (props)=>{
    return (
        <>
            <div className="p-4 mb-3 bg-light rounded ">
                <a
                  href={props.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {props.img&&<img src={props.img} style={props.imgStyle} alt="..."></img>}
                  {props.text&&<h3 className='fw-bold'>{props.text.toUpperCase()}</h3>}
                </a>
              </div>
        </>
    )
}

export default SideBanner