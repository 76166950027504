import { useTranslation } from 'react-i18next';
import './style.css';
import { useState, useEffect, useContext} from 'react';
import { useLocation,useNavigate,Link } from 'react-router-dom';
import { LanguageContext } from 'services/LanguageContext';
import validator from 'validator';





export default ({brd}) => {
    // breadcrumb responsivity ====================== : 
    const [windowWidth, setWindowWidth] = useState(getWindowWidth());
    const { language } = useContext(LanguageContext);
    const { t} = useTranslation();
  useEffect(() => {
    
    function handleWindowResize() {
      setWindowWidth(getWindowWidth());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getWindowWidth() {
    return window.innerWidth ;
  }
// ===================================================

    const navigate = useNavigate()
    const location =useLocation()
    const path = location.pathname
    
    const wordsToreplaceInUi = {
      FRANCAIS : "FRANÇAIS",
      COOPERATION:"COOPÉRATION",
      EDUCATIVE : "ÉDUCATIVE",
      ETUDIER: "ÉTUDIER",
      ALGERIE : "ALGÉRIE",
      'D ALLEMAND':"D'ALLEMAND",
      'A PARTIR' : "À PARITR",
      "A 15":"À 15",
      GENERAL : "GÉNÉRAL",
      IDEES:"ID%C3%A9ES",
      'ID%C3%A9ES':"IDÉES"
    }
    const wordsToreplaceInArray = {
      FRANÇAIS : "FRANCAIS",
      COOPÉRATION:"COOPERATION",
      ÉDUCATIVE : "EDUCATIVE",
      ÉTUDIER: "ETUDIER",
      ALGÉRIE : "ALGERIE",
    }

    // create an array from the current path
    //96da4ffb-59ea-414b-930a-a67aa45f6991
    const pathArray = path.split("/").filter(el=>el!=="" && !validator.isUUID(el))
     // replace "-" and "%20" with " "
    const cleanArray = pathArray.map(el=>el.replace(/-|%20/g, " ").toLowerCase())
    const cleanArray2 = cleanArray.map(el=>el.replace(/-|%20/g, " ").toLowerCase())
    
    const handleNavigation =(e)=>{
      // get the postion of curent path inside the path array 
      const position = cleanArray.indexOf(e.target.innerText.replace(/FRANÇAIS|COOPÉRATION|ÉDUCATIVE|ÉTUDIER|ALGÉRIE/g,matched=>wordsToreplaceInArray[matched]).toLowerCase())
      // remove element from routes array
      cleanArray.length=position+1
      // navigate to the new path
      navigate(`/${cleanArray.map(el=>el.replace(/ /g, "-")).join('/')}`)
    }

    // if there is a brd prop : replace the last element fo the cleanArray with the props value
    // create a copy from the clean array to avoid modifying its value
    let newCleanArray = [...cleanArray]
    // replace the last element of the newClean array with 'brd prop'
    newCleanArray.splice(-1,1,brd)
    // final array change its value according to the brd props
    let finalArray = brd?newCleanArray:cleanArray
    
    return windowWidth > 700?(
      <div className={windowWidth < 700? "container":""} dir={language==='ar'?"rtl":""}>
        <ol className="breadcrumb d-flex justify-content-start ms-0">
          <li>
            <Link to="/">{t("ACCUEIL")}</Link>
          </li>
          {finalArray.map((route, index) => {
            return (
              <li 
                key={index}
                className="active"
                style={{ cursor: "pointer" }}
                onClick={handleNavigation}
              >
                {t(decodeURI(route
                  .toUpperCase()
                  .replace(
                    /FRANCAIS|COOPERATION|EDUCATIVE|ETUDIER|ALGERIE|D ALLEMAND|A PARTIR|A 15|GENERAL|ID%C3%A9ES/g,
                    (matched) => wordsToreplaceInUi[matched]
                  )))}
              </li>
            );
            // return <li key={index} className="active" style={{cursor:"pointer"}} onClick={handleNavigation}>{route.toUpperCase()}{brd}</li>
          })}
        </ol>
      </div>
    ):"";
      
    
  }