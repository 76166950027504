import React from 'react'
import { Card } from 'react-bootstrap'
import parse from 'html-react-parser'

function TestCard({children,test}) {
  const {name,intro} = test
  try{var text = parse(test?.['short-description'])}catch($){
    text = test?.['short-description']
  }
  return (
    // <>
    // {/* <h3>{title}</h3> */}
    //   <div className="text-black-50" >
    //     {children}
    //   </div>
    // </>
    // <Card className="p-2 h-100 " style={{padding:"1rem",height:"100%",position:"relative",margin:'1rem auto'}}>
    <Card className="p-2 h-100 mb-md-0">
      <Card.Body>
          <Card.Title>
              <h4 style={{color:'rgba(5, 131, 189, 0.932)'}}>{test?.title??name}</h4>
              <hr/>
          </Card.Title>
          <Card.Text className='text-black-50' style={{textAlign:'justify'}}>
          <p className='lh-lg'>
              {text??intro}
          </p>
          </Card.Text>     
      </Card.Body>
    </Card>
  )
}

export default TestCard