import React from "react";
import  ReactDOM  from "react-dom";
import styles from "./newsletter.module.css"


function NewsLetterModal({ children, open,onClose }) {
  if (!open) return null;

  return ReactDOM.createPortal(
    <>
        <div className={styles.overlay} onClick={()=>onClose(false)}></div>
        <div className={styles.modal}>
            {children}
        </div>
    </>
  ,document.getElementById('portal'));
}

export default NewsLetterModal;
