import React from 'react'
import {  Accordion} from 'react-bootstrap'

const SubcourseAccordion = (props) => {
  return (
    <Accordion className='my-3'>
                  <Accordion.Item>
                    <Accordion.Header>
                      <h5 className='fw-bold'>{props.subcourse}</h5>
                    </Accordion.Header>
                    <Accordion.Body className='text-start fs-16 ' >
                        <p className='fs-16'> Ce package de cours comprend :</p>
                    <ul className=" text-start">
                            <li>
                            2h x 2 séances par semaine pendant 10 semaines (40h)
                            </li>
                            <li>
                            Test de positionnement oral et écrit
                            </li>
                            <li>Manuel + CD audio</li>
                            <li>
                            Gratuité à l'adhésion de la médiathèque de l’Institut
                            français pendant un an
                            </li>
                        </ul>
                        <p className='fs-16'>A  noter:  Gratuité  à l'adhésion  de  la  médiathèque  de  l’Institut  français  pendant  un  an  + Réduction de 50% à l’inscription des examens DELF/DALF.</p>
                    </Accordion.Body>
                  </Accordion.Item>
    </Accordion>
  )
}

export default SubcourseAccordion