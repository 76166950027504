// export const Examens = [
//     {
//         id:1,
//         title:"DELF Prim",
//         description:"Le DELF Prim est destiné aux enfants de 7 à 11 ans qui sont scolarisés au niveau de l'enseignement élémentaire.",
//         newLine:"Le DELF Prim se compose de 3 niveaux : le A1.1, le A1 et le A2. Basé sur une évaluation tr`es positive et non sanctionnante, il est parfait pour stimuler l'envie et la motivation des enfants dans l'apprentissage du français.",
//         url:"DELF Prim"
//     },
//     {
//         id:2,
//         title:"DELF Junior",
//         description:"Le DELF Junior/scolaire est destiné aux adolescents de 12 à 18 ans qui sont scolarisés dans l'enseignement secondaire, au niveau du coll`ege et du lycée.",
//         newLine:"Le DELF Junior/scolaire est composé de 4 niveaux :  le A1, le A2, le B1 et le B2.",
//         url:"DELF Junior"
//     },
//     {
//         id:3,
//         title:"DELF Tout public",
//         description:"Le  DELF  Tous  Publics  est  destiné  à  tout  apprenant  de  français,  de  18  à  77  ans  (ou plus ☺)  qui  souhaite  valider  de  manière  officielle  et  pérenne  leur  apprentissage  de  la  langue française.",
//         newLine:"Le DELF Tous Publics est composé de 4 niveaux :  le A1, le A2, le B1 et le B2.",
//         url:"DELF Tout public"
//     },
//     {
//         id:4,
//         title:"DELF Pro",
//         description:"Le DELF Pro est destiné à des étudiants ou à des travailleurs qui ont pour objectif une insertion ou une promotion professionnelle en France ou dans un milieu francophone.",
//         newLine:"Le DELF Pro se compose de 4 niveaux : le A1, le A2, le B1 et le B2 Il  constitue  une  véritable  plus-value  pour  les  personnes  désireuses  de  s'insérer  ou  de progresser sur le marché du travail francophone.",
//         url:"DELF Pro"
//     },
//     {
//         id:5,
//         title:"DALF",
//         description:"Le DALF est destiné à des adultes en situation universitaire ou professionnelle.",
//         newLine:"Le DALF se compose de 2 niveaux :  le C1 et le C2 qui sont les niveaux les plus élevés. L'obtention d'un DALF constitue un gage de tr`es grande maîtrise en langue française.",
//         url:"DALF"
//     }]

    export const Examens = [
        {
            id:1,
            img:"https://images.pexels.com/photos/9572684/pexels-photo-9572684.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            name:"DELF Prim",
            intro:`Le DELF Prim est destiné aux enfants de 7 à 11 ans qui sont scolarisés au niveau de l'enseignement élémentaire.
            Le DELF Prim se compose de 3 niveaux : le A1.1, le A1 et le A2. Basé sur une évaluation trés positive et non sanctionnante, il est parfait pour stimuler l'envie et la motivation des enfants dans l'apprentissage du français.`,
            url:"DELF Prim",
            prix:"5000"
        },
        {
            id:2,
            img:"https://images.pexels.com/photos/9572684/pexels-photo-9572684.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            name:"DELF Junior",
            intro:`Le DELF Junior/scolaire est destiné aux adolescents de 12 à 18 ans qui sont scolarisés dans l'enseignement secondaire, au niveau du collège et du lycée.
            Le DELF Junior/scolaire est composé de 4 niveaux :  le A1, le A2, le B1 et le B2.`,
            url:"DELF Junior",
            prix:"6000"
        },
        {
            id:3,
            img:"https://images.pexels.com/photos/9572684/pexels-photo-9572684.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            name:"DELF Tout public",
            intro:`Le  DELF  Tous  Publics  est  destiné  à  tout  apprenant  de  français,  de  18  à  77  ans  (ou plus ☺)  qui  souhaite  valider  de  manière  officielle  et  pérenne  leur  apprentissage  de  la  langue française.",
            Le DELF Tous Publics est composé de 2 niveaux :  le A1, le A2`,
            url:"DELF Tout public",
            prix:"8000"
        },
        {
            id:4,
            img:"https://images.pexels.com/photos/9572684/pexels-photo-9572684.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            name:"DELF Pro B1",
            intro:`Le DELF Pro est destiné à des étudiants ou à des travailleurs qui ont pour objectif une insertion ou une promotion professionnelle en France ou dans un milieu francophone.
            Le DELF Pro se compose de 2 niveaux : le B1 et le B2 Il  constitue  une  véritable  plus-value  pour  les  personnes  désireuses  de  s'insérer  ou  de progresser sur le marché du travail francophone.`,
            url:"DELF Pro B1",
            prix:"10000"
        },
        {
            id:5,
            img:"https://images.pexels.com/photos/9572684/pexels-photo-9572684.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            name:"DELF Pro B2",
            intro:`Le DELF Pro est destiné à des étudiants ou à des travailleurs qui ont pour objectif une insertion ou une promotion professionnelle en France ou dans un milieu francophone.
            Le DELF Pro se compose de 2 niveaux : le B1 et le B2 Il  constitue  une  véritable  plus-value  pour  les  personnes  désireuses  de  s'insérer  ou  de progresser sur le marché du travail francophone.`,
            url:"DELF Pro B2",
            prix:"14000"
        },
        {
            id:6,
            img:"https://images.pexels.com/photos/9572684/pexels-photo-9572684.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
            name:"DALF",
            intro:`Le DALF est destiné à des adultes en situation universitaire ou professionnelle.
            Le DALF se compose de 2 niveaux :  le C1 et le C2 qui sont les niveaux les plus élevés. L'obtention d'un DALF constitue un gage de très grande maîtrise en langue française.`,
            url:"DALF",
            prix:"16000"
        }]