import React,{useState,useEffect, useContext} from 'react'
import {Container,Row,Col} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'
import liad from "../../assets/img/liad-img.jpg"
import EtudesCarousel from 'components/Etudes/EtudesCarousel'
import Bandeau from 'components/Etudes/Bandeau'
import EventsGrid from 'components/General/EventsGrid'
import BgShape from 'components/General/BgShape'
import styles from "./EtudierEnFrance.module.css"
import { getEtude } from 'services/etudes'
import { getAsset } from 'services/assets'
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'
function LiadPage({region}) {
    const [data, setData] = useState()
    const [loading, setLoading] = React.useState(true);
    const { language } = useContext(LanguageContext);
    useEffect( () => {
      function fetchData() {
        getEtude('national').then((results)=>{
          //console.log(results?.items?.filter(x=>x.data?.slug.iv.toLowerCase()==='liad'))
setData(results?.items?.filter(x=>x.data?.slug.iv.toLowerCase()==='liad')?.[0]?.data);   
setLoading(false) 
} )} 
      fetchData();
  }, []);
  const [srcImage,setSrcImage] = useState();
  React.useEffect( () => {

    function fetchData() {
              
            var img = data?.Image?.iv? data?.Image?.iv[0]: data?.Image?.[language]?data?.Image?.[language][0]:data?.Image?data?.Image[0]:''; //todo default image
            
      if(img)
  getAsset(img).then((srcImage)=>{
//console.log(srcImage)
    setSrcImage(srcImage);
    setLoading(false)
        })
      }
       fetchData() 
    },[data]);
  
//console.log(data)

try{var text = parse(data?.Description?.[language])}catch($){
  text = data?.Description?.[language]
}
  return loading? <ShimmerPostDetails card cta variant="EDITOR" /> :(
    <div className=" position-relative overflow-hidden" dir={language==='ar'?"rtl":""}>
            {/*<BgShape type="red"  right="-60px" top="800px" rotate="-140deg"/>*/}
            {/*<BgShape type="yellow"  right="-50px" bottom="200px" rotate="-150deg"/>*/}
            {/*<BgShape type="blue"  left="-80px" bottom="500px" rotate="140deg"/>*/}
            <EtudesCarousel type="liad"/>
            <Breadcrumb/>
            <Container >
                <MainTitle>Le  Lycée  International  Alexandre  Dumas</MainTitle>
                    <Row className='text-start my-5'>
                        <Col lg={6} className={`text-black-50 h-100 ${styles.liad}`} >
                        <a href='https://www.liad-alger.fr' target="_blank" rel="noreferrer">
                    {data?.Titre?.[language]??"La petite école d'Hydra (PEH)"}
                  </a>
                  {text??
                                <p>
                                    <a href='https://www.liad-alger.fr' target="_blank" rel='noreferrer'>Le  Lycée  International  Alexandre  Dumas  (LIAD)  et  l'Ecole  Primaire  Internationale Alexandre  Dumas  (EPIAD)</a>  se  situent  à Alger  et  assurent  l'enseignement  de  la  petite section à la terminale.  Le lycée et le collège proposent une section internationale en arabe.
                                    Appartenant au réseau de l’Agence pour l’enseignement français à l’étranger (AEFE) et homologué par le Ministère de l’éducation nationale et de la jeunesse français, le Lycée International Alexandre Dumas (LIAD) a ouvert ses portes à la rentrée 2002.


A Alger, le LIAD couvre l’ensemble des cycles d’enseignement et accueille les élèves en classes de maternelle et primaire sur le site de l’Ecole Primaire Internationale Alexandre Dumas (EPIAD) et en classes de collège et lycée (site du LIAD).

Le LIAD dispose de deux annexes :  une annexe à Oran qui accueille les élèves de la maternelle à la seconde (une offre d’internat est proposé à Alger pour les élèves de première et terminale) et une annexe à Annaba qui accueille les élèves de la maternelle à la classe de 5e.
                                </p>}
                        </Col>
                        <Col lg={6}>
                            <img src={srcImage} className="w-100" alt="liad"/>
                        </Col>
                    </Row>
            </Container>
            <Bandeau className="mb-5" link='https://www.liad-alger.fr'>
                <p className='fs-5 my-0 text-center'>{data?.Citation?.[language]??"Retrouvez toutes les informations sur le site officiel de <span className='red-ifa fw-bold'>LIAD</span>"}</p>
            </Bandeau>
            {/*<Container>
                <MainTitle >Agenda</MainTitle>
                <EventsGrid type="LIAD" region={region && region}/>
            </Container>*/}
    </div>
  )
}

export default LiadPage