import React, { useEffect } from 'react'
import mission from "../../assets/img/alger3.jpg"
import { Container} from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css"
import CoverImg from 'components/General/CoverImg'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'
import MissionLinks from 'components/Institut/MissionLinks'
import ResidenceCard from 'components/Institut/ResidenceCard'
import MissionLink from 'components/Institut/MissionLink'


const residences = [
    {
        title:"Résidence  d’écriture  à  Taghit",
        img:"https://images.unsplash.com/photo-1556660617-b3f34009a2a4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=862&q=80",
        text:"depuis  2019,  l’Institut  propose  trois  résidences d’écriture  dans  l’un  des  lieux  les  plus  magiques  du  pays."
    },
    {
        title:"Résidence à la Cité de la bande dessinée d’Angoulême ",
        img:"https://images.unsplash.com/photo-1464520606738-6dab99180409?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80",
        text:"depuis 2019, l’Institut s’associe à  la  Cité  de  la  Bande  dessinée  pour  envoyer  des  bédéistes  algériens  en  résidence  à Angoulême."
    },
    {
        title:"Résidence   d’écriture   scénaristique   à   Timimoune",
        img:"https://images.unsplash.com/photo-1622711768193-945e72bcf118?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
        text:"cette   résidence   permet d’accueillir  deux  ou  trois secnéraistes  des  deux  rives  en  un  lieu.    L’objectif de cette résidence est de créer les conditions d’un échange d’expérience, d’une entraide entre auteurs confrontés au développement de leurs scénarios."
    },
    {
        title:"Résidence  Méditerranée",
        img:"https://images.unsplash.com/photo-1483068919052-4ade7bdc0edb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=874&q=80",
        text:"En  association  avec  la  Friche  La  Belle  de  Mai à  Marseille, l’Institut propose chaque année à plusieurs artistes algériens d’y effectuer une résidence de trois mois aux côtés d’autres artistes du pourtour méditerranéen."
    },
    {
        title:"Résidences  à  la  Cité  internationale  des  arts ",
        img:"https://images.unsplash.com/photo-1494034547187-271c87f7f4fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
        text:"ce  programme  s’adresse  aux  artistes algériens  qui  souhaitent  développer  un  projet  de  recherche  et  de  création  à  Paris, pendant  une  durée  de  trois  mois. Les artistes  peuvent  présenter  un  projet  de  recherche  sur  un  sujet/une  thématique  de leur choix, s’inscrivant dans des disciplines variées."
    },
]

const formations = [
    {
        title:"Le secteur du livre",
        img:"https://images.unsplash.com/photo-1476275466078-4007374efbbe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=829&q=80",
        text:"Chaque année, l’IFA propose des formations à destination des professionnels algériens des différents métiers du livre en partenariat avec le  Centre national  du  livre  algérien, l’ASFORED  et  l’Association  internationale  des  libraires francophones."
    },
    {
        title:"Le secteur de l’industrie cinématographique.",
        img:"https://images.unsplash.com/photo-1478720568477-152d9b164e26?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
        text:<ul>
                <li className='mb-1'>Depuis  huit  ans,  l’Institut  français  d’Algérie  organise, en  partenariat  avec  le Festival Premiers Plans d’Angers, le Laboratoire documentaire d’Algerie- Lab DZ- orienté vers la réalisation documentaire et la pratique du son.</li>
                <li>Universités d’été à la  Femis  et à l’Ecole  de  l’image  Les  Gobelins  et formation au perfectionnement à la réalisation documentaire à l’Atelier Varan.</li>
            </ul>
    },{
        title:"Le  secteur  du  design",
        img:"https://images.unsplash.com/photo-1497864149936-d3163f0c0f4b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80",
        text:"En  2020,  l’Institut  français  d’Algérie  lance  la  Biennale algéro-française  du  design  autour  du  thème  « réinventer  la  ville  par  le  design».  Designers, architectes, ingénieurs, chercheurs, artistes, étudiants ont été mobilisés pour proposer des projets innovants pour la ville avec comme vecteur le développe- ment  durable, économique,  culturel  et  social.  Les  projets  retenus  ont été  présentés dans la cadre de trois expositions conçues par la commissaire d’epxoisiton Feriel Gasmi Issiakhem."
    }
]


export default function MissionThree() {
  useEffect(()=>{
    window.scrollTo({top:0})
  },[])
  return (
    <>
      <CoverImg img={mission} />
      <Breadcrumb />
      <Container className="lh-lg mb-5">
        <MainTitle> Mission de coopération culturelle</MainTitle>
        <div className="intro text-black-50">
          <p className="lh-lg text-start">
            L’action culturelle de l’Institut français en Algérie a pour
            objectif de favoriser la création artistique et professionnelle en
            lien avec la culture algérienne et française (formation, bourses de
            mobilité, résidence d’artistes. . . ), de diffuser la création
            artistique algérienne en Algérie et en France et de developper les
            échanges entre professionnales des deux rives.
            <br />
            L’institut français d’Algérie dispose de programmes structurants
            permettant d’atteindre cet objectif :
          </p>
          <div className="lh-lg text-start">
            {/*1- Appels à projet */}
            <div className="my-4">
              <h4 className="blue-ifa fw-bold"> Les appels à projet</h4>
              <p>
                Dans les domaines de l’édition (cession des droits d’auteurs,
                traduction d’œuvre vers l’arabe ou le français, aide à la
                publication), du cinéma (aide à l’écriture, à la production, ou
                à la postproduction) et artistique (soutien aux projets de
                création, de formation, d’organisation de mani- festations).
              </p>
              <MissionLinks>
                <MissionLink>
                  Retrouvez les derniers projets soutenus par l’IFA et qui font
                  parler d’eux
                </MissionLink>
                <MissionLink>Appels à projets</MissionLink>
                <MissionLink>Actualité</MissionLink>
              </MissionLinks>
            </div>
            {/*2- Les résidences artistiques */}
            <div className="my-4">
              <h4 className="blue-ifa fw-bold my-4">
                {" "}
                Les résidences artistiques
              </h4>
              <div>
                {residences.map((residence, index) => {
                  return <ResidenceCard key={index} item={residence} />;
                })}
              </div>
              <MissionLinks>
                <MissionLink>Modalités et des dates</MissionLink>
                <MissionLink>
                  Parcours et les projets de nos anciens résidents
                </MissionLink>
              </MissionLinks>
            </div>
            {/* 3-  formations de professionnels */}
            <div className="my-4">
              <h4 className="blue-ifa fw-bold my-4">
                {" "}
                Nos formations de professionnels
              </h4>
              <div>
                {formations.map((formation, index) => {
                  return (
                    <ResidenceCard key={index} item={formation} order="last" />
                  );
                })}
              </div>
              <MissionLinks>
                    <MissionLink> Appels  à  candidature  pour  ces  différentes  formations</MissionLink>
              </MissionLinks>
            </div>
          </div>
          <p className='lh-lg text-start'>
            L’Institut français d’Algérie a également le plaisir de participer à
            deux grands temps forts culturels algériens, le Salon international
            du Livre d’Alger (SILA) où des auteurs viennent présenter leurs
            derniers ouvrages et dialoguer. l’Institut est présent depuis sa
            création au Festival International de la Bande Dessinée d’Alger
            (FIBDA), le plus important du continent africain.
          </p>
        </div>
      </Container>
    </>
  );
}
