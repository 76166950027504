import React, { useContext, useEffect, useState } from 'react'
import CoverImg from 'components/General/CoverImg'
import { Container , Row,Col,Button,Badge,ListGroup,Table} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import { Link,useLocation } from 'react-router-dom'
import BgShape from 'components/General/BgShape'
import MainTitle from 'components/General/MainTitle'
import { getTests } from 'services/tests-exams'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'

import cover from "../../assets/img/cover_exams.jpg";
import CityMenuCard from 'components/Cities/CityMenuCard';
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList,ShimmerSectionHeader,ShimmerFeaturedGallery } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'
function TestsPresentationPage() {
  const [presentation,setPresentation] = useState()
  const [exams,setExams] = useState()
  const [data,setData] = useState()
  const [loading, setLoading] = React.useState(true);
  const { language } = useContext(LanguageContext);
  const tests = [
    {
      title:"TCF",
      url:"tcf",
      img:"https://images.pexels.com/photos/419635/notebook-empty-design-paper-419635.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    },
    {
      title:"DELF DALF",
      url:"delf-et-dalf",
      img:"https://images.pexels.com/photos/267705/pexels-photo-267705.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
    }
  ]
  useEffect( () => {
    function fetchData() {
      getTests('national').then((results)=>{
        results?.items?.[0]?.data?.tests?.[language]?.map(e => {
            
            var img = e?.Image?.iv? e?.Image?.iv[0]: e?.Image?.[language]?e?.Image?.[language][0]:e?.Image?e?.Image[0]:''; //todo default image
            e.src=img;
            e.img=undefined;
  /*getAsset(img).then((srcImage)=>{
    e.srcImage=srcImage;
    setPresentation(results.items?.[0]?.data?.Presentation      );});
    setExams(results.items?.[0]?.data?.tests)
  
});*/
});
setPresentation(results.items?.[0]?.data?.Presentation      );
setExams(results.items?.[0]?.data?.tests)
setData(results.items?.[0]?.data)
setLoading(false)
  });
    }
    fetchData();
}, []);
const [banner, setBanner] = useState();
useEffect( () => {
  if(data?.banner?.[language])
  getAsset(data?.banner?.[language]).then((srcImage)=>{
    setBanner(srcImage);
  });
  
}, [data?.banner?.[language]]);
////console.log(presentation)
try{var text = parse(presentation?.[language])}catch($){
  text = presentation?.[language??'fr']
}
  return loading? (<><ShimmerPostDetails card cta variant="SIMPLE" /><ShimmerPostList postStyle="STYLE_FOUR" col={3} row={1} gap={30} /></>) :(
    <div className=" position-relative overflow-hidden" dir={language==='ar'?"rtl":""}>
    {/*<BgShape type="blue"  right="-40px" top="80px" rotate="-140deg"/>*/}
    {/* <BgShape type="yellow"  right="-30px" top="700px" rotate="-150deg"/> */}
    {/*<BgShape type="red"  left="-80px" top="500px" rotate="140deg"/>*/}
      <CoverImg img={banner??cover} />
      <Breadcrumb />
      <Container >
        <MainTitle>Présentation </MainTitle>
        <div>
          <p className="lh-lg text-black-50 text-start ">
            {text??`
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.`}
          </p>
        </div>
        <Row className="py-5 justify-content-center gap-3">
          {exams?.[language]?.length>0?exams?.[language]?.reverse().map((el, index) => {
            el.url=el.title?.includes('DALF') || el.title?.includes('ديلف') ?'delf-et-dalf': el.title?.includes('تي سي أف') ?'tcf' : el.title?.replace('&',"ET");
            return (
              <Col key={index}  md={5} className="mb-4">
                <CityMenuCard menu={el} height="250"/>
              </Col>
            );
          }):tests?.map((el, index) => {
            return (
              <Col key={index}  md={5} className="mb-4">
                <CityMenuCard menu={el} height="250"/>
              </Col>
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default TestsPresentationPage