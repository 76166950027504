import React from 'react'
import { Row,Col,Card} from 'react-bootstrap'


function ResidenceCard({item,order}) {
  return (
    <Card className='mb-3'>
        <Row >
          <Col md={4} xs={order==="last"?{ order: order }:""}>
            <Card.Img
              variant="bottom"
              src={item.img}
              
            />
          </Col>
          <Col md={8}>
            <Card.Body>
              <Card.Title className="fw-bold">
                {item.title}
              </Card.Title>
              <Card.Text className='mt-4'>
                {item.text}
              </Card.Text>
            </Card.Body>
          </Col>
        </Row>
    </Card>
  )
}

export default ResidenceCard