const mediatheques = [
  {
    region: "alger",
    img: "https://images.unsplash.com/photo-1529148482759-b35b25c5f217?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    description:
      "La Mediatheque de l’Institut français d’Algerie à Alger a pour mission de répondre aux besoins de  culture,  de  loisir  et  d’information  sur  la  France  contemporaine.   Elle  s’adresse  à tous les publics :  petits et grands, collégiens, lycéens et ​étudiants, enseignants,  professionnels, retraités...",
    contact: {
      adresse: "07 Rue Capitaine HASSANI, Alger Centre 16000",
      phone: " + 213 (0) 21 73 78 20 ",
      email: "mediatheque.alger@if-algerie.com",
    },
    horaires: {
      fermé:
        "La médiathèque est fermée les dimanches matin (jusqu’à 12h), les jours fériés et le mois d'août)",
      ouvert: [
        {
          periode: "De novembre à avril",
          days: [
            {
              day: "Samedi",
              time: "13h00 à 17h30",
            },
            {
              day: "Dimanche",
              time: "9h00 à 17h30",
            },
            {
              day: "Lundi",
              time: "9h00 à 17h30",
            },
            {
              day: "Mardi",
              time: "9h00 à 17h30",
            },
            {
              day: "Mercredi",
              time: "9h00 à 17h30",
            },
            {
              day: "Jeudi",
              time: "9h00 à 17h30",
            },
          ],
        },
        {
          periode: "De mai à octobre",
          days: [
            {
              day: "Samedi",
              time: " 9h30 à 18h00",
            },
            {
              day: "Dimanche",
              time: " 9h30 à 18h00",
            },
            {
              day: "Lundi",
              time: " 9h30 à 18h00",
            },
            {
              day: "Mardi",
              time: " 9h30 à 18h00",
            },
            {
              day: "Mercredi",
              time: " 9h30 à 18h00",
            },
            {
              day: "Jeudi",
              time: " 9h30 à 18h00",
            },
          ],
        },
        {
          periode: "Pendant le Ramadhan",
          days: [
            {
              day: "Samedi",
              time: " 9h30 à 16h30",
            },
            {
              day: "Dimanche",
              time: " 9h30 à 16h30",
            },
            {
              day: "Lundi",
              time: " 9h30 à 16h30",
            },
            {
              day: "Mardi",
              time: " 9h30 à 16h30",
            },
            {
              day: "Mercredi",
              time: " 9h30 à 16h30",
            },
            {
              day: "Jeudi",
              time: " 9h30 à 16h30",
            },
          ],
        },
      ],
    },
    inscription: {
      intro:
        "Les inscriptions se font à l'accueil de la médiathèque, du dimanche au jeudi de 9h30 à 12h et de 14h à 16h en présentant :",
      documents: [
        "Une photocopie de la CNI ou la photocopie de la carte scolaire pour les 12-18 ans (collégiens et lycéens)",
        "Une  autorisation  parentale  pour  les  personnes  âgées  de  3  ans  et  plus  (moins  de  18 ans)",
        "Deux photos",
        "Un certificat de résidence ou justificatif de domicile",
        "Une attestation de travail pour les enseignants de langue française ou la photocopie du certificat de scolarité pour les étudiants",
      ],
      tarifs: [
        "1000 DA pour les adhérents âgés de 3 à 18 ans (primaire, collège, lycée)",
        "2000 DA pour les étudiants et les enseignants de français",
        "3000 DA pour toutes autres catégories",
      ]
    },
    // ces espaces sont pour la ville d'oran => pour la ville d'alger il n y a pas des espaces
    espaces: [
      {
        espace: "Le kiosque",
        desc: "venez vous informer au quotidien dans le grand hall de l’Institut grâce  à la presse locale, a` nos chaines télévisées et à nos revues internationales.",
      },
      {
        espace: "Espace jeunesse",
        desc: "un grand espace réservée à la jeunesse, avec des ouvrages de fiction, des  documentaires,  de  nombreuses  revues,  des  bandes  dessinées  et  bien  sûr...des mangas !",
      },
      {
        espace: "Espace convivialité",
        desc: "Sur la grande mezzanine de l’Institut, venez découvrir nos fonds consacrés au patrimoine et à la vie en Algérie, en France et dans le monde mais aussi à la vie pratique, au bien-être ou encore au jardinage et à la cuisine.  Des tables sont aussi  réservées  pour  les  lecteurs  munis  d’ordinateurs  portables  avec  une connexion Wifi.",
      },
      {
        espace: "Le  français  pour  tous ",
        desc: " Ouvert  à tous  les  enseignants  (primaire,  secondaire,  universitaire)  ou  futurs  enseignants  de  fran¸cais  mais  aussi  à  tous  les  apprenants,  cet espace offre la possibilité de consulter un fonds riche d’ouvrages, des revues spécialisées et une collection de méthodes de fran¸cais réguli`erement renouvelée, incluant de multiples supports.",
      },
      {
        espace: "Espace  littérature ",
        desc: "Venez  savourer  nos  romans  français,  maghrébins  ou étrangers, mais aussi nos nombreux romans policiers. Vous y trouverez aussi des ouvrages de théorie et critique littéraire, de poésie et de théatre ainsi que de nombreuses bandes dessinées. ",
      },
      {
        espace: "Espace Société et civilisation",
        desc: "La salle offre `a ses usagers un fonds de livres et revues en  libre  accès  riche  et  varié  en  sciences  humaines  (histoire,  littérature, philosophie,religion,  ...)   sciences  sociales  (sociologie,  droit,  économie,  science politique,  ...)   et une documentation scientifique et technique dans des domaines aussi variés que riches:  médecine, informatique, gestion- marketing, ingénierie. . .",
      },
    ],
    // Random ateliers
    ateliers: [
      {
        id: 1,
        title: `Club de lecture Choix Goncourt de l’Algérie`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 2,
        title: `L’heure du conte`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 3,
        title: `Ateliers d’écriture`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 4,
        title: `Ateliers créatif`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 5,
        title: `Ateliers jeux vidéos`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 6,
        title: `Débat d’idées`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
    ],
    culturetheque: `Les  médiathèques  de  l’institut  français  d’Algérie  vous  proposent  de  découvrir  plus  de 5000 documents en ligne sur la France contemporaine.
        L’abonnement  annuel  à l'une  des  médiathèques  de  l’institut  français  d'Algérie,  vous donne un accès gratuit à Culturethèque pendant 1 année ! Inscrivez-vous en ligne et consultez gratuitement culturethèque pendant 3 semaines sur :`,
  },
  {
    region: "oran",
    img: "https://images.unsplash.com/photo-1600431521340-491eca880813?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80",
    description:
      "Notre médiathèque vous accueille dans un espace chaleureux et résolument contemporain.   Sous  les  majestueuses  poutres  en  bois  brut  de  notre  espace  littérature  ou sous la charpente métallique de notre espace convivialité, pour approfondir votreconnaissance du français,pour réviser vos examens ou tout simplement pour lire,  jouer et vous cultiver,  la médiathèque de l’Institut français d’Oran vous ouvre grand ses portes.Nos  médiathécaires  sauront  vous  orienter  et  vous  conseiller  pour  découvrir  tous  nos espaces et toutes nos ressources : plus de 21 000 ouvrages, de nombreuses revues et d’innombrables ressources numériques.  La médiathèque propose aussi de très nombreuses animations, pour les petits comme pour les grands.",
    contact: {
      adresse: " 112 rue Larbi Ben M’hidi 31009 - Oran, Algérie",
      phone: "+ 213 (0) 41 41 39 22 ",
      email: "mediatheque.oran@if-algerie.com",
    },
    horaires: {
      ouvert: [
        {
          days: [
            {
              day: "Samedi",
              time: "9h00 à 17h30",
            },
            {
              day: "Dimanche",
              time: "9h30 à 17h30",
            },
            {
              day: "Lundi",
              time: "9h30 à 17h30",
            },
            {
              day: "Mardi",
              time: "9h30 à 17h30",
            },
            {
              day: "Mercredi",
              time: "13h00 à 17h30",
            },
            {
              day: "Jeudi",
              time: "13h00 à 17h30",
            },
          ],
        },
      ],
    },
    inscription: {
      intro: "Pour s’inscrire, il faut fournir les documents suivants",
      documents: [
        " Photocopie de la carte d’identit´e nationale (C.I.N)",
        " Une photo",
        " Deux photos ",
        " Carte valable pour une année",
      ],
      tarifs: [
        "2000 DA pour toute personne de moins de 25 ans, lycéens en terminale, étudiants, enseignants de français et retraités.",
        "3000 DA pour toutes les autres catégories",
      ],
    },
    espaces: [
      {
        espace: "Le kiosque",
        desc: "venez vous informer au quotidien dans le grand hall de l’Institut grâce  à la presse locale, a` nos chaines télévisées et à nos revues internationales.",
      },
      {
        espace: "Espace jeunesse",
        desc: "un grand espace réservée à la jeunesse, avec des ouvrages de fiction, des  documentaires,  de  nombreuses  revues,  des  bandes  dessinées  et  bien  sûr...des mangas !",
      },
      {
        espace: "Espace convivialité",
        desc: "Sur la grande mezzanine de l’Institut, venez découvrir nos fonds consacrés au patrimoine et à la vie en Algérie, en France et dans le monde mais aussi à la vie pratique, au bien-être ou encore au jardinage et à la cuisine.  Des tables sont aussi  réservées  pour  les  lecteurs  munis  d’ordinateurs  portables  avec  une connexion Wifi.",
      },
      {
        espace: "Le  français  pour  tous ",
        desc: " Ouvert  à tous  les  enseignants  (primaire,  secondaire,  universitaire)  ou  futurs  enseignants  de  fran¸cais  mais  aussi  à  tous  les  apprenants,  cet espace offre la possibilité de consulter un fonds riche d’ouvrages, des revues spécialisées et une collection de méthodes de fran¸cais réguli`erement renouvelée, incluant de multiples supports.",
      },
      {
        espace: "Espace  littérature ",
        desc: "Venez  savourer  nos  romans  français,  maghrébins  ou étrangers, mais aussi nos nombreux romans policiers. Vous y trouverez aussi des ouvrages de théorie et critique littéraire, de poésie et de théatre ainsi que de nombreuses bandes dessinées. ",
      },
      {
        espace: "Espace Société et civilisation",
        desc: "La salle offre `a ses usagers un fonds de livres et revues en  libre  accès  riche  et  varié  en  sciences  humaines  (histoire,  littérature, philosophie,religion,  ...)   sciences  sociales  (sociologie,  droit,  économie,  science politique,  ...)   et une documentation scientifique et technique dans des domaines aussi variés que riches:  médecine, informatique, gestion- marketing, ingénierie. . .",
      },
    ],
    ateliers: [
      {
        id: 1,
        title: `Club de lecture Choix Goncourt de l’Algérie`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 2,
        title: `L’heure du conte`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 3,
        title: `Ateliers d’écriture`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 4,
        title: `Ateliers créatif`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 5,
        title: `Ateliers jeux vidéos`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 6,
        title: `Débat d’idées`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
    ],
    culturetheque: `Les  médiathèques  de  l’institut  français  d’Algérie  vous  proposent  de  découvrir  plus  de 5000 documents en ligne sur la France contemporaine.
        L’abonnement  annuel  à l'une  des  médiathèques  de  l’institut  français  d'Algérie,  vous donne un accès gratuit à Culturethèque pendant 1 année ! Inscrivez-vous en ligne et consultez gratuitement culturethèque pendant 3 semaines sur :`,
  },
  {
    region: "constantine",
    img: "https://images.unsplash.com/photo-1529148482759-b35b25c5f217?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    description:
      "La  Médiathèque  de  l’institut  français  d’Algérie à Constantine  est ouverte aux grands comme au jeune public.",
    contact: {
      adresse: " 1, Boulevard de l’indépendance – 25001 - Constantine",
      phone: "+ 213 (0) 31-91-25-91 | + 213 (0) 31-91- 24-92 ",
      email: "mediatheque.constantine@if-algerie.com",
    },
    horaires: {
      ouvert: [
        {
          days: [
            {
              day: "Samedi",
              time: "9h30  à 17h15",
            },
            {
              day: "Dimanche",
              time: "9h30  à 17h15",
            },
            {
              day: "Lundi",
              time: "9h30  à 17h15",
            },
            {
              day: "Mardi",
              time: "9h30  à 17h15",
            },
            {
              day: "Mercredi",
              time: "9h30  à 17h15",
            },
            {
              day: "Jeudi",
              time: "9h30  à 17h15",
            },
          ],
        },
      ],
    },
    inscription: {
      intro:
        "Les inscriptions se font à l'accueil de la médiathèque, du dimanche au jeudi de 9h30 à 12h et de 14h à 16h en présentant :",
      documents: [
        {
          category: "Etudiants et lycéens",
          documents: [
            "Un carte d'étudiant ou un certificat de scolarité de l'année en cours.",
            "Un justificatif de domicile",
          ],
        },
        {
          category: "Enfants 6 - 15 ans ",
          documents: [
            "Autorisation parentale légalisée à la mairie",
            "Décharge parentale (formulaire à retirer à la médiathèque)",
            "Extrait d’acte de naissance",
            "Justificatif de domicile des parents (facture sonalgaz, algérie télécom. . . )",
          ],
        },
        {
          category: "Autres",
          documents: [
            "Un  justificatif  de  domicile  (facture  sonalgaz,  algérie  télécom. . . ) ",
          ],
        },
      ],
      tarifs: [
        "1000 DA pour toutes enfants de 6 - 15 ans. ",
        "2000 DA pour les étudiants et les lycéens.",
        "3000 DA pour toutes les autres catégories.",
      ],
    },
    espaces: [
      {
        espace: "Accueil",
        desc: "Inscriptions, renseignements, orientation",
      },
      {
        espace: "Espace presse",
        desc: "5 titres de presse quotidienne régionale et nationale, 19 titres de périodiques français (arts et culture, gastronomie, décoration, actualité. . . )  en consultation sur place.",
      },
      {
        espace: "Fonds arabe ",
        desc: "Un fonds de 125 titres (classiques, romans, poésie. . . )  en langue arabe.",
      },
      {
        espace: "Fonds ados",
        desc: "Un fonds de littérature spécialement destinée aux adolescents",
      },
      {
        espace: "Espace jeunesse",
        desc: [
          "Un  large  choix  de  bandes-dessinées,  albums,  revues,  orientés  exclusivement  vers le public jeune",
          "Une ludothèque proposant une grande sélection de jeux de société",
          "Des animations tout au long de l’année et ateliers a` travers le jeu éducatif, la lecture, le conte ou encore l’outil numérique.",
        ],
      },
      {
        espace: "Le salon",
        desc: [
          "Espace de détente et de confort, lieu paisible par excellence, on y trouve des ouvrages sur les sciences humaines et les arts, les « Beaux livres » et un large choix de bandes- dessinées.",
          "On y propose également un tout nouveau fonds de livres audio, une sélection variée d’ouvrages lus et enregistrés, a` dévorer par les oreilles, confortablement installé dans un fauteuil.",
        ],
      },
      {
        espace: "Espace multimédia",
        desc: {
          intro:
            "doté d’une variété d’équipements des plus récentes technolo- gies, c’est un espace accessible à tous, proposant un accompagnement à la découverte des Nouvelles Technologies de l’Information et de la Communication.",
          services: [
            "Des  ordinateurs  en  libre-accès,  équipés  en  logiciels  de  bureautique  pour  travailler, consulter des DVD, accéder à Culturethèque ou naviguer sur le Web ;",
            "Coin  jeux  vidéo  proposant  une  gamme  variée  de  jeux  sur  consoles  Xbox  One  et Playstation 4.",
            "Casques de Réalité virtuelle Oculus Quest et PSVR, pour vivre une expérience sen- sationnelle à travers les mondes virtuels",
            "Une imprimante 3D, accessible lors d’ateliers dédiés, pour imaginer des objets et les voir naître à partir  d’un  modèle numérique.   Un  temps privilégié  d’initiation à la modélisation, à l’impression 3D et à la culture du « Do It Yourself »",
          ],
        },
      },
      {
        espace: "La bibliothèque de  l’apprenant ",
        desc: {
          intro:
            "section   de   la   médiathèque   spécialement conçue  pour  accompagner  les  apprenants  de  français,  quel  que  soit  leur  niveau,  dans l’apprentissage de la langue.",
          services: [
            "Des  documents  pédagogiques,   méthodes  de  langue,   livres  de  grammaire,   livres d’exercice, supports d’apprentissage numériques, dictionnaires. ",
            "Des  « documents  culturels  » :   livres  de  fiction  ou  documentaire  identifiés  selon leur  niveau  de  langue  et  d’accessibilité.   Ils  sont  classifiés  et  proposés  par  niveau d’apprentissage, du niveau débutant (A1) au niveau indépendant (B2).",
          ],
        },
      },
      {
        espace: " Salle Etude et vie pratique",
        desc: [
          "Vous y retrouverez un fonds bien fourni dans le domaine des sciences sociales, les sciences et techniques, la vie pratique, la gastronomie, l’architecture. . .",
          "C’est également un espace de travail et d’étude ou` l’on peut s’attabler pour préparer un exposé, un examen ou simplement consulter un ouvrage.",
        ],
      },
      {
        espace: "La  salle  des  Pléiades ",
        desc: " outre  une  grande  collection  d’ouvrages  de  cette  édition éponyme consacrant les plus grands classiques de la littérature française, on trouve également dans cette salle une riche sélection d’œuvres littéraires et poétiques contemporaines en langue française, originaires de France, du Maghreb ou d’ailleurs.",
      },
    ],
    // Random ateliers
    ateliers: [
      {
        id: 1,
        title: `Club de lecture Choix Goncourt de l’Algérie`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 2,
        title: `L’heure du conte`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 3,
        title: `Ateliers d’écriture`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 4,
        title: `Ateliers créatif`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 5,
        title: `Ateliers jeux vidéos`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 6,
        title: `Débat d’idées`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
    ],
    culturetheque: `Les  médiathèques  de  l’institut  français  d’Algérie  vous  proposent  de  découvrir  plus  de 5000 documents en ligne sur la France contemporaine.
        L’abonnement  annuel  à l'une  des  médiathèques  de  l’institut  français  d'Algérie,  vous donne un accès gratuit à Culturethèque pendant 1 année ! Inscrivez-vous en ligne et consultez gratuitement culturethèque pendant 3 semaines sur :`,
  },
  {
    region: "annaba",
    img: "https://images.unsplash.com/photo-1529148482759-b35b25c5f217?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    description:
      "Lieu de vie pour tous, La médiathèque de l’institut français d’Algérie à annaba accueille petits, jeunes,  adultes  et  seniors  dans  un  espace  réaménagé,  moderne,  convivial  et  adapté  aux besoins de chacun.",
    contact: {
      adresse: " Bd 1er Novembre 1954, Annaba 23000",
      phone: "+ 213 (0) 38 45 12 50 | + 213 (0) 38 45 12 51 ",
      email: "mediatheque.annaba@if-algerie.com",
    },
    horaires: {
      ouvert: [
        {
          days: [
            {
              day: "Samedi",
              time: "9h30 à 12h00 et de 13h00 à 17h00",
            },
            {
              day: "Dimanche",
              time: "9h30 à 17h00",
            },
            {
              day: "Lundi",
              time: "9h30 à 17h00",
            },
            {
              day: "Mardi",
              time: "13h00 à 17h00",
            },
            {
              day: "Mercredi",
              time: "9h30 à 17h00",
            },
            {
              day: "Jeudi",
              time: "9h30 à 12h00 et de 13h00 à 17h00",
            },
          ],
        },
      ],
    },
    inscription: {
      intro:
        "Pour emprunter des documents une inscription préalable est nécessaire.  Inscrivez-vous en fournissant le dossier suivant :",
      documents: [
        " Un certificat de résidence ou justificatif de domicile.",
        " Une photo d'identité.",
        " Deux photos. ",
        " Une photocopie d’une pièce d’identité nationale.",
      ],
      tarifs: [
        "1000 DA tarif enfants (de 6 à 12 ans).",
        "2000 DA tarif réduit (´étudiants, retraités et chômeurs) sous réserve de présentation d’un justificatif.",
        "3000 DA plein tarif.",
        "Un formulaire d’inscription que vous téléchargez en ligne ou à renseigner sur place. ",
      ],
      carte: [
        "Vous pouvez renouveler votre carte 15 jours avant l’expiration de celle-ci en présentant: l’ancienne  carte  ;  un  certificat  de  scolarité,  pour  les  élèves étudiants. Les  15  jours seront reconduits sur le compte de la nouvelle carte.",
        "La  carte  est  valable  un  an.   Elle  vous  permet  d'accéder  à la  médiathèque  et  tous  les événements  culturels  de  l’institut.  Vous  pouvez  empruntez  jusqu'à 9 documents  (3  livres, 2 BD, 2 magazines   2 DVD) pour une durée de 15 jours, renouvelable une fois.",
      ],
    },
    // Random ateliers
    ateliers: [
      {
        id: 1,
        title: `Club de lecture Choix Goncourt de l’Algérie`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 2,
        title: `L’heure du conte`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 3,
        title: `Ateliers d’écriture`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 4,
        title: `Ateliers créatif`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 5,
        title: `Ateliers jeux vidéos`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 6,
        title: `Débat d’idées`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
    ],
    culturetheque: `Les  médiathèques  de  l’institut  français  d’Algérie  vous  proposent  de  découvrir  plus  de 5000 documents en ligne sur la France contemporaine.
        L’abonnement  annuel  à l'une  des  médiathèques  de  l’institut  français  d'Algérie,  vous donne un accès gratuit à Culturethèque pendant 1 année ! Inscrivez-vous en ligne et consultez gratuitement culturethèque pendant 3 semaines sur :`,
  },
  {
    region: "tlemcen",
    img: "https://images.unsplash.com/photo-1529148482759-b35b25c5f217?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    description:
      "La Médiathèque de l’institut français d’Algérie à Tlemcen, pôle culturel de l’ambassade de France en  Alg´erie  est  un  service  public  ouvert  au  grand  comme  au  jeune  public,  elle  contribue et a pour vocation aux loisirs,  à la culture,  répond au besoin d’information sur la France contemporaine.",
    contact: {
      adresse: "-------------------------------",
      phone: "---------------------------------",
      email: "---------------------------------",
    },
    horaires: {
      ouvert: [
        {
          days: [
            {
              day: "Samedi",
              time: "10h00 à 17h30",
            },
            {
              day: "Dimanche",
              time: "10h00 à 17h30",
            },
            {
              day: "Lundi",
              time: "10h00 à 17h30",
            },
            {
              day: "Mardi",
              time: "10h00 à 17h30",
            },
            {
              day: "Mercredi",
              time: "10h00 à 17h30",
            },
            {
              day: "Jeudi",
              time: "10h00 à 17h30",
            },
          ],
        },
      ],
    },
    inscription: {
      intro:
        "La carte d'adhérent est immédiatement fabriquée et délivrée sur simple présentation :",
      documents: [
        " Une  copie  d’une  pièce  d'identité",
        " Une  photo  récente",
        " Un  justificatif  de  domicile ",
      ],
      acquittement: "après acquittement des frais d’inscription.",
      adhesion:
        "L'adhésion est valable 12 mois, de date à date (par exemple du 2 mars 2022 au 2 mars 2023. . . ).",
      tarifs: [
        {
          type: "Tarif Scolaire",
          montant:
            "1000 DA pour les enfants des niveaux primaire, moyen et secondaire avec l’bligation d'adhésion de l’un des parents, grands parents ou frère/soeur de + de 18 ans.",
        },
        {
          type: "Tarif Enfant ",
          montant:
            "1000 DA pour les enfants de 3 à 7 ans avec l’obligation d'adhésion de l’un des parents, grands parents ou frère/soeur de + de 18 ans.",
        },
        {
          type: "Tarif Réduit",
          montant:
            "2000 DA pour les étudiants, enseignants de français, retraités ",
        },
        {
          type: "Tarif Adulte",
          montant: "3000 DA",
        },
        {
          type: "Tarif Adulte",
          montant: "3000 DA",
        },
        {
          type: "Pass Famille 4 personnes",
          montant: "6000 DA",
        },
        {
          type: "Pass Famille 5 personnes et +",
          montant: "5000 DA",
        },
        {
          type: "Tarif groupe",
          montant: "réduction de 10% `a 50% à partir du plein tarif",
        },
      ],
      pret: [
        "2 bandes dessinées (1 semaine).",
        "1 cédérom (1 semaine) ou 1 disque (1 semaine).",
        "4 livres (1 mois).",
        "2 magazines (1 semaine).",
        "1 film de fiction ou un documentaire (1 semaine).",
        "1 livre numérique pour 10 jours.",
      ],
    },
    espaces: [
      {
        espace: "Rez-de-chaussée",
        desc: "un coin Kiosque, CD-Rom et jeunesse, qui propose une diversité de livres, DVD, revues, BD et Mangas, et une nouveauté avec la création d’un espace Petite enfance.",
      },
      {
        espace: "Premier ​étage",
        desc: "un espace bulle permettant aux étudiants de travailler dans le calme, cette bulle englobe aussi un espace internet, la salle des sciences humaines et sociales, actualité et vie pratique.",
      },
      {
        espace: "Second  étage",
        desc: "des  romans  pour  tous  les  goûts,  biographies,  BD  adultes,  la  bibliothèque de l’apprenant, un espace CD, une ludothèque avec des jeux de société et un espace jeux vidéo.",
      },
    ],
    // Random ateliers
    ateliers: [
      {
        id: 1,
        title: `Club de lecture Choix Goncourt de l’Algérie`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 2,
        title: `L’heure du conte`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 3,
        title: `Ateliers d’écriture`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 4,
        title: `Ateliers créatif`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 5,
        title: `Ateliers jeux vidéos`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
      {
        id: 6,
        title: `Débat d’idées`,
        description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text`,
      },
    ],
    culturetheque: `Les  médiathèques  de  l’institut  français  d’Algérie  vous  proposent  de  découvrir  plus  de 5000 documents en ligne sur la France contemporaine.
        L’abonnement  annuel  à l'une  des  médiathèques  de  l’institut  français  d'Algérie,  vous donne un accès gratuit à Culturethèque pendant 1 année ! Inscrivez-vous en ligne et consultez gratuitement culturethèque pendant 3 semaines sur :`,
  },
];


export default mediatheques