import React from 'react'
import {Container} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import CoverImg from 'components/General/CoverImg'
import culture from "../../assets/img/culture.jpg"

const LoginPage = () => {
  return (
    <>
    <CoverImg img={culture}/>
    <Breadcrumb/>
    <Container>
        <div className="arc-en-ciel">
            {/* <div data-module="student" data-action="identify-panel" data-param-redirect="" data-param-tag-active="login" ></div> */}
            {/* <div data-module="student" data-action="identify-panel" data-param-redirect="http://localhost:3000/culture//mon-compte" data-param-tag-active="login" data-param-reminder-url="identification"></div> */}


            <div data-module="student"
            data-action="student-controls"
            data-param-login-link="/connexion"
            data-param-logout-link="students/logout"
            data-param-account-link="mon-compte"
            data-param-my-cart-link="/panier"
            data-redirect-url-after-logout = "/connexion"
            className='mb-5'></div>

            <div data-module="student" data-action="identify-panel" data-param-redirect="/mon-compte" data-param-tag-active="login" data-param-reminder-url="identification"></div>
            {/* <div data-module="student" data-action="password-update"></div> */}
            {/* <div data-module="student" data-action="reminder" data-param-login-url="students/login"></div> */}
            
        </div>
    </Container>
    </>
  )
    
}

export default LoginPage