import { FaCheckCircle } from "react-icons/fa";
import TestCard from 'components/TestEtExamens/TestCard';
import { Container, Row, Col} from "react-bootstrap";

const CourseDetails = ({data})=>{
  return (
      <Col  className="mb-4 mb-lg-0 cursor-pointer">
    {data?.map((detail,index)=><TestCard key={index} test={detail}/>)}
    </Col>

  );
}

export default CourseDetails