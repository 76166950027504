import {login} from "./auth"
import { baseUrl } from "./consts"
import { mediathequeRoutes } from "./apiRoutes"



export async function getMediathequeRegion(region) {
  //console.log(region)
  const response=await login();
if (response.ok) {
  var data = await response.json();
  //console.log(data)
  const res = await fetch(`${baseUrl}${mediathequeRoutes.filter(x=>x.region===region)[0].url}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Authorization":"Bearer "+data['access_token']
    },
    
  });

  const json = await res.json();

  if (json.errors) {
    console.error(json.errors);
    throw new Error("Failed to fetch API");
  }
  //console.log(json);
  return json;
}
}



