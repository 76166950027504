import React, {useState,useEffect, useContext} from "react";
import { Container} from "react-bootstrap";
import Breadcrumb from "components/breadcrumb/breadcrumb";
import SocialSection from "components/General/SocialSection";
import MainTitle from "components/General/MainTitle";
import HistoiryCard from "components/Institut/HistoiryCard";
import CoverImg from "components/General/CoverImg";
import BgShape from "components/General/BgShape";
import institut from "../../assets/img/essa-img.jpg"
import presentations from "data/institut/presentations";
import { useGlobal } from "contexts/GlobalContext";
import { fetchAPIInstitut, fetchAPIInstitutDetails } from "services/institut-region";
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList,ShimmerSectionHeader } from 'react-shimmer-effects';
import { LanguageContext } from "services/LanguageContext";
// import InstitutCarousel from "components/Institut/InstitutCarousel";


export default function InstitutVillePresentationPage({ville}) {
  const {changeBackgroundColor} = useGlobal()
  const [institut, setInstitut] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const { language } = useContext(LanguageContext);
  useEffect(()=>{
    changeBackgroundColor("white")
  },[])
  useEffect( () => {
    function fetchData() {
        fetchAPIInstitut(ville).then((items)=>{
        //const res = await fetchAPIInstitutDetails(results.posts[0].id)
        //console.log(items)
        var el=items.items[0]?.data;
        //console.log(el);
        getAsset(el?.Image.iv[0]).then((url)=>{
          el.srcImage=url;
          el.id=items.items[0]?.id;
          setInstitut(el);
          setLoading(false)
      });
        
      });
    }
      
setPresentation(cityPresentation.text)
changeBackgroundColor("white")
    fetchData();
}, []);
   // get the presentation data & update the state
   const cityPresentation = presentations.filter(el=>el.region === ville)[0]
   const [presentation,setPresentation] = useState([])
  //  useEffect(()=>{
  //    setPresentation(cityPresentation.text)
  //  },[])
   // vérifier si la ville actuelle commence par un voyelle 
   const accentCities = ["alger","oran","annaba"]
   const isAccent = ()=>accentCities.indexOf(ville) !== -1
   const [banner, setBanner] = useState();
useEffect( () => {
  if(institut?.banner?.[language])
  getAsset(institut?.banner?.[language]).then((srcImage)=>{
    setBanner(srcImage);
  });
  
}, [institut?.banner?.[language]]);
   try{var text = parse(institut?.Description[language??'fr'])}catch($){
    text = institut?.Description[language??'fr']
  }
  return loading? <ShimmerPostDetails card cta variant="EDITOR" /> :(
    <div className=" position-relative" dir={language==='ar'?"rtl":""}>
         {/*<BgShape type="red"  right="-70px" top="160px" rotate="-140deg"/>*/}
         {/*<BgShape type="yellow"  left="-80px" bottom="200px" rotate="150deg"/>*/}
        <CoverImg img={banner}/>
        <Breadcrumb/>
        <Container >
            <MainTitle>{institut?.Title[language??'fr'] ??`
  Institut français ${isAccent() ? "d'" : 'de '}${ville[0].toUpperCase() + ville.slice(1)}` }</MainTitle>
            <HistoiryCard img={institut?.srcImage??cityPresentation.img}>
                {
                  text??cityPresentation.text.map(el=><p key={el.id}>{el.paragraph}</p>)
                }
            </HistoiryCard>
            {/* <div className="mt-5">
                <MainTitle>L'institut En Images</MainTitle>
                <InstitutCarousel className="mb-5"/>
            </div> */}
        </Container>
            <SocialSection institut={true} ville={ville}/>
    </div>
  );
}
