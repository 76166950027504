import React, { useContext, useEffect, useState } from "react";
import { Container} from "react-bootstrap";
import Breadcrumb from "components/breadcrumb/breadcrumb";
import SocialSection from "components/General/SocialSection";
import MainTitle from "components/General/MainTitle";
import institut from "../../assets/img/essa-img.jpg"
import CoverImg from "components/General/CoverImg";
import styles from './institut.module.css'
import BgShape from "components/General/BgShape";
import {equipes}  from "data/institut/equipes"
import TeamGrid from "components/Cities/TeamGrid";
import { useGlobal } from "contexts/GlobalContext";
import { getEquipe, getEquipeRegion } from 'services/equipe'
import { getAsset } from "services/assets";
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList,ShimmerSectionHeader } from 'react-shimmer-effects';
import { LanguageContext } from "services/LanguageContext";
export default function InstitutVilleEquipePage({ville}) {
  const {changeBackgroundColor} = useGlobal()
  const [loading, setLoading] = React.useState(true);
  const { language } = useContext(LanguageContext);
  useEffect(()=>{
    changeBackgroundColor("grey")
  },[])
  const [team,setTeam] = useState([])
  // get region team and update the 'team' state
  // NB : for a real server => use async fct to fetch the data and update the state
  const regionTeam = equipes.find(el=>el.region===ville).equipe
  useEffect(()=>{
    setTeam(regionTeam)
  },[])
  useEffect(()=>{
    changeBackgroundColor("white")
  },[])
  const [data, setData] = useState()
  useEffect( () => {
    function fetchData() {
      getEquipeRegion({region:ville}).then((results)=>{
        results?.items?.[0]?.data?.equipe?.[language]?.map(e => {
            
            var img = e?.Image?.iv? e?.Image?.iv[0]: e?.Image?.[language]?e?.Image?.[language][0]:e?.Image?e?.Image[0]:''; //todo default image
            e.src=img;
            
            
});
setData(results?.items?.[0]?.data?.equipe?.[language]);   
setLoading(false) 
} )} 
    fetchData();
}, []);
const [banner, setBanner] = useState();
  useEffect( () => {
    if(data?.banner?.[language])
    getAsset(data?.banner?.[language]).then((srcImage)=>{
      setBanner(srcImage);
    });
    
  }, [data?.banner?.[language]]);
//console.log(data)
  return loading? <ShimmerSimpleGallery imageType="circular" imageHeight={200} caption /> :(
    <>
     {/*<BgShape type="red"  right="-80px" top="200px" rotate="-140deg"/>*/}
     {/*<BgShape type="blue"  left="-80px" top="850px" rotate="140deg"/>*/}
     {/*<BgShape type="yellow"  right="-50px" top="1200px" rotate="-150deg"/>*/}
     {/*<BgShape type="red"  left="-80px" top="2000px" rotate="140deg"/>*/}
       
        <CoverImg img={banner??institut}/>
        <Breadcrumb/>
            <Container className="px-3 z-10" dir={language==='ar'?"rtl":""}>
                <MainTitle>Notre Equipe</MainTitle> 
                
                {
                  data?.length>0?
                    data?.map((el,i)=>{
                        return <div key={i} className="text-center">
                                    <h4 className={styles.title}>{el.Titre}</h4>
                                    <TeamGrid membres={el.members}/>
                                </div>
                    }):team?.map((el,i)=>{
                      return <div key={i} className="text-center">
                                  <h4 className={styles.title}>{el.Titre}</h4>
                                  <TeamGrid membres={el.members}/>
                              </div>
                  })
                }
            </Container>
        
            <SocialSection institut={true} ville="alger"/>



    </>
  );
}
