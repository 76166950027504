import React, {useEffect} from 'react'
import mission from "../../assets/img/alger3.jpg"
import { Container} from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css"
import CoverImg from 'components/General/CoverImg'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'



export default function MissionTwo() {
  useEffect(()=>{
    window.scrollTo({top:0})
  },[])
  return (
    <>
      <CoverImg img={mission} />
      <Breadcrumb />
      <Container className="lh-lg mb-5">
        <MainTitle> Mission de coopération institutionnelle</MainTitle>
        <div className="intro text-black-50">
          <p className="mt-5 lh-lg text-start">
            Un des objectifs de l’Institut français d’Algérie est de nouer des
            relations fortes et positives entre les administrations françaises
            et algériennes. Il s’agit notamment d’encourager le dialogue entre
            les sociétés civiles des deux rives afin de développer des projets
            de citoyenneté méditerranéenne. Le respect de l’environnement, la
            promotion de l’employabilité des jeunes, la défense des droits
            humains et la réduction des vulnérabilités sociales sont au cœur de
            cette démarche.
          </p>
          <p className="lh-lg text-start">
            L’institut français d’Algérie mobilise des moyens très diversifiés
            pour la mise en œuvre des projets déposés : mise à disposition du
            meilleur de l’expertise française, soutiens financiers, ateliers de
            formation et de renforcement de capacités, immersion dans des
            réseaux d’échanges.
          </p>
          <p className="lh-lg text-start">
            Cette aventure commune a permis l’émergence de projets emblématiques
            tels que :
            <ul>
              <li>Le plan national de détection de l’autisme</li>
              <li>
                Le soutien aux sports urbains et à la féminisation des pratiques
                sportives
              </li>
              <li>
                Le soutien aux politiques territoriales de gestion des zones
                humides
              </li>
              <li>
                Le soutien à la préservation du patrimoine du bâti en terre.
              </li>
            </ul>
            Ces quelques exemples contribuent à la construction d’un avenir
            commun plus sûr, plus juste et plus durable.
          </p>
        </div>
      </Container>
    </>
  );
}
