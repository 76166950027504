import React, { useContext, useEffect, useState } from 'react'
import CoverImg from 'components/General/CoverImg'
import { Container , Row,Col,Button,Badge,ListGroup,Table} from 'react-bootstrap'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import cover from '../../assets/img/cours-de-français.jpg'
import { Link,useLocation } from 'react-router-dom'
import BgShape from 'components/General/BgShape'
import MainTitle from 'components/General/MainTitle'
import CourseCard from 'components/cours de francais/CourseCard'
import styles from "./CoursDeFrançais.module.css"
import { getCour, getCoursRegion } from 'services/cours'
import { getAsset } from "services/assets";
import parse from 'html-react-parser'
import CourseDescription from 'components/cours de francais/CourseDescription';
import CoursePrice from 'components/cours de francais/CoursePrice';
import CourseDetails from 'components/cours de francais/CourseDetails';
import CoursHorairesCard from 'components/cours de francais/CoursHorairesCard';
import SubcourseAccordion from 'components/cours de francais/SubcourseAccordion';
import ContactCard from 'components/General/ContactCard';
import DossierInscriptionCard from 'components/General/DossierInscriptionCard';
import InscriptionCard from 'components/General/InscriptionCard';
import { useParams } from 'react-router-dom';
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext'
function DetailCours({region}) {
    const {id} = useParams();

    const [cour,setCour] = useState();
    const [banner,setBanner] = useState();
    const [loading, setLoading] = React.useState(true);
    const { language } = useContext(LanguageContext);
    useEffect( () => {
      function fetchData() {
        //console.log(region)
        //console.log(id)
        if(region==='national'){
        getCour({region,id}).then((e)=>{
          
              var img = e?.data.Image?.iv? e?.data.Image?.iv[0]: e?.data.Image?.[language]?e?.data.Image?.[language][0]:''??''; //todo default image
    getAsset(img).then((srcImage)=>{
      e.data.srcImage=srcImage;
      setCour(e.data);});
      setLoading(false)
  });}
          else{
            getCoursRegion(region).then((results)=>{
              results?.items?.map(e => {
              if(e?.data?.Titre?.[language]?.trim()?.toLowerCase()===id.trim().toLowerCase()){
                //console.log(e)
              var img = e?.data.Image?.iv? e?.data.Image?.iv[0]: e?.data.Image?.[language]?e?.data.Image?.[language][0]:''??''; //todo default image
    getAsset(img).then((srcImage)=>{
      e.data.srcImage=srcImage;
      setCour(e.data);
      setLoading(false)});
    }
  });
  });
          }
      }
      
      fetchData();
    }, [region,id]);

    useEffect( () => {
      if(cour?.banner?.[language])
      getAsset(cour?.banner?.[language]).then((srcImage)=>{
        setBanner(srcImage);
      });
      
    }, []);
    // get all course of the region
    //const regionCourses = cours.filter(cour=> cour.region ===region)[0].cours
    
    // get the selected course
    
    // get the course title for breadcrumb (when it's not available in the selected city)
    //let brdCourseTitle = cours.find(cr=>cr.region ==="national").cours.find(cr=>cr.url===id).title
    //console.log(cour)
    try{var text = parse(cour?.Description?.[language??'fr'])}catch($){
      text = cour?.Description?.[language??'fr']??''
    }
  return loading? <ShimmerPostDetails card cta variant="EDITOR" /> :(
    <div
      className="position-relative overflow-hidden pb-1 bg-grey z-10" dir={language==='ar'?"rtl":""}
    >
      {/*<BgShape type="red"  right="-50px" top="800px" rotate="-150deg"/>*/}
      {/*<BgShape type="yellow"  left="-80px" top="500px" rotate="140deg"/>*/}
      {/*<BgShape type="blue"  left="-80px" top="2000px" rotate="140deg"/>*/}
      <CoverImg img={cour?.srcImage??cover} />
      <Breadcrumb brd={cour?.Titre?.[language] || ""}/>

      <Container style={{ zIndex: "2" }} >
        {!cour?
        <div className={styles["no-course"]}> Ce cours n’est pas encore
disponible pour cette ville. </div>
        : 
        <Row className='my-3'>
            <MainTitle>{cour?.Titre?.[language]}</MainTitle>
            <Col md={8}>
                {/*<div className="mb-4">
                    <img src={cour?.srcImage} className="w-100" alt={cour?.Titre?.[language]}></img>
                </div>*/}
                <CourseDescription description={text}/>
                <CourseDetails data={cour?.details}/>
                {/* <div>
                    <div className="text-start">
                        <h5 className='fw-bold'>Cours de Français Langue Etrangère (FLE)</h5>
                        <ul className="list-unstyled text-start">
                            <li className="bg-white p-3 rounded my-3">
                            2h x 2 séances par semaine pendant 10 semaines (40h)
                            </li>
                            <li className="bg-white p-3 rounded my-3">
                            Test de positionnement oral et écrit
                            </li>
                            <li className="bg-white p-3 rounded my-3">Manuel + CD audio</li>
                            <li className="bg-white p-3 rounded my-3">
                            Gratuit´e à l'adhésion de la médiathèque de l’Institut
                            français pendant un an
                            </li>
                        </ul>
                    </div>
               
                </div> */}
                {/* Subcourses */}
                {cour?.subcourses?.map((sub)=><SubcourseAccordion subcourse={sub}/>)}
                {/* Price (only for cities) */}
                {region!=="national" ? <CoursePrice data={cour?.Tarifs?.[language]}/>:null}
            </Col>
            <Col md={4}>
                {region === "national" ? (
                <InscriptionCard region={region} url={cour?.Titre?.[language]??'#'} type="cours"/>
                ) : (
                <>
                    <InscriptionCard region={region} url={cour.url} type="cours" course={id} title={cour?.title} aecid={cour?.idAec}/>
                    <DossierInscriptionCard price={cour?.['Frais-inscription']?.iv} content={cour?.dossier?.[language]}/>
                    {/*<CoursHorairesCard data={cour?.Horaires?.[language]??cour?.Horaires?.iv}/>*/}
                    <ContactCard data={cour?.Contact?.[language]}/>
                </>
                )}
            </Col>
        </Row>
        
        }
      </Container>
    </div>
  );
}

export default DetailCours