import React,{useState} from 'react'
import { Button } from 'react-bootstrap'
import { HiMail } from 'react-icons/hi';
import Modal from "../../pages/newsletter/Modal"
import NewsLetterForm from 'pages/newsletter/NewsLetterForm';
import NewsLetterModal from 'pages/newsletter/NewsLetterModal';


function NewsletterButton({size}) {
    const [isOpen,setIsOpen] = useState(false)
  return (
    <>
        <Button
                variant="primary"
                size={size}
                style={{
                    borderRadius: "0",
                    backgroundColor: "#3558a2",
                    whiteSpace: "nowrap",
                    width: "100%",
                    // height: "40px",
                    padding:"0.8rem"
                }}
                onClick={()=>setIsOpen(true)}
        >
           <HiMail/> S’abonner à la newsletter
            
            </Button>
            <NewsLetterModal open={isOpen} onClose={()=>setIsOpen(false)}>
                <NewsLetterForm onClose={()=>setIsOpen(false)}/>
            </NewsLetterModal>
    </>
  )
}

export default NewsletterButton