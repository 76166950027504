import React,{useState,useEffect, useContext} from 'react'
import {Container} from 'react-bootstrap'
import styles from "./EtudierEnFrance.module.css"
import campus from "../../assets/img/Campus.png";
import Bandeau from 'components/Etudes/Bandeau'
import Breadcrumb from 'components/breadcrumb/breadcrumb'
import MainTitle from 'components/General/MainTitle'
import EtudesCarousel from 'components/Etudes/EtudesCarousel'
import EventsGrid from 'components/General/EventsGrid'
import BgShape from 'components/General/BgShape';
import InteractiveMapGrid from 'components/Etudes/InteractiveMapGrid';
// import LeafletDzMap from 'components/Etudes/LeafletDzMap';
import { getEtude } from 'services/etudes'
import { getAsset } from 'services/assets'
import parse from 'html-react-parser'
import { ShimmerTitle,ShimmerSimpleGallery,ShimmerPostDetails,ShimmerPostList } from 'react-shimmer-effects';
import { LanguageContext } from 'services/LanguageContext';
function CampusFrance({region}) {
    const [data, setData] = useState()
    const [loading, setLoading] = React.useState(true);
    const { language } = useContext(LanguageContext);
    useEffect( () => {
      function fetchData() {
        getEtude('national').then((results)=>{
          //console.log(results?.items?.filter(x=>x.data?.slug.iv.toLowerCase()==='campus-france'))
setData(results?.items?.filter(x=>x.data?.slug.iv.toLowerCase()==='campus-france')?.[0]?.data); 
setLoading(false)   
} )} 
      fetchData();
  }, []);
  const [srcImage,setSrcImage] = useState();
  React.useEffect( () => {

    function fetchData() {
              
            var img = data?.Image?.iv? data?.Image?.iv[0]: data?.Image?.[language]?data?.Image?.[language][0]:data?.Image?data?.Image[0]:''; //todo default image
            
      if(img)
  getAsset(img).then((srcImage)=>{
//console.log(srcImage)
    setSrcImage(srcImage);
    setLoading(false)
        })
      }
       fetchData() 
    },[data]);
  
//console.log(data)

try{var text = parse(data?.Description?.[language])}catch($){
  text = data?.Description?.[language]
}
  return loading? <ShimmerPostDetails card cta variant="SIMPLE" /> :(
    <div className=" position-relative overflow-hidden" dir={language==='ar'?"rtl":""}>
            {/*<BgShape type="red"  right="-60px" top="500px" rotate="-140deg"/>*/}
            {/*<BgShape type="yellow"  right="-50px" bottom="200px" rotate="-150deg"/>*/}
            {/*<BgShape type="blue"  left="-80px" bottom="400px" rotate="140deg"/>*/}
            <EtudesCarousel type="campus france"/>
            <Breadcrumb />
            <Container >
                <div>
                    <MainTitle className={`my-5 mb-4`}>{data?.Titre?.[language]??'Campus France'}</MainTitle>
                    {text??
                    <p className='lh-lg text-black-50 text-start'>
                    Campus France Algérie est un service de l’Institut français d’Algérie, déployé dans les 5 antennes de l’Institut (Alger, Oran, Annaba, Tlemcen, Constantine) et ouvert aux Algériens et aux étudiants internationaux résidant en Algérie qui souhaitent poursuivre leurs études supérieures en France.

Campus France Algérie a pour mission d’informer et d’orienter les étudiants sur les études en France à travers :

-       - un accueil de qualité dans les antennes de l’Institut Français d’Algérie

-        - une procédure dématérialisée et transparente « Études en France » permettant la création d’un seul dossier pour plusieurs candidatures

- des agents à l’écoute pour conseiller les candidats via la messagerie Études en France.

-       Les espaces Campus France, outre leur mission de suivi de la campagne Études en France annuelle, proposent des séances d’information sur le système d’enseignement supérieur français, des ateliers d’aide à l’orientation et des ateliers CV et lettres de motivation, accessibles gratuitement dans les cinq villes.

La procédure Campus France est obligatoire pour toute inscription dans une école ou une université française.
                    </p>}
                </div>
                {/* Interactive map */}
                <InteractiveMapGrid ville={region}/>
                {/* <LeafletDzMap/> */}
            </Container>

            <Bandeau link="https://www.algerie.campusfrance.org/" className={styles.bandeau} >
                <div className='d-flex justify-content-center align-items-center flex-column'>
                    <img src={campus} alt='campus france' className={styles.campus}/>
                    <p className='fs-5 my-0 text-center mt-2'>{data?.Citation?.[language]??"Retrouvez toutes les informations sur le site officiel de <span className='red-ifa fw-bold'>Campus France Algérie</span> "}</p>                </div> 
            </Bandeau>

            {/*<Container>
                <MainTitle className={`${styles.title} mb-5  mt-5`}>Agenda</MainTitle>
                <EventsGrid type="Campus France" region={region&&region}/>
  </Container>*/}
      </div>
    
  )
}

export default CampusFrance